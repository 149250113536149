
import { Prescription } from '@/models/Prescription'
import {Component, Vue, Watch } from 'vue-property-decorator'
import {RefillRequestBatch} from '@/models/RefillRequestBatch';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import RefillRequestBatchHistory from '@/components/RefillRequestBatch/RefillRequestBatchHistory.vue';
import { DatePicker } from 'element-ui'
import moment from 'moment';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';

@Component({
    name: 'RefillRequestBatchPage',
    components: {
        [DatePicker.name]: DatePicker,
        PrescriptionsListComponent,
        RefillRequestBatchHistory
    }
})
export default class RefillRequestBatchPage extends Vue {
    private items:Array<Prescription> = new Array<Prescription>();
    private startDate : Date | null = null;
    private endDate : Date | null= null;
    
    get disableCreate():boolean {
        return !this.items?.length;
    }

    created(){
        this.endDate = moment().toDate();
        this.startDate = moment().subtract(1, 'month').toDate();
    }
    
    private isLoading = false;
    loadData() {
        if(this.startDate === null || this.endDate === null || moment(this.startDate).isAfter(this.endDate)) return;
        
        this.isLoading = true;
        this.$http.get<Array<Prescription>>('RefillRequestBatch/elligible-for-refill-request', {params: {
            startDate: this.startDate, endDate: this.endDate,
        }})
        .then(res => {
            this.items = res.data?.map(rx => new Prescription(rx)) || new Array<Prescription>();
        })
        .catch(err => {
            console.error('Error loading elligible rxs for refill request', {err, response: err.response});
        })
        .finally(() => {
            this.isLoading = false;
        })
    }
    
    confirm(){
        this.$bvModal.msgBoxConfirm('Are you sure you want to request a refill for all items in the list?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
        .then(value => {
            if (!value) return;
            this.createBatch();
        })
        .catch(err => {
            console.error("Error caught on Clear button.", err);
        });
    }

    private isSaving = false;
    createBatch(){
        this.isSaving = true;
        this.$http.post<RefillRequestBatch>('RefillRequestBatch', this.items)
        .then(res => {
            if(res.data?.id) {
                this.loadData();
                this.$notification(NotificationOptions.notificationOptionsPreset("Refill Request is being executed in batch for all items in the list.", NotificationOptions.NotificationTypes.success))
            }
        })
        .catch(err => {
            console.error('Error creating new refill request batch.', {err, response: err.response});
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Refill Request Batch."));
        })
        .finally(() => {
            this.isSaving = false;
        })
    }

    showHistory(){
        this.$bvModal.show('historyModal');
    }
}
