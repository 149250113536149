import { render, staticRenderFns } from "./NonGSDDDrugDetailsPage.vue?vue&type=template&id=43d7e8de&scoped=true"
import script from "./NonGSDDDrugDetailsPage.vue?vue&type=script&lang=ts"
export * from "./NonGSDDDrugDetailsPage.vue?vue&type=script&lang=ts"
import style0 from "./NonGSDDDrugDetailsPage.vue?vue&type=style&index=0&id=43d7e8de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d7e8de",
  null
  
)

export default component.exports