export enum DeliveryCode {
    Standard,
    Overnight,
    WillCall,
    TwoDay,
    StandardOvernight,
    FirstClass,
    USPSPriority,
    Delivery
}

export namespace DeliveryCode{
    export class Tools {
        static getMostImportant(codes:Array<DeliveryCode | null> | null) : DeliveryCode {
            if(!codes?.length) return DeliveryCode.Standard;

            if (codes.includes(DeliveryCode.WillCall))
                return DeliveryCode.WillCall;
            else if (codes.includes(DeliveryCode.Overnight))
                return DeliveryCode.Overnight;
            else if (codes.includes(DeliveryCode.TwoDay))
                return DeliveryCode.TwoDay;
            else if (codes.includes(DeliveryCode.StandardOvernight))
                return DeliveryCode.StandardOvernight;
            else
                return DeliveryCode.Standard;

        }
    }
}
