
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { Patient } from '@/models/Patient';
import { BillingFlags, Plan } from '@/models/Plan';
import { PatientPlan, RelationshipCode } from '@/models/PatientPlan';
import Axios from 'axios';
import PlanDetailsPage from '@/pages/Plan/PlanDetailsPage.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { CombinedPlan } from "@/models/CombinedPlan";
import { isNullOrUndefined } from "util";

@Component({
    components: {
        PlanDetailsPage,
        SearchComponent,
        EnumSelector,
    }
})
export default class PatientPlansComponent extends Vue {
    @VModel({ type: Patient, default: new Patient() }) private patient!: Patient;
    @Prop({ default: false }) private showButtonOnly!: boolean;
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: null }) private addTemplate!: CombinedPlan;
    @Prop({ default: "", type: String }) private id!: string;
    private showNewPlan = false;
    private prePopulatePlanString: any = "";

    private insuranceInfos: CombinedPlan[] = [];
    private plans: Plan[] = [];
    private addedPlan: Plan = new Plan();
    private addedPatientPlan: PatientPlan = new PatientPlan();
    private relationshipCodes = RelationshipCode;
    private planFields: any[] = [
        { key: "plan.name", label: "Name", },
        { key: "plan.bin", label: "BIN", },
        { key: "plan.pcn", label: "PCN", },
        { key: "patientPlan.memberId", label: "Member Id", },
        { key: "patientPlan.groupId", label: "Group Id", },
        { key: "patientPlan.cardholderId", label: "Cardholder Id", },
        { key: "patientPlan.relationshipCode", label: "Relationship Code", },
        { key: "patientPlan.active", label: "Active", },
    ];
    private planButtonTitles = {
        new: "",
        clear: "",
        save: "Save Changes",
        cancel: ""
    };

    @Watch("addTemplate")
    addTemplateUpdated(val: CombinedPlan, oldVal: any) {
        if (isNullOrUndefined(val)) return;

        console.log("addTemplate: ", val, oldVal);

        this.addedPatientPlan = new PatientPlan(val.patientPlan);
        this.addedPatientPlan.relationshipCode = RelationshipCode.Cardholder;
        this.addedPlan = new Plan(val.plan);
        this.prePopulatePlanString = val.plan.bin + " " + val.plan.pcn;
        this.$bvModal.show(`Insurance-Add-Modal-${this.id}`);
    }

    @Watch("addedPlan")
    addedPlanUpdate(val: Plan, oldVal: Plan) {
        //Hide new Plan form if a existing one was selected.
        if (val.id) {
            this.showNewPlan = false;
            if (!this.isInventoryAvailable) {
                this.addedPatientPlan.groupId = this.addedPatientPlan.groupId || val.defaultGroupId;
                this.addedPatientPlan.cardholderId = this.addedPatientPlan.cardholderId || val.defaultCardholderId;
                this.addedPatientPlan.personCode = this.addedPatientPlan.personCode || val.defaultPersonCode;
                this.addedPatientPlan.relationshipCode = val.defaultRelationshipCode;
            } else {
                this.addedPatientPlan.groupId = '';
                this.addedPatientPlan.cardholderId = '';
                this.addedPatientPlan.personCode = '';
                this.addedPatientPlan.relationshipCode = 0;
            }
        }
    }

    mounted() {
        this.fetchPlans();
    }

    clear() {
        this.addedPatientPlan = new PatientPlan();
        this.addedPlan = new Plan();
        this.$emit('hide');
    }

    @Watch('patient')
    fetchPlans() {
        if (!this.patient.id) return;

        Axios.get(`insurance/${this.patient.id}`)
            .then(response => {
                this.plans = response.data.plans;
                this.insuranceInfos = response.data.plans.map((item: any, i: number) => {
                    return { plan: item, patientPlan: response.data.patientPlans[i] };
                });
            })
            .catch(error => {
                console.error("Error while loading the insurance plans", { error, response: error?.response });
            });
    }

    updatePlanActivity(p: CombinedPlan) {
        Axios.post<CombinedPlan[]>("/Plan/UpdatePatientPlan", p.patientPlan)
            .then(resp => {

                this.insuranceInfos = resp.data
                this.$emit("plan-saved");
            })
            .catch(err => {
                console.error("Error while updating patient plan activity.", { err, response: err?.response });
            });
    }

    applyPlansToPatient() {
        this.addedPatientPlan.patientId = this.patient.id;
        this.addedPatientPlan.planId = this.addedPlan.id;
        this.addedPatientPlan.relationshipCode = Number(this.addedPatientPlan.relationshipCode);
        let planName = this.addedPlan.name;
        Axios.post(`/Plan/AssignToPatient`, this.addedPatientPlan)
            .then(response => {
                this.$notification(NotificationOptions.notificationOptionsPreset(`Plan ${planName} was assigned to patient successfully.`, NotificationOptions.NotificationTypes.success));
                this.$emit('plan-saved');
                this.fetchPlans();
                this.addedPatientPlan = new PatientPlan();
                this.addedPlan = new Plan();
            }).catch(error => {
            console.error("Error while assigning plan to patient.", { error, response: error?.response });
            this.$notification(NotificationOptions.error(error));
        });

    }

    showPlanForm() {
        this.showNewPlan = true;
        this.addedPlan = new Plan();
    }

    onPlanCreated(newPlan: Plan) {
        this.addedPlan = newPlan;
        this.showNewPlan = false;
    }

    get isValid(): boolean {
        return !!(this.patient?.id);
    }

    get isInventoryAvailable(): boolean {
        let hasInventory = this.addedPlan.inventoryQuantityAvailable > 0;
        let isConnectiveRx = (this.addedPlan.flags & BillingFlags.ConnectiveRx) != 0;
        let isArcutisCopay = (this.addedPlan.flags & BillingFlags.ArcutisCopay) != 0;
        return hasInventory || isConnectiveRx || isArcutisCopay;
    }
}
