

    import { Component, Vue } from 'vue-property-decorator';
    import PatientFormComponent from '@/components/Patient/PatientFormComponent.vue'

    @Component({
        name: "PatientDetailsPage",
        components: { PatientFormComponent },
    })
    export default class PatientDetailsPage extends Vue { }
