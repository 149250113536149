
import { Vue, Component } from "vue-property-decorator";
import { DatePicker } from "element-ui";
import moment from "moment";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { BvTableCtxObject } from "bootstrap-vue";
import Axios, { AxiosRequestConfig } from "axios";
import { Prescription, RefillStatusCode } from "@/models/Prescription";
import SearchComponent from "@/components/SearchComponent.vue";
import { Drug } from "@/models/Drug/Drug";

@Component({
    name: "DrugDashboard",
    components: {
        [DatePicker.name]: DatePicker,
        SearchComponent
    }
})
export default class DrugDashboard extends Vue {
    private drug: Drug = new Drug();

    private toDate: any = moment().toDate();
    private fromDate: any = moment()
        .subtract(7, "days")
        .toDate();

    private fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        {
            key: "prescriber",
            formatter: this.prescriberFormatter,
            sortable: true
        },
        {
            key: "patient",
            label: "Patient",
            formatter: this.patientFormatter,
            sortable: true,
            class: "patient-col"
        },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        {
            key: "writtenDate",
            formatter: (d: any) => moment(d).format("L"),
            sortable: true
        },
        {
            key: "fillDate",
            formatter: (d: any) => moment(d).format("L"),
            sortable: true
        },
        {
            key: "patientPay",
            formatter: (d: number) => `$${d?.toFixed(2) ?? "0.00"}`,
            sortable: true
        },
        { key: "programName", label: "Program", sortable: true },
        { key: "state" },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" }
    ];

    get tableFilter() {
        this.setURIParams();
        return {
            searchTerm: this.drug.drugId,
            drugSource: this.drug.source,
            fromDate: this.fromDate,
            toDate: this.toDate
        };
    }

    mounted() {
        this.getURIParams();
    }

    getURIParams() {
        if (this.routePackageID) this.drug.drugId = Number(this.routePackageID);
        if (this.routeFromDate) this.fromDate = this.routeFromDate;
        if (this.routeToDate) this.toDate = this.routeToDate;
        if (this.routeSource) this.drug.source = this.routeSource;
    }

    setURIParams() {
        if (!this.drug?.drugId) return;

        let query = this.$route.query;
        let strToDate = moment(this.toDate).format("YYYY-MM-DD");
        let strFromDate = moment(this.fromDate).format("YYYY-MM-DD");
        if (
            query.packageID != this.drug.drugId.toString() ||
            query.source != this.drug.source.toString() ||
            query.fromDate != strFromDate ||
            query.toDate != strToDate
        )
            this.$router.replace({
                name: "DrugDashboard",
                query: {
                    packageID: this.drug.drugId.toString(),
                    source: this.drug.source.toString(),
                    fromDate: strFromDate,
                    toDate: strToDate
                }
            });
    }

    get routePackageID(): string {
        return (this.$route?.query?.packageID as string) || "";
    }

    get routeSource(): number {
        return Number(this.$route?.query?.source) || 0;
    }

    get routeFromDate(): Date | null {
        let strDate = this.$route?.query?.fromDate as string;
        if (strDate) return moment(strDate, "YYYY-MM-DD").toDate();
        else return null;
    }

    get routeToDate(): Date | null {
        let strDate = this.$route?.query?.toDate as string;
        if (strDate) return moment(strDate, "YYYY-MM-DD").toDate();
        else return null;
    }

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = Object.assign(new Patient(), pt);
        return pt.displayNamesForPerson();
    }

    ctxProvider(ctx: BvTableCtxObject, callback: Function) {
        if (ctx.apiUrl == null) return null;

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter
        };
        Axios.get<Prescription[]>(uri, config)
            .then(resp => {
                callback(resp.data.map(rx => new Prescription(rx)));
            })
            .catch(_ => {
                callback([]);
            });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        if (rx.status == RefillStatusCode.TransferOut) return "table-info";
        else if (rx.status == RefillStatusCode.Void) return "table-dark";
        else if (!rx.isRefillableStatus) return "table-danger";
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }
}
