
 import {PharmacyBenefitManager, Flags} from '@/models/PharmacyBenefitManager'
 import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
 import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
 import axios from 'axios';
 import EnumSelector from '@/components/EnumSelector.vue';
 import { NotificationOptions } from "@/util/NotificationOptionsPresets";
 @Component({
     name: "PBMComponent",
     components: {
         NewClearSaveButtons,
         EnumSelector
     }
 })
 export default class PBMComponent extends Vue{
    private PBMRecord:PharmacyBenefitManager  = new PharmacyBenefitManager();
    protected PBMflags = Flags;
    private PBMFlag:number = 0;
    @Prop({type: Boolean, default: false}) private createNewPbm!: boolean;
    
  
    created(){
        if(this.createNewPbm){
            this.createPBM();
        }
    }

    createPBM(){
        this.PBMRecord= new PharmacyBenefitManager();
    }
    backToPatientDashboard() {
         if (this.createNewPbm) {
                this.$emit("close");
                return;
            }
    }

    showConfirmationModal() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            })
                .then(value => {
                   this.PBMRecord = new PharmacyBenefitManager();
                })
                .catch(err => {
                    console.log("Error caught in showConfirmationModal()");
                    console.error(err);
                });
        }
    
    submitPBM(){
        if(this.PBMRecord?.name != undefined  || this.PBMRecord?.name != "" || this.PBMRecord?.name != null){
                this.PBMRecord.flags = this.PBMFlag;
            axios.post<PharmacyBenefitManager>(`/PharmacyBenefitManager`, this.PBMRecord)
      .then(res => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Pharmacy Benefit Manager Created Successfully", NotificationOptions.NotificationTypes.success));
                     this.PBMRecord = res.data;
                      this.$emit('created', this.PBMRecord)
                 })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Create Pharmacy Benefit Manager", NotificationOptions.NotificationTypes.danger));
                    console.error("Error while saving PBM.", {err, response: err.response});
                })
        }
      
    }

      get disableFields():boolean{
        return false;
    }
    
     get isFormValid() {
            return true;
        }

    private get _buttonTitles() {
            let res = {};
                res = {
                    save: "Save Changes",
                    clear: "",
                    new: "",
                    cancel: "Back to Audit Record",
                };
            return res;
        }
 }
