import { render, staticRenderFns } from "./InventoryComponent.vue?vue&type=template&id=1a1de545&scoped=true"
import script from "./InventoryComponent.vue?vue&type=script&lang=ts"
export * from "./InventoryComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a1de545",
  null
  
)

export default component.exports