
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import axios from "axios";
    import Navbar from "@/components/Navbar/Navbar.vue";
    import NavbarToggleButton from "@/components/Navbar/NavbarToggleButton.vue";
    import { UserProductivity } from "@/models/UserProductivity";
    import { ProductivityStatsBase } from "@/models/ProductivityStatsBase";
    import { PharmacistProductivity } from "@/models/PharmacistProductivity";

    @Component({
        name:'TopNavbar',        
        components: {
            Navbar,
            NavbarToggleButton
        }
    })
    export default class TopNavbar extends Vue {
        private activeNotifications = false;
        private showNavbar = false;
        private userProductivity = {};
        private viewProductivity = false;
        private errorCounter = 0;
        private isLoading = false;

        get routeName():string {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        }

        @Watch('routeName') changedRoute(){
            this.getProductivity();
        }

        created() {
            this.isLoading = true;
            this.getProductivity();
            if (localStorage.getItem("ViewProductivityStats") == null || localStorage.getItem("ProductivityStatsDate") != new Date().toLocaleDateString()) {
                localStorage.setItem("ViewProductivityStats", 'true');
                this.viewProductivity = true;
            }
            if (localStorage.getItem("ViewProductivityStats") == 'true') {
                this.viewProductivity = true;
            }
            //Dispense Error Counter live connection setup
            this.$root.$data.dispenseErrorHub?.on("UpdateErrorCounter", (res:any) => {
                this.errorCounter = res;
                console.log("***UpdateErrorCounter***", res);
            });

            this.$http.get('DispenseError/error-count')
            .then(res => {
                this.errorCounter = res?.data || 0;
            })
            .catch(err => {
                console.error('Error while pulling number of dispense errors assigned.', {err, response: err?.response});
            })            
        }

        capitalizeFirstLetter(str:string | null | undefined ) {
            if(!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        }
        closeDropDown() {
            this.activeNotifications = false;
        }
        toggleNavbar() {
            this.showNavbar = !this.showNavbar;
        }
        toggleProductivity() {

            if (localStorage.getItem("ViewProductivityStats") == 'true') {
                localStorage.setItem("ViewProductivityStats", 'false');
                this.viewProductivity = false;
            }
            else {
                localStorage.setItem("ViewProductivityStats", 'true');
                this.viewProductivity = true;
            }
        }
        getProductivity() {
            axios.get<ProductivityStatsBase>('reports/GetProductivityReport')
            .then(response => {
                let stats = response.data;
                if(stats.isPharmacist)
                    this.userProductivity = Object.assign(new PharmacistProductivity(), stats);
                else 
                    this.userProductivity = Object.assign(new UserProductivity(), stats);
            })
            .catch(err => {
                console.error('Error while getting productivity stats', { err, response: err?.response })
            })
            .finally(() => this.isLoading = false)
        }
    };
