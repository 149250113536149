//import HasID from "@/models/HasID";
import HasLabel from "@/models/HasLabel";
//import internal from "stream";

import TrackedObject from "./TrackedObject";

export interface PharmacyBenefitManager extends TrackedObject {
    id: number;
    name: string;
    flags: Flags;
}

export enum Flags{
    None,
    CovidPcn
    }
export class PharmacyBenefitManager extends HasLabel {
    constructor(pbm?: PharmacyBenefitManager) {
        super();
        if (pbm) {
            Object.assign(this, pbm);
        }
    }

    toString(): string {
        let label = "";
        if(this.name){
            label = "Id: " + this.id + " Name: " + this.name
        }
        return label;
    }
}