
    /* eslint-disable no-new */
    import PerfectScrollbar from "perfect-scrollbar";
    import "perfect-scrollbar/css/perfect-scrollbar.css";

    function hasElement(className: string) {
        return document.getElementsByClassName(className).length > 0;
    }

    function initScrollbar(className: string) {
        if (hasElement(className)) {
            new PerfectScrollbar(`.${className}`);
        } else {
            // try to init it later in case this component is loaded async
            setTimeout(() => {
                initScrollbar(className);
            }, 100);
        }
    }

    import TopNavbar from "./TopNavbar.vue";
    import ContentFooter from "./ContentFooter.vue";
    //import UserMenu from "./Extra/UserMenu.vue";
    import { ZoomCenterTransition } from "vue2-transitions";
    import { Component, Vue, Watch } from "vue-property-decorator";
    import { PhoneStats } from "@/models/PhoneStats";

    import SideBar from "@/components/SidebarPlugin/SideBar.vue";
    import SidebarItem from "@/components/SidebarPlugin/SidebarItem.vue";

    @Component({
        components: {
            TopNavbar,
            ContentFooter,
            //UserMenu,
            ZoomCenterTransition,
            SideBar,
            SidebarItem,
        },
    })
    export default class Dashboard extends Vue {
        mounted() {
            const docClasses = document.body.classList;
            const isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                // if we are on windows OS we activate the perfectScrollbar function
                initScrollbar("sidebar");
                initScrollbar("sidebar-wrapper");

                docClasses.add("perfect-scrollbar-on");
            } else {
                docClasses.add("perfect-scrollbar-off");
            }
        }

        @Watch("$root.dataHub.state") onConnectionStatusChange() {
            console.log("dataHubStatus = " + this.$root.$data.dataHub.state);
        }

        get disconnected(): boolean {
            if (!(this.$root.$data.dataHub as signalR.HubConnection)) {
                return true;
            }
            return this.$root.$data.dataHub.state == "Disconnected";
        }

        get reconnecting(): boolean {
            if (!(this.$root.$data.dataHub as signalR.HubConnection)) {
                return false;
            }
            return this.$root.$data.dataHub.state == "Connecting" || this.$root.$data.dataHub.state == "Reconnecting";
        }

        get res(): PhoneStats {
            return this.$root.$data.phoneStats;
        }

        get bgColor(): string {
            if ((this.res.longestHold ?? 0) >= 30) {
                return "red";
            } else if ((this.res.longestHold ?? 0) >= 15) {
                return "orange";
            } else {
                return "black";
            }
        }

        toggleSidebar() {
            if ((this.$refs?.sidebar as any)?.showSidebar) {
                (this.$refs!.sidebar as any).displaySidebar(false);
            }
        }
    }
