
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Drug } from '@/models/Drug/Drug';
    import axios from 'axios';

    @Component({
        name: "EquivalentDrugsComponent",
        components: {}
    })
    export default class EquivalentDrugsComponent extends Vue {

        @Prop({ type: Drug, default: new Drug() }) private drug!: Drug;
        @Prop({ type: Boolean, default: false }) private closeEquivalentDrugList!: boolean;
        @Prop({ type: Boolean, default: false }) private disabled!: boolean;
        @Prop({ type: Boolean, default: true }) private onlyActive!: boolean;
        @Prop({ type: Boolean, default: false }) private prepopulate!: boolean;
        @Prop({ type: Boolean, default: true }) private showSearchButton!: boolean;


        private isLoading = false;
        protected equivalentDrugs: Array<Drug.EquivalentDrug> = [];
        private searchWasClicked = false;
        private equivalentDrugFields = [
            { key: 'ndc', label: 'NDC' },
            { key: 'brandGenericStatus', label: 'Brand/Generic' },
            { key: 'productNameLong', label: 'Name', sortable: true },
            { key: 'marketer', sortable: true },
            { key: 'packageSize', sortable: true },
        ];

        @Watch('drug.productID', { immediate: true })
        drugChanged() {
            if (this.prepopulate) this.findEquivalentDrugs();
        }

        findEquivalentDrugs() {
            if (!this.drug || !this.drug.productID || !this.drug.packageID) return;
            this.searchWasClicked = true;
            this.isLoading = true;
            axios.get<Array<Drug.EquivalentDrug>>(`/Drug/${this.drug.productID}/Equivalent/${this.drug.packageID}`, { params: { onlyActive: this.onlyActive } })
                .then(response => {
                    this.equivalentDrugs = response.data.filter(d => d.packageID != this.drug.packageID);
                })
                .catch(error => {
                    console.error('Error while searching for equivalent drugs.', { error, response: error?.response });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        equivalentDrugSelected(items: Array<Drug.EquivalentDrug>) {
            if (!items.length) return;
            this.$emit('drugSelected', items[0].packageID);
            if (this.closeEquivalentDrugList) {
                this.equivalentDrugs = [];
                this.searchWasClicked = false;
            }
        }
    }
