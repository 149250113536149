
import Checkbox from "@/components/Inputs/Checkbox.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import PrescriptionsListComponent from "@/components/Prescription/PrescriptionsListComponent.vue";
import OrdersListComponent from "@/components/Order/OrdersListComponent.vue";
import ShippingTicketComponent from "@/components/Shipping/ShippingTicketComponent.vue";
import NotesList from "@/components/Note/NotesList.vue";
import NotesForm from "@/components/Note/NotesForm.vue";
import { Patient, PatientProgram } from "@/models/Patient";
import CaseDetailComponent from "@/components/Cases/CaseDetailComponent.vue";
import { Prescription } from "@/models/Prescription";
import RxTransferModalComponent from "@/components/RxTransfer/RxTransferModalComponent.vue";
import DoctorFollowUpCasesAlertComponent from "@/components/Doctor/DoctorFollowUpCasesAlertComponent.vue";
import { CaseDetail } from "@/models/Case";
import { Store } from "@/models/Store";
import { EntityType } from "@/models/Note";
import { Order } from "@/models/Order";
import { LockHandler } from "@/mixins/LockHandler";
import axios, { AxiosRequestConfig } from "axios";
import { DatePicker, Table, TableColumn } from "element-ui";
import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import Card from "@/components/Cards/Card.vue";
import PriorAuthorizationListComponent from "@/components/PriorAuthorization/PriorAuthorizationListComponent.vue";
import moment from "moment";
import { GSDDPatientAllergy } from "@/models/GSDD";
import PatientPlansComponent from "@/components/Insurance/PatientPlansComponent.vue";
import { RxTransfer, transferStatus } from "@/models/RxTransfer";
import IRSReportComponent from "@/components/EmbeddedReports/IRSReportComponent.vue";
import NewConsultationFormComponent from "@/components/Consultation/NewConsultationFormComponent.vue";
import ConsultationListComponent from "@/components/Consultation/ConsultationListComponent.vue";
import { Shipment } from "@/models/Shipment";
import PatientDocumentsModal from "@/components/Patient/PatientDocumentsModal.vue";
import PatientDemographicsComponent from "@/components/Patient/PatientDemographicsComponent.vue";
import FaxToPhysicianFormModal from "@/components/Prescription/FaxToPhysicianFormModal.vue";
import { FaxType } from "@/models/PrescriberFollowUpCase";
import OnlineOrdersModal from "@/components/Patient/OnlineOrdersModal.vue";
import PatientInsuranceModalComponent from "@/components/Patient/PatientInsuranceModalComponent.vue";
import { PatientInsuranceUpload } from "@/models/PatientInsuranceUpload";
import RXSubscriptionsGrid from "@/components/Prescription/RXSubscriptionsGrid.vue";
import ElectronicPriorAuthorizationListPage from "@/pages/PriorAuthorization/ElectronicPriorAuthorizationListPage.vue";
import { CombinedPlan } from "@/models/CombinedPlan";
import { Plan } from "@/models/Plan";
import { PatientPlan } from "@/models/PatientPlan";
import PatientPaymentMethodsModal from '@/components/Patient/PatientPaymentMethodsModal.vue';
import ResetPatientAppPasswordComponent from '@/components/Patient/ResetPatientAppPasswordComponent.vue';
import PatientAssistanceProgramTable from "@/components/PatientAssistanceProgram/PatientAssistanceProgramTable.vue";
import EmergencyContactList from "@/components/Patient/EmergencyContactList.vue";
import { Prescriber } from "@/models/Prescriber";
import PatientDocumentsForm from "@/components/Patient/PatientDocumentsForm.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import PatientFormComponent from "@/components/Patient/PatientFormComponent.vue";

enum searchType {
    Unknown = 1,
    Empty = 2,
    String = 3,
    Phone = 4,
    Rx = 5,
}

@Component({
    components: {
        PatientFormComponent,
        PatientDocumentsForm,
        ElectronicPriorAuthorizationListPage,
        SearchComponent,
        Checkbox,
        PrescriptionsListComponent,
        OrdersListComponent,
        NotesList, NotesForm,
        Card,
        [DatePicker.name]: DatePicker,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        ConsultationListComponent,
        PriorAuthorizationListComponent,
        CaseDetailComponent,
        PatientPlansComponent,
        RxTransferModalComponent,
        IRSReportComponent,
        NewConsultationFormComponent,
        ShippingTicketComponent,
        PatientDocumentsModal,
        PatientDemographicsComponent, FaxToPhysicianFormModal,
        OnlineOrdersModal, DoctorFollowUpCasesAlertComponent,
        PatientInsuranceModalComponent, RXSubscriptionsGrid,
        PatientPaymentMethodsModal,
        ResetPatientAppPasswordComponent,
        PatientAssistanceProgramTable,
        EmergencyContactList
    },
})
export default class PatientDashboardPage extends Mixins(LockHandler) {
    @Ref('doctor-followup-alerts') doctorFollowupAlerts!: DoctorFollowUpCasesAlertComponent;

    protected showPatientForm: boolean = false;
    protected searchShouldFocus: boolean = false;
    public patient: Patient = new Patient();
    protected primaryPrescriber?: Prescriber;
    protected patientPrograms: PatientProgram[] = [];
    private prescriptions: Prescription[] = [];
    protected orders: Order[] = [];
    protected loadingOrders = false;
    protected allergies: GSDDPatientAllergy[] = [];
    private casesList: CaseDetail[] = [];
    protected caseSelected: CaseDetail | any = {};
    private displayName = "";
    private searchValue = "";
    protected showActiveTransferAlert = false;
    protected selectedRxTransfer: number | null = null;
    protected showPatientAlert = false;
    private showCancelled = false;
    private showShipped = true;
    protected showCasesNotification = false;
    protected outboundCallOptOut = false;
    private unfinishedRxTransfers: RxTransfer[] = [];
    protected newConsultationStore: Store = new Store();
    protected patientShipments: Shipment[] = [];

    protected faxFormTypeRequestEnum = FaxType;

    protected patientSearchType: searchType = searchType.Unknown;

    private filterStore: Store = {} as Store;
    protected patientEntityType = EntityType.Patient;
    private latestInsuranceUpload: PatientInsuranceUpload =
        {} as PatientInsuranceUpload;
    private selectedPapDocuments: number[] = [];

    protected patientButtonTitles = {
        new: "",
        clear: "",
        save: "Save Patient",
        cancel: "Close Patient Form",
    };

    protected prescriptionListActionButtons: Array<string> = [];
    @Ref() private consultationList!: ConsultationListComponent;
    protected shipmentToViewInTicket: Shipment = new Shipment();

    @Ref('pap-table') private papTable!: PatientAssistanceProgramTable;

    get patientAlertText(): string {
        if (!this.patient || !this.patient.alert) return "";

        return `Patient Memo: ${this.patient.alert}`;
    }

    patientFormHidden() {
        this.patient = new Patient();
    }

    onPatientCreated(newPatient: Patient) {
        console.log("create new patient: ", newPatient);
        this.showPatientForm = false;
        this.patient = newPatient;
    }

    get routePatientId(): number {
        let res: number;
        res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get insuranceUploaded(): boolean {
        return this.latestInsuranceUpload &&
            moment(this.latestInsuranceUpload.uploaded).isValid();
    }

    get insuranceUploadedDisplayDate(): string {
        return moment(this.latestInsuranceUpload.uploaded).fromNow();
    }

    get hasOldPortal(): boolean {
        return this.patient.createdBy?.indexOf(":") > 0;
    }

    get oldPortalLink(): string {
        if (this.patient.createdBy?.indexOf(":") > 0) {
            const oldPatIds = this.patient.createdBy.split(":");
            return `https://portal.cphrx1.local/Patient/${oldPatIds[0]}/${oldPatIds[1]}`;
        }
        return "";
    }

    get patientName(): string {
        let res = "";
        if (this.patient) res = this.patient.displayNamesForPerson();
        return res;
    }

    created() {
        this.prescriptionListActionButtons.push("edit");
        this.prescriptionListActionButtons.push("subscribe");
        this.prescriptionListActionButtons.push("suppress");

        // If route as a PatientId as argument, load its data by default.
        if (this.routePatientId) {
            axios
                .get(`/Patient/${this.routePatientId}`)
                .then((response) => {
                    this.patient = Object.assign(new Patient(), response.data);
                })
                .catch((error) => {
                    console.error(
                        "Problem loading patient information by route Id",
                        {
                            error,
                            response: error?.response,
                        }
                    );
                });
        } else {
            this.searchShouldFocus = true;
        }
    }

    @Watch("$route.params.id") onParamChanged(value: string, oldValue: string) {
        if (value != oldValue) {
            this.patient = Object.assign(new Patient(), { id: value });
        }
    }

    @Watch("patient") onPatientChanged(
        value: Patient | null,
        oldValue: Patient | null
    ) {
        if (!value) {
            this.displayName = "";
            return;
        }
        const patient: Patient = value as Patient;
        if (!patient.id) {
            patient.updateNamesForPersonFromDisplayName(this.displayName);
        } else {
            if (!oldValue || oldValue.id != patient.id) {
                if (this.$route?.params.id != patient.id.toString())
                    this.$router.push({
                        name: "PatientDashboard",
                        params: { id: patient.id.toString() },
                    });

                this.clearData();
                // Get the full patient object instead of the tiny stub we get from backend.
                axios
                    .get(`/Patient/${patient.id}`)
                    .then((response) => {
                        this.patient = Object.assign(
                            new Patient(),
                            response.data
                        );
                        this.displayName = patient.id
                            ? patient.displayNamesForPerson()
                            : "";
                        if (!this.routePatientId)
                            this.$router.push({
                                name: "PatientDashboard",
                                params: { id: patient.id.toString() },
                            });
                        if (this.patient.primaryPrescriberId) {
                            axios.get(`/Prescriber/${this.patient.primaryPrescriberId}`).then(response => {
                                this.primaryPrescriber = new Prescriber(response.data);
                            });
                        }
                        axios.get<PatientProgram[]>(`/Patient/${this.patient.id}/programs`)
                            .then(resp => {
                                this.patient.programs = resp.data;
                            });
                    })
                    .catch((error) => {
                        console.error(
                            "Problem loading patient information its selection on the search component",
                            {
                                error,
                                response: error?.response,
                            }
                        );
                    });

                //Get programs associated to the patient
                axios
                    .get(`/Patient/${patient.id}/programs`)
                    .then((response) => {
                        this.patientPrograms = response.data;
                    })
                    .catch((error) => {
                        console.error("Problem loading programs", {
                            error,
                            response: error?.response,
                        });
                    });

                this.loadPrescriptions();
                this.loadOrders(patient.id);

                //Get patient's allergies
                axios
                    .get(`Allergy/GetGSDDAllergiesForPatient/${patient.id}`)
                    .then((response) => {
                        this.allergies = response.data;
                    })
                    .catch((error) => {
                        console.error("Problem loading allergies", {
                            error,
                            response: error?.response,
                        });
                    });

                axios
                    .get(`/Patient/${patient.id}/Cases`)
                    .then((response) => {
                        this.casesList = response.data;
                    })
                    .catch((error) => {
                        console.error(
                            "Problem loading insurance Patient Cases",
                            { error, response: error?.response }
                        );
                    });

                axios
                    .get(`/Patient/${patient.id}/GetOutboundOptStatus`)
                    .then((response) => {
                        this.outboundCallOptOut = response.data;
                    })
                    .catch((_error) => {
                        console.error("Error Getting Opt Status");
                    });

                axios
                    .get(`/RxTransfer/${patient.id}/GetUnfinishedByPatient`)
                    .then((response) => {
                        this.unfinishedRxTransfers = response.data;
                    })
                    .catch((error) => {
                        console.error("Error Getting RxTransfers", {
                            error,
                            response: error?.response,
                        });
                    });

                axios
                    .get(`/Shipping/GetShipmentsByPatient/${patient.id}`)
                    .then((response) => {
                        this.patientShipments = response.data;
                    })
                    .catch((error) => {
                        console.error("Error Getting Shipments", {
                            error,
                            response: error?.response,
                        });
                    });

                //getLatestInsuranceUpload
                axios
                    .get<PatientInsuranceUpload>(
                        `/PatientInsuranceUpload/GetLatest/${patient.id}`
                    )
                    .then((response) => {
                        this.latestInsuranceUpload = response.data;
                        this.latestInsuranceUpload.uploaded;
                    });
            }
        }
    }

    clearData() {
        this.patientPrograms = [];
        this.prescriptions = [];
        this.orders = [];
        this.loadingOrders = false;
        this.allergies = [];
        let rxSubscriptionsGrid = this.$refs
            .rxSubscriptionsGrid as RXSubscriptionsGrid;
        if (rxSubscriptionsGrid) rxSubscriptionsGrid.clearData();
    }

    clearClick() {
        this.clearData();
        this.patient = new Patient();
    }

    loadPrescriptions() {
        if (!this.patient?.id) return;

        //Get patient's prescriptions and format the data to be shown in the grid.
        axios
            .get(`/Patient/${this.patient.id}/prescriptions`)
            .then((response) => {
                this.prescriptions = response.data.map((rx: Prescription) => new Prescription(rx));
                this.loadRxsAvailableForAutorefill();
            })
            .catch((error) => {
                console.error("Problem loading prescriptions", {
                    error,
                    response: error?.response,
                });
            });
    }

    get papFilter(): AxiosRequestConfig {
        let filter: AxiosRequestConfig;
        filter = {
            params: {
                id: this.patient.id
            }
        };
        return filter;
    }

    loadRxsAvailableForAutorefill() {
        this.$http
            .get<Array<Prescription>>(
                `autorefill/available-for-autorefill/${this.patient.id}`
            )
            .then((res) => {
                if (res.data?.length) {
                    const availableRxsForAutorefill = res.data.map((rx) =>
                        Object.assign(new Prescription(), rx)
                    );
                    this.prescriptions.forEach((rx) => {
                        rx.isAvailableForAutorefill =
                            availableRxsForAutorefill.some(
                                (rx2) => rx2.rxID == rx.rxID
                            );
                    });
                }
            })
            .catch((err) => {
                console.error(
                    "Error loading Rxs available for subscription to auto refill.",
                    { err, response: err?.response }
                );
            });
    }

    loadAutoRefillData() {
        this.loadRxsAvailableForAutorefill();
        let rxSubscriptionsGrid = this.$refs
            .rxSubscriptionsGrid as RXSubscriptionsGrid;
        if (rxSubscriptionsGrid) rxSubscriptionsGrid.loadData();
    }

    loadOrders(patientId?: string | number) {
        patientId = patientId || this.patient.id;
        let storeId =
            this.filterStore?.id == 0 || this.filterStore?.id
                ? this.filterStore?.id
                : "";
        //Get patient's prescriptions and format the data to be shown in the grid.
        this.loadingOrders = true;
        axios
            .get(
                `/Patient/${patientId}/orders/${this.showCancelled}/${this.showShipped}/${storeId}`
            )
            .then((response) => {
                this.orders = response.data;
                this.orders.sort((a: Order, b: Order) => {
                    return (
                        new Date(b.created).getTime() -
                        new Date(a.created).getTime()
                    );
                });
            })
            .catch((error) => {
                console.error("Problem loading patient orders", {
                    error,
                    response: error?.response,
                });
            })
            .finally(() => {
                this.loadingOrders = false;
            });
    }

    @Watch("patient.alert") onAlertChange(value: string, oldValue: string) {
        this.showPatientAlert = !!value && !oldValue;
    }

    @Watch("unfinishedRxTransfers") onRxTransfersChange() {
        this.showActiveTransferAlert = this.unfinishedRxTransfers.length > 0;
    }

    @Watch("casesList") onCasesListChange() {
        this.showCasesNotification = this.casesList.length > 0;
    }

    @Watch("searchValue") searchValueChange(_value: string, _oldValue: string) {
        this.updatePatientSearchType();
    }

    updatePatientSearchType() {
        let type: searchType;
        if (!this.searchValue.length) {
            type = searchType.Empty;
        } else if (this.searchValue.charAt(0) == "+") {
            type = searchType.Phone;
        } else if (isNaN(Number(this.searchValue))) {
            type = searchType.String;
        } else {
            type = searchType.Rx;
        }
        this.patientSearchType = type;
    }

    refreshNotesList() {
        if (this.$refs.patientNotesList instanceof NotesList)
            (this.$refs.patientNotesList as any).loadNotes();
    }

    storeLabel(object: Store) {
        let res = "";
        if (object.name) res = object.name;

        return res;
    }

    convertDate(date: string | Date) {
        return moment.utc(date).format("L");
    }

    getRxTransferEnum(status: number) {
        return transferStatus[status];
    }

    selectCase(item: CaseDetail) {
        this.caseSelected = item;
    }

    SendInsuranceRequest() {
        axios
            .post(`/PatientInsuranceUpload/SendRequest/${this.patient.id}`)
            .then(() => {
                this.$notification({
                    message: "Message Sent!",
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                });
            })
            .catch((error) => {
                this.$notification({
                    message:
                        "Error Sending Message:" +
                        error.response?.data.substring(0, 100),
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                });
            });
    }

    ChangeOutboundOptOut(status: boolean) {
        axios
            .post(
                `/Patient/${this.patient.id}/${status}/ChangeOutboundOptStatus`
            )
            .then((_response) => {
                this.outboundCallOptOut = status;
            })
            .catch((error) => {
                console.error("Error Changing Opt Status", {
                    error,
                    response: error?.response,
                });
            });
    }

    StartRxTransfer() {
        this.$bvModal.show("rxTransferModal");
    }

    viewTransferDetails(transferId: number) {
        this.selectedRxTransfer = transferId;
        this.$bvModal.show("rxTransferModalEdit");
    }

    StartIRSReport() {
        this.$bvModal.show("irsReportModal");
    }

    openNewConsultationModal() {
        this.$bvModal.show("newConsultationModal");
    }

    private covidStore: Store = {} as Store;
    private covidPlans: CombinedPlan[] = [];
    private covidPlan: CombinedPlan = {} as CombinedPlan;
    protected covidDisabled: boolean = false;

    openCovidModal() {
        axios
            .get<{ plans: Plan[]; patientPlans: PatientPlan[] }>(
                `Insurance/${this.patient.id}`
            )
            .then((response) => {
                this.covidPlans = response.data.patientPlans
                    .filter((pp) => pp.active)
                    .map((pp) => {
                        return new CombinedPlan({
                            patientPlan: new PatientPlan(pp),
                            plan: new Plan(
                                response.data.plans.find(
                                    (p) => p.id == pp.planId
                                )
                            ),
                        } as CombinedPlan);
                    });

                this.covidPlan = this.covidPlans[0];
                this.$bvModal.show("createCovidTestScript");
            })
            .catch((error) => {
                console.error("Error while loading the insurance plans", {
                    error,
                    response: error?.response,
                });
            });
    }

    createCovidScript() {
        this.covidDisabled = true;
        if (this.covidPlan.patientPlan?.id && this.covidStore?.id) {
            axios
                .post(
                    `patient/${this.patient.id}/${this.covidStore.id}/${this.covidPlan.patientPlan.id}`
                )
                .then((response) => {
                    let script = Object.assign(
                        new Prescription(),
                        response.data
                    ) as Prescription;
                    if (script.rxID) {
                        this.$router.push({
                            name: "PrescriptionDetailsId",
                            params: { rxid: script.rxID },
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error creating Covid Test Script", {
                        error,
                        response: error?.response,
                    });
                    this.$notification({
                        message:
                            "Error Sending Message:" + error.response?.data,
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                    });
                });
        }
    }

    updateConsultationsList() {
        this.$bvModal.hide("newConsultationModal");
        (this.consultationList as any).getConsultation();
    }

    showShippingTicket(shipment: Shipment) {
        this.shipmentToViewInTicket = shipment;
        this.$bvModal.show("shippingTicketModal");
    }

    addPap() {
        const data = this.selectedPapDocuments.map(documentId => {
            return { id: documentId };
        });
        axios.post(`api/PatientAssistanceProgram/Create/${this.patient.id}`, data)
            .then(resp => {
                console.log("resp : ", resp);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Patient Assistance Program"));
            })
            .catch(err => {
                console.warn("err: ", err);
                this.$notification(NotificationOptions.error(err));
            })
            .finally(() => {
                this.selectedPapDocuments = [];
                this.papTable.clearAndRefresh();
            });
    }

    sendPapText() {
        const fulfilled = (_textResp: any) => {
            this.$notification({
                message: "Message Sent!",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
            });
        };
        const rejected = (err: any) => {
            this.$notification({
                message: "Error Sending Message:" + err.response?.data,
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
            });
        };
        axios
            .post("patient/SendPatientAssistanceProgramText", this.patient)
            .then(fulfilled)
            .catch(rejected);
    }

    sendNpcText() {
        const fulfilled = (_textResp: any) => {
            this.$notification({
                message: "Message Sent!",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
            });
        };
        const rejected = (err: any) => {
            this.$notification({
                message: "Error Sending Message:" + err.response?.data,
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
            });
        };
        axios
            .post("patient/sendNpcText/Carepoint", this.patient)
            .then(fulfilled)
            .catch(rejected);

    }

    sendRefillText() {
        const fulfilled = (_textResp: any) => {
            this.$notification({
                message: "Message Sent!",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
            });
            console.log("did this point get hit");
        };
        const rejected = (err: any) => {
            this.$notification({
                message: "Error Sending Message:" + err.response?.data,
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
            });
        };

        axios
            .post("patient/sendRefillText/Carepoint", this.patient)
            .then(fulfilled)
            .catch(rejected);
    }

    fetchFollowUpCases() {
        this.doctorFollowupAlerts?.fetchFollowUpCases();
    }

    paymentMethodsModal() {
        this.$bvModal.show("PaymentMethodModalMaintenance");
    }

    resetPatientAppModal() {
        this.$bvModal.show("ResetPatientAppModal");
    }

    hidePatientAppModal() {
        this.$bvModal.hide("ResetPatientAppModal");
    }
}

