import TrackedObject from "./TrackedObject";
import HasID from "./HasID";
import { Note } from "./Note";
import { Person } from "./Person";
import { Prescription } from "./Prescription";
import TextUtils from "@/util/TextUtils"

export interface DispenseError extends HasID, TrackedObject {
    storeID?: number;
    rxNumber?: number;
    rfNumber?: number;
    reason?: string;
    patientFirstName?: string;
    patientMiddleName?: string;
    patientLastNameName?: string;
    productNameShort?: string;
    pv?:string;
    resolved?: Date;
    resolvedBy?: string;
    accepted?: Date;
    acceptedBy?: string;
    assignedTo?: string;
    userNameAssignedTo?: string;
    errorType?: DispenseError.DispenseErrorTypeEnum
    notes?: Array<Note>
    viewedBy?: string;
}

export class DispenseError {
    constructor(rx?: Prescription) {
        this.storeID = (rx?.storeID || 0);
        this.rxNumber = (rx?.rxNumber || 0);
        this.rfNumber = (rx?.rfNumber || 0);
    }

    get fullPatientName(): string {
        return Person.concatName(this.patientFirstName, this.patientLastNameName, this.patientMiddleName);
    }

    get rxID(): string {
        return `${this.storeID || 0}-${this.rxNumber || 0}-${this.rfNumber || 0}`;
    }

    get statusEnum(): DispenseError.DispenseErrorStatusEnum {
        let res: DispenseError.DispenseErrorStatusEnum = DispenseError.DispenseErrorStatusEnum.Created;
        if (this.accepted)
            res = DispenseError.DispenseErrorStatusEnum.Accepted;
        else if (this.resolved)
            res = DispenseError.DispenseErrorStatusEnum.Resolved;
        return res;
    }

    get status(): string {
        return DispenseError.DispenseErrorStatusEnum[this.statusEnum];
    }

    get statusStyle(): string {
        const status: DispenseError.DispenseErrorStatusEnum = this.statusEnum;
        if (status == DispenseError.DispenseErrorStatusEnum.Accepted)
            return "info";
        else if (status == DispenseError.DispenseErrorStatusEnum.Resolved)
            return "success";
        else return "warning";
    }

    get errorTypeDesc(): string | null {
        if (this.errorType || this.errorType == 0)
            return DispenseError.ErrorTypeToString(this.errorType);
        else return null;
    }

    static ErrorTypeToString(errorType: DispenseError.DispenseErrorTypeEnum): string {
        let errorDesc = '';
        errorDesc = DispenseError.ERROR_TYPE_DISPLAY_NAMES.find(dis => dis.enum == errorType)?.name || '';
        if (!errorDesc) {
            errorDesc = DispenseError.DispenseErrorTypeEnum[errorType];
            errorDesc = TextUtils.camelCaseToNormal(errorDesc);
        }
        return errorDesc;
    }
}

export namespace DispenseError {
    export enum DispenseErrorTypeEnum {
        DAW,
        DaysSupply,
        DrugNotprocessedOrTransferred,
        IncorrectDirections,
        IncorrectQuantity,
        NeedAllergy,
        NeedFrequencyOnRx,
        NeedPhysicalAddress,
        NeedPumpApprovedByMD,
        NotBilledOrBilledIncorrectly,
        NoRMA,
        NoRxScanned,
        PharmacyNotLicensedOrNonServiceState,
        Refills,
        RxOriginCode,
        RefillTooSoon,
        RxBilledTwice,
        WrongMedication,
        WrongNDC,
        WrongOriginalDate,
        WrongPatient,
        WrongPhysician,
        WrongStrength,
        WrongQuantityOrPackageSize,
        Other,
        Payment
    }

    export const ERROR_TYPE_DISPLAY_NAMES = [
        { enum: DispenseError.DispenseErrorTypeEnum.DAW, name: 'DAW' },
        { enum: DispenseError.DispenseErrorTypeEnum.DaysSupply, name: 'Days Supply' },
        { enum: DispenseError.DispenseErrorTypeEnum.DrugNotprocessedOrTransferred, name: 'Drug Not Processed or Transferred' },
        { enum: DispenseError.DispenseErrorTypeEnum.IncorrectDirections, name: 'Incorrect Directions' },
        { enum: DispenseError.DispenseErrorTypeEnum.NeedAllergy, name: 'Need Allergy' },
        { enum: DispenseError.DispenseErrorTypeEnum.NeedFrequencyOnRx, name: 'Need Frequency on Rx' },
        { enum: DispenseError.DispenseErrorTypeEnum.NeedPhysicalAddress, name: 'Need Physical Address' },
        { enum: DispenseError.DispenseErrorTypeEnum.NeedPumpApprovedByMD, name: 'Need Pump Approved By MD' },
        { enum: DispenseError.DispenseErrorTypeEnum.NotBilledOrBilledIncorrectly, name: 'Not Billed or Billed Incorrectly' },
        { enum: DispenseError.DispenseErrorTypeEnum.NoRMA, name: 'No RMA' },
        { enum: DispenseError.DispenseErrorTypeEnum.NoRxScanned, name: 'No Rx Scanned' },
        { enum: DispenseError.DispenseErrorTypeEnum.PharmacyNotLicensedOrNonServiceState, name: 'Pharmacy Not Licensed' },
        { enum: DispenseError.DispenseErrorTypeEnum.Refills, name: 'Refills' },
        { enum: DispenseError.DispenseErrorTypeEnum.RxOriginCode, name: 'Rx Origin Code' },
        { enum: DispenseError.DispenseErrorTypeEnum.RefillTooSoon, name: 'Refill Too Soon' },
        { enum: DispenseError.DispenseErrorTypeEnum.RxBilledTwice, name: 'Rx Billed Twice' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongMedication, name: 'Wrong Medication' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongNDC, name: 'Wrong NDC' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongOriginalDate, name: 'Wrong Written Date' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongPatient, name: 'Wrong Patient' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongPhysician, name: 'Wrong Physician' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongStrength, name: 'Wrong Strength' },
        { enum: DispenseError.DispenseErrorTypeEnum.WrongQuantityOrPackageSize, name: 'Wrong Quantity or Package Size' },
        { enum: DispenseError.DispenseErrorTypeEnum.Other, name: 'Other' },
        { enum: DispenseError.DispenseErrorTypeEnum.Payment, name: 'Issue Collecting Copay' },
    ];

    export enum DispenseErrorStatusEnum {
        Created,
        Resolved,
        Accepted
    }
}
