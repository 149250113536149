
    import Vue from 'vue'
    import { Component, Watch, Prop } from 'vue-property-decorator';
    import SearchComponent from '@/components/SearchComponent.vue';
    import { InventoryBatch } from '@/models/InventoryBatch';
    import {Store} from '@/models/Store'
    import {Vendor} from '@/models/Vendor'
    import {Drug} from '@/models/Drug/Drug'
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { Program } from '@/models/Program';
    import { Inventory } from '@/models/Inventory';

    @Component({
        name: "InventoryBatchFormComponent",
        components: {
            SearchComponent
        },
    })
    export default class InventoryBatchFormComponent extends Vue {

        //Batch ID
        @Prop({default: 0}) private value!: number;
        get batchID():number{
            return this.value;
        }

        private Drug = Drug;

        private batch : InventoryBatch = new InventoryBatch();
        private newItem : InventoryBatch.InventoryBatchItem = new InventoryBatch.InventoryBatchItem();

        private store: Store = new Store();
        private vendor: Vendor = new Vendor();
        private program: Program = new Program();
        private drug: Drug = new Drug();

        private isLoading = false;
        private isSaving = false;
        private isSubmitting = false;

        private fields: any[] = [
            { key: "shippedQuantity", label: "Shipped Qty" },
            { key: "receivedQuantity", label: "Received Qty" },
            { key: "packageSize", label: "Package Size" },
            { key: "totalShippedUnits", label: "Units Shipped" },
            { key: "totalReceivedUnits", label: "Units Received" },
            { key: "drugName", label: "Drug" },
            { key: "ndc", label: "NDC", formatter: Drug.formatNDC542 },
            { key: "actions" },
        ];

        @Watch('newItem.shippedQuantity') receivedQuantityChanged(){
            this.newItem.receivedQuantity = this.newItem.shippedQuantity;
        }

        colorCodeTable(row : InventoryBatch.InventoryBatchItem, type: string){
            if (!row || type !== 'row')
                return;

            if(!row.packageID) return 'table-danger';
        }

        created(){
            this.loadBatch();
        }

        loadBatch(){
            if(!this.batchID) return;

            this.isLoading = true;
            this.$http.get<InventoryBatch>(`/Inventory/inventory-batch/${this.batchID}`)
            .then(res => {
                this.batch = new InventoryBatch(res.data);
            })
            .catch(err => {
                console.error('Error while loading inventory batch', {err, response: err?.response})
            })
            .finally(() => {
                this.isLoading = false;
            })
        }

        addProduct(){
            const recQty = this.newItem.receivedQuantity;
            const shipQty = this.newItem.shippedQuantity;
            this.newItem = new InventoryBatch.InventoryBatchItem(this.drug);
            this.newItem.receivedQuantity = recQty;
            this.newItem.shippedQuantity = shipQty;
            this.batch.items.push(this.newItem);
            this.clearNewItemForm();
        }

        editProduct(){
            let item = this.batch.items.find(i => i.id == this.newItem.id);
            if(item){
                item.packageID = Number(this.drug.packageID);
                item.productNameShort = this.drug.productNameShort;
                item.packageSize = this.drug.packageSize;
                item.ndc = this.drug.ndc?.replace(/-/g,"");
                item.receivedQuantity = this.newItem.receivedQuantity;
                item.shippedQuantity = this.newItem.shippedQuantity;
            }
            this.clearNewItemForm();
        }

        setEditProduct(item:InventoryBatch.InventoryBatchItem){
            if(!item.id){
                this.batch.items = this.batch.items.filter(i => i.packageID != Number(item.packageID))
            }
            this.clearNewItemForm();
            this.scrollToBeginning();
            this.newItem = Object.assign(new InventoryBatch.InventoryBatchItem(), item);
        }

        removeProduct(item:InventoryBatch.InventoryBatchItem){
            this.batch.items = this.batch.items.filter(i => i.packageID != item.packageID);
        }

        clearNewItemForm(){
            this.drug = new Drug();
            this.newItem = new InventoryBatch.InventoryBatchItem();
            this.isCancelBatch = null;
        }

        scrollToBeginning() {
            //const swithID = 'prescription-component';
            const swithID = 'item-form';
            const switch_dom = document.getElementById(swithID);
            if (switch_dom)
                switch_dom.scrollIntoView({ block: "center", behavior: "smooth" });
        }

        confirmDialog(type:string, item:InventoryBatch.InventoryBatchItem){
            let action = type;
            action = type == 'remove product' ? 'remove the product from' : type;
            this.$bvModal.msgBoxConfirm(`Are you sure you want to ${action} this purchase order?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: type == 'cancel' ? 'danger' : 'info',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (!value) return;
                if(type == 'submit') this.submitBatch();
                if(type == 'cancel') this.cancelBatch();
                if(type == 'remove product') this.removeProduct(item);
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
        }

        submitBatch(){
            // Save and submit
            this.saveNewBatchPromise.then((res) => {
                if(res?.id){
                    const batch = res;
                    const ids = batch.items.map(item => item.id);
                    this.isSubmitting = true;
                    this.$http.post<Array<Inventory>>(`/Inventory/inventory-batch-submit`, ids)
                    .then(res => {
                        if(res.data?.length >= 0){
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset("Batch items successfully submitted"
                                , NotificationOptions.NotificationTypes.success
                            ))
                            this.clearForm();
                            this.$emit('batchSaved');
                            this.$bvModal.hide('inventory-batch-form');
                        }
                        else
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset("Selected batch items were submitted, but no Inventories were updated"
                                , NotificationOptions.NotificationTypes.warning
                            ));
                    })
                    .catch(err => {
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory Batch Item"))
                        console.error('Error while submitting Inventory Batch Item', {err, response: err?.response})
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    })
                }
            })

        }

        private isCancelBatch : boolean | null = null;
        cancelBatch(){
            this.isCancelBatch = true;
            this.saveNewBatchPromise.then((res) => {
                if(res?.id){
                    this.clearForm();
                    this.$emit('batchSaved');
                    this.$bvModal.hide('inventory-batch-form');
                }
            })
        }

        saveNewBatch(){
            this.saveNewBatchPromise.then((res) => {
                if(res?.id){
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Purchase Order"))
                    this.clearForm();
                    this.$emit('batchSaved');
                    this.$bvModal.hide('inventory-batch-form');
                }
            })
        }

        clearForm(){
            this.batch = new InventoryBatch();

            this.store = new Store();
            this.vendor = new Vendor();
            this.program = new Program();

            this.clearNewItemForm();
        }

        get saveNewBatchPromise(){
            return new Promise<InventoryBatch>((resolve, reject) => {
                if(!this.isNewBatchItemValid) reject('Invalid new batch');

                this.batch.vendorID = Number(this.vendor.id);
                this.batch.storeID = Number(this.store.id);
                if(Number(this.program.id) != 0){
                    this.batch.programID = Number(this.program.id);
                }

                this.isSaving = true;
                this.$http.post<InventoryBatch>(`/Inventory/inventory-batch/${this.isCancelBatch ? 'true' : ''}`, this.batch)
                .then(res => {
                    if(res.data.id >= 0){
                        resolve(new InventoryBatch(res.data))
                    }
                    else{
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Purchase Order"))
                        reject('No new inventory batch created.')
                    }
                })
                .catch(err => {
                    const errorMsg = 'Error while saving new Inventory Batch Item';
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory Batch Item"));
                    console.error(errorMsg, {err, response: err?.response});
                    reject(errorMsg);
                })
                .finally(() => {
                    this.isSaving = false;
                })
            })
        }

        get disableSubmit():boolean {
            return this.batch.items.some(i => !i.packageID);
        }

        get isNewBatchItemValid():boolean {
            return !!(this.store?.id && this.vendor?.id && this.batch.items?.length > 0);
        }

        get disableSave(){
            return !this.isNewBatchItemValid || this.isSaving;
        }

        get isProductFormValid():boolean{
            return !!(this.newItem.shippedQuantity && this.drug.packageID)
        }
        get disableAdd():boolean{
            // Enable until quantity and drug has been selected and drug is not repeated.
            return !!(!this.isProductFormValid || this.batch.items.some(i => i.packageID == Number(this.drug.packageID)))
        }
        get isBusy():boolean {
            return this.isSaving || this.isSubmitting;
        }
    }
