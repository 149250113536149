import HasID from './HasID';
import HasAddress from './HasAddress';
import TrackedObject from './TrackedObject';
import HasLabel from './HasLabel';

export interface Store extends HasID, HasAddress, TrackedObject {
    name: string;
    phoneNumber: string;
    faxNumber: string;
    emailAddress: string;
    webAddress: string;
    npiNumber: string;
    mailNPINumber: string;
    pidNumber: string;
    fedIDNumber: string;
    storeNumber: string;
    storeID: string;
    deaNumber: string;
    ncpdp: string;
    allowOutOfState: boolean;
    capabilities: StoreCapabilities;
    active: boolean;
}

export enum StoreCapabilities {
    TwoStepShipping = 1 << 0,
    UseUSPSInState = 1 << 1,
}

export class Store extends HasLabel {

    constructor(obj?: Store) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (this.name)
            return `${this.id} - ${this.name}`;
        else return '';
    }

    get fullAddress(): string {
        return `${this.address1 || ""} ${this.address2 || ""}, ${this.addressCity || ""}, ${this.addressState || ""} ${this.addressZip || ""}`;
    }

    static get flagOptions(): Array<string> {
        return Object.keys(StoreCapabilities)
            .filter(val => !isNaN(Number(val)))
            .map(k => StoreCapabilities[Number(k)]);
    }

    get selectedCapabilities(): string[] {
        const ret: string[] = [];
        if (this.capabilities) {
            Store.flagOptions.forEach((o: any) => {
                if (this.capabilities & (StoreCapabilities[o] as any)) ret.push(o);
            });
        }
        return ret;
    }

    set selectedCapabilities(vals: string[]) {
        this.capabilities = 0;
        vals.forEach((flag: any) => {
            const flagVal: any = StoreCapabilities[flag];
            this.capabilities |= flagVal;
        });
    }

    get selectedCapabilitiesEnums(): Array<StoreCapabilities> {
        return this.selectedCapabilities?.map<StoreCapabilities>((f: any) => (StoreCapabilities[f] as any));
    }
}
