
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { LockHandler, Lock } from '@/mixins/LockHandler';
import moment from "moment";

import { PrescriberFollowUpCase, FaxType, PrescriberFollowUpStatus } from '@/models/PrescriberFollowUpCase'
import { Prescriber } from '@/models/Prescriber';
import { Patient } from '@/models/Patient';
import { PatientDocument } from '@/models/PatientDocument';
import { Prescription } from '@/models/Prescription';
import { Drug } from '@/models/Drug/Drug.ts';
import { EntityType, Note } from '@/models/Note';

import ImageComponent from '@/components/Prescription/ImageComponent.vue';
import PrescriptionDetailsSummary from '@/components/Prescription/PrescriptionDetailsSummary.vue'
import NotesList from '@/components/Note/NotesList.vue'
import NotesForm from '@/components/Note/NotesForm.vue'
import PatientRxHistoryModalComponent from '@/components/Patient/PatientRxHistoryModalComponent.vue';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';

@Component({
    name: "DoctorFollowUpCaseForm",
    components: {
        ImageComponent,
        PrescriptionDetailsSummary,
        NotesList, NotesForm,
        PatientRxHistoryModalComponent
    }
})
export default class DoctorFollowUpCaseForm extends Mixins(LockHandler) {
    @Prop() private value!: PrescriberFollowUpCase;
    private lock: Lock | null = null;
    private isLocked = false;
    private lockMessage = '';
    private isSending = false;
    private MAX_DAYS: number = 7

    private doctor: Prescriber = new Prescriber();
    private patient: Patient = new Patient();
    private followUpCase: PrescriberFollowUpCase = new PrescriberFollowUpCase();

    private prescription: Prescription | null = null;
    private drug: Drug | null = null;

    private imageID: number = 0;
    private entityType = EntityType.PrescriberFollowUpCase;

    protected notes: Array<Note> = Array<Note>();
    protected prescriberFollowUpStatusEnum = PrescriberFollowUpStatus;
    protected requestTypeEnum = FaxType;

    loadInfo() {
        if (!this.value.id) return;

        this.isLocked = false;
        this.addLockWithURL(`/prescriber/follow-up-case/${this.value.id}/lock`, 60000)
            .then(res => this.lock = res)
            .catch(err => {
                console.log(`Added lock -- error == ${err}`);
                if (err.response && err.response.status == 418) {
                    // patient locked
                    this.isLocked = true;
                    const lock = err.response.data;
                    const expirationStr = moment.utc(lock?.expires).local().format("MMM-DD-YYYY HH:mm");
                    this.lockMessage = `The Doctor Follow-Up Case is locked by ${lock?.lockedBy} until ${expirationStr}.`;
                    this.$bvModal.msgBoxOk(this.lockMessage);
                }
            })
            .finally(() => this.fetchData());
    }

    created() {
        this.loadInfo();
    }

    @Watch('value')
    onValueChange(newValue: PrescriberFollowUpCase, oldValue: PrescriberFollowUpCase) {
        if (newValue.id && oldValue.id && newValue.id === oldValue.id) return;
        this.loadInfo();
    }

    async fetchData() {
        await this.fetchNotes();
        this.followUpCase = new PrescriberFollowUpCase(this.value);

        let resDoctor = await this.$http.get<Prescriber>(`/Prescriber/${this.value.prescriberId}`)
            .catch(err => this.catchError(err, "Error loading doctor information."));

        if (resDoctor?.data.id) this.doctor = new Prescriber((resDoctor as AxiosResponse).data);

        let resPatient = await this.$http.get<Patient>(`/Patient/${this.value.patientId}`)
            .catch(err => this.catchError(err, "Error loading patient information."));

        if (resPatient?.data.id) this.patient = new Patient(resPatient.data);

        let resDocs = await this.$http.get<Array<PatientDocument>>(`/Patient/${this.value.patientId}/get-documents`)
            .catch(err => this.catchError(err, "Error loading patient documents."));

        if (resDocs?.data.length)
            this.imageID = resDocs.data.find((doc: PatientDocument) => doc.id == this.value.documentId)?.imageId ?? 0;

        if (this.value.packageID) {
            let resDrug = await this.$http.get<Drug>(`/drug/${this.value.packageID}`)
                .catch(err => this.catchError(err, "Error loading drug information."));

            if (resDrug?.data?.packageID)
                this.drug = new Drug(null, resDrug.data);
        }

        if (this.value.rxNumber) {
            const rxID = Prescription.createRxId(this.value.storeID, this.value.rxNumber, this.value.rfNumber);
            let resRx = await this.$http.get<Prescription>(`/prescription/${rxID}`)
                .catch(err => this.catchError(err, "Error loading prescription information."));

            if (resRx?.data.rxNumber)
                this.prescription = new Prescription(resRx.data);
        }
    }

    catchError(err: any, errorMsg: string): AxiosResponse {
        console.error(errorMsg, { err, response: err?.response })
        return {} as AxiosResponse;
    }

    get prescriptionDetailsRoute() {
        return {
            name: 'PrescriptionDetails'
            , params: {
                storeID: this.prescription?.storeID,
                rxNumber: this.prescription?.rxNumber,
                rfNumber: (this.prescription?.rfNumber || 0)
            }
        };
    }

    submit(statusToSubmit: PrescriberFollowUpStatus) {
        this.followUpCase.status = statusToSubmit;

        this.isSending = true;
        this.$http.post<PrescriberFollowUpCase>('/Prescriber/follow-up-queue', this.followUpCase)
            .then(res => {
                if (res?.data.id) {
                    this.followUpCase = new PrescriberFollowUpCase(res.data);
                    this.$notification(NotificationOptions.notificationOptionsPreset(`Follow-up passed to status: ${this.followUpCase.statusName}`, NotificationOptions.NotificationTypes.success));
                    this.$emit('submitted', this.followUpCase);
                } else this.$notification(NotificationOptions.errorSaveNotificationPreset(`Follow-up case`))
            })
            .catch(err => {
                this.catchError(err, "Error submitting follow up case");
                this.$notification(NotificationOptions.errorSaveNotificationPreset(`Follow-up case`))
            })
            .finally(() => this.isSending = false)
    }

    onSavingNote() {
        this.$http.post(`/api/Prescriber/follow-up-queue`, this.followUpCase);
        this.fetchNotes();
    }

    async fetchNotes() {
        const res = await this.$http.get<Array<Note>>(`/note/${this.value.id}/${this.entityType}`)
            .catch(err => this.catchError(err, "Error loading notes."));

        if (res?.data?.length) this.notes = res.data.map((n: Note) => new Note(n));
    }

    get disableHold(): boolean {
        return moment().subtract(this.MAX_DAYS - 1, 'day').isSameOrAfter(moment(this.value.created), 'day')
    }

    confirmDialog(status: PrescriberFollowUpStatus) {
        let message = '';
        if (status == PrescriberFollowUpStatus.InProgress)
            message = "The case will be hidden for 48hrs from the queue to try again another day"
        if (status == PrescriberFollowUpStatus.NotResponded)
            message = "The case will be closed because the doctor won't be able to be contacted or the request was denied"
        if (status == PrescriberFollowUpStatus.Responded)
            message = "The case will be closed because all the information needed was gathered"
        this.$bvModal.msgBoxConfirm(`${message}. Are you sure?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'info',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.submit(status);
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
    }

    mounted() {
        document.addEventListener("keydown", this.preventDefaultForHotkeys);
        document.addEventListener("keyup", this.hotKeysListener);
    }

    beforeDestroy() {
        document.removeEventListener("keydown", this.preventDefaultForHotkeys);
        document.removeEventListener("keyup", this.hotKeysListener);
    }

    preventDefaultForHotkeys(e: any) {
        if (e.code === "F5") e.preventDefault();
    }

    hotKeysListener(e: any) {
        if (e.code === 'F5' && this.patient?.id) {
            this.openRxHistory();
        }
    }

    openRxHistory() {
        const modal = this.$refs["patient_rx_history_modal"] as PatientRxHistoryModalComponent;
        if (modal) {
            (this.$refs["patient_rx_history_modal"] as PatientRxHistoryModalComponent)?.openRxHistory();
        } else {
            console.error("Reference dont exist for the component", modal)
        }
    }
}

