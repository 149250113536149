
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';   

    import { Patient } from '@/models/Patient';    
    //import SearchComponent from '@/components/SearchComponent.vue';
    import axios, {AxiosError, AxiosRequestConfig} from 'axios'; 
    import { Table, TableColumn } from 'element-ui'   
    import StripeVueCard from '@/components/Payments/StripeVueCardcomponent.vue';
    import {CustomerInfo, Payment, StripeCard} from '@/models/Payment';
import { watchFile } from 'fs';

    @Component({
        name: "PatientPaymentMethodsModal",
        components: {StripeVueCard,
        [Table.name]: Table,
            [TableColumn.name]: TableColumn,            
        }
    })

    export default class PatientPaymentMethodsModal extends Vue {
        @Prop() patient!: Patient;
              
        private stripeCustomerId: string | null = this.patient.stripeCustomerId;
        private stripeCardList: Array<StripeCard> = [];

        created() {             
            this.stripeCustomerId = this.patient.stripeCustomerId;              
            this.loadPatientPaymentMethods();                                                       
        }  
        @Watch('patient.stripeCustomerId')
        refresh() { 
            this.stripeCustomerId = this.patient.stripeCustomerId;              
            this.loadPatientPaymentMethods();                                                       
        }              

        newCardAdded(stripeResponse:StripeCard){           
            this.stripeCustomerId = stripeResponse.customerId;
            this.loadPatientPaymentMethods();
            //this.stripeCardList.push(response.data);
        } 
        async loadPatientPaymentMethods(){
            let sc: number = 0;
            let scGood: number[] = [200,204];
            if (!this.stripeCustomerId) return; 
            const resultx = await this.fetchPatientPaymentMethods()
            .then(value => {                                       
                     sc=value;
                     this.stripeCardList = this.stripeCardList.filter(p => p.pendingDelete == false); 
                     return;                  
                    });                
        }
        //@Watch('stripeCustomerId') 
        fetchPatientPaymentMethods(): Promise<number | any> {
            let scValid: number = 0; 
            let errCode: any;                                             
            return axios.get(`/stripe/cards/${this.patient.stripeCustomerId}`)
                    .then(response => {
                        this.stripeCardList = response.data;                        
                        scValid = Number(response.status);
                        return scValid;
                    })
                    .catch(error => {
                        console.error("Error while getting Payment Cards from Patient", {error, response: error?.response})
                        //var errCode =  error.response? error.response.status : 0;
                        return error.response?.status 
                    })
                    .finally(() => {               
            });                                                              
            //return scValid? scValid : errCode;  
        }
        async onDeleteCard(card: StripeCard){
            let sc: number = 0;
            let scGood: number[] = [200,204];
            const resultx = await this.deleteCard(card)
            .then(value => {                                       
                     sc=value;                   
                    }
                );
            this.stripeCardList = [];
            this.loadPatientPaymentMethods();         
        }
        deleteCard(card: StripeCard): Promise<number | any> {
            //this.stripeCustomerId = stripeResponse.customerId; 
            let scValid: number = 0; 
            let errCode: any;          
             return axios.delete(`/stripe/${card.id}?patientid=${this.patient.id}&customerid=${card.customerId}`)
                    .then(response => {
                        scValid = Number(response.status);
                        return scValid;
                    })
                    .catch(error => {
                        console.error("Error deleting Payment Card from Patient", {error, response: error?.response})
                        var errCode =  error.response? error.response.status : 0;
                        return errCode                                                
                    });
                    return scValid? scValid : errCode;                                  
            } 
         deleteConfirmDialog(item: StripeCard){
            this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this Payment Method?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (!value) return;
                this.onDeleteCard(item);
            })
            .catch(err => {
                console.error("Error caught on Delete button.", err);
            });
        }               
    }
