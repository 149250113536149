
    import Axios from 'axios';
    import { Table, TableColumn } from "element-ui";
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { DispenseError } from '@/models/DispenseError';
    import { Store } from '@/models/Store';

    import DispensingErrorModalComponent from '@/components/DispensingError/DispensingErrorModalComponent.vue'
    import DispensingErrorCardComponent from '@/components/DispensingError/DispensingErrorCardComponent.vue'
    import SearchComponent from '@/components/SearchComponent.vue'
    import Checkbox from "@/components/Inputs/Checkbox.vue";

    @Component({
        name: "DispenseErrorListComponent",
        components:{
            DispensingErrorModalComponent,DispensingErrorCardComponent,
            SearchComponent, Checkbox,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        }
    })
    export default class DispenseErrorListComponent extends Vue {
        @Prop({default: () => { return [] } }) private statusFilter! : Array<DispenseError.DispenseErrorStatusEnum>
        @Prop() private cardsMode!: boolean;
        @Prop() private hideStatusFilter!: boolean;

        private statusEnums = DispenseError.DispenseErrorStatusEnum;
        private errors : Array<DispenseError> = [];
        private selectedDispenseError : DispenseError = new DispenseError();
        private storeFilter: Store = new Store();
        private pharmacistCreatedErrors = false;
        private filterCreated = false;
        private filterAccepted = false;
        private filterResolved = false;


        created(){
            this.filterCreated = this.statusFilter.some(st => st == DispenseError.DispenseErrorStatusEnum.Created);
            this.filterResolved = this.statusFilter.some(st => st == DispenseError.DispenseErrorStatusEnum.Resolved);
            this.filterAccepted = this.statusFilter.some(st => st == DispenseError.DispenseErrorStatusEnum.Accepted);
            
            this.storeFilter.id = this.persistentStoreFilterId || 0;

            this.loadErrors();
        }

        loadErrors(){

            Axios.get("DispenseError", { 
                params: { 
                    statusFilter : this.strStatusFilter, 
                    storeID: this.storeFilter?.id,
                    onlyAssignedErrors: this.onlyAssignedErrors
                } 
            })
            .then(response => {
                this.errors = response.data.map((d:DispenseError) => Object.assign(new DispenseError(), d));
                if(this.errors?.length)
                    this.errors = this.errors.sort(this.compareFunc);
                if (this.pharmacistCreatedErrors) {
                    this.errors = this.errors.filter(e => e.createdBy == e.viewedBy)
                }
            })
            .catch(error => {
                console.error("Error while listing dispense errors.", {error, response: error?.response})
            })
        }

        private compareFunc = (a:DispenseError, b:DispenseError):number => {
            const compStatus = this.statusPriority(a.statusEnum) - this.statusPriority(b.statusEnum);
            if(compStatus == 0)
            {
                let dateA = new Date(a.created);
                let dateB = new Date(b.created);
                return dateA.getTime() - dateB.getTime();
            } else return compStatus;
        }

        statusPriority(statusNbr:DispenseError.DispenseErrorStatusEnum):number {
            if(statusNbr == DispenseError.DispenseErrorStatusEnum.Resolved) return 0
            else if(statusNbr == DispenseError.DispenseErrorStatusEnum.Created) return 1
            else if(statusNbr == DispenseError.DispenseErrorStatusEnum.Accepted) return 2
            else return 100;
        }

        get persistentStoreFilterId(): number|null {
            const val = Number(localStorage.getItem('dispenseError_storeFilterId'));
            return val;
        }

        set persistentStoreFilterId(storeId:number|null){
            localStorage.setItem('dispenseError_storeFilterId', (storeId || 0).toString());
        }

        get strStatusFilter():string {
            let res = '';
            if(this.selectedStatusFilter?.length)
                res = this.selectedStatusFilter.map(f => Number(f).toString()).reduce((f1, f2) => f1+','+f2)
            return res;
        }

        get selectedStatusFilter() : Array<DispenseError.DispenseErrorStatusEnum | null> {
            let res : Array<DispenseError.DispenseErrorStatusEnum | null> = [
                this.filterCreated ? DispenseError.DispenseErrorStatusEnum.Created : null
                ,this.filterResolved ? DispenseError.DispenseErrorStatusEnum.Resolved : null
                ,this.filterAccepted ? DispenseError.DispenseErrorStatusEnum.Accepted : null
            ].filter(st => st != null);

            return res;
        }

        get onlyAssignedErrors():boolean{
            const onlyAssigned = Boolean(this.$route.params.onlyAssigned);
            return onlyAssigned;
        }

        openErrorDispenseModal(dispenseErrorId:number){
            // Load Error
            Axios.get(`DispenseError/${dispenseErrorId}`)
            .then(response => {
                this.selectedDispenseError = response.data;
                //Open modal with error details
                this.$bvModal.show("dispense-error-modal");
            })
            .catch(err => {
                console.error("Error while getting dispense error details", err);
            })

        }

        statusStyle(status: DispenseError.DispenseErrorStatusEnum)
        {
            if(status == DispenseError.DispenseErrorStatusEnum.Accepted)
                return "info";
            else if (status == DispenseError.DispenseErrorStatusEnum.Resolved)
                return "success";
            else return "warning";
        }

        storeFilterChanged(store:Store){
            this.persistentStoreFilterId = Number(store?.id);
            
            this.loadErrors();
        }
        
    }

