import {AxiosError} from "axios";

export class NotificationOptions {
    static presetBase = {
        message:"",
        timeout: 10000,
        icon: "now-ui-icons ui-1_bell-53",
        horizontalAlign: "center",
        verticalAlign: "top",
        type:""
    };

    static error(message: any): any {
        return this.notificationOptionsPreset(message, NotificationOptions.NotificationTypes.danger);
    }

    static notificationOptionsPreset(message: any, typeMessage: NotificationOptions.NotificationTypes): any {
        function isAxiosError(item: any): item is AxiosError {
            return (item as AxiosError).isAxiosError !== undefined;
        }

        if (isAxiosError(message)){
            message = message.response?.data;
        }
        if (message.message) message = message.message;

        this.presetBase.message = message;
        this.presetBase.type = NotificationOptions.NotificationTypes[typeMessage];
        return this.presetBase;
    }

    static successSaveNotificationPreset(entityName: string): any{
        this.presetBase.message = `${entityName} Successfully Saved`;
        this.presetBase.type = "success";
        return this.presetBase;
    }

    static successDeletedNotificationPreset(entityName: string): any{
        this.presetBase.message = `${entityName} Successfully Deleted`;
        this.presetBase.type = "success";
        return this.presetBase;
    }

    static errorSaveNotificationPreset(entityName: string, error?: AxiosError): any {

        this.presetBase.message = `${entityName} was not able to be saved`;
        this.presetBase.type = "danger";
        if (!error?.response?.data && error?.response?.status === 403) error.response.data = "You're not authorized to perform this action.";
        if (error?.response?.data) {
            this.presetBase.message += ` - ${error.response.data}`;
        }
        return this.presetBase;
    }
}

export namespace NotificationOptions {

    export enum NotificationTypes {
        success,
        danger,
        warning,
        info
    }
}
