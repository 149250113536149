

    import { Component, Vue } from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { Patient } from '@/models/Patient';
    import { Order } from '@/models/Order';
    import SearchComponent from '@/components/SearchComponent.vue';

    import PatientDemographicsComponent from '@/components/Patient/PatientDemographicsComponent.vue'

    @Component({
        name: "MergePatientsPage",
        components: { PatientDemographicsComponent, SearchComponent }
    })
    export default class MergePatientsPage extends Vue { 
        private sourcePatient = new Patient();
        private targetPatient = new Patient();
        private sourcePatientOrders = new Array<Order>();
        
        get mergeInformation():string {
            return "All records from source patient will be associated to the target patient and the source patient record will be deleted from the database. "
            + "The data to be merged is: prescriptions, orders, payments, auto-refill subscriptions, cases, consultations, documents, notes, allergies and programs.";
        }

        get disableSubmit() : boolean{
            return !this.sourcePatient?.id || !this.targetPatient?.id || this.sourcePatientHaveActiveOrders;
        }

        get sourcePatientHaveActiveOrders():boolean {
            return !!this.sourcePatientOrders?.length;
        }

        updated(){
            console.log('sourcePatientHaveActiveOrders', this.sourcePatientHaveActiveOrders);
            console.log('sourcePatientOrders', this.sourcePatientOrders);
        }

        loadPatientPromise(patientID:number|string):Promise<Patient|string>{
            return new Promise<Patient | string>((resolve, reject)=> {
                this.$http.get<Patient>(`/Patient/${patientID}`)
                .then(res => {
                    if(res.data?.id) resolve(res.data);
                    else reject('Patient Not Found');
                })
                .catch(err => {
                    let errorMsg = "Error loading Source Patient Orders";
                    console.error(errorMsg, {err, response: err?.response});
                    reject(errorMsg);
                })
            })
        }
        
        private loadingSourcePatient = false;
        fetchSourcePatientOrders(sourcePatient:Patient){
            if(!sourcePatient?.id) return;
            
            this.sourcePatientOrders = new Array<Order>();
            this.loadingSourcePatient = true;
            this.loadPatientPromise(sourcePatient.id)
            .then(res => {
                if((res as Patient)?.id){
                    this.sourcePatient = new Patient(res as Patient);

                    this.$http.get<Array<Order>>(`/Patient/${sourcePatient.id}/orders/`)
                    .then(res => {
                        if(res.data?.length) 
                            this.sourcePatientOrders = res.data.map(o => new Order(o));
                    })
                    .catch(err => {
                        console.error("Error loading Source Patient Orders", {err, response: err?.response});
                    })
                    .finally(() => {
                        this.loadingSourcePatient = false;
                    })
                }
            })
        }

        private loadingTargetPatient = false;
        loadTargetPatient(targetPatient:Patient){
            if(!targetPatient) return;

            this.loadingTargetPatient = true;
            this.loadPatientPromise(targetPatient.id)
            .then(res => {
                if((res as Patient)?.id) this.targetPatient = new Patient(res as Patient);
            })
            .finally(() => {
                this.loadingTargetPatient = false;
            })
        }

        confirmDialog(){
            this.$bvModal.msgBoxConfirm("This action is not reversible. Are you sure?", {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (!value) return;
                this.submitMerge();
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
        }

        private isMerging = false;
        submitMerge(){
            if(!this.sourcePatient?.id || !this.targetPatient?.id) return;
            
            this.isMerging = true;
            let errorMsg = 'Error while merging patients.'
            this.$http.post<Patient>(`/patient/merge-patients/${this.sourcePatient.id}/${this.targetPatient.id}`)
            .then(res => {
                if(res.data?.id){
                    this.targetPatient = new Patient(res.data);
                    this.sourcePatient = new Patient();
                    this.$notification(NotificationOptions.notificationOptionsPreset("Source patient was successfuly merged into target patient and removed from database.", NotificationOptions.NotificationTypes.success));
                } else {
                    this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));  
                    console.error(errorMsg, {res, response: res?.data});  
                }
            })
            .catch(err => {
                if(err?.response?.status == 403)
                    errorMsg = "You don't have permissions to execute this functionality."
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, {err, response: err?.response});
            })
            .finally(() => this.isMerging = false)
        }
    }
