
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator';
    import Axios from 'axios';
    import { DatePicker } from "element-ui";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets"

    import { Prescription } from '@/models/Prescription';
    import { Store } from '@/models/Store';

    import SearchComponent from '@/components/SearchComponent.vue';
    import EnumSelector from '@/components/EnumSelector.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import moment from "moment";

    //import Card from '@/components/Cards/Card.vue';

    @Component({
        name: "PendingForBillingQueuePage",
        components: {
            SearchComponent,
            EnumSelector,
            [DatePicker.name]: DatePicker,
            PrescriptionsListComponent
        },
    })
    export default class PendingForBillingQueuePage extends Vue {
        private prescriptions:Array<Prescription> = [];
        private storeFilter: Store = new Store();
        private fillDate:Date = moment().toDate();

        created(){
            this.search();
        }

        search(){
            Axios.get(`/Prescription/get-pending-billing/${this.storeFilter?.id || ''}`)
            .then(response => {
                this.prescriptions = response.data;
            })
            .catch(error => {
                console.warn("Error while fetching prescriptions by date",{error, response: error?.response});
            });
        }

        processQueue(){
            //Get into the batch only those for today
            (this.$refs.prescriptionList as any).openBatch(this.prescriptions);
        }

        get disableProcessButton():boolean{
            return !this.prescriptions.length;
        }
    }

