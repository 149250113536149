
    import { Inventory } from '@/models/Inventory';
    import { Store } from '@/models/Store';
    import { Program } from '@/models/Program';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Table, TableColumn } from 'element-ui';
    import axios from 'axios';
    import SearchComponent from '@/components/SearchComponent.vue';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    @Component({
        name: "InventoryComponent",
        components: {
            SearchComponent,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        }
    })
    export default class InventoryComponent extends Vue {

        @Prop({ default: 0 }) private drugSource!: Number;
        @Prop() private packageId!: string;
        @Prop() private billingUnit!: string;
        @Prop() private inventories!: Inventory[];

        private showForm: boolean = false;
        private store: Store = new Store();
        private program: Program = new Program();

        private inventory: Inventory = new Inventory();

        private inventoryFields = [
            { key: "programName", label: "Program" },
            { key: "storeName", label: "Store" },
            "quantity",
            "allocated",
            { key: "net", label: "Net", formatter: this.netFormatter },
            { key: "actions", label: "" },
        ];

        netFormatter(value: any, key: number, item: Inventory) {
            const netAmount = (item.quantity || 0) - (item.allocated || 0);
            return netAmount;
        }

        submitInformation() {
            this.inventory.drugSource = Number(this.drugSource);
            this.inventory.packageId = Number(this.packageId);
            this.inventory.billingUnit = this.billingUnit;
            this.inventory.storeId = Number(this.store.id);
            this.inventory.programId = Number(this.program.id);

            let updateInventory = this.inventories.find(item => item.storeId == this.inventory.storeId && (item.programId || 0) == (this.inventory.programId || 0));
            if (updateInventory) {
                this.inventory.id = updateInventory.id;
                updateInventory.quantity = this.inventory.quantity;
            }
            axios.post<Inventory>('/Inventory/', this.inventory)
                .then(response => {
                    if (response.data?.id) {
                        if (!updateInventory) this.inventories.push(new Inventory(response.data));
                        this.$notification(NotificationOptions.successSaveNotificationPreset("Inventory"));
                    }
                    this.clearForm();
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory"));
                    console.error("Error while updating inventory.", { error, response: error?.response });
                })
                .finally(() => {
                    this.closeForm();
                });
        }

        updateInventory(item: Inventory) {
            this.showForm = true;
            this.store.id = item.storeId;
            this.program.id = Number(item.programId || 0);
            this.inventory.quantity = item.quantity;
        }

        deleteInventoryConfirmDialog(item: Inventory) {
            this.$bvModal.msgBoxConfirm(`This inventory record associated to this drug will get deleted. Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (!value) return;
                    this.deleteInventory(item);
                })
                .catch(err => {
                    console.error("Error caught on Clear button.", err);
                });
        }

        deleteInventory(item: Inventory) {
            if (!item?.id) return;

            this.$http.delete(`inventory/${item.id}`)
                .then(res => {
                    if (res.data) {
                        this.$notification(NotificationOptions.notificationOptionsPreset("Inventory record successfully deleted.", NotificationOptions.NotificationTypes.success));
                        this.$emit('removed');
                    }
                })
                .catch(err => {
                    const errorMsg = "Not possible to delete inventory record.";
                    this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                    console.error(errorMsg, { err, response: err?.response });
                });
        }

        openForm() {
            this.clearForm();
            this.showForm = true;
        }

        closeForm() {
            this.clearForm();
            this.showForm = false;
        }

        clearForm() {
            this.inventory = new Inventory();
            this.store = new Store();
            this.program = new Program();
        }

    }
