import TrackedObject from "./TrackedObject";
import { Prescription } from "./Prescription";
import { Prescriber } from "./Prescriber";
import { Patient } from "./Patient";
import TextUtils from "@/util/TextUtils";

export interface PriorAuthorization extends TrackedObject {
    id: number;
    coverMyMedsId: string;
    prescription: Prescription;
    prescriber: Prescriber;
    patient: Patient;
    drugManufacturer: string;
    status: PriorAuthorizationStatusCode;
    completeReason: string;
    continuationOfTherapy: boolean;
    responses: CoverMyMedsResponse[];
    latestCallback: CoverMyMedsCallback;
}

export enum PriorAuthorizationStatusCode {
    Initiated, //Active
    SentToPrescriber, //Active
    Abandoned,
    SubmittedToInsurance, //Active
    Approved,
    Denied,
    Cancelled,
    AnticipatedApproval //Active
}

export class PriorAuthorization{
    constructor(obj?:PriorAuthorization){
        if(obj) {
            Object.assign(this, obj);
            this.patient = new Patient(obj.patient);
        }
    }

    get statusName():string {
        return TextUtils.camelCaseToNormal(PriorAuthorizationStatusCode[this.status]);;
    }
}

// ReSharper disable InconsistentNaming
export interface CoverMyMedsCallback {
    event_key: string;
    message: string;
    pharmacy_npi: string;
    rx_number: string;
    error: string;
}
// ReSharper restore InconsistentNaming

export interface CoverMyMedsResponse {
    actions: any;
    message: string;
    primaryAction: CoverMyMedsAction;
    requiredAction: CoverMyMedsAction;
    status: string;
    error: string;
}

export interface CoverMyMedsAction {
    buttonLabel: string;
    description: string;
    url: string;
}
