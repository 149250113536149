
    import Vue from 'vue';
    import { Component } from 'vue-property-decorator';
    import { DatePicker } from "element-ui";

    import SearchComponent from '@/components/SearchComponent.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';

    import { Prescription } from '@/models/Prescription';
    import { Store } from '@/models/Store';
    import Checkbox from "@/components/Inputs/Checkbox.vue";

    @Component({
        name: "PV1Queue",
        components: {
            SearchComponent,
            [DatePicker.name]: DatePicker,
            PrescriptionsListComponent,
            Checkbox
        },
    })
    export default class PV1Queue extends Vue {
        protected prescriptions: Array<Prescription> = [];
        private storeFilter: Store = new Store();

        private isLoading: boolean = false;
        private actionButtons = ['pv1'];

        load() {
            if (this.storeFilter.id) {
                this.isLoading = true;

                this.$http.get('/prescription/pv1-queue')
                    .then(response => {
                        this.prescriptions = response.data;
                    })
                    .catch(error => {
                        console.error("Error while loading PV1 queue.", { error, response: error?.response });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            }
        }
    }

