export interface Configuration {
    refillTextDays: number;
    // testDate: Date;

}

export class Configuration {
    constructor(obj?: Configuration) {
        this.refillTextDays = 0;
        // this.testDate = new Date(2022,5,1);

        if (obj) {
            Object.assign(this, obj);
            // this.testDate = new Date(obj.testDate);
        }
    }

    toString(): string {
        return JSON.stringify(this);
    }

    typeOf(name: string): string {
        // using a default populated Configuration object here so that when we change the values in the inputs
        // it doesn't think that the type has changed. (eg. number type going blank will then switch to string type)
        const conf = new Configuration();
        type configType = keyof typeof conf;
        const val = conf[name as configType];
        const valType = typeof val;

        if (valType != "object") return valType;
        if ((val as any) instanceof Date) return 'Date';

        return valType;
    }
}
