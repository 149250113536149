
    import axios, {AxiosRequestConfig} from 'axios';
    import {Component, Vue} from 'vue-property-decorator';
    import SearchComponent from '@/components/SearchComponent.vue';
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
    import {TransferRXRecord, PharmacyRecord} from "@/models/Pharmacy"
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import PDFObject from "pdfobject";

    @Component({
        name: 'TransferRxReportPage',
        components: {
            SearchComponent,
            NewClearSaveButtons,
            Checkbox
        }
    })
    export default class TransferRxReportPage extends Vue{
        private items:Array<TransferRXRecord> = new Array<TransferRXRecord>();
        private isLoading = false;
        private isLoadingPDF = false
        private RXdetails: TransferRXRecord = new TransferRXRecord()
        private fields: any[] = [
            // { key: "id", label: 'ShortageId'},
            { key: "patientName", label: "Patient Name"},
            { key: "drugName", label: "Drug Name"},
            { key: "transferredDate", label: "Date Transferred"},
            { key: "transferredBy", label: "Transferred By"},
            { key: "isVerbalTransfer", label: "Transferred Method"},
        ];
        private pharmacyfilter: PharmacyRecord = new PharmacyRecord();
        created() {
            console.log("%c Created AuditTrackingPage", "color: green");
            this.loadItems(0);
        }
        
        loadItems(value: number){
            this.isLoading = true;
            axios.get<Array<TransferRXRecord>>(`StoredPharmacy/getAllTransferredRx/${value}`)
            .then(res => {
                this.items = res.data.map(i => new TransferRXRecord(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
            })
            .finally(()=>{
                this.isLoading = false;
            });
        }

        openPDFpreview(details:TransferRXRecord){
        this.$bvModal.show("pdf-preview");
        this.RXdetails = details;
        this.isLoadingPDF = true;
        const config: AxiosRequestConfig = {
            params: { filename: details.docFileName },
            responseType: "blob",
        };
        axios
            .get<any>("StoredPharmacy/get-transfer-document-filename", config)
            .then((res) => {
                        const objectUrl = URL.createObjectURL(res.data);
                        const options = {
                            pdfOpenParams: {
                                view: "FitW",
                                pagemode: "thumbs",
                                toolbar: 1,
                                scrollbar: 0,
                            },
                        };
                        PDFObject.embed(objectUrl, "#pdfPreview", options);
                        this.isLoadingPDF = false;
            })
            .catch((err) => {
                console.error("Error while viewing a document.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
                this.isLoadingPDF = false;
            });

        }
        reSendRX(){
            const config: AxiosRequestConfig = {
            params: { filename: this.RXdetails.docFileName, storedPharmacyId: this.RXdetails.storedPharmacyId, transferRxId: this.RXdetails.id}
                };
        axios
            .get<any>("StoredPharmacy/resend-transfer-document", config)
            .then((res) => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Document has been resend successfully", NotificationOptions.NotificationTypes.success));
                
            })
            .catch((err) => {
                console.error("Error while resending the document.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
                this.isLoadingPDF = false;
            });
        }
        onPharmacyChange(value: PharmacyRecord) {
            if(value==null){
                this.loadItems(0);
            }else{
                this.loadItems(value.id);
            }
        }
    }
