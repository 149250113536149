
    import Vue from 'vue';
    import Axios from 'axios';
    import { Component } from 'vue-property-decorator';
    import { SigTranslation } from "@/models/SigTranslation";

    @Component({
        name: 'SigMaintenancePage',
        components: {
        },
    })
    export default class SigMaintenancePage extends Vue {

        private sigs: Array<SigTranslation> = [];
        private newSig: SigTranslation = {} as SigTranslation;
        private sigFilter: string = "";
        private currentPage = 1;
        private filteredSigs: Array<SigTranslation> = [];
        private fields: any[] = [
            { key: "shortCode" },
            { key: "expansion" },
            { key: "options" }
        ];
        created() {
            this.getSigs();
        }

        addSig() {
            if (this.newSig.shortCode && this.newSig.expansion) {
                Axios.post('SigTranslation/Add/', this.newSig).then(response => {
                    this.getSigs();
                    this.$notification({
                        message: "Sig Added",
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success"
                    });
                    this.newSig.shortCode = "";
                    this.newSig.expansion = "";
                }).catch(error => {
                    console.error(error);
                    this.$notification({
                        message: "Failed to add Sig: " + error.response?.data.substring(0, 100),
                        timeout: 50000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger"
                    });
                });
            }
        }

        getSigs() {
            Axios.get('SigTranslation/')
                .then(response => {
                    this.sigs = response.data;
                    if (this.sigFilter != "") {
                        this.filterSigs();
                    }
                    else {
                        this.filteredSigs = response.data;
                    }

                })
                .catch(error => {
                    console.warn(error);
                });
        }

        removeSig(sigId: any) {
            Axios.post('SigTranslation/Remove/' + sigId.item.id)
                .then(response => {
                    this.getSigs();
                    this.$notification({
                        message: "Sig Removed",
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success"
                    });
                })
                .catch(error => {
                    this.$notification({
                        message: "Failed to remove sig: " + error.response?.data.substring(0, 100),
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger"
                    });
                });
        }

        filterSigs() {
            if (this.sigFilter != "") {
                this.filteredSigs = this.sigs.filter(s => s.expansion.includes(this.sigFilter.toUpperCase()) || s.shortCode.includes(this.sigFilter.toUpperCase()));
            }
            if (this.sigFilter == "")
                this.filteredSigs = this.sigs;

        }
    }

