import { Person } from "./Person";

export interface Prescriber extends Person {
    emailAddress: string;
    phoneNumber: string;
    altNumber: string;
    faxNumber: string;

    deaNumber: string;
    npiNumber: string;

    stateLicenseNumber: string;
    sureScriptsId: string;
    programId: number | null;

    allowsPa: boolean;
    reminderOptOut: boolean;
    alert: string;
    
}

export class Prescriber extends Person {
    constructor(prescriber?: Prescriber) {
        super();
        this.allowsPa = true;
        if (prescriber) Object.assign(this, prescriber);
    }
    toString(): string {
        if(this.id && this.lastName)
            return "ID: " + this.id + " " + this.lastName 
                + (this.firstName ? ", " + this.firstName : "")
                + (this.fullAddress ? ", " + this.fullAddress : "");
        else return ''
    }

}
