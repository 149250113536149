
    import Vue from 'vue';
    import { Component, Watch } from 'vue-property-decorator';
    import Axios from 'axios';
    import { DatePicker } from "element-ui";

    import SearchComponent from '@/components/SearchComponent.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';

    import { Prescription } from '@/models/Prescription';
    import { Program } from '@/models/Program';
    import { Store } from '@/models/Store';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import moment from "moment";
    import { DeliveryCode } from "@/models/DeliveryCode";
    import { OrderLabelData } from "@/models/OrderLabelData";

    @Component({
        name: "PrintQueuePage",
        components: {
            SearchComponent,
            [DatePicker.name]: DatePicker,
            PrescriptionsListComponent,
            Checkbox
        },
    })
    export default class PrintQueuePage extends Vue {
        private prescriptions: Array<OrderLabelData> = [];
        private selectedRxsIn: Array<Prescription> = [];
        private selectedRxsOut: Array<Prescription> = [];
        private printerAddress: string = "";
        private rxToPrint: Prescription = new Prescription();
        private N_ORDERS_SELECTION = 10;
        private showOutOfStocks = false;
        private showConsignment = false;
        private outOfStockValue = '';
        private programFilter: Program = new Program();
        private storeFilter: Store = new Store();
        private shouldOverride: boolean = false;
        private needsOverride: boolean = false;
        private ipAddress: string = "";

        get pageMode() {
            return this.$route.name;
        }

        private isLoading: boolean = false;
        private isPrinting: boolean = false;
        private isPrintingRxRf: boolean = false;
        private isPrintingBadge: boolean = false;

        private additionalFields: Array<any> = [
            {
                index: null,
                field: { key: "orderDate", label: "Order Date", sortable: true, formatter: this.dateFormatter },
            },
            {
                index: null,
                field: { key: "drugsFilledDate", label: "Filled", sortable: true, formatter: this.filledDateFormatter },
            },
            {
                index: 1,
                field: { key: "ndc", label: "NDC", sortable: true, formatter: this.ndcFormatter },
            }
        ];
        private fieldsToRemove: Array<string> = ['qtyLeft'];

        private priorityCodes = [DeliveryCode.Overnight, DeliveryCode.StandardOvernight];

        private currentSort: string = "";
        private currentDesc: boolean = true;

        @Watch("currentSort")
        @Watch("currentDesc")
        currentSortUpdated(newValue: string, oldValue: string) {
            let scripts = this.prescriptions.sort((a, b) => this.customSort(a, b, this.currentSort, this.currentDesc));
            if (this.currentDesc) scripts = scripts.reverse();
            this.prescriptions = scripts;
        }

        mounted() {
            this.storeFilter = new Store(this.lastStore) ?? new Store();
        }

        dateFormatter(value: Date, _key: string, _item: any) {
            if (!value) {
                return "";
            }
            return moment.utc(value).format("L");
        }

        filledDateFormatter(value: Date, key: string, item: any) {
            if (!value || item.fillDate > item.drugsFilledDate) {
                return "";
            }
            return moment.utc(value).format("L");
        }

        ndcFormatter(value: string) {
            if (value == null) {
                return value;
            }
            return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
        };

        loadOrders() {
            if (this.storeFilter.id) {
                this.isLoading = true;
                let url = `/Print/prescriptions-pending/${this.storeFilter.id}/${(this.programFilter.id || '')}`;
                if (this.pageMode == "StalePrintQueue") {
                    url = `/Print/GetStalePrintQueue/${this.storeFilter.id}/${(this.programFilter.id || '')}`;
                }

                //Get patient's prescriptions and format the data to be shown in the grid.
                Axios.get<OrderLabelData[]>(url, {
                    params: {
                        outOfStock: this.showOutOfStocks,
                        isConsignment: this.showConsignment
                    }
                })
                    .then(response => {
                        let scripts = response.data.map((rx: any) => {
                            return new OrderLabelData(rx);
                        });
                        scripts = scripts.sort(this.sortDeliveryCode);
                        this.prescriptions = scripts;
                    })
                    .catch(error => {
                        console.warn("Error while loading print queue.", { error, response: error?.response });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }

        private toString(value: any): string {
            if (value === null || typeof value === "undefined") {
                return "";
            }
            if (value instanceof Object) {
                return Object.keys(value)
                    .sort()
                    .map(key => this.toString(value[key]))
                    .join(" ");
            }
            return String(value);
        }

        get customSort(): (a: Prescription, b: Prescription, sortBy?: string, sortDesc?: boolean, formatter?: Function, compareOptions?: any, compareLocale?: any) => number {
            return (a: Prescription, b: Prescription, sortBy?: string, sortDesc?: boolean, formatter?: Function, compareOptions?: any, compareLocale?: any) => {
                if (sortBy == undefined || sortBy === "deliveryCode" || sortBy === "") {
                    return this.sortDeliveryCode(b, a);
                }
                const aSort = (a as any)[sortBy];
                const bSort = (b as any)[sortBy];
                if ((typeof aSort === "number" && typeof bSort === "number") ||
                    (aSort instanceof Date && bSort instanceof Date)) {
                    return a < b ? -1 : a > b ? 1 : 0;
                } else {
                    return this.toString(aSort).localeCompare(this.toString(bSort), compareLocale, compareOptions);
                }
            };
        }

        get sortDeliveryCode(): (a: Prescription, b: Prescription) => number {
            return (a: Prescription, b: Prescription) => {
                if (this.priorityCodes.includes(b.deliveryCode)) return 1;
                if (this.priorityCodes.includes(a.deliveryCode)) return -1;
                if (b.deliveryCode == DeliveryCode.WillCall) return 1;
                if (a.deliveryCode == DeliveryCode.WillCall) return -1;
                return 0;
            };
        }

        get lastStore(): any {
            const objStr = localStorage.getItem('printQueue_lastStore');
            if (objStr)
                return JSON.parse(objStr);
            else return null;
        }

        @Watch('storeFilter')
        lastStoreChanged(val1: any, _val2: any) {
            if (val1 == new Store()) {
                return;
            }
            localStorage.setItem('printQueue_lastStore', JSON.stringify(val1));

        }

        @Watch('showConsignment')
        @Watch('showOutOfStocks')
        @Watch('storeFilter')
        @Watch('programFilter')
        reloadOrders(_newValue: any, _oldValue: any) {
            this.loadOrders();
        }

        @Watch('pageMode') pageModeChanged() {
            this.clearFilters();
            this.prescriptions = [];
            this.selectedRxsIn = [];
            this.selectedRxsOut = [];
            this.outOfStockValue = '';
            this.rxToPrint = new Prescription();
        }

        get allowPrinting() {
            return this.selectedRxsOut?.length;
        }

        selectOrders() {
            this.selectedRxsIn = this.prescriptions.slice(0, this.N_ORDERS_SELECTION);
            this.selectedRxsOut = this.selectedRxsIn;
        }

        rxSelectionChanged(rows: Array<Prescription>) {
            this.selectedRxsOut = rows;
        }

        async printWithoutPatEd() {
            this.isPrinting = true;
            for await (const script of this.selectedRxsOut) {
                await Axios.post(`/Print/SkipPatEd/${script.storeID}/${script.rxNumber}/${script.rfNumber}/${script.orderId}?ipAddress=${this.printerAddress}`);
            }

            this.loadOrders();
            this.isPrinting = false;
        }

        print() {
            this.isPrinting = true;
            //Get patient's prescriptions and format the data to be shown in the grid.
            const ids = {
                PrintMode: this.pageMode,
                Labels: this.selectedRxsOut.map(rx => {
                    return {
                        OrderId: rx.orderId,
                        StoreId: rx.storeID,
                        RxNumber: rx.rxNumber,
                        RfNumber: rx.rfNumber,
                        ProgramId: rx.programID
                    };
                })
            };
            //Get patient's prescriptions and format the data to be shown in the grid.
            Axios.post(`/Print/prescriptions-pending`, ids)
                .then(response => {
                    const printedLabels = response.data.printedLabels || 0;
                    const sentLabels = ids.Labels.length || 0;
                    // Notify the number of labels printed
                    if (printedLabels)
                        this.$notification(NotificationOptions.notificationOptionsPreset(`Printed ${printedLabels} labels.`, NotificationOptions.NotificationTypes.success));
                    else if (sentLabels > printedLabels) {
                        this.$notification(NotificationOptions.notificationOptionsPreset(`${sentLabels - printedLabels} labels were not printed.`, NotificationOptions.NotificationTypes.danger));
                        console.error("Errors while printing", response.data);
                    }
                    // Reload Print Queue.
                    this.loadOrders();
                })
                .catch(error => {
                    console.warn(error);
                    this.$notification(NotificationOptions.notificationOptionsPreset(`Not able to print labels`, NotificationOptions.NotificationTypes.danger));
                })
                .finally(() => {
                    this.isPrinting = false;
                });
        }

        removeWithoutPrinting() {
            const ids = {
                Labels: this.selectedRxsOut.map(rx => {
                    return {
                        OrderId: rx.orderId,
                        StoreId: rx.storeID,
                        RxNumber: rx.rxNumber,
                        RfNumber: rx.rfNumber
                    };
                })
            };
            Axios.post(`/Print/RemoveFromPrintQueue`, ids)
                .then(_response => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(`Labels Removed`, NotificationOptions.NotificationTypes.success));
                    this.loadOrders();
                })
                .catch(_error => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(`Unable to Remove Labels`, NotificationOptions.NotificationTypes.danger));
                });
        }

        printByRxRf() {
            this.rxToPrint.storeID = Number(this.storeFilter.id);
            this.rxToPrint.rfNumber = this.rxToPrint.rfNumber || 0;
            console.log('this.rxToPrint', this.rxToPrint.rxID);
            if (!this.rxToPrint.hasFullId) return;


            this.isPrintingRxRf = true;
            Axios.get(`/Print/${this.rxToPrint.rxID}/rx-pated`)
                .then(res => {
                    if (res.data.printedLabels > 0 && res.data.badLabels?.length == 0)
                        this.$notification(NotificationOptions.notificationOptionsPreset(`PatEd for Rx ${this.rxToPrint.rxID} was successfully printed`, NotificationOptions.NotificationTypes.success));
                })
                .catch(err => {
                    if ((err.response.data as string).includes("No Prescription found"))
                        this.$notification(NotificationOptions.notificationOptionsPreset("Prescription not found", NotificationOptions.NotificationTypes.danger));
                    else
                        console.error("Error while pulling printing Rx", { err, response: err?.response });
                })
                .finally(() => {
                    this.isPrintingRxRf = false;
                });

        }

        printBadge() {
            console.log('ipAddress', this.ipAddress);
            this.isPrintingBadge = true;
            Axios.post(`/Print/PrintMyBadge?ipAddress=${this.ipAddress}`)
                .then(_res => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Badge was successfully printed", NotificationOptions.NotificationTypes.success));
                })
                .catch(_err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Error printing badge.", NotificationOptions.NotificationTypes.danger));
                })
                .finally(() => {
                    this.isPrintingBadge = false;
                });

        }

        sendOutOfStock(e: any) {
            e.preventDefault();
            Axios.post<number>(`/Print/${this.outOfStockValue}/OutOfStock`, null, { params: { shouldOverride: this.shouldOverride } })
                .then(res => {
                    const newStockQuantity = res.data;
                    let message = `Marked out of stock. Net available: ${newStockQuantity}`;

                    this.$notification(NotificationOptions.notificationOptionsPreset(message, NotificationOptions.NotificationTypes.success));
                    this.needsOverride = false;
                    this.shouldOverride = false;
                    this.loadOrders();
                })
                .catch(err => {
                    this.needsOverride = true;
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Out Of Stock", err));
                })
                .finally(() => {
                    this.outOfStockValue = '';
                });
        };

        rxID(prescription: Prescription | null): string {
            if (!prescription) return "";
            return `${prescription.storeID}-${prescription.rxNumber}-${prescription.rfNumber}`;
        }

        // FILTERS
        search() {
            this.loadOrders();
        }

        clearFilters() {
            this.programFilter = new Program();
            this.storeFilter = new Store(this.lastStore) ?? new Store();
            this.showOutOfStocks = false;
        }

        filterProgramChanged(newFilter: Program) {
            this.programFilter = newFilter;
        }

        colorCodeTable(rx: Prescription, type: any) {
            if (!rx || type !== "row") return;


            if (this.priorityCodes.includes(rx.deliveryCode)) {
                return 'table-danger';
            }

            if (rx.deliveryCode == DeliveryCode.WillCall) {
                return 'table-warning';
            }
        }
    }

