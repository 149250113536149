

import Vue from 'vue'
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { Note, EntityType } from '@/models/Note';
import { Table, TableColumn } from "element-ui";
import axios, { AxiosError } from 'axios';

@Component({
    name: "NotesList",
    components:{
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    }
})
export default class NotesList extends Vue {
    @Prop({default: 0}) private entityId!: number;
    @Prop({default: 0}) private entityType!: EntityType;

    @Prop({default: 1}) private pageSize!: number;
    @Prop({default: true}) private paging!: boolean;
    @Prop({default: "Notes not found"}) private emptyText!: string;
    
    /// Only send notes as props if you dont want this component to load them by EntityId and EntityName
    @Prop() private notes!: Array<Note>;

    private loadedNotes: Array<Note> = [];
    private pageNbr: number = 0;
    private entityTypes = EntityType;

    created(){
        if(!this.notes)
            this.loadNotes();
    }

    loadMore(){
        this.pageNbr++;
        this.loadNotes();
    }

    loadPrevious(){
        this.pageNbr--;
        this.loadNotes();
    }

    loadNotes(){
        let url = `/Note/${this.entityId}/${this.entityType}`;
        var getParams = {};
        if(this.pagingEnabled)
            getParams = {pageSize: this.pageSize, pageNbr: this.pageNbr};

        axios.get(url, {params:getParams})
            .then(response => {
                this.loadedNotes = response.data;
            })
            .catch(error => {
                console.error("Error while loading notes",{ error, response: error?.response });
            });
    }

    @Watch('entityId') IdChanged(){
        this.pageNbr = 0;
        this.loadNotes();
    }
    
    get notesToShow(){
        return this.notes || this.loadedNotes;
    }
    
    get pagingEnabled(){
        return this.paging && !(this.notes && this.notes.length);
    }
}
