
    import { Component, Mixins } from 'vue-property-decorator';
    import axios from 'axios';

    import AddressFormFields from '@/components/AddressFormFields.vue';
    import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
    import SearchComponent from '@/components/SearchComponent.vue';
    import { Lock, LockHandler } from '@/mixins/LockHandler';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { Store } from '@/models/Store';

    @Component({
        name: "StoreDetailsPage",
        components: {
            AddressFormFields,
            NewClearSaveButtons,
            SearchComponent,
        },
    })
    export default class StoreDetailsPage extends Mixins(LockHandler, DisableAutocompleteMixin) {
        private store: Store = new Store();
        private storeLock: Lock | null = null;
        private newMode = false;
        private capabilitiesOptions = Store.flagOptions;

        get disableFields(): boolean {
            return !!(this.storeLock || (this.store && !this.store.id)) && !this.newMode;
        }

        protected buttonTitles: NewClearSaveTitles = {
            new: "New Store",
            clear: "Clear",
            save: "Save Changes",
            cancel: "",
        };

        showConfirmationModal() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            })
                .then(value => {
                    if (!value) return;
                    this.clearForm();
                })
                .catch(err => {
                    console.log("Error caught in showConfirmationModal()");
                    console.error(err);
                });
        }

        clearForm() {
            if (this.storeLock && this.storeLock.refreshURL) {
                this.releaseLockAtURL(this.storeLock.refreshURL);
                this.storeLock = null;
            }
            this.store = new Store();
            this.newMode = false;
        }

        fetchStore(value: Store) {
            this.addLockWithURL(`/Store/${value.id}/lock`, 60000)
                .then(result => {
                    this.storeLock = result;
                })
                .catch(error => {
                    if (error.response && error.response.status == 418) {
                        // already locked
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(`The Store is locked by ${lockedBy} until ${expires}.`);
                    }
                })
                .finally(() => {
                    axios.get(`/Store/${value.id}`)
                        .then(response => {
                            this.store = new Store(response.data);
                        })
                        .catch(error => {
                            console.warn(error);
                        })
                        .finally(() => {
                            //grab any additional information if needed.
                        });
                });
        }

        createStore() {
            this.newMode = true;
            this.store = new Store();
        }

        submitInformation() {
            axios.post('/Store/', this.store)
                .then(response => {
                    this.store = new Store(response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Store"));
                })
                .catch(error => {
                    console.warn(error);
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Store", error));
                });
        }

        get validForm(): boolean {
            return !!(this.store?.name);
        }

    }

