
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DispenseError } from '@/models/DispenseError';
    import { Note, EntityType } from '@/models/Note';

    @Component({
        name: "DispensingErrorCardComponent"
    })
    export default class DispensingErrorCardComponent extends Vue {
        @Prop() private value!: DispenseError;
        @Prop({default: 'dispenseAlertConfirmation'}) private detailsModalId!: string;
        
        private action: string = '';
        private typeOfErrors = DispenseError.DispenseErrorTypeEnum;
        private statusEnum = DispenseError.DispenseErrorStatusEnum;
        
        clickedDetails(){
            this.$emit("onClickDetails", this.value.id)
        }
    }

