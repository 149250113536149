
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { Patient } from "@/models/Patient";
import { DeliveryCode } from "@/models/DeliveryCode";
import TextMessagesList from "@/components/TextMessage/TextMessagesList.vue";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { PhoneOptStatus } from "@/models/OptStatus";

@Component({
    name: "PatientDemographicsComponent",
    components: { TextMessagesList, Checkbox },
})
export default class PatientDemographicsComponent extends Vue {
    @Prop() private value!: Patient;
    @Ref() private textMessagesListModal!: TextMessagesList;

    private numberType: string = "";

    get patient(): Patient {
        return this.value;
    }

    get isRegistered(): any {
        return !!(this.patient.emailAddress && this.patient.patientAppUserName);
    }

    get deliveryName(): string {
        let res = "";
        if (this.patient) res = DeliveryCode[this.patient.defaultDelivery];
        return res;
    }

    // pregnant
    get pregnant(): any {
        return !!this.patient?.pregnant;
    }

    // lactating
    get lactating(): any {
        return !!this.patient?.lactating;
    }

    // smoker
    get smoker(): any {
        return !!this.patient?.smoker;
    }

    getColorForOptStatus(status: PhoneOptStatus): string {
        switch (status) {
            case PhoneOptStatus.optIn:
                return "green";
            case PhoneOptStatus.optOut:
                return "red";
            case PhoneOptStatus.unknown:
            default:
                return "yellow";
        }
    }

    get phoneStyle() {
        return `color: ${this.getColorForOptStatus(this.patient.optInOptOutPhoneStatus)}`;
    }

    get cellPhoneStyle() {
        return `color: ${this.getColorForOptStatus(this.patient.optInOptOutCellPhoneStatus)}`;
    }

    openTextMessageModal(numberType: string) {
        this.numberType = numberType;
        // var component = this.$refs.textMessagesListModal as TextMessagesList;
        // if (component) component.openModal(this.numberType);
        this.textMessagesListModal.openModal(this.numberType);
    }
}
