/**
 * Enum of all available TWAIN enum capabilities and its possible values.
 * @enum {object}
 */
export const CapEnum = {
  // TwAL
  Alarms: {
    ALARM: 0,
    FEEDER_ERROR: 1,
    FEEDER_WARNING: 2,
    BARCODE: 3,
    DOUBLE_FEED: 4,
    JAM: 5,
    PATCH_CODE: 6,
    POWER: 7,
    SKEW: 8
  },
  // TwBP
  AutoDiscardBlankPages: {
    DISABLE: -2,
    AUTO: -1
  },
  // TwAS
  AutoSize: {
    NONE: 0,
    AUTO: 1,
    CURRENT: 2
  },
  // TwBD
  BarCodeSearchMode: {
    HORZ: 0,
    VERT: 1,
    HORZ_VERT: 2,
    VERT_HORZ: 3
  },
  // TwBM1
  BatteryMinutes: {
    INFINITE: -2,
    CANNOT_REPORT: -1
  },
  // TwBM2
  BatteryPercentage: {
    INFINITE: -2,
    CANNOT_REPORT: -1
  },
  // TwBR
  BitDepthReduction: {
    THRESHOLD: 0,
    HALF_TONE: 1,
    CUST_HALFTONE: 2,
    DIFFUSION: 3,
    DYNAMIC_THRESHOLD: 4
  },
  // TwBO
  BitOrder: {
    LSB_FIRST: 0,
    MSB_FIRST: 1
  },
  // TwCS
  CameraSide: {
    BOTH: 0,
    TOP: 1,
    BOTTOM: 2
  },
  // TwCB
  ClearBuffers: {
    AUTO: 0,
    CLEAR: 1,
    NO_CLEAR: 2
  },
  // TwCompression
  Compression: {
    NONE: 0,
    PACK_BITS: 1,
    GROUP3_1D: 2,
    GROUP3_1DEOL: 3,
    GROUP3_2D: 4,
    GROUP4: 5,
    JPEG: 6,
    LZW: 7,
    JBIG: 8,
    PNG: 9,
    RLE4: 10,
    RLE8: 11,
    BIT_FIELDS: 12,
    ZIP: 13,
    JPEG_2000: 14
  },
  // TwDE
  DeviceEvent: {
    CHECK_AUTOMATIC_CAPTURE: 0,
    CHECK_BATTERY: 1,
    CHECK_DEVICE_ONLINE: 2,
    CHECK_FLASH: 3,
    CHECK_POWER_SUPPLY: 4,
    CHECK_RESOLUTION: 5,
    DEVICE_ADDED: 6,
    DEVICE_OFFLINE: 7,
    DEVICE_READY: 8,
    DEVICER_EMOVED: 9,
    IMAGE_CAPTURED: 10,
    IMAGE_DELETED: 11,
    PAPER_DOUBLE_FEED: 12,
    PAPER_JAM: 13,
    LAMP_FAILURE: 14,
    POWER_SAVE: 15,
    POWER_SAVE_NOTIFY: 16,
    CUSTOM_EVENTS: 32768
  },
  // TwDF
  DoubleFeedDetection: {
    ULTRASONIC: 0,
    BY_LENGTH: 1,
    INFRARED: 2
  },
  // TwDP
  DoubleFeedDetectionResponse: {
    STOP: 0,
    STOP_AND_WAIT: 1,
    SOUND: 2,
    DO_NOT_IMPRINT: 3
  },
  // TwUS
  DoubleFeedDetectionSensitivity: {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2
  },
  // TwDX
  Duplex: {
    NONE: 0,
    ONE_PASS_DUPLEX: 1,
    TWO_PASS_DUPLEX: 2
  },
  // TwFA
  FeederAlignment: {
    NONE: 0,
    LEFT: 1,
    CENTER: 2,
    RIGHT: 3
  },
  // TwFO
  FeederOrder: {
    FIRST_PAGE_FIRST: 0,
    LAST_PAGE_FIRST: 1
  },
  // TwFP
  FeederPocket: {
    POCKET_ERROR: 0,
    POCKET1: 1,
    POCKET2: 2,
    POCKET3: 3,
    POCKET4: 4,
    POCKET5: 5,
    POCKET6: 6,
    POCKET7: 7,
    POCKET8: 8,
    POCKET9: 9,
    POCKET10: 10,
    POCKET11: 11,
    POCKET12: 12,
    POCKET13: 13,
    POCKET14: 14,
    POCKET15: 15,
    POCKET16: 16
  },
  // TwFE
  FeederType: {
    GENERAL: 0,
    PHOTO: 1
  },
  // TwFT
  Filter: {
    RED: 0,
    GREEN: 1,
    BLUE: 2,
    NONE: 3,
    WHITE: 4,
    CYAN: 5,
    MAGENTA: 6,
    YELLOW: 7,
    BLACK: 8
  },
  // TwFL
  FlashUsed: {
    NONE: 0,
    OFF: 1,
    ON: 2,
    AUTO: 3,
    RED_EYE: 4
  },
  // TwFR
  FlipRotation: {
    BOOK: 0,
    FAN_FOLD: 1
  },
  // TwIC
  IccProfile: {
    NONE: 0,
    LINK: 1,
    EMBED: 2
  },
  // TwFF
  ImageFileFormat: {
    TIFF: 0,
    PICT: 1,
    BMP: 2,
    XBM: 3,
    JFIF: 4,
    FPX: 5,
    TIFF_MULTI: 6,
    PNG: 7,
    SPIFF: 8,
    EXIF: 9,
    PDF: 10,
    JP2: 11,
    JPX: 13,
    DEJAVU: 14,
    PDFA: 15,
    PDFA2: 16
  },
  // TwIF
  ImageFilter: {
    NONE: 0,
    AUTO: 1,
    LOW_PASS: 2,
    BAND_PASS: 3,
    TEXT: 3,
    HIGH_PASS: 4,
    FINE_LINE: 4
  },
  TWScanFormatType: {
    JPEG: "jpeg",
    PNG: "png",
    BMP: "bmp",
    TIFF: "tiff",
    PDF: "pdf"
  },
  // TwIM
  ImageMerge: {
    NONE: 0,
    FRONT_ON_TOP: 1,
    FRONT_ON_BOTTOM: 2,
    FRONT_ON_LEFT: 3,
    FRONT_ON_RIGHT: 4
  },
  // TwCI
  IndicatorsMode: {
    INFO: 0,
    WARNING: 1,
    ERROR: 2,
    WARMUP: 3
  },
  // TwPixelType
  IPixelType: {
    BW: 0,
    GRAY: 1,
    RGB: 2,
    PALLETE: 3,
    CMY: 4,
    CMYK: 5,
    YUV: 6,
    YUVK: 7,
    CIEXYZ: 8,
    LAB: 9,
    SRGB: 10,
    SCRGB: 11,
    INFRARED: 16
  },
  // TwUnits
  IUnits: {
    INCHES: 0,
    CENTIMETERS: 1,
    PICAS: 2,
    POINTS: 3,
    TWIPS: 4,
    PIXELS: 5,
    MILLIMETERS: 6
  },
  // TwSX
  IXferMech: {
    NATIVE: 0,
    FILE: 1,
    MEMORY: 2,
    MEMORY_FILE: 4
  },
  // TwJC
  JobControl: {
    NONE: 0,
    JSIC: 1,
    JSIS: 2,
    JSXC: 3,
    JSXS: 4
  },
  // TwJQ
  JpegQuality: {
    UNKNOWN: -4,
    LOW: -3,
    MEDIUM: -2,
    HIGH: -1
  },
  // TwJS
  JpegSubsamplingQuality: {
    _444YCBCR: 0,
    _444RGB: 1,
    _422: 2,
    _421: 3,
    _411: 4,
    _420: 5,
    _410: 6,
    _311: 7
  },
  // TwLanguage
  Language: {
    DANISH: 0,
    DUTCH: 1,
    ENGLISH: 2,
    FRENCH_CANADIAN: 3,
    FINNISH: 4,
    FRENCH: 5,
    GERMAN: 6,
    ICELANDIC: 7,
    ITALIAN: 8,
    NORWEGIAN: 9,
    PORTUGUESE: 10,
    SPANISH: 11,
    SWEDISH: 12,
    ENGLISH_USA: 13,
    AFRIKAANS: 14,
    ALBANIA: 15,
    ARABIC: 16,
    ARABIC_ALGERIA: 17,
    ARABIC_BAHRAIN: 18,
    ARABIC_EGYPT: 19,
    ARABIC_IRAQ: 20,
    ARABIC_JORDAN: 21,
    ARABIC_KUWAIT: 22,
    ARABIC_LEBANON: 23,
    ARABIC_LIBYA: 24,
    ARABIC_MOROCCO: 25,
    ARABIC_OMAN: 26,
    ARABIC_QATAR: 27,
    ARABIC_SAUDIARABIA: 28,
    ARABIC_SYRIA: 29,
    ARABIC_TUNISIA: 30,
    ARABIC_UAE: 31,
    ARABIC_YEMEN: 32,
    BASQUE: 33,
    BYELORUSSIAN: 34,
    BULGARIAN: 35,
    CATALAN: 36,
    CHINESE: 37,
    CHINESE_HONGKONG: 38,
    CHINESE_PRC: 39,
    CHINESE_SINGAPORE: 40,
    CHINESE_SIMPLIFIED: 41,
    CHINESE_TAIWAN: 42,
    CHINESE_TRADITIONAL: 43,
    CROATIA: 44,
    CZECH: 45,
    DUTCH_BELGIAN: 46,
    ENGLISH_AUSTRALIAN: 47,
    ENGLISH_CANADIAN: 48,
    ENGLISH_IRELAND: 49,
    ENGLISH_NEWZEALAND: 50,
    ENGLISH_SOUTHAFRICA: 51,
    ENGLISH_UK: 52,
    ESTONIAN: 53,
    FAEROESE: 54,
    FARSI: 55,
    FRENCH_BELGIAN: 56,
    FRENCH_LUXEMBOURG: 57,
    FRENCH_SWISS: 58,
    GERMAN_AUSTRIAN: 59,
    GERMAN_LUXEMBOURG: 60,
    GERMAN_LIECHTENSTEIN: 61,
    GERMAN_SWISS: 62,
    GREEK: 63,
    HEBREW: 64,
    HUNGARIAN: 65,
    INDONESIAN: 66,
    ITALIAN_SWISS: 67,
    JAPANESE: 68,
    KOREAN: 69,
    KOREAN_JOHAB: 70,
    LATVIAN: 71,
    LITHUANIAN: 72,
    NORWEGIAN_BOKMAL: 73,
    NORWEGIAN_NYNORSK: 74,
    POLISH: 75,
    PORTUGUESE_BRAZIL: 76,
    ROMANIAN: 77,
    RUSSIAN: 78,
    SERBIAN_LATIN: 79,
    SLOVAK: 80,
    SLOVENIAN: 81,
    SPANISH_MEXICAN: 82,
    SPANISH_MODERN: 83,
    THAI: 84,
    TURKISH: 85,
    UKRANIAN: 86,
    ASSAMESE: 87,
    BENGALI: 88,
    BIHARI: 89,
    BODO: 90,
    DOGRI: 91,
    GUJARATI: 92,
    HARYANVI: 93,
    HINDI: 94,
    KANNADA: 95,
    KASHMIRI: 96,
    MALAYALAM: 97,
    MARATHI: 98,
    MARWARI: 99,
    MEGHALAYAN: 100,
    MIZO: 101,
    NAGA: 102,
    ORISSI: 103,
    PUNJABI: 104,
    PUSHTU: 105,
    SERBIAN_CYRILLIC: 106,
    SIKKIMI: 107,
    SWEDISH_FINLAND: 108,
    TAMIL: 109,
    TELUGU: 110,
    TRIPURI: 111,
    URDU: 112,
    VIETNAMESE: 113
  },
  // TwLP
  LightPath: {
    REFLECTIVE: 0,
    TRANSMISSIVE: 1
  },
  // TwLS
  LightSource: {
    RED: 0,
    GREEN: 1,
    BLUE: 2,
    NONE: 3,
    WHITE: 4,
    UV: 5,
    IR: 6
  },
  // TwNF
  NoiseFilter: {
    NONE: 0,
    AUTO: 1,
    LONE_PIXEL: 2,
    MAJORITY_RULE: 3
  },
  // TwOR
  Orientation: {
    ROT0: 0,
    PORTRAIT: 0,
    ROT90: 1,
    ROT180: 2,
    ROT270: 3,
    LANDSCAPE: 3,
    AUTO: 4,
    AUTO_TEXT: 5,
    AUTO_PICTURE: 6
  },
  // TwOV
  OverScan: {
    NONE: 0,
    AUTO: 1,
    TOP_BOTTOM: 2,
    LEFT_RIGHT: 3,
    ALL: 4
  },
  // TwPH
  PaperHandling: {
    NORMAL: 0,
    FRAGILE: 1,
    THICK: 2,
    TRIFOLD: 3,
    PHOTOGRAPH: 4
  },
  //TwPCH
  PatchCode: {
    PATCH1: 0,
    PATCH2: 1,
    PATCH3: 2,
    PATCH4: 3,
    PATCH6: 4,
    PATCHT: 5
  },
  //TwBD
  PatchCodeSearchMode: {
    HORZ: 0,
    VERT: 1,
    HORZ_VERT: 2,
    VERT_HORZ: 3
  },
  //TwPCH
  PatchCodeSearchPriorities: {
    PATCH1: 0,
    PATCH2: 1,
    PATCH3: 2,
    PATCH4: 3,
    PATCH6: 4,
    PATCHT: 5
  },
  PixelFlavor: {
    CHOCOLATE: 0,
    VANILLA: 1
  },
  PlanarChunky: {
    CHUNKY: 0,
    PLANAR: 1
  },
  PowerSupply: {
    EXTERNAL: 0,
    BATTERY: 1
  },
  Printer: {
    IMPRINTER_TOP_BEFORE: 0,
    IMPRINTER_TOP_AFTER: 1,
    IMPRINTER_BOTTOM_BEFORE: 2,
    IMPRINTER_BOTTOM_AFTER: 3,
    ENDORSER_TOP_BEFORE: 4,
    ENDORSER_TOP_AFTER: 5,
    ENDORSER_BOTTOM_BEFORE: 6,
    ENDORSER_BOTTOM_AFTER: 7
  },
  PrinterFontStyle: {
    NORMAL: 0,
    BOLD: 1,
    ITALIC: 2,
    LARGE_SIZE: 3,
    SMALL_SIZE: 4
  },
  PrinterIndexTrigger: {
    PAGE: 0,
    PATCH1: 1,
    PATCH2: 2,
    PATCH3: 3,
    PATCH4: 4,
    PATCH5: 5,
    PATCH6: 6
  },
  PrinterMode: {
    SINGLE_STRING: 0,
    MULTI_STRING: 1,
    COMPOUND_STRING: 2
  },
  Segmented: {
    NONE: 0,
    AUTO: 1,
    MANUAL: 2
  },
  SupportedBarCodeTypes: {
    CODE3O_F9: 0,
    CODE2O_F5_INTERLEAVED: 1,
    CODE2O_F5_NON_INTERLEAVED: 2,
    CODE_93: 3,
    CODE_128: 4,
    UCC_128: 5,
    CODA_BAR: 6,
    UPCA: 7,
    UPCE: 8,
    EAN8: 9,
    EAN13: 10,
    POST_NET: 11,
    PDF417: 12,
    CODE2O_F5_INDUSTRIAL: 13,
    CODE2O_F5_MATRIX: 14,
    CODE2O_F5_DATALOGIC: 15,
    CODE2O_F5_IATA: 16,
    CODE3O_F9F_ULLASCII: 17,
    CODA_BAR_WITH_START_STOP: 18,
    MAXI_CODE: 19,
    QRCODE: 20
  },
  SupportedDats: {
    CONTROL: 1,
    IMAGE: 2,
    AUDIO: 4,
    DSM2: 268435456,
    APP2: 536870912,
    DS2: 1073741824
  },
  SupportedExtImageInfo: {
    BARCODE_X: 4608,
    BARCODE_Y: 4609,
    BARCODE_TEXT: 4610,
    BARCODE_TYPE: 4611,
    DESHADE_TOP: 4612,
    DESHADE_LEFT: 4613,
    DESHADE_HEIGHT: 4614,
    DESHADE_WIDTH: 4615,
    DESHADE_SIZE: 4616,
    SPECKLES_REMOVED: 4617,
    HORZLINE_X_COORD: 4618,
    HORZLINE_Y_COORD: 4619,
    HORZLINE_LENGTH: 4620,
    HORZLINE_THICKNESS: 4621,
    VERTLINE_X_COORD: 4622,
    VERTLINE_Y_COORD: 4623,
    VERTLINE_LENGTH: 4624,
    VERTLINE_THICKNESS: 4625,
    PATCH_CODE: 4626,
    ENDORSED_TEXT: 4627,
    FORM_CONFIDENCE: 4628,
    FORM_TEMPLATE_MATCH: 4629,
    FORM_TEMPLATE_PAGE_MATCH: 4630,
    FORM_HORZ_DOC_OFFSET: 4631,
    FORM_VERT_DOC_OFFSET: 4632,
    BARCODE_COUNT: 4633,
    BARCODE_CONFIDENCE: 4634,
    BARCODE_ROTATION: 4635,
    BARCODE_TEXT_LENGTH: 4636,
    DESHADE_COUNT: 4637,
    DESHADE_BLACK_COUNT_OLD: 4638,
    DESHADE_BLACK_COUNT_NEW: 4639,
    DESHADE_BLACK_RL_MIN: 4640,
    DESHADE_BLACK_RL_MAX: 4641,
    DESHADE_WHITE_COUNT_OLD: 4642,
    DESHADE_WHITE_COUNT_NEW: 4643,
    DESHADE_WHITE_RL_MIN: 4644,
    DESHADE_WHITE_RL_AVE: 4645,
    DESHADE_WHITE_RL_MAX: 4646,
    BLACKS_PECKLES_REMOVED: 4647,
    WHITE_SPECKLES_REMOVED: 4648,
    HORZLINE_COUNT: 4649,
    VERTLINE_COUNT: 4650,
    DESKEWS_TATUS: 4651,
    SKEW_ORIGINAL_ANGLE: 4652,
    SKEW_FINAL_ANGLE: 4653,
    SKEW_CONFIDENCE: 4654,
    SKEW_WINDOWX1: 4655,
    SKEW_WINDOWY1: 4656,
    SKEW_WINDOWX2: 4657,
    SKEW_WINDOWY2: 4658,
    SKEW_WINDOWX3: 4659,
    SKEW_WINDOWY3: 4660,
    SKEW_WINDOWX4: 4661,
    SKEW_WINDOWY4: 4662,
    BOOK_NAME: 4664,
    CHAPTER_NUMBER: 4665,
    DOCUMENT_NUMBER: 4666,
    PAGE_NUMBER: 4667,
    CAMERA: 4668,
    FRAME_NUMBER: 4669,
    FRAME: 4670,
    PIXEL_FLAVOR: 4671,
    ICC_PROFILE: 4672,
    LAST_SEGMENT: 4673,
    SEGMENT_NUMBER: 4674,
    MAG_DATA: 4675,
    MAG_TYPE: 4676,
    PAGE_SIDE: 4677,
    FILE_SYSTEM_SOURCE: 4678,
    IMAGE_MERGED: 4679,
    MAG_DATA_LENGTH: 4680,
    PAPER_COUNT: 4681,
    PRINTER_TEXT: 4682
  },
  SupportedPatchCodeTypes: {
    PATCH1: 0,
    PATCH2: 1,
    PATCH3: 2,
    PATCH4: 3,
    PATCH6: 4,
    PATCHT: 5
  },
  SupportedSizes: {
    NONE: 0,
    A4_LETTER: 1,
    A4: 1,
    B5_LETTER: 2,
    JISB5: 2,
    US_LETTER: 3,
    US_LEGAL: 4,
    A5: 5,
    B4: 6,
    ISOB4: 6,
    B6: 7,
    ISOB6: 7,
    US_LEDGER: 9,
    US_EXECUTIVE: 10,
    A3: 11,
    B3: 12,
    ISOB3: 12,
    A6: 13,
    C4: 14,
    C5: 15,
    C6: 16,
    _4A0: 17,
    _2A0: 18,
    A0: 19,
    A1: 20,
    A2: 21,
    A7: 22,
    A8: 23,
    A9: 24,
    A10: 25,
    ISOB0: 26,
    ISOB1: 27,
    ISOB2: 28,
    ISOB5: 29,
    ISOB7: 30,
    ISOB8: 31,
    ISOB9: 32,
    ISOB10: 33,
    JISB0: 34,
    JISB1: 35,
    JISB2: 36,
    JISB3: 37,
    JISB4: 38,
    JISB6: 39,
    JISB7: 40,
    JISB8: 41,
    JISB9: 42,
    JISB10: 43,
    C0: 44,
    C1: 45,
    C2: 46,
    C3: 47,
    C7: 48,
    C8: 49,
    C9: 50,
    C10: 51,
    US_STATEMENT: 52,
    BUSINESS_CARD: 53
  },
  TiffCompression: {
    NOT_SET: -1, // it will be use value setting from the last scan
    LZW: 2,
    CCITT3: 3, // CCITT Group 3 1-Dimensional Modified Huffman run length encoding. For black and white only.
    CCITT4: 4, // CCITT Group 4 2-Dimensional Modified Huffman run length encoding. For black and white only.
    RLE: 5, // Run Length Encoding - for black and white only.
    NONE: 6
  }
};

/**
 * List of all available capabilities.
 * @enum {object}
 */
export const CapName = {
  /**
   * [AudibleAlarms] Turns specific audible alarms on and off.
   * CAP_ALARMS
   * @type CapEnum.TwAL[]
   */
  ALARMS: "alarms",

  /**
   * [AudibleAlarms] Controls the volume of a device’s audible alarm.
   * CAP_ALARMVOLUME
   * @type int
   */
  ALARM_VOLUME: "alarmVolume",

  /**
   * [AutomaticCapture] The number of images to automatically capture. This does not refer to the number of images to be
   * sent to the Application, use CAP_XFERCOUNT for that.
   * CAP_AUTOMATICCAPTURE
   * @type int
   */
  AUTOMATIC_CAPTURE: "automaticCapture",

  /**
   * [Feeder] If TRUE, the Source will automatically feed the next page from the document feeder after the
   * number of frames negotiated for capture from each page are acquired. CAP_FEEDERENABLED
   * must be TRUE to use this capability.
   * CAP_AUTOFEED
   * @type bool
   */
  AUTO_FEED: "autoFeed",

  /**
   * [ImageInformation] Author of acquired image (may include a copyright string).
   * Gets the author.
   * CAP_AUTHOR
   * @type string
   */
  AUTHOR: "author",

  /**
   * [AutomaticAdjustments] Reduces the amount of data captured from the device, potentially improving the performance of the driver.
   * Read only.
   * ICAP_AUTOMATICDESKEW
   * @type bool
   */
  AUTOMATIC_CROP_USES_FRAME: "automaticCropUsesFrame",

  /**
   * [AutomaticScanning] Enables the source’s automatic document scanning process.
   * CAP_AUTOSCAN
   * @type bool
   */
  AUTO_SCAN: "autoScan",

  /**
   * [AutomaticAdjustments] Discards blank pages.
   * ICAP_AUTODISCARDBLANKPAGES
   * @type CapEnum.TwBP
   */
  AUTO_DISCARD_BLANK_PAGES: "autoDiscardBlankPages",

  /**
   * [ImageAcquire] The source Auto-brightness function (if any).
   * ICAP_AUTOBRIGHT
   * @type bool
   */
  AUTO_BRIGHT: "autoBright",

  /**
   * [AutomaticAdjustments] Turns automatic border detection on and off.
   * ICAP_AUTOMATICBORDERDETECTION
   * @type bool
   */
  AUTOMATIC_BORDER_DETECTION: "automaticBorderDetection",

  /**
   * [AutomaticAdjustments] Configures a Source to check for paper in the Automatic Document Feeder.
   * CAP_AUTOMATICSENSEMEDIUM
   * @type bool
   */
  AUTOMATIC_SENSE_MEDIUM: "automaticSenseMedium",

  /**
   *  [AutomaticAdjustments] Turns automatic skew correction on and off.
   *  ICAP_AUTOMATICDESKEW
   * @type bool
   */
  AUTOMATIC_DESKEW: "automaticDeskew",

  /**
   * [AutomaticAdjustments] When <c>true</c>, depends on source to automatically rotate the image.
   * ICAP_AUTOMATICROTATE
   * @type bool
   */
  AUTOMATIC_ROTATE: "automaticRotate",

  /**
   * [AutomaticAdjustments] Gets auto size capability.
   * Force the output image dimensions to match either the current value of ICAP_SUPPORTEDSIZES
   * or any of its current allowed values.
   * ICAP_AUTOSIZE
   * @type CapEnum.TwAS
   */
  AUTO_SIZE: "autoSize",

  /**
   * [AutomaticAdjustments] Controls the automatic detection of the length of a document, this is intended for use with an
   * Automatic Document Feeder.
   * ICAP_AUTOMATICLENGTHDETECTION
   * @type bool
   */
  AUTOMATIC_LENGTH_DETECTION: "automaticLengthDetection",

  /**
   * [AutomaticAdjustments] Detects the pixel type of the image and returns either a color image or a non-color image specified by CapName.AUTOMATIC_COLOR_NON_COLOR_PIXEL_TYPE.
   * ICAP_AUTOMATICCOLORENABLED
   * @type bool
   */
  AUTOMATIC_COLOR_ENABLED: "automaticColorEnabled",

  /**
   * [AutomaticAdjustments] Specifies the non-color pixel type to use when automatic color is enabled.
   * ICAP_AUTOMATICCOLORNONCOLORPIXELTYPE.
   * @type CapEnum.TwPixelType
   */
  AUTOMATIC_COLOR_NON_COLOR_PIXEL_TYPE: "automaticColorNonColorPixelType",

  /**
   * [BarCodeDetection] Specifies the maximum number of supported search priorities.
   * ICAP_BARCODEMAXSEARCHPRIORITIES
   * @type uint
   */
  BAR_CODE_MAX_SEARCH_PRIORITIES: "barCodeMaxSearchPriorities",

  /**
   * [BarCodeDetection] A prioritized list of bar code types dictating the order in which bar codes will be sought.
   * ICAP_BARCODESEARCHPRIORITIES
   * @type CapEnum.TwBT
   */
  BAR_CODE_SEARCH_PRIORITIES: "barCodeSearchPriorities",

  /**
   * [BarCodeDetection] Restricts bar code searching to certain orientations, or prioritizes one orientation over the other.
   * ICAP_BARCODESEARCHMODE
   * @type CapEnum.TwBD
   */
  BAR_CODE_SEARCH_MODE: "barCodeSearchMode",

  /**
   * [BarCodeDetection] Restricts the number of times a search will be retried if none are found on each page.
   * ICAP_BARCODEMAXRETRIES
   * @type uint
   */
  BAR_CODE_MAX_RETRIES: "barCodeMaxRetries",

  /**
   * [BarCodeDetection] Gets the bar code timeout.
   * Restricts the total time spent on searching for a bar code on each page.
   * ICAP_BARCODETIMEOUT
   * @type uint
   */
  BAR_CODE_TIMEOUT: "barCodeTimeout",

  /**
   * [BarCodeDetection] Support this capability if the scanner supports any Bar code recognition. If the device allows this
   * feature to be turned off, then default to off.
   * ICAP_BARCODEDETECTIONENABLED
   * @type bool
   */
  BAR_CODE_DETECTION_ENABLED: "barCodeDetectionEnabled",

  /**
   * [PowerMonitoring] The minutes of battery power remaining to the device.
   * Read only.
   * CAP_BATTERYMINUTES
   * @type CapEnum.TwBM1
   */
  BATTERY_MINUTES: "batteryMinutes",

  /**
   * [PowerMonitoring] When used with MSG_GET, return the percentage of battery power level on camera. If -1 is
   * returned, it indicates that the battery is not present.
   * Read only.
   * CAP_BATTERYPERCENTAGE
   * @type CapEnum.TwBM2
   */
  BATTERY_PERCENTAGE: "batteryPercentage",

  /**
   * [ImageType] Specifies the pixel bit depths for the Current value of <see cref="PixelType"/>.
   * ICAP_BITDEPTH
   * @type ushort
   */
  BIT_DEPTH: "bitDepth",

  /**
   * [ImageType] Specifies the Reduction Method the Source should use to reduce the bit depth of the data. Most
   * commonly used with <see cref="PixelType"/> = <see cref="TwPixelType.BW"/> to reduce gray data to black and white.
   * ICAP_BITDEPTHREDUCTION
   * @type CapEnum.TwBR[]
   */
  BIT_DEPTH_REDUCTION: "bitDepthReduction",

  /**
   * [Compression] Specifies how the bytes in an image are filled by the Source. <see cref="TwBO.MsbFirst"/> indicates that the
   * leftmost bit in the byte (usually bit 7) is the byte’s Most Significant Bit.
   * ICAP_BITORDER
   * @type CapEnum.TwBO[]
   */
  BIT_ORDER: "bitOrder",

  /**
   * [Compression] Used for CCITT data compression only. Indicates the bit order representation of the stored
   * compressed codes.
   * ICAP_BITORDERCODES
   * @type CapEnum.TwBO
   */
  BIT_ORDER_CODES: "bitOrderCodes",

  /**
   * [ImageAcquire] The brightness values available within the Source.
   * ICAP_BRIGHTNESS
   * @type float
   */
  BRIGHTNESS: "brightness",

  /**
   * [AutomaticScanning] When set to <c>true</c> the device will deliver images from the current camera.
   * CAP_CAMERAENABLED
   * @type bool
   */
  CAMERA_ENABLED: "cameraEnabled",

  /**
   * [AutomaticScanning] This capability selects the order of output for Single Document Multiple Image (SDMI) mode
   * based on an array of pixel types; it does not constrain the allowed pixel types.
   * CAP_CAMERAORDER
   * @type CapEnum.TwPixelType[]
   */
  CAMERA_ORDER: "cameraOrder",

  /**
   * [UserInterface] This capability queries the Source for UI support for preview mode. If <c>true</c>, the Source supports
   * preview UI.
   * Read only.
   * CAP_CAMERAPREVIEWUI
   * @type bool
   */
  CAMERA_PREVIEW_UI: "cameraPreviewUI",

  /**
   * [AutomaticScanning] TWAIN models a duplex scanner as conceptually having two 'cameras' - a 'top' camera that
   * captures the front of each page, and a 'bottom' camera that captures the back.
   * CAP_CAMERASIDE
   * @type CapEnum.TwCS
   */
  CAMERA_SIDE: "cameraSide",

  /**
   * [ImageFormation] General note about acquired image.
   * CAP_CAPTION
   * @type string
   */
  CAPTION: "caption",

  /**
   *  [Compression] Used for CCITT Group 3 2-dimensional compression.The ‘K’ factor indicates how often the new
   *  compression baseline should be re-established.A value of 2 or 4 is common in facsimile
   *  communication.A value of zero in this field will indicate an infinite K factor—the baseline is only
   *  calculated at the beginning of the transfer.
   *  ICAP_CCITTKFACTOR
   * @type ushort
   */
  CCITT_K_FACTOR: "ccittKFactor",

  /**
   * [AutomaticScanning] Getting Value reports the presence of data in the scanner’s buffers. Setting with a value of
   * TwCB.Clear immediately clears the buffers.
   * CAP_CAMERASIDE
   * @type CapEnum.TwCB
   */
  CLEAR_BUFFERS: "clearBuffers",

  /**
   * [Feeder] Source will eject the current page being acquired from and will leave the feeder
   * acquire area empty.
   * CAP_CLEARPAGE
   * @type bool
   */
  CLEAR_PAGE: "clearPage",

  /**
   * [Color] Disables the Source’s color and gamma tables for color and grayscale images, resulting in output
   * that that could be termed “raw”.
   * ICAP_COLORMANAGEMENTENABLED
   * @type bool
   */
  COLOR_MANAGEMENT_ENABLED: "colorManagementEnabled",

  /**
   * [ImageAcquire] The contrast values available within the Source.
   * ICAP_CONTRAST
   * @type float
   */
  CONTRAST: "contrast",

  /**
   * [UserInterface] Allows the application to query the data source to see if it supports the new operation triplets.
   * CAP_CUSTOMDSDATA
   * @type bool
   */
  CUSTOM_DS_DATA: "customDSData",

  /**
   * [UserInterface] Uniquely identifies an interface for a Data Source, so that an Application can properly interpret its custom content.
   * This is a read only capability.
   * Read only.
   * CAP_CUSTOMINTERFACEGUID
   * @type string
   */
  CUSTOM_INTERFACE_GUID: "customInterfaceGuid",

  /**
   * [ImageType] Specifies the square-cell halftone (dithering) matrix the Source should use to halftone the image.
   * ICAP_CUSTHALFTONE
   * @type byte
   */
  CUST_HALFTONE: "custHalftone",

  /**
   * [AsyncDeviceEvents] Selects which events the application wants the source to report.
   * CAP_DEVICEEVENT
   * @type CapEnum.TwDE[]
   */
  DEVICE_EVENT: "deviceEvent",

  /**
   * [Device] If <c>true</c>, the physical hardware (e.g., scanner, digital camera, image database, etc.) that represents
   * the image source is attached, powered on, and communicating.
   * Read only.
   * CAP_DEVICEONLINE
   * @type bool
   */
  DEVICE_ONLINE: "deviceOnline",

  /**
   * [Device] The date and time of the device’s clock.
   * Managed in the form “YYYY/MM/DD HH:mm:SS:sss”
   * CAP_DEVICETIMEDATE
   * @type string
   */
  DEVICE_TIME_DATE: "deviceTimeDate",

  /**
   * [DoubleFeedDetection] Type of the double feed detection method. To disable set empty array.
   * CAP_DOUBLEFEEDDETECTION
   * @type CapEnum.TwDF[]
   */
  DOUBLE_FEED_DETECTION: "doubleFeedDetection",

  /**
   * [DoublefeedDetection] When <see cref="DoublefeedDetection.Mode"/>  includes <see cref="TwDF.ByLength"/> , it allows an Application to set
   * the minimum length for detecting double feed documents.A value of zero always means “off”.
   * CAP_DOUBLEFEEDDETECTIONLENGTH
   * @type float
   */
  DOUBLE_FEED_DETECTION_LENGTH: "doubleFeedDetectionLength",

  /**
   * [DoublefeedDetection] When <see cref="DoublefeedDetection.Mode"/> includes <see cref="TwDF.Ultrasonic"/>, allows an Application to set
   * how sensitive the double feed detection is.
   * CAP_DOUBLEFEEDDETECTIONSENSITIVITY
   * @type CapEnum.TwUS
   */
  DOUBLE_FEED_DETECTION_SENSITIVITY: "doubleFeedDetectionSensitivity",

  /**
   * [DoublefeedDetection] It allows an Application to set how the source will respond to detecting a double feed.
   * CAP_DOUBLEFEEDDETECTIONRESPONSE
   * @type CapEnum.TwDP[]
   */
  DOUBLE_FEED_DETECTION_RESPONSE: "doubleFeedDetectionResponse",

  /**
   * [Duplex] This indicates whether the scanner supports duplex. If so, it further indicates whether one-path or
   * two-path duplex is supported.
   * CAP_DUPLEX
   * @type CapEnum.TwDX
   */
  DUPLEX: "duplex",

  /**
   * [Duplex] Is duplex enabled.
   * CAP_DUPLEXENABLED
   * @type bool
   */
  DUPLEX_ENABLED: "duplexEnabled",

  /**
   * [UserInterface] Allows an application to query a source to see if it implements the new user interface settings
   * dialog.
   * Read only.
   * CAP_ENABLEDSUIONLY
   * @type bool
   */
  ENABLE_DS_UI_ONLY: "enableDSUIOnly",

  /**
   * [ImprinterEndorser] Allows the application to specify the scanner’s starting endorser / imprinter number.
   * CAP_ENDORSER
   * @type uint
   */
  ENDORSER: "endorser",

  /**
   * [ImageInformation] Allows the application to query the data source to see if it
   * supports the new operation triplet DG_IMAGE/DAT_EXTIMAGEINFO/ MSG_GET.
   * ICAP_EXTIMAGEINFO
   * @type bool
   */
  EXT_IMAGE_INFO: "extImageInfo",

  /**
   * [Device] Specifies the exposure time used to capture the image, in seconds.
   * ICAP_EXPOSURETIME
   * @type float
   */
  EXPOSURE_TIME: "exposureTime",

  /**
   * [Caps] Returns a list of the capabilities already negotiated.
   * Read only.
   * CAP_EXTENDEDCAPS
   * @type CapEnum.TwCap[]
   */
  EXTENDED_CAPS: "extendedCaps",

  /**
   * [Feeder!] If TRUE, the Source will eject the current page and advance the next page in the document feeder
   * into the feeder acquire area.
   * If CAP_AUTOFEED is TRUE, the same action just described will occur and CAP_AUTOFEED will
   * remain active.
   * CAP_FEEDERENABLED must equal TRUE to use this capability
   * @type bool
   */
  FEED_PAGE: "feedPage",

  /**
   * [Feeder] Indicates the alignment of the document feeder.
   * CAP_FEEDERALIGNMENT
   * @type CapEnum.TwFA
   */
  FEEDER_ALIGNMENT: "feederAlignment",

  /**
   * [Feeder] If <c>true</c>, Source’s feeder is enabled.
   * CAP_FEEDERENABLED
   * @type bool
   */
  FEEDER_ENABLED: "feederEnabled",

  /**
   * [Feeder] Reflect whether there are documents loaded in the Source’s feeder.
   * This is a read only capability.
   * Read only.
   * CAP_FEEDERLOADED
   * @type bool
   */
  FEEDER_LOADED: "feederLoaded",

  /**
   * [Feeder] Indicates the alignment of the document feeder.
   * CAP_FEEDERORDER
   * @type CapEnum.TwFO
   */
  FEEDER_ORDER: "feederOrder",

  /**
   * [Feeder] Improve the movement of paper through a scanner ADF.
   * If CAP_FEEDERENABLED is TRUE, and CAP_FEEDERPREP is TRUE, then the scanner will perform
   * any action needed to improve the movement of paper through the transport.
   * CAP_FEEDERPREP
   * @type bool
   */
  FEEDER_PREP: "feederPrep",

  /**
   * [Feeder] Report what pockets are available to receive paper as it exits from the device.
   * This capability enumerates the available output or collation pockets on the device.
   * TWFP_POCKET1 - TWFP_POCKET16 are organized from top to bottom and left to right, facing in the direction of the motion of the paper.
   * CAP_FEEDERPOCKET
   * @type CapEnum.TwFP[]
   */
  FEEDER_POCKET: "feederPocket",

  /**
   * [Feeder] Allows application to set scan parameters depending on the type of feeder being used.
   * ICAP_FEEDERTYPE
   * @type CapEnum.TwFE
   */
  FEEDER_TYPE: "feederType",

  /**
   * [Color] Describes the color characteristic of the subtractive filter applied to the image data. Multiple
   * filters may be applied to a single acquisition.
   * ICAP_FILTER
   * @type CapEnum.TwFT[]
   */
  FILTER: "filter",

  /**
   * [Device] For devices that support flash. MSG_SET selects the flash to be used (if any). MSG_GET reports the
   * current setting.This capability replaces ICAP_FLASHUSED, which is only able to negotiate the
   * flash being on or off.
   * ICAP_FLASHUSED2
   * @type CapEnum.TwFL
   */
  FLASH_USED2: "flashUsed2",

  /**
   * [AutomaticAdjustments] Orients images that flip orientation every other image.
   * ICAP_FLIPROTATION
   * @type CapEnum.TwFR
   */
  FLIP_ROTATION: "flipRotation",

  /**
   * [Page] The list of frames the Source will acquire on each page.
   * ICAP_FRAMES
   * @type RectangleF
   */
  FRAMES: "frames",

  /**
   * [Color] Gamma correction value for the image data.
   * ICAP_GAMMA
   * @type float
   */
  GAMMA: "gamma",

  /**
   * [ImageType] A list of names of the halftone patterns available within the Source.
   * ICAP_HALFTONES
   * @type string
   */
  HALFTONES: "halftones",

  /**
   * [ImageAcquire] Specifies which value in an image should be interpreted as the lightest “highlight.” All values
   * “lighter” than this value will be clipped to this value.
   * ICAP_HIGHLIGHT
   * @type float
   */
  HIGHLIGHT: "highlight",

  /**
   * [Color] Informs the application if the source has an ICC profile and can embed or link it in the current
   * ICAP_IMAGEFILEFORMAT.Tells the source if the application would like an ICC profile
   * embedded or linked into the image file the source will write.
   * ICAP_ICCPROFILE
   * @type CapEnum.TwIC
   */
  ICC_PROFILE: "iccProfile",

  /**
   * [Transfer] Buffered Memory transfer compression schemes.
   * ICAP_COMPRESSION
   * @type CapEnum.TwCompression
   */
  ICOMPRESSION: "iCompression",

  /**
   * [ImageAcquire] Gets or sets the image indices that will be delivered during the standard image transfer.
   * ICAP_IMAGEDATASET
   * @type uint[]
   */
  IMAGE_DATA_SET: "imageDataSet",

  /**
   * [Transfer] File formats for file transfers.
   * ICAP_IMAGEFILEFORMAT
   * @type CapEnum.TwFF
   */
  IMAGE_FILE_FORMAT: "imageFileFormat",

  /**
   * [Device] For devices that support image enhancement filtering. This capability selects the algorithm used
   * to improve the quality of the image.
   * ICAP_IMAGEFILTER
   * @type CapEnum.TwIF
   */
  IMAGE_FILTER: "imageFilter",

  /**
   * [AutomaticAdjustments] Merges the front and rear image of a document in one of four orientations: front on the top, front
   * on the bottom, front on the left or front on the right.
   * ICAP_IMAGEMERGE
   * @type CapEnum.TwIM
   */
  IMAGE_MERGE: "imageMerge",

  /**
   * [AutomaticAdjustments] Specifies a Y-Offset in ICAP_UNITS units. Front and rear images less than or equal to this value
   * are merged according to the settings of ICAP_IMAGEMERGE. If either the front or the rear image is
   * greater than this value, they are not merged.
   * ICAP_IMAGEMERGEHEIGHTTHRESHOLD
   * @type float
   */
  IMAGE_MERGE_HEIGHT_THRESHOLD: "imageMergeHeightThreshold",

  /**
   * [UserInterface] If <c>true</c>, the Source displays a progress indicator during acquisition and transfer, regardless of
   * whether the Source's user interface is active. If FALSE, the progress indicator is suppressed if the
   * Source's user interface is inactive.
   * The Source displays device-specific instructions and error messages if either the user interface or
   * progress indicator is turned on.In this case it returns TWCC_OPERATIONERROR to alert the
   * application that it handled the error, and communicated the problem to the user.
   * If both the user interface and progress indicator are turned off, then the Source never displays any
   * message to the user, even if TWCC_OPERATIONERROR is returned.Messages to the user are under
   * the sole control of the Application.
   * CAP_INDICATORS
   * @type bool
   */
  INDICATORS: "indicators",

  /**
   * [UserInterface] Specify what messages can be displayed if <see cref="Indicators"/> is TRUE.
   * CAP_INDICATORSMODE
   * @type CapEnum.TwCI
   */
  INDICATORS_MODE: "indicatorsMode",

  /**
   * [ImageType] The type of pixel data (B/W, gray, color, etc.).
   * ICAP_PIXELTYPE
   * @type CapEnum.TwPixelType
   */
  IPIXEL_TYPE: "iPixelType",

  /**
   * [Device] Unless a quantity is dimensionless or uses a specified unit of measure, this setting determines
   * the unit of measure for all quantities.
   * ICAP_UNITS
   * @type CapEnum.TwUnits
   */
  IUNITS: "iUnits",

  /**
   * [Transfer] Gets the transfer mechanism.
   * ICAP_XFERMECH
   * @type CapEnum.TwSX
   */
  IXFER_MECH: "iXferMech",

  /**
   * [Transfer] Allows multiple jobs in batch mode. The application can decide how the job can be processed,
   * according to the flags listed below.
   * CAP_JOBCONTROL
   * @type CapEnum.TwJC
   */
  JOB_CONTROL: "jobControl",

  /**
   * [Compression] Allows the application and Source to agree upon a common set of color descriptors that are made
   * available by the Source.This ICAP is only useful for JPEG-compressed buffered memory image
   * transfers.
   * ICAP_JPEGPIXELTYPE
   * @type CapEnum.TwPixelType
   */
  JPEG_PIXEL_TYPE: "jpegPixelType",

  /**
   * [Compression] Use this capability and JpegQuality as a shortcut to select JPEG quantization.
   * ICAP_JPEGSUBSAMPLING
   * @type CapEnum.TwJS
   */
  JPEG_SUB_SAMPLING: "jpegSubSampling",

  /**
   * [Compression] Use this capability as a shortcut to select JPEG quantization tables that tradeoff quality versus
   * compressed image size. Used in concert with DAT_JPEGCOMPRSSION it is possible for an
   * Application to obtain the tables that are appropriate for varying percentages of quality within a
   * given Source.
   * ICAP_JPEGQUALITY
   * @type CapEnum.TwJQ
   */
  JPEG_QUALITY: "jpegQuality",

  /**
   * [Device] TRUE means the lamp is currently, or should be set to ON.Sources may not support MSG_SET
   * operations.
   * ICAP_LAMPSTATE
   * @type bool
   */
  LAMP_STATE: "lampState",

  /**
   * [Language] Allows application and source to identify which languages they have in common.
   * CAP_LANGUAGE
   * @type CapEnum.TwLanguage
   */
  LANGUAGE: "language",

  /**
   * [Device] Describes whether the image was captured transmissively or reflectively.
   * ICAP_LIGHTPATH
   * @type CapEnum.TwLP
   */
  LIGHT_PATH: "lightPath",

  /**
   * [Device] Describes the general color characteristic of the light source used to acquire the image.
   * ICAP_LIGHTSOURCE
   * @type CapEnum.TwLS
   */
  LIGHT_SOURCE: "lightSource",

  /**.
   * [AutomaticScanning] Describes the number of pages that the scanner can buffer.
   * CAP_MAXBATCHBUFFERS
   * @type uint
   */
  MAX_BATCH_BUFFERS: "maxBatchBuffers",

  /**
   * [Page] The maximum number of frames the Source can provide or the application can accept per page.
   * This is a bounding capability only.It does not establish current or future behavior.
   * ICAP_MAXFRAMES
   * @type ushort
   */
  MAX_FRAMES: "maxFrames",

  /**
   * [MICR] Gets the micr enabled capability.
   * Get this capability to determine if the Source supports check scanning. If set to <c>true</c> check
   * scanning is enabled, if set to <c>false</c> check scanning is disabled.
   * CAP_MICRENABLED
   * @type bool
   */
  MICR_ENABLED: "micrEnabled",

  /**
   * [Device] Allows the source to define the minimum height (Y-axis) that the source can acquire (measured in units of CapName.IUNITS).
   * This is a read only capability.
   * Read only.
   * ICAP_MINIMUMHEIGHT
   * @type float
   */
  MINIMUM_HEIGHT: "minimumHeight",

  /**
   * [Device] Allows the source to define the minimum width (X-axis) that the source can acquire (measured in units of CapName.IUNITS).
   * This is a read only capability.
   * Read only.
   * ICAP_MINIMUMWIDTH
   * @type float
   */
  MINIMUM_WIDTH: "minimumWidth",

  /**
   * [ImageAcquire] How the Source can/should mirror the scanned image data prior to transfer.
   * ICAP_MIRROR
   * @type CapEnum.TwMR
   */
  MIRROR: "mirror",

  /**
   * [Device] For devices that support noise filtering, selects the algorithm to be used.
   * ICAP_NOISEFILTER
   * @type CapEnum.TwNF
   */
  NOISE_FILTER: "noiseFilter",

  /**
   * [ImageAcquire] Defines which edge of the “paper” the image’s “top” is aligned with.
   * ICAP_ORIENTATION
   * @type CapEnum.TwOR
   */
  ORIENTATION: "orientation",

  /**
   * [Device] Overscan is used to scan outside of the boundaries described by ICAP_FRAMES, and is used to
   * help acquire image data that may be lost because of skewing.
   * ICAP_OVERSCAN
   * @type CapEnum.TwOV
   */
  OVER_SCAN: "overScan",

  /**
   * [Feeder] Determines whether source can detect documents on the ADF or flatbed.
   * Read only.
   * CAP_PAPERDETECTABLE
   * @type bool
   */
  PAPER_DETECTABLE: "paperDetectable",

  /**
   * [Feeder] Control paper handling.
   * CAP_PAPERHANDLING
   * @type CapEnum.TwPH[]
   */
  PAPER_HANDLING: "paperHandling",

  /**
   * [PatchCodeDetection] Gets patch code detection enabled capability.
   * Turns patch code detection on and off.
   * ICAP_PATCHCODEDETECTIONENABLED
   * @type bool
   */
  PATCH_CODE_DETECTION_ENABLED: "patchCodeDetectionEnabled",

  /**
   * [PatchCodeDetection] Restricts the number of times a search will be retried if none are found on each page.
   * ICAP_PATCHCODEMAXRETRIES
   * @type uint
   */
  PATCH_CODE_MAX_RETRIES: "patchCodeMaxRetries",

  /**
   * [PatchCodeDetection] A prioritized list of patch code types dictating the order in which patch codes will be sought.
   * Read only.
   * ICAP_PATCHCODESEARCHPRIORITIES
   * @type CapEnum.TwPCH[]
   */
  PATCH_CODE_SEARCH_PRIORITIES: "patchCodeSearchPriorities",

  /**
   * [PatchCodeDetection] The maximum number of supported search priorities.
   * ICAP_PATCHCODEMAXSEARCHPRIORITIES
   * @type uint
   */
  PATCH_CODE_MAX_SEARCH_PRIORITIES: "patchCodeMaxSearchPriorities",

  /**
   * [PatchCodeDetection] Restricts patch code searching to certain orientations, or prioritizes one orientation over the other
   * ICAP_PATCHCODESEARCHMODE
   * @type CapEnum.TwBD
   */
  PATCH_CODE_SEARCH_MODE: "patchCodeSearchMode",

  /**
   * [PatchCodeDetection] Restricts the total time spent on searching for a patch code on each page.
   * ICAP_PATCHCODETIMEOUT
   * @type uint
   */
  PATCH_CODE_TIMEOUT: "patchCodeTimeout",

  /**
   * [ImageType] Sense of the pixel whose numeric value is zero.
   * ICAP_PIXELFLAVOR
   * @type CapEnum.TwPF
   */
  PIXEL_FLAVOR: "pixelFlavor",

  /**
   * [Compression] Sense of the pixel whose numeric value is zero (minimum data value).
   * ICAP_PIXELFLAVORCODES
   * @type CapEnum.TwPF
   */
  PIXEL_FLAVOR_CODES: "pixelFlavorCodes",

  /**
   * [Color] Allows the application and Source to identify which color data formats are available. There are
   * two options, “planar” and “chunky.”
   * ICAP_PLANARCHUNKY
   * @type CapEnum.TwPC
   */
  PLANAR_CHUNKY: "planarChunky",

  /**
   * [PowerMonitoring] MSG_GET reports the kinds of power available to the device. MSG_GETCURRENT reports the
   *  current power supply in use.
   * This is a read only capability.
   * Read only.
   * CAP_POWERSUPPLY
   * @type CapEnum.TwPS
   */
  POWER_SUPPLY: "powerSupply",

  /**
   * [PowerMonitoring] When used with MSG_SET, set the camera power down timer in seconds. When used with
   * MSG_GET, return the current setting of the power down time.
   * CAP_POWERSAVETIME
   * @type int
   */
  POWER_SAVE_TIME: "powerSaveTime",

  /**
   * [ImprinterEndorser] It gets the current list of available printer devices, along with the one currently being
   * used for negotiation. Setting value selects the current device for negotiation, and optionally
   * constrains the list. Reseting restores all the available devices(useful after setting a value has been
   * used to constrain the list).
   * CAP_PRINTER
   * @type CapEnum.TwPR
   */
  PRINTER: "printer",

  /**
   * [ImprinterEndorser] Orientation of each character in the font.
   * CAP_PRINTERCHARROTATION
   * @type uint[]
   */
  PRINTER_CHAR_ROTATION: "printerCharRotation",

  /**
   * [ImprinterEndorser] Turns the current imprinter device on or off.
   * CAP_PRINTERENABLED
   * @type bool
   */
  PRINTER_ENABLED: "printerEnabled",

  /**
   * [Device] The maximum physical height (Y-axis) the Source can acquire (measured in units of CapName.IUNITS).
   * Read only.
   * ICAP_PHYSICALHEIGHT
   * @type float
   */
  PHYSICAL_HEIGHT: "physicalHeight",

  /**
   * [Device] The maximum physical width (X-axis) the Source can acquire (measured in units of CapName.IUNITS).
   * Read only.
   * ICAP_PHYSICALWIDTH.
   * @type float
   */
  PHYSICAL_WIDTH: "physicalWidth",

  /**
   * [ImprinterEndorser] Style (ex: bold, italic…).
   * CAP_PRINTERFONTSTYLE
   * @type CapEnum.TwPrinterFontStyle[]
   */
  PRINTER_FONT_STYLE: "printerFontStyle",

  /**
   * [ImprinterEndorser] Gets the index of the current imprinter.
   * CAP_PRINTERINDEX
   * @type uint
   */
  PRINTER_INDEX: "printerIndex",

  /**
   * [ImprinterEndorser] Lead character for padding.
   * CAP_PRINTERINDEXLEADCHAR
   * @type string[]
   */
  PRINTER_INDEX_LEAD_CHAR: "printerIndexLeadChar",

  /**
   * [ImprinterEndorser] Maximum allowed printer index value.
   * CAP_PRINTERINDEXMAXVALUE
   * @type uint[]
   */
  PRINTER_INDEX_MAX_VALUE: "printerIndexMaxValue",

  /**
   * [ImprinterEndorser] Maximum allowed digits in printer index value.
   * CAP_PRINTERINDEXNUMDIGITS
   * @type uint[]
   */
  PRINTER_INDEX_NUM_DIGITS: "printerIndexNumDigits",

  /**
   * [ImprinterEndorser] Increment between printer index values.
   * CAP_PRINTERINDEXSTEP
   * @type uint[]
   */
  PRINTER_INDEX_STEP: "printerIndexStep",

  /**
   * [ImprinterEndorser] Triggers for incrementing the printer index value.
   * CAP_PRINTERINDEXTRIGGER
   * @type CapEnum.TwPrinterIndexTrigger[]
   */
  PRINTER_INDEX_TRIGGER: "printerIndexTrigger",

  /**
   * [ImprinterEndorser] Specifies the appropriate current imprinter device mode.
   * CAP_PRINTERMODE
   * @type CapEnum.TwPM
   */
  PRINTER_MODE: "printerMode",

  /**
   * [ImprinterEndorser] Specifies the string(s) that are to be used in the string component when the current imprinter
   * device is enabled.
   * CAP_PRINTERSTRING
   * @type string
   */
  PRINTER_STRING: "printerString",

  /**
   * [ImprinterEndorser] Preview of what a printer string will look like.
   * CAP_PRINTERSTRINGPREVIEW
   * @type string[]
   */
  PRINTER_STRING_PREVIEW: "printerStringPreview",

  /**
   * [ImprinterEndorser] Specifies the string that shall be used as the current imprinter device’s suffix.
   * CAP_PRINTERSUFFIX
   * @type string
   */
  PRINTER_SUFFIX: "printerSuffix",

  /**
   * [ImprinterEndorser] Gets the printer vertical offset.
   * Specifies a Y-Offset in <see cref="Device.Unit"/> for the current imprinter device.
   * CAP_PRINTERVERTICALOFFSET
   * @type float
   */
  PRINTER_VERTICAL_OFFSET: "printerVerticalOffset",

  /**
   * [Feeder] Capable of acquiring multiple images of the same page without changing the physical registration of that page.
   * Read only.
   * CAP_REACQUIREALLOWED
   * @type bool
   */
  REACQUIRE_ALLOWED: "reacquireAllowed",

  /**
   * [Feeder] The Source will return the current page to the input side of the document feeder and feed
   * the last page from the output side of the feeder back into the acquisition area.
   * CAP_REWINDPAGE
   * @type bool
   */
  REWIND_PAGE: "rewindPage",

  /**
   * [ImageAcquire] How the Source can/should rotate the scanned image data prior to transfer.
   * ICAP_ROTATION
   * @type float
   */
  ROTATION: "rotation",

  /**
   * [Page] Describes the segmentation setting for captured images. Image segmentation occurs when either
   * the device, the Source or the Application breaks up an image into a stream of image components
   * (text, pictures, graphics) that must be assembled by the application to reconstruct the original
   * document.
   * CAP_SEGMENTED
   * @type CapEnum.TwSG
   */
  SEGMENTED: "segmented",

  /**
   * [Device] A string containing the serial number of the currently selected device in the Source. Multiple
   * devices may all report the same serial number.
   * Read only.
   * CAP_SERIALNUMBER
   * @type string
   */
  SERIAL_NUMBER: "serialNumber",

  /**
   * [ImageAcquire] Darkest shadow, values darker than this value will be clipped to this value.
   * ICAP_SHADOW
   * @type float
   */
  SHADOW: "shadow",

  /**
   * [BarCodeDetection] Provides a list of bar code types that can be detected by the current Data Source.
   * This is a read only capability.
   * Read only.
   * ICAP_SUPPORTEDBARCODETYPES
   * @type CapEnum.TwBT[]
   */
  SUPPORTED_BAR_CODE_TYPES: "supportedBarCodeTypes",

  /**
   * [Caps] Returns a list of all the capabilities for which the Source will answer inquiries. Does not indicate
   * which capabilities the Source will allow to be set by the application.Some capabilities can only be
   * set if certain setup work has been done so the Source cannot globally answer which capabilities
   * are “set-able.”
   * Read only.
   * CAP_SUPPORTEDCAPS
   * @type CapEnum.TwCap[]
   */
  SUPPORTED_CAPS: "supportedCaps",

  /**
   * For Sources that allow unique values to be set for things like the top and bottom or for each
   * segment on a page.
   * Returns a list of all the capabilities for which the Source allows to have unique values.
   * Read only.
   * CAP_SUPPORTEDCAPSSEGMENTUNIQUE
   * @type uint[]
   */
  SUPPORTED_CAPS_SEGMENT_UNIQUE: "SupportedCapsSegmentUnique",

  /**
   * [Caps] Inquire Source’s DAT messages that are valid.
   * Read only.
   * CAP_SUPPORTEDDATS
   * @type CapEnum.TwDG[]
   */
  SUPPORTED_DATS: "supportedDats",

  /**
   * [ImageInformation] Gets the supported ext image information.
   * Lists all of the information that the Source is capable of
   * returning from a call to DAT_EXTIMAGEINFO.
   * Read only.
   * ICAP_SUPPORTEDEXTIMAGEINFO
   * @type CapEnum.TwEI[]
   */
  SUPPORTED_EXT_IMAGE_INFO: "supportedExtImageInfo",

  /**
   * [PatchCodeDetection] A list of patch code types that may be detected by the current Data Source.
   * This is a read only capability.
   * Read only.
   * ICAP_SUPPORTEDPATCHCODETYPES
   * @type CapEnum.TwPCH[]
   */
  SUPPORTED_PATCH_CODE_TYPES: "supportedPatchCodeTypes",

  /**
   * [Page] Gets the supported sizes capability.
   * For devices that support fixed frame sizes. Defined sizes match typical page sizes. This specifies
   * the size(s) the Source can/should use to acquire image data.
   * ICAP_SUPPORTEDSIZES
   * @type CapEnum.TwSS
   */
  SUPPORTED_SIZES: "supportedSizes",

  /**
   * [ImageType] Specifies the dividing line between black and white. This is the value the Source will use to
   *  threshold, if needed, when <see cref="PixelType"/> = <see cref="TwPixelType.BW"/>.
   *  ICAP_THRESHOLD
   * @type float
   */
  THRESHOLD: "threshold",

  /**
   * [ImageAcquire] Allows an application to request the delivery of thumbnail representations for the set of images
   * that are to be delivered.
   * CAP_THUMBNAILSENABLED
   * @type bool
   */
  THUMBNAILS_ENABLED: "thumbnailsEnabled",

  /**
   * [Transfer] This is used with buffered memory transfers. If TRUE, Source can provide application with tiled
   * image data.
   * ICAP_TILES
   * @type bool
   */
  TILES: "tiles",

  /**
   * [AutomaticCapture] For automatic capture, this value selects the number of milliseconds before the first picture is to be
   * taken, or the first image is to be scanned.
   * CAP_TIMEBEFOREFIRSTCAPTURE
   * @type int
   */
  TIME_BEFORE_FIRST_CAPTURE: "timeBeforeFirstCapture",

  /**
   * [AutomaticCapture] For automatic capture, this value selects the milliseconds to wait between pictures taken, or images scanned.
   * CAP_TIMEBETWEENCAPTURES
   * @type int
   */
  TIME_BETWEEN_CAPTURES: "timeBetweenCaptures",

  /**
   * [Compression] Used only with CCITT data compression. Specifies the minimum number of words of
   * compressed codes(compressed data) to be transmitted per line.
   * ICAP_TIMEFILL
   * @type ushort
   */
  TIME_FILL: "timeFill",

  /**
   * [UserInterface] If <c>true</c>, indicates that this Source supports acquisition with/without the UI.
   * Read only.
   * CAP_UICONTROLLABLE
   * @type bool
   */
  UI_CONTROLLABLE: "uIControllable",

  /**
   * [Transfer] If TRUE the Source will issue a MSG_XFERREADY before starting the scan.
   * ICAP_UNDEFINEDIMAGESIZE
   * @type bool
   */
  UNDEFINED_IMAGE_SIZE: "undefinedImageSize",

  /**
   * [Transfer] Number of images the application is willing to accept this session.
   * CAP_XFERCOUNT
   * @type short
   */
  XFER_COUNT: "xferCount",

  /**
   * [Resolution] Current/Available optical resolutions for x-axis.
   * ICAP_XNATIVERESOLUTION
   * @type float
   */
  X_NATIVE_RESOLUTION: "xNativeResolution",

  /**
   * [Resolution] Measured in units of pixels per unit as defined by CapName.IUNITS.
   * ICAP_XRESOLUTION
   * @type float
   */
  X_RESOLUTION: "xResolution",

  /**
   * [ImageAcquire] All the X-axis scaling values available. A value of ‘1.0’ is equivalent to 100% scaling. Do not use
   * values less than or equal to zero.
   * ICAP_XSCALING
   * @type float
   */
  X_SCALING: "xScaling",

  /**
   * [Resolution] Current/Available optical resolutions for x-axis.
   * ICAP_YNATIVERESOLUTION
   * @type float
   */
  Y_NATIVE_RESOLUTION: "yNativeResolution",

  /**
   * [Resolution] Measured in units of pixels per unit as defined by CapName.IUNITS.
   * ICAP_YRESOLUTION
   * @type float
   */
  Y_RESOLUTION: "yResolution",

  /**
   * [ImageAcquire] All the Y-axis scaling values available. A value of ‘1.0’ is equivalent to 100% scaling. Do not use
   *  values less than or equal to zero.
   * ICAP_YSCALING
   * @type float
   */
  Y_SCALING: "yScaling",

  /**
   * [Device] Camera lens zooming.
   * ICAP_ZOOMFACTOR
   * @type short
   */
  ZOOM_FACTOR: "zoomFactor"
};
