
import SearchComponent from '@/components/SearchComponent.vue';
import NonGSDDFormComponent from '@/components/NonGSDD/NonGSDDFormComponent.vue';
import GSDDMergeModalComponent from '@/components/NonGSDD/GSDDMergeModalComponent.vue';
import VendorPriceComponent from '@/components/Vendor/VendorPriceComponent.vue';
import WarningLabels from '@/components/NonGSDD/WarningLabels.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NonGSDDDrug, NonGSDDPatEd, NonGSDDWarningLabels } from '@/models/Drug/NonGSDDDrug';
import { DrugView } from '@/models/Drug/DrugView';
import { Inventory } from '@/models/Inventory';
import InventoryComponent from '@/components/Inventory/InventoryComponent.vue';
import Axios, { AxiosRequestConfig } from "axios";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { Vendor } from "@/models/Vendor";
import { VendorDrugMap } from "@/models/VendorDrugMap";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { DrugProgram, DrugStatus } from "@/models/Drug/Drug";
import DrugStatusEnum = DrugStatus.DrugStatusEnum;
import DrugDocumentsComponent from '@/components/Drug/DrugDocumentsComponent.vue'
import { Program } from "@/models/Program";

@Component({
    name: "NonGSDDDrugDetailsPage",
    components: {
        SearchComponent,
        WarningLabels,
        NonGSDDFormComponent,
        InventoryComponent,
        GSDDMergeModalComponent,
        VendorPriceComponent,
        DrugDocumentsComponent,
    }
})
export default class NonGSDDDrugDetailsPage extends Vue {
    protected inventories: Inventory[] = [];
    protected gsddDrugMergeMatch: DrugView[] = [];
    private isLoading = false;
    private drugSearch: NonGSDDDrug = new NonGSDDDrug();
    private drugOriginal: NonGSDDDrug = new NonGSDDDrug();
    private drug: NonGSDDDrug = new NonGSDDDrug();
    private drugEdit: NonGSDDDrug = new NonGSDDDrug();
    private status: DrugStatus.DrugStatusEnum | null = null;
    // private patEd: NonGSDDPatEd = new NonGSDDPatEd();
    private warningLabels: NonGSDDWarningLabels[] = [];
    private brandTitle: string = "Brand";
    private patEdTitle: string = "Patient Education";
    private editBrandForm: boolean = false;
    private editPatEdForm: boolean = false;
    private formDataModified: boolean = false;
    private primary: boolean = false;
    private price: number = 0;
    private vendor: Vendor = new Vendor();
    private vendorFields = [
        "primary",
        "vendorName",
        {
            key: "cost", sortable: true, formatter: (value: number) => {
                return `$${value.toFixed(2)}`;
            },
        },
        { key: "action", label: "" }
    ];
    private showVendorForm = false;
    private modeVendorForm = 'new';
    private alert = "";
    private selectedProgram: Program = new Program();

    get routeDrugId(): number {
        let res: number;
        res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get drugSource(): DrugSource {
        return DrugSource.NonGsddProduct;
    }

    get drugStatus() {
        const status = this.status ?? 0;
        return DrugStatus.DrugStatusEnum[status];
    }

    // from Product
    get drugName(): any {
        if (!this.drug) return ``;
        return this.drug.name;
    }

    get awp(): any {
        return this.drug?.awp?.toFixed(2) ?? "";
    }

    get packageSize(): any {
        if (!this.drug || !this.drug.packageSize) return ``;
        return `${this.drug.packageSize}`;
    }

    get unitOfMeasure(): any {
        if (!this.drug) return ``;
        return this.drug.unitOfMeasure;
    }

    get minimumDispenseQuantity(): any {
        if (!this.drug || !this.drug.minimumDispenseQuantity) return ``;
        return `${this.drug.minimumDispenseQuantity}`;
    }

    get dispensingFee(): string {
        return this.drug?.dispensingFee?.toFixed(2) ?? "";
    }

    get deaClass(): any {
        if (!this.drug) return ``;
        return this.drug.deaClass;
    }

    get din(): string {
        if (!this.drug) return '';
        return this.drug.din;
    }

    get patEdDescription(): any {
        if (!this.drug.patEd || !this.drug.patEd.description) return ``;
        return this.drug.patEd.description;
    }

    get patEdContraindications(): any {
        if (!this.drug.patEd || !this.drug.patEd.contraindications) return ``;
        return this.drug.patEd.contraindications;
    }

    get patEdAdministration(): any {
        if (!this.drug.patEd || !this.drug.patEd.administration) return ``;
        return this.drug.patEd.administration;
    }

    get patEdMissedDoseInstructions(): any {
        if (!this.drug.patEd || !this.drug.patEd.missedDoseInstructions) return ``;
        return this.drug.patEd.missedDoseInstructions;
    }

    get patEdMonitoring(): any {
        if (!this.drug.patEd || !this.drug.patEd.monitoring) return ``;
        return this.drug.patEd.monitoring;
    }

    get patEdSideEffects(): any {
        if (!this.drug.patEd || !this.drug.patEd.sideEffects) return ``;
        return this.drug.patEd.sideEffects;
    }

    get patEdStorage(): any {
        if (!this.drug.patEd || !this.drug.patEd.storage) return ``;
        return this.drug.patEd.storage;
    }

    get ncpdpBillingUnit(): string {
        return this.drug ? this.drug.unitOfMeasure : ``;
    }

    get vendors(): VendorDrugMap[] {
        if (!this.drug?.vendors) return [];
        return this.drug.vendors;
    }

    // vendors table
    set vendors(value: VendorDrugMap[]) {
        if (this.drug) this.drug.vendors = value;
    }

    created() {
        if (this.routeDrugId) {
            this.fetchDrug(this.routeDrugId);
        }
    }

    @Watch('$route.params.id')
    onParamChanged(value: string, oldValue: string) {
        if (value != oldValue) {
            this.fetchDrug(Number(value));
        }
    }

    @Watch('drug')
    onDrugChanged(value: NonGSDDDrug | null, _oldValue: NonGSDDDrug | null) {
        if (!value) return;

        if (value.productID && value.productID != -1 && value.productID != this.routeDrugId) {
            this.$router.push({ name: 'Non-GSDD-Drug', params: { id: value.productID.toString() } });
        } else {
            this.fetchInventories();
        }
    }

    priceUpdate(price: any) {
        this.price = price;
    }

    primaryUpdate(primary: boolean) {
        this.primary = primary;
    }

    deleteConfirmDialog(item: VendorDrugMap) {
        this.$bvModal.msgBoxConfirm(`This vendor information associated to this drug will get deleted. Are you sure?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.removeVendor(item);
            })
            .catch(err => {
                console.error("Error caught on Clear button.", err);
            });
    }

    fetchVendors() {
        this.vendors = [];
        if (this.drug) this.drug.vendors = [];
        if (!this.drug || !this.drug.productID) return;
        // fetch vendor/drug mappings with cost
        Axios.get(`/Drug/${this.drug.productID}/Vendors`, { params: { source: this.drugSource } })
            .then(result => {
                if (this.drug) this.drug.vendors = result.data;
                this.vendors = result.data;
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                this.blockNoCost();
            });
    }

    blockNoCost(_drug?: DrugProduct): boolean {
        return false;
        // let ret = false;
        // // console.log("Drug: ", drug);
        //
        // if (drug && drug.activeStatus == DrugStatus.DrugStatusEnum.Inactive) {
        //     ret = false;
        // } else {
        //     if (this.status == DrugStatus.DrugStatusEnum.Active || this.status == DrugStatus.DrugStatusEnum.ActiveAndPreferred) {
        //         //Check if there is a cost associated with this drug
        //         const hasPrice = this.vendors?.some(ven => ven.cost) ?? false;
        //         ret = !hasPrice;
        //     }
        // }
        // // console.log("blockNoCost? ", ret);
        // ret = false;
        // return ret;
    }

    removeVendor(item: VendorDrugMap) {
        const vendorID = item.vendorID;
        if (!(this.drug?.productID && vendorID)) return;

        const config: AxiosRequestConfig = {
            params: {
                vendorID: vendorID,
                source: this.drugSource
            }
        };
        Axios.delete<boolean>(`/Drug/${this.drug.productID}/Vendors`, config)
            .then(res => {
                if (res.data === true) {
                    this.fetchVendors();
                    this.$notification(NotificationOptions.notificationOptionsPreset("Vendor Price removed.", NotificationOptions.NotificationTypes.success));
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.error(error));
                console.error({ error, response: error?.response });
            });
    }

    submitVendorInformation() {
        if (!this.drug || !this.drug.productID || !this.vendor || !this.vendor.id || !this.price) return;

        const vendorDrugMap = {
            packageID: +this.drug.productID, // the + converts to a number.
            vendorID: this.vendor.id,
            displayOrder: 1,
            cost: +this.price,
            primary: this.primary,
            drugSource: this.drugSource
        };

        Axios.post<VendorDrugMap>(`/Drug/${this.drug.productID}/Vendors`, vendorDrugMap)
            .then(res => {
                if (res.data.vendorID) {
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Vendor information"));
                    this.fetchVendors();
                    this.closeVendorForm();
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Vendor information"));
                console.error("Error saving vendor information", { error, response: error?.response });
            });
    }

    updateVendor(vendor: VendorDrugMap) {
        this.openVendorForm();
        this.price = vendor.cost;
        this.primary = vendor.primary;
        this.vendor.id = vendor.vendorID;
        this.modeVendorForm = 'edit';
    }

    openVendorForm() {
        this.clearVendorForm();
        this.showVendorForm = true;
    }

    closeVendorForm() {
        this.clearVendorForm();
        this.showVendorForm = false;
    }

    clearVendorForm() {
        this.primary = false;
        this.price = 0;
        this.vendor = new Vendor();
        this.modeVendorForm = 'new';
    }

    async clearClicked() {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.drugSearch = new NonGSDDDrug();
        this.drug = new NonGSDDDrug();
        await this.$router.push({ name: 'Non-GSDD-Drug-New' });
    }

    async onDrugSelected(drug: NonGSDDDrug) {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.editBrandForm = false;
        this.editPatEdForm = false;
        //this.clearClicked();
        this.status = null;
        this.warningLabels = [];
        const productId = Number(drug.productID || 0);
        this.fetchDrug(productId);
    }

    saveProgram() {
        Axios.post(`/Drug/${this.drug?.drugId}/${this.drug.source}/program-autoselect`, Number(this.selectedProgram?.id) || 0)
            .then(response => {
                if (response.data?.programId) {
                    // this.currentAssociatedProgramID = response.data?.programId;
                    this.$notification(NotificationOptions.successSaveNotificationPreset('Program association'));
                } else
                    this.$notification(NotificationOptions.notificationOptionsPreset('Program was successfully removed from this drug', NotificationOptions.NotificationTypes.success));
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset('Program association'));
                this.selectedProgram = new Program();
                console.error('There was an error while updating program', { error, response: error?.response });
            });
    }

    fetchDrug(productID: number) {
        if (!productID || productID == -1) {
            this.drug = new NonGSDDDrug();
            return;
        }

        Axios.get(`/Drug/${productID}`, {
            params: {
                getCategories: true,
                getVendors: true,
                drugSource: this.drugSource
            }
        })
            .then(response => {
                const drug: NonGSDDDrug = response.data;
                this.drug = new NonGSDDDrug(null, drug);
                if (drug.patEd) this.drug.patEd = Object.assign(new NonGSDDPatEd(), drug.patEd);
                this.status = drug.activeStatus;
                if (drug.warningLabels) {
                    this.warningLabels = Object.assign(new Array<NonGSDDWarningLabels>(), drug.warningLabels);
                }

                Axios.get(`/Drug/${drug.drugId}/Alert`, { params: { drugSource: drug.source } })
                    .then(response => {
                        this.alert = response.data;
                    })
                    .catch(error => {
                        console.error('There was an error while getting Alerts', { error, response: error?.response });
                    });
                Axios.get<Array<DrugProgram>>(`api/Drug/${this.drug.drugId}/${this.drug.source}/Program`)
                    .then(response => {
                        if (response.data?.length) {
                            const dps = response.data.map(dp => new DrugProgram(dp));
                            this.selectedProgram = dps.find(dp => dp.autoselect)?.program || new Program();
                        }
                    })
                    .catch(error => {
                        console.error("Error while getting program", { error, response: error?.response });
                    });
            })
            .catch(error => {
                console.error('Problem while getting drug details.', { error, response: error?.response });
            })
            .finally(() => {
                this.isLoading = false;
                this.drugOriginal = new NonGSDDDrug(null, this.drug);
                this.drugOriginal.patEd = new NonGSDDPatEd();
                this.drugOriginal.warningLabels = new Array<NonGSDDWarningLabels>();
                if (this.drug.patEd) this.drugOriginal.patEd = Object.assign(new NonGSDDPatEd(), this.drug.patEd);
                if (this.drug.warningLabels) this.drugOriginal.warningLabels = this.drug.warningLabels;
            });
    }

    onEditBrand() {
        this.drugEdit = new NonGSDDDrug(null, this.drug);
        this.editBrandForm = true;
    }

    onEditPatEd() {
        this.drugEdit = new NonGSDDDrug(null, this.drug);
        this.drugEdit.patEd = Object.assign(new NonGSDDPatEd(), this.drug.patEd);
        this.editPatEdForm = true;
    }

    onBrandFormContinue() {
        this.drug = new NonGSDDDrug(null, this.drugEdit);
        this.status = this.drugEdit.activeStatus;
        this.drug.patEd = new NonGSDDPatEd(this.drugEdit.patEd);
        this.editBrandForm = false;
        this.checkFormDataModified();
    }

    onPatEdFormContinue() {
        this.drug = new NonGSDDDrug(null, this.drugEdit);
        this.status = this.drugEdit.activeStatus;
        this.drug.patEd = new NonGSDDPatEd(this.drugEdit.patEd);
        this.editPatEdForm = false;
        this.checkFormDataModified();
    }

    async onNewBrand() {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
        }
        this.formDataModified = false;
        this.drug = new NonGSDDDrug(-1);
        this.drug.patEd = new NonGSDDPatEd();
        this.drug.warningLabels = new Array<NonGSDDWarningLabels>();
        this.drugEdit = new NonGSDDDrug(null, this.drug);
        this.drugOriginal = new NonGSDDDrug(null, this.drug);
        this.drugOriginal.patEd = new NonGSDDPatEd();
        this.drugOriginal.warningLabels = new Array<NonGSDDWarningLabels>();
        this.warningLabels = new Array<NonGSDDWarningLabels>();
        this.onEditBrand();
        this.onEditPatEd();
    }

    async onSaveClicked() {
        let fetchByNdcResults: NonGSDDDrug[] = [];
        let sc: number = 0;
        let scGood: number[] = [200, 204];
        if ((this.drugOriginal.ndc && this.drug.ndc != this.drugOriginal.ndc) || !this.drugOriginal.ndc) {
            await this.fetchByNdc(this.drug.ndc)
                .then(value => {
                    if (value.length == null) sc = value;
                    else {
                        sc = value[0];
                        fetchByNdcResults = value[1];
                    }
                });
            if (!scGood.includes(sc)) {
                return;
            }
        }
        let ignoreDuplicateNdc: boolean = false;
        if (sc == 200 && fetchByNdcResults.map(item => item.ndc).includes(this.drug.ndc)) {
            ignoreDuplicateNdc = await this.confirmIgnoreDuplicateNdc(this.drug.ndc);
            if (!ignoreDuplicateNdc) return;
        }
        this.drug.warningLabels = this.warningLabels;
        let newProductID: number = -1;
        await this.dbSave(this.drug).then(value => {
            if (value.length == null) {
                sc = Number(value);
            } else {
                sc = Number(value[0]);
                newProductID = value[1];
            }
        });
        if (sc != 200) {
            return;
        }
        this.formDataModified = false;
        this.warningLabels = [];
        if (newProductID > 0) this.drug.productID = newProductID;
        await this.onDrugSelected(this.drug);
        this.$notification(NotificationOptions.successSaveNotificationPreset("Drug"));
        return;
    }

    async onCancelClicked() {
        let ignoreFormDataModified: boolean = false;
        if (this.formDataModified) {
            ignoreFormDataModified = await this.confirmIgnoreFormDataModified();
            if (!ignoreFormDataModified) return;
            this.formDataModified = false;
        }
        this.drug = new NonGSDDDrug(null, this.drugOriginal);
        this.status = this.drugOriginal.activeStatus ? Number(this.drugOriginal.activeStatus) : 0;
        this.drug.patEd = new NonGSDDPatEd(this.drugOriginal.patEd);
        this.drug.warningLabels = Object.assign(new Array<NonGSDDWarningLabels>(), this.drugOriginal.warningLabels);
        this.warningLabels = Object.assign(new Array<NonGSDDWarningLabels>(), this.drugOriginal.warningLabels);
        this.editBrandForm = false;
        this.editPatEdForm = false;
        this.formDataModified = false;
        return;
    }

    async onGSDDMergeClicked() {
        let fetchByGSDDMergeResults: DrugView[] = [];
        let sc: number = 0;
        let scGood: number[] = [200];
        await this.fetchGSDDMerge(this.drug.ndc, this.drug.packageSize, this.drug.unitOfMeasure)
            .then(value => {
                if (value.length == null) sc = value;
                else {
                    sc = value[0];
                    fetchByGSDDMergeResults = value[1];
                }
            });
        if (!scGood.includes(sc)) {
            return;
        }
        if (fetchByGSDDMergeResults.length == 0) {
            const error = "No matching NDC, PackageSize, UnitOfMeasure was found for a GSDD drug";
            this.$notification(NotificationOptions.error(error));
            return;
        }
        this.gsddDrugMergeMatch = fetchByGSDDMergeResults;
        this.StartGSDDMergeModal();
    }

    StartGSDDMergeModal() {
        this.$bvModal.show("GSDDDrugMerge");
    }

    GSDDMergeSuccess() {
        this.formDataModified = false;
        this.drugSearch = new NonGSDDDrug();
        this.drug = new NonGSDDDrug();
        this.$bvModal.hide("GSDDDrugMerge");
        this.$notification(NotificationOptions.successSaveNotificationPreset("Merge"));
        this.$router.push({ name: 'Non-GSDD-Drug-New' });
    }

    GSDDMergeCanceled() {
        this.$bvModal.hide("GSDDDrugMerge");
    }

    checkFormDataModified() {
        const drugsMatch = JSON.stringify(this.drugOriginal) == JSON.stringify(this.drug);
        const warningLabelsMatch = JSON.stringify(this.drugOriginal.warningLabels) == JSON.stringify(this.warningLabels);
        this.formDataModified = !(drugsMatch && warningLabelsMatch);
    }

    confirmIgnoreFormDataModified(): Promise<boolean> {
        return this.$bvModal.msgBoxConfirm(`Data has been modified. New search selection, or Clicking 'New Drug', Clear buttons will not save data - Are you sure?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                return value == null ? false : value;
            })
            .catch(err => {
                console.error("Error caught on New Drug or Clear button.", err);
                return false;
            });
    }

    confirmIgnoreDuplicateNdc(ndc: string): Promise<boolean> {
        return this.$bvModal.msgBoxConfirm(`NDC: ` + ndc + ` - already exist! - Are you sure?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                return value == null ? false : value;
            })
            .catch(err => {
                console.error("Error caught on verify duplicate NDC.", err);
                return false;
            });
    }

    fetchByNdc(ndc: string): Promise<[number, Array<NonGSDDDrug>] | any> {
        return Axios.get(`/NonGSDD/ndc/${ndc}`)
            .then(response => {
                const drugNdc: NonGSDDDrug[] = response.data;
                const sc: number = Number(response.status);
                return [sc, drugNdc];
            })
            .catch(error => {
                this.$notification(NotificationOptions.error(error));
                console.error('Error while getting NonGSDDDrug ByNdc.', { error, response: error?.response });
                return error.response?.status;
            });
    }

    dbSave(drug: NonGSDDDrug): Promise<[number, number]> {
        this.isNullPatEd(drug.patEd);
        return Axios.post<NonGSDDDrug>('/NonGSDD', drug, { params: { bypassDuplicateCheck: true } })
            .then(response => {
                const sc: number = Number(response.status);
                let drugResponse = response.data;
                this.drug = new NonGSDDDrug(null, drugResponse);
                const newProductID: number = drugResponse.productID!;
                return [sc, newProductID];
            })
            .catch(error => {
                const errorMsg = "Error while saving NonGSDDDrug";
                console.error(errorMsg, { error, response: error?.response });
                this.$notification(NotificationOptions.error(error));
                return error.response?.status;
            });
    }

    fetchGSDDMerge(ndc: string, packageSize: Number, unitOfMeasure: string): Promise<[number, Array<DrugView>] | any> {
        return Axios.get('/NonGSDD/gsdd-merge-search-match', {
            params: {
                ndc: ndc,
                packageSize: packageSize,
                unitOfMeasure: unitOfMeasure
            }
        })
            .then(response => {
                const drugMatch: DrugView[] = response.data;
                const sc: number = Number(response.status);
                return [sc, drugMatch];
            })
            .catch(error => {
                this.$notification(NotificationOptions.error(error));
                console.error('Error while searching GSDD drugs to merge.', { error, response: error?.response });
                return error.response?.status;
            });
    }

    isNullPatEd(patEd: NonGSDDPatEd) {
        if (patEd.description == null) patEd.description = "";
        if (patEd.contraindications == null) patEd.contraindications = "";
        if (patEd.administration == null) patEd.administration = "";
        if (patEd.missedDoseInstructions == null) patEd.missedDoseInstructions = "";
        if (patEd.monitoring == null) patEd.monitoring = "";
        if (patEd.sideEffects == null) patEd.sideEffects = "";
        if (patEd.storage == null) patEd.storage = "";
    }

    fetchInventories() {
        if (!this.drug?.productID) return;

        Axios.get<Inventory[]>(`/Drug/${this.drug.productID}/Inventory`, { params: { drugSource: this.drugSource } })
            .then(response => {
                console.log("inventory response: ", response.data);
                this.inventories = response.data;
            })
            .catch(error => {
                console.error('There was an error while getting Inventory', { error, response: error?.response });
            });
    }

    saveAlert() {
        if (!this.alert.length) return;
        if (!this.drug || !this.drug.drugId) return;

        Axios.post(`/Drug/${this.drug.drugId}/Alert`, { body: this.alert }, { params: { drugSource: this.drug.source } })
            .then(response => {
                this.alert = response.data;
                this.$notification(NotificationOptions.successSaveNotificationPreset("Alert"));

            })
            .catch(error => {
                console.error('There was an error while updating alert', { error, response: error?.response });
                this.$notification(NotificationOptions.error(error));
            });
    }
}
