import HasID from "./HasID";
import HasLabel from "./HasLabel";
import TrackedObject from "./TrackedObject";

export interface Printer extends HasID, TrackedObject {
    ipAddress: string
    name: string
    storeId: number
    type: string
    description: string
}

export class Printer extends HasLabel {

    constructor(obj?:Printer){
        super();
        if(obj) Object.assign(this, obj);
    }

    toString(): string {
        if (this.name)
            return `${this.id} - ${this.name}`;
        else return '';
    }
}