
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import SearchComponent from '@/components/SearchComponent.vue';
    import InventoryBatchFormComponent from '@/components/Inventory/InventoryBatchFormComponent.vue';

    import {InventoryBatch } from '@/models/InventoryBatch'
    import {Store} from '@/models/Store'
    import {Vendor} from '@/models/Vendor'
    import {Drug} from '@/models/Drug/Drug'
    import { Inventory } from '@/models/Inventory';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
import moment from 'moment';

    @Component({
        name: "InventoryBatchQueuePage",
        components: {
            SearchComponent, Checkbox
            , InventoryBatchFormComponent
        },
    })
    export default class InventoryBatchQueuePage extends Vue {
        private isLoading = false;
        private items:Array<InventoryBatch> = new Array<InventoryBatch>();

        private storeFilter: Store = new Store();
        private vendorFilter: Vendor = new Vendor();
        private drugFilter: Drug = new Drug();
        private selectedBatchID = 0;

        private fields: any[] = [
            { key: "ndcProblemIndicator", label: ''},
            { key: "purchaseOrderNumber", label: "Purchase Order #" },
            { key: "linesQty", label: "Lines" },
            { key: "vendorName", label: "Vendor" },
            { key: "storeName", label: "Store" },
            { key: "programName", label: "Program" },
            { key: "created", label: "Created" },
            { key: "createdBy", label: "Created By" },
            { key: "actions", label: ''},
        ];
        private sortBy:string = 'created'
        private showSubmitted = false;
        private showRemoved = false;

        created(){
            this.loadItems();
        }

        loadItems(){
            this.isLoading = true;
            this.$http.get<Array<InventoryBatch>>(`/Inventory/inventory-batch-queue`, {
                params: {
                    storeID: this.storeFilter?.id
                    , vendorID: this.vendorFilter?.id
                    , packageID: this.drugFilter?.packageID
                    , showSubmitted: this.showSubmitted
                    , showRemoved: this.showRemoved
                }
            })
            .then(res => {
                this.items = res.data.map(i => Object.assign(new InventoryBatch.InventoryBatchItem(), i));
            })
            .catch(err => {
                console.error('Error while loading inventory batch items', {err, response: err?.response})
            })
            .finally(() => {
                this.isLoading = false;
            })
        }

        colorCodeTable(row : InventoryBatch, type: string){
            if (!row || type !== 'row')
                return;

            if(row.submitted) return 'table-info';
            if(row.canceled) return 'table-danger';
        }

        rowTitle(row : InventoryBatch){
            const dt = row.submitted || row.canceled;
            const action = row.submitted ? 'Submitted' : (row.canceled ? 'Canceled' : '');
            const actionBy = row.submittedBy  || row.canceledBy;
            if(dt) {
                let dtStr = moment.utc(dt).local().format('lll');
                return `${action} by ${actionBy} at ${dtStr}`;
            }
        }

        openDetails(batchID:number){
            this.selectedBatchID = batchID;
            this.$bvModal.show('inventory-batch-form');
        }

        addPurchase(){
            this.selectedBatchID = 0;
            this.$bvModal.show('inventory-batch-form');
        }

    }
