import HasID from "./HasID";

export interface Note extends HasID {
    body: string;
    entityType: number;
    entityId: number | string;
    created: Date;
    createdBy: string;
    deleted: Date | null;
    deletedBy: string | null;
}

export enum EntityType {
    Unknown,
    PrescriptionHardCopyAnnotation,
    Patient,
    Case,
    Program,
    PriorAuthorization,
    DispenseError,
    RxTransferQueue,
    Prescriber,
    PrescriberFollowUpCase,
    ElectronicPriorAuthorization,
    DrugShortageReport,
    SurplusReports,
    GsddDrug,
    NonGsddDrug,
    CompoundDrug,
}

export class Note implements Note{
    constructor(obj?:Note){
        if(obj) Object.assign(this, obj);
    }
}
