import { render, staticRenderFns } from "./ConfigurationPage.vue?vue&type=template&id=56126a0e&scoped=true"
import script from "./ConfigurationPage.vue?vue&type=script&lang=ts"
export * from "./ConfigurationPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56126a0e",
  null
  
)

export default component.exports