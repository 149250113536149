

import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { PatientAssistanceProgram, PatientAssistanceProgramStatus } from "@/models/PatientAssistanceProgram";
import moment from "moment/moment";
import Axios, { AxiosRequestConfig } from "axios";
import { BTable, BvTableCtxObject } from "bootstrap-vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
    name: "PatientAssistanceProgramTable"
})
export default class PatientAssistanceProgramTable extends Vue {
    @Prop() tableFilter!: AxiosRequestConfig;
    @Prop() apiUrl!: string;
    @Prop({ type: Boolean, default: false }) allowRemoval!: boolean;
    protected modalItem: PatientAssistanceProgram | null = null;
    protected imageSrc: string = "";
    protected showModal: boolean = false;
    protected showAll: boolean = false;
    protected attachmentSources: string[] = [];
    @Ref('pap-queue-table') readonly table!: BTable;

    clearAndRefresh() {
        this.modalItem = null;
        this.attachmentSources = [];
        this.table.refresh();
    }

    async approveApplication() {
        const url = `PatientAssistanceProgram/Approve/${this.modalItem?.id}`;
        try {
            await Axios.post(url);
            this.clearAndRefresh();
        } catch (err) {
            if (err.message == "Request failed with status code 403") {
                err.message = "You are not authorized for PAP Approval or Rejection";
            }
            this.$notification(NotificationOptions.error(err.message));
        }
    }

    async rejectApplication() {
        const url = `PatientAssistanceProgram/Reject/${this.modalItem?.id}`;
        try {
            await Axios.post(url);
            this.clearAndRefresh();
        } catch (err) {
            if (err.message == "Request failed with status code 403") {
                err.message = "You are not authorized for PAP Approval or Rejection";
            }
            this.$notification(NotificationOptions.error(err.message));
        }
    }

    canRemove(item: PatientAssistanceProgram) {
        if (item.status != PatientAssistanceProgramStatus.Created) return true;
        if (item.attachmentIds.length != 0) return true;
        return false;
    }

    async removalClick(item: PatientAssistanceProgram) {
        try {
            await Axios.post(`api/PatientAssistanceProgram/Remove/${item.id}`);
            this.clearAndRefresh();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async editClick(item: PatientAssistanceProgram) {
        this.modalItem = item;

        try {
            await this.fetchAttachmentSources();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

        const url = `Image/${item.imageId}`;
        const config: AxiosRequestConfig = { responseType: "blob" };

        try {
            const responseImage = await Axios.get(url, config);
            this.imageSrc = URL.createObjectURL(responseImage.data);
            this.showModal = true;
        } catch (err) {
            alert(err);
        }
    }

    async fetchAttachmentSources() {
        if (!this.modalItem) return;

        const config: AxiosRequestConfig = { responseType: "blob" };
        for (const attachmentId of this.modalItem.attachmentIds) {
            const url = `Image/${attachmentId}`;
            try {
                const img = await Axios.get(url, config);
                this.attachmentSources.push(URL.createObjectURL(img.data));
            } catch (err) {
                this.$notification(NotificationOptions.error(err));
            }
        }
    }

    get tableFields() {
        let fields = [
            { key: "id", sortable: true },
            { key: "patient" },
            { key: "status", formatter: (val: number) => PatientAssistanceProgramStatus[val].toString() },
            { key: "created", sortable: true, formatter: (d: any) => moment.utc(d).local().format("L LT") },
            { key: "updated", sortable: true, formatter: (d: any) => moment.utc(d).local().format("L LT") },
            { key: "edit", label: "" },
        ];
        if (this.allowRemoval) {
            fields.push({ key: "removal", label: "" });
        }
        return fields;
    }

    async tableProvider(ctx: BvTableCtxObject) {
        const uri = ctx.apiUrl + "";
        const config = ctx.filter as AxiosRequestConfig;
        try {
            const resp = await Axios.get<PatientAssistanceProgram[]>(uri, config);
            return resp.data.filter(a => !isNaN(a.imageId)).map(a => new PatientAssistanceProgram(a));
        } catch (err) {
            return [];
        }
    }

    rowClass(item: any, type: any) {
        if (!item || type !== "row") return;

        if (item.status === 3) return 'table-danger';
        if (item.status === 2) return 'table-success';
    }

}
