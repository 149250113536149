
import { Component, Mixins, Prop } from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import { NonGSDDDrug } from '@/models/Drug/NonGSDDDrug';
import EnumSelector from '@/components/EnumSelector.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { DrugStatus } from "@/models/Drug/Drug";

@Component({
    name: "NonGSDDFormComponent",
    components: {
        EnumSelector,
        Checkbox
    }
})
export default class NonGSDDFormComponent extends Mixins(DisableAutocompleteMixin) {
    @Prop() private drug!: NonGSDDDrug;
    @Prop() private formSection!: string;
    @Prop({ default: () => false, type: Function }) private blockNoCost!: Function;
    protected statuses = DrugStatus.DrugStatusEnum;

    get disableFields(): boolean {
        return false;
    }

    created() {
        //console.log("%c Created NonGSDDFormComponent", "color: green");
    }

    continueClicked() {
        if (this.drug.ndc == null || this.drug.name == null || this.drug.unitOfMeasure == null ||
            this.drug.minimumDispenseQuantity == null ||
            this.drug.ndc.trim() == "" || this.drug.name.trim() == "" || this.drug.unitOfMeasure.trim() == "" ||
            this.drug.minimumDispenseQuantity < 0.0001) {
            this.$bvModal.msgBoxOk(`Required Fields: NDC, Name, Unit of Measure, Minimum Dispense Quantity - can not be left empty`);
            return;
        }
        (this.drug as any).billingUnit = this.drug.unitOfMeasure;
        this.$emit('continue');
    }

    backToNonGSDDDashboard() {
        this.$emit("close");
        return;
    }
}
