
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import Axios, {AxiosRequestConfig} from 'axios';
    import Vue2Dropzone from 'vue2-dropzone';
    import moment from "moment";

    import {BTable, BvTableCtxObject} from "bootstrap-vue";
    import SearchComponent from "@/components/SearchComponent.vue";
    import PatientDocumentsModal from '@/components/Patient/PatientDocumentsModal.vue';

    import {NotificationOptions} from "@/util/NotificationOptionsPresets";
    import {LockHandler} from '@/mixins/LockHandler';
    import {Prescriber} from '@/models/Prescriber';
    import {QueueItem} from '@/models/QueueItem';
    import {Program} from '@/models/Program';
    import {Patient} from '@/models/Patient';
    import {Drug} from "@/models/Drug/Drug";
    import {Store} from "@/models/Store";
    import IngestionImage from "@/components/IngestionImage.vue";
    //@ts-ignore
    import {Scanner} from "@/components/TWScan";

    @Component({
        name: "IngestionComponent",
        components: {
            "VueDropzone": Vue2Dropzone,
            SearchComponent,
            PatientDocumentsModal,
            IngestionImage
        },
    })
    export default class IngestionComponent extends Mixins(LockHandler) {
        private itemsSelected: Array<QueueItem> = [];
        private programs: Array<Program> = [];
        private destinationBucket: any = null;
        private selectedBucket: any = null;
        private store: Store = new Store();
        private scanner: Scanner = new Scanner(this.fetchQueue);
        private scanners: Array<string> = [];
        private selectedScanner: string = "";
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
            this.selectedType = this.persistentType;
            this.fetchPrograms();
            this.interval = setInterval(this.fetchQueue, 600000);
            this.scanner.getAvailableScanners().then((result: Array<string>) => {
                this.scanners = result;
                const previouslySelectedScanner = this.persistentScanner;
                if(this.scanners.indexOf(previouslySelectedScanner) > 0) {
                    this.selectedScanner = previouslySelectedScanner;
                }
            });
        }

        beforeDestroy() {
            clearInterval(this.interval);
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }

        get persistentType(): any {
            return localStorage.getItem('ingestion_selectedType');
        }

        get persistentBucket(): any {
            const objStr = localStorage.getItem('ingestion_selectedBucket');
            if (objStr)
                return JSON.parse(objStr);
            else return null;
        }

        get persistentScanner(): any{
            return localStorage.getItem('ingestion_selected_scanner');
        }

        @Watch('selectedType')
        selectedTypeChanged(val1: any, val2: any) {
            localStorage.setItem('ingestion_selectedType', val1);
        }

        @Watch('selectedBucket')
        selectedBucketChanged(val1: any, val2: any) {
            localStorage.setItem('ingestion_selectedBucket', JSON.stringify(val1));
        }

        @Watch('selectedScanner')
        selectedScannerChanged(val1: any, val2: any){
            localStorage.setItem('ingestion_selected_scanner', val1);
        }

        private interval!: NodeJS.Timeout;
        // dropzone
        // properties
        get dropzoneOptions(): any {
            return {
                url: `${Axios.defaults.baseURL}/image`,
                thumbnailWidth: 150,
                withCredentials: true,
                paramName: 'images',
            };
        }
        // events
        dropzoneSending(file: any, xhr: XMLHttpRequest, formData: FormData) {
            xhr.setRequestHeader("Authorization", Axios.defaults.headers["Authorization"]);
        }

        dropzoneUploadSuccess(file: any, response: any) {
            const dropzone: any = this.$refs.myVueDropzone;
            dropzone.removeFile(file);

            this.fetchQueue();
        }
        dateTimeFormatter(d: any) {
            return moment.utc(d).local().format("L LT");
        }
        // table
        // properties
        private escriptFields: any[] = [
            {key: "selected", label: "", class: "selected-col", },
            {key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },
            {key: "escriptResponse.message.patient", label: "Patient", formatter: this.patientFormatter},
            {key: "escriptResponse.message.prescriber", label: "Prescriber", formatter: this.prescriberFormatter},
            {key: "escriptResponse.message.drugs.drugDescription", label: "Drug", },
            {key: "escriptResponse.message.prescription.memo", label: "Memo", },
            //{key: "source", class: "source-col"},
            {key: "otherType", label: "Type", sortable: true, formatter: this.typeFormatter, sortByFormatted: true, /*class: "type-col",*/ },
            {key: "buttons", label: "", class: "button-col", },
        ];
        private otherFields = [
            {key: "selected", label: "", class: "selected-col", },
            {key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },

            {key: "imgSrc", label: "Image", class: "image-col", },
            {key: "otherType", label: "Type", sortable: true, formatter: this.typeFormatter, sortByFormatted: true, class: "type-col", },
            {key: "source", class: "source-col"},
            {key: "buttons", label: "", class: "button-col", },
        ];
        private programTransferFields = [
            {key: "selected", label: "", class: "selected-col", },
            {key: "ingested", label: "Received", formatter: this.dateTimeFormatter, sortable: true, class: "date-col", },
            {key: "escriptResponse.message.patient", label: "Patient", formatter: this.patientFormatter},
            {key: "escriptResponse.message.prescriber", label: "Prescriber", formatter: this.prescriberFormatter},
            {key: "escriptResponse.message.prescription", label: "Drug", formatter: this.transferDrugFormatter},
            {key: "escriptResponse.message.sendingPharmacy.name", label: "Source", class: "source-col", sortable: true, },
            {key: "buttons", label: "", class: "button-col", },
        ];

        private sortBy = "ingested";
        private sortDesc = false;
        private tableItems: QueueItem[] = [];
        private transProps = {name: "flip-list"};
        private urlsCache: {id: string | number, url: string; contentType: string;}[] = [];
        private selectedType = "other";
        private searchValue = "";

        get tableFilter(): {url: string, searchText: string, type: string;} {
            const filter = {url: "", searchText: "", type: this.selectedType, storeId: this.store?.id};
            if (this.selectedBucket) {
                filter.url = `/${this.selectedBucket.bucketType}/${this.selectedBucket.bucket}`;
            }
            filter.searchText = this.searchValue;
            return filter;
        }

        get fields() {
            if (this.selectedType === "escript") return this.escriptFields;
            if (this.selectedType === "programTransfer") return this.programTransferFields;
            if (this.selectedType === "other") return this.otherFields;
            return null;
        }

        tableProvider(ctx: BvTableCtxObject, callback: Function) {
            const allowLocked = this.$route.query["allowLocked"];
            const filter = ctx.filter as unknown as {url: string, searchText: string, type: string, storeId: any;};
            console.log("ctx.filter: ", ctx.filter, filter);
            const uri = ctx.apiUrl + filter.url;
            const config: AxiosRequestConfig = {
                params: {
                    allowLocked: allowLocked,
                    searchText: filter.searchText,
                    queueType: filter.type,
                    storeId: filter.storeId,
                },
            };
            Axios.get<QueueItem[]>(uri, config)
                .then(resp => {
                    const dat = resp.data;
                    Promise.all(dat.map(this.createImageSource))
                        .finally(() => {
                            this.itemsSelected = [];
                            callback(resp.data);
                        });
                })
                .catch(err => {
                    this.itemsSelected = [];
                    console.error("Error while loading items for ingestion.", {err, response: err.response});
                    callback([]);
                });

            return null;
        }
        // formatters
        patientFormatter(pt: Patient) {
            return Object.assign(new Patient(), pt).displayNamesForPerson();
        }
        prescriberFormatter(dr: Prescriber) {
            return Object.assign(new Prescriber(), dr).displayNamesForPerson();
        }
        drugFormatter(drug: Drug) {
            return Object.assign(new Drug(), drug).toString();
        }
        transferDrugFormatter(rxVM: any) {
            return `${rxVM.prescribedNdc} - ${rxVM.medicationName}`;
        }
        typeFormatter(value: null, key: string, item: QueueItem) {
            if (item?.source === "escript") return item?.escriptResponse?.eScript?.messageType;
            const type = item?.escriptResponse?.eScript?.messageType;
            if (type) return type;
            if (item?.source === "programTransfer") return item.escriptResponse.message.kind;
            if (!isNaN(Number(item?.source))) return "Fax";
            if (item?.source.startsWith("+")) return "Fax";

            return "Unknown";
        }

        imageUrlForQueueItem(item: QueueItem): string {
            if (item.imageID != null) {
                return `/image/${item.imageID}`;
            }
            return `/escript/${item.escriptID}/image`;
        }

        async fetchPrograms() {
            const resp = await Axios.get<Program[]>('/Program?active=true');
            this.programs = resp.data;
            this.loadBucketOptions();
        }

        fetchQueue() {
            console.log("queue force refetch");
            this.itemsSelected = [];
            const table = this.$refs.escriptTable as BTable;
            if (table?.refresh) table.refresh();
        }

        routeItem(item: QueueItem) {
            item.bucketType = this.destinationBucket.bucketType;
            item.bucket = this.destinationBucket.bucket;
            return Axios.post("IngestionQueue/Route", item);
        }

        routeItems() {
            Promise.all(this.itemsSelected.map(this.routeItem))
                .finally(this.fetchQueue);
        }
        scan() {
            if(this.selectedScanner == ""){
                this.$notification(NotificationOptions.notificationOptionsPreset("Please select a scanner.", NotificationOptions.NotificationTypes.danger));
                return;
            }
            this.scanner.Scan(this.selectedScanner);
        }

        get typeOptions() {
            return [
                {value: null, text: "Select Queue Type"},
                {value: "escript", text: "Escript"},
                {value: "programTransfer", text: "ProgramTransfer"},
                {value: "other", text: "Images"},
            ];
        }

        private bucketOptions: Array<any> = [];
        loadBucketOptions() {
            const programOptions = this.programs.map(p => {
                return {value: {bucketType: 1, bucket: p.id}, text: p.name};
            });

            const staticOptions = [
                {value: {bucketType: 0, bucket: 0}, text: 'Prior Auth'},
                {value: {bucketType: 0, bucket: 1}, text: 'Audit'},
                {value: {bucketType: 0, bucket: 2}, text: 'MD Responses'},
                {value: {bucketType: 0, bucket: 3}, text: 'Misc'}
            ];

            this.bucketOptions = [
                {value: null, text: 'Select Bucket'},
                {label: 'Programs', options: programOptions},
                {label: 'Other', options: staticOptions}
            ];

            this.selectedBucket = this.persistentBucket;
        }

        createImageSource(item: QueueItem) {
            if (this.selectedType == "escript") return Promise.resolve();
            if (this.selectedType == "programTransfer") return Promise.resolve();
            return new Promise<any>((resolve, reject) => {
                if (item.imgSrc) return resolve({url: item.imgSrc, item: item});

                const cachedUrl = this.urlsCache.find(o => o.id == item.id)

                if (cachedUrl) {
                    item.imgSrc = cachedUrl.url;
                    item.imageType = cachedUrl.contentType;
                    return resolve({url: cachedUrl.url, item: item});
                }
                const url = this.imageUrlForQueueItem(item);
                Axios.get(url, {responseType: "blob"})
                    .then(r => {
                        const objectUrl = URL.createObjectURL(r.data);
                        item.imgSrc = objectUrl;
                        item.imageType = r.headers['content-type'];
                        this.urlsCache.push({id: item.id, url: objectUrl, contentType: item.imageType});
                        return resolve({url: objectUrl, item: item});
                    })
                    .catch(err => {
                        return reject(err);
                    });
            });
        }

        notScript(queueItem: any) {
            Axios.post(`/IngestionQueue/`, queueItem)
                .then(response => {
                    this.urlsCache = this.urlsCache.filter(o => o.id != response.data.id);
                })
                .catch(error => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(error.response.data, NotificationOptions.NotificationTypes.danger));
                    console.warn('IngestionQueue marked complete error: ', error);
                })
                .finally(this.fetchQueue);
        }

        private selectedQueueItem:QueueItem|null = null;
        setPatientDocument(queueItem: any){
            this.selectedQueueItem = queueItem;
            this.$bvModal.show('patient_documents_modal');
        }

        processQueueItem(queueItem: QueueItem) {
            if (this.itemsSelected.length > 0) {
                console.log(this.$store);
                this.$store.queueItems = this.itemsSelected;
                const idx = this.$store.queueItems.findIndex(qi => qi.id == queueItem.id);
                this.$store.queueItems.splice(idx, 1);
                console.log(this.$store);

                const lockTime = this.$store.queueItems.length * 1000 * 60 * 2; // 1000 ms/sec * 60 sec/min * 2 min

                this.$store.queueItems.forEach(qi => {
                    this.addLockWithURL(`/IngestionQueue/${qi.id}/lock`, lockTime);
                });
            }
            this.addLockWithURL(`/IngestionQueue/${queueItem.id}/lock`, 60000)
                .then(result => {
                    this.$router.push({
                        name: 'Prescription',
                        query: {
                            imageID: `${queueItem.imageID}`,
                            escriptID: `${queueItem.escriptID}`,
                            queueItemID: `${queueItem.id}`,
                            programTransfer: `${queueItem.programTransferId}`
                        }
                    });
                })
                .catch(error => {
                    console.warn(`Added lock -- error == ${error}`);
                    if (error.response && error.response.status == 418) {
                        console.log(`QueueItem is locked`);
                        console.log(error.response.data);
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(`The QueueItem is locked by ${lockedBy} until ${expires}.`);
                    }
                });
        }
    }
