

import { Component, Vue } from "vue-property-decorator";
import Axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { BvTableCtxObject } from "bootstrap-vue";
import { Prescription, RefillStatusCode } from '@/models/Prescription';
import moment from "moment";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { DatePicker } from "element-ui";

@Component({
    name: "DoctorDashboard",
    components: {
        [DatePicker.name]: DatePicker,
    },
})
export default class DoctorDashboard extends Vue {
    private searchTerm = "";
    private searchValue = "";
    private toDate: any = moment().toDate();
    private fromDate: any = moment().subtract(7, "days").toDate();
    private cancelTokenSource?: CancelTokenSource;
    private fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "prescriber", formatter: this.prescriberFormatter, sortable: true, },
        { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col" },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        { key: "writtenDate", formatter: (d: any) => moment(d).format("L"), sortable: true },
        { key: "fillDate", formatter: (d: any) => moment(d).format("L"), sortable: true, },
        { key: "patientPay", formatter: (d: number) => `$${d?.toFixed(2) ?? '0.00'}`, sortable: true },
        { key: "programName", label: "Program", sortable: true },
        { key: "state" },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" },
    ];

    get tableFilter() {
        this.setURIParams();
        return {
            searchTerm: this.searchValue,
            fromDate: this.fromDate,
            toDate: this.toDate,
        };
    }

    mounted() {
        this.getURIParams();
    }

    getURIParams() {
        if (this.routeDoctorSearch) {
            this.searchTerm = this.routeDoctorSearch;
            this.updateSearchValue();
        }
        if (this.routeFromDate) this.fromDate = this.routeFromDate;
        if (this.routeToDate) this.toDate = this.routeToDate;
    }

    setURIParams() {
        if (!this.searchValue) return;

        let query = this.$route.query;
        let strToDate = moment(this.toDate).format('YYYY-MM-DD');
        let strFromDate = moment(this.fromDate).format('YYYY-MM-DD');
        if (query.doctorSearch != this.searchValue || query.fromDate != strFromDate || query.toDate != strToDate)
            this.$router.replace({
                name: 'DoctorDashboard', query: {
                    doctorSearch: this.searchValue, fromDate: strFromDate, toDate: strToDate,
                }
            })
    }

    get routeDoctorSearch(): string {
        return (this.$route?.query?.doctorSearch as string) || '';
    }

    get routeFromDate(): Date | null {
        let strDate = this.$route?.query?.fromDate as string;
        if (strDate)
            return moment(strDate, 'YYYY-MM-DD').toDate();
        else return null;
    }

    get routeToDate(): Date | null {
        let strDate = this.$route?.query?.toDate as string;
        if (strDate)
            return moment(strDate, 'YYYY-MM-DD').toDate();
        else return null;
    }

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = Object.assign(new Patient(), pt);
        return pt.displayNamesForPerson();
    }

    updateSearchValue() {
        this.searchValue = this.searchTerm
    }

    ctxProvider(ctx: BvTableCtxObject, cb: Function) {
        if (ctx.apiUrl == null) return null;

        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource?.cancel("Operation canceled due to new request.");
        }
        this.cancelTokenSource = Axios.CancelToken.source();

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter,
            cancelToken: this.cancelTokenSource.token
        };

        Axios.get<Prescription[]>(uri, config)
            .then(resp => cb(resp.data.map(rx => new Prescription(rx))))
            .catch(() => {
                cb([])
            })
            .finally(() => {
                this.cancelTokenSource = undefined;
            });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-dark';
        else if (!rx.isRefillableStatus)
            return 'table-danger';
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }
}

