
    import Card from '@/components/Cards/Card.vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DispenseError } from '@/models/DispenseError';
    import DispenseErrorListComponent from '@/components/DispensingError/DispenseErrorListComponent.vue'
    
    @Component({
        name: "ReportedErrorsPage",
        components:{
            DispenseErrorListComponent
        }
    })
    export default class ReportedErrorsPage extends Vue {
        private statusFilters = [
            DispenseError.DispenseErrorStatusEnum.Created
        ];

        get onlyAssignedErrors():boolean{
            const onlyAssigned = Boolean(this.$route.params.onlyAssigned);
            return onlyAssigned;
        }
        
    }
