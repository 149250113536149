

import {Component, Vue, VModel, Watch} from "vue-property-decorator";
import {Prescription} from "@/models/Prescription";
import {Claim} from "@/models/Claim";
import axios, {AxiosRequestConfig} from "axios";
import {BvTableFieldArray} from "bootstrap-vue";
import moment from "moment";
import * as numeral from "numeral";

@Component({
    name: "ClaimComponent"
})
export default class ClaimComponent extends Vue {
    @VModel() private prescription!: Prescription;
    private claims: Claim[] = [];
    private fields: BvTableFieldArray = [
        {key:"info", label:""},
        {key:"planName", label:"Plan",},
        {key:"transactionStatus", label:"Status",},
        {key:"usualAndCustomary", label:"Submitted",},
        {key:"adjudicated", label:"Adjudicated",},
        {key:"planPaid", label:"Plan Paid"},
        {key:"voucherPaid", label:"Voucher Paid",},
        {key:"patientPay", label:"Copay",},
        {key:"created", formatter: (d)=>d?moment(d).format("L"):""},
        {key:"reversed", formatter: (d)=>d?moment(d).format("L"):""},
    ];

    @Watch("value")
    prescriptionUpdated(rx: Prescription, oldRx: Prescription) {
        this.fetchClaims();
    }

    mounted() {
        this.fetchClaims();
    }

    fetchClaims() {
        const rx = this.prescription;
        const config: AxiosRequestConfig = {
            params: {
                getReversed: true
            }
        };
        axios.get(`Insurance/GetClaims/${rx.storeID}/${rx.rxNumber}/${rx.rfNumber}`, config)
            .then(resp => {
                console.log("resp.data", resp.data);
                this.claims = resp.data;
            })
            .catch(err => {
                console.warn("get claims err: ", err);
            });
    }
}
