
import Component from "vue-class-component";
import SearchComponent from "@/components/SearchComponent.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import Vue from "vue";
import { Store } from "@/models/Store";
import { Team, Voicemail } from "@/models/Voicemail";
import { Watch } from "vue-property-decorator";
import moment from "moment";
import { Patient } from "@/models/Patient";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";

@Component({
  components: {
    SearchComponent,
    EnumSelector,
  },
})
export default class VoicemailsPage extends Vue {
  private storeFilter: Store = new Store();
  private patient: Patient = new Patient();
  private id: Number = 0;
  private itemsSelected: Array<Voicemail> = [];
  private selectedTeam: Team = Team.untriaged;
  private triageLocation: Number = 0;
  private teams = Team;
  private incompleteVoicemails: Voicemail[] = [];
  private pharmacist: string='';

  get routeVoicemailId(): number {
      let res = 0;
      res = parseInt(this.$route.params.id);
      if (isNaN(res))
          return 0;
      return res;
  }
  mounted() {
    this.getOnCallPharmacist()

    if (this.routeVoicemailId) {
      this.getSingleVoicemail();
    }else {
      this.getIncompleteVoicemails();
    }
  }

  relativeTime(item: Voicemail): string {
    return moment(item.received).fromNow();
  }
  toggleInfo(item: Voicemail) {
    console.log("toggle info", item);
    let idToExpand = `accordion-${item.id}`;

    (this.$refs[idToExpand] as any)[0].show = false;

    this.$root.$emit("bv::toggle::collapse", idToExpand);
  }
 
 getSingleVoicemail() {
         Axios.get<Voicemail>(`/Voicemail/${this.routeVoicemailId}`)
            .then(response => {
              let voicemail = Object.assign(new Voicemail(), response.data);
              this.incompleteVoicemails = [voicemail];
                console.log(this.incompleteVoicemails)
                this.$nextTick(()=>{

                  this.toggleInfo(voicemail);
                });
            })
            .catch(error => {
              console.error("Problem loading voicemail information by route Id", {
                error,
                    response: error?.response
                });
            });
 }

  getIncompleteVoicemails() {
    Axios.get<Voicemail[]>(`/Voicemail/Incomplete/${this.selectedTeam}`)
    .then((response) => {
      this.incompleteVoicemails = response.data.map(value => new Voicemail(value));
      console.log(this.incompleteVoicemails);
    })
    .catch((error: AxiosError) => {
      let errorMessage = "Error Fetching Voicemails";
      if (error && error?.response?.data) {
        errorMessage += ` - ${error.response.data}`;
        }
        let notifcationOptions = NotificationOptions.notificationOptionsPreset(errorMessage, NotificationOptions.NotificationTypes.danger);
        this.$notification(notifcationOptions);
      });
  }
  triageVoicemail(item: Voicemail) {
    Axios.post(`/Voicemail/Triage/${item.id}/${this.triageLocation}`)
    .then((response) => {
        return this.getIncompleteVoicemails();
      })
      .catch((error) => {
        this.$notification(NotificationOptions.errorSaveNotificationPreset("Voicemail Triage", error));
      });
  }
  completeVoicemail(item: Voicemail) {
    let patientId = Number(this.patient?.id);
    if(patientId == NaN) return; 
    item.patientId = patientId;
    Axios.post(`Voicemail/Complete`, item)
      .then((response) => {
        return this.getIncompleteVoicemails();
        
      })
      .catch((error) => {
        this.$notification(NotificationOptions.errorSaveNotificationPreset("Voicemail Complete", error));
      });
  }
  downloadVoicemail(item: Voicemail) {
    let config: AxiosRequestConfig = {
      responseType: 'blob'
    }
    Axios.get(`/Voicemail/DownloadAudio/${item.id}`, config)
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], {type:"audio/wav"}));
      let sourceItem: any = document.getElementById(`source-${item.id}`);
      sourceItem.src = url;
      let audioItem: any = document.getElementById(`audio-${item.id}`);
      audioItem.hidden = false;
      audioItem.pause();
      audioItem.load();
      audioItem.play();
      console.log(response)
    })
    .catch((error: AxiosError) => {
      let errorMessage = "Unable To Download Audio for Voicemail";
      if (error && error?.response?.data) {
        errorMessage += ` - ${error.response.data}`;
        }
        let notifcationOptions = NotificationOptions.notificationOptionsPreset(errorMessage, NotificationOptions.NotificationTypes.danger);
        this.$notification(notifcationOptions);
      });
  }
  getOnCallPharmacist() {
     Axios.get(`/Voicemail/OnCallPharmacist`).then(response => {
      this.pharmacist = response.data
      console.log(this.pharmacist)
  
  })
  }
  @Watch("selectedTeam")
  selectedTeamUpdated(newVal: Team, oldVal: Team) {
    console.log("fetch the list for the team we've now selected.");
    return this.getIncompleteVoicemails();
  }
}
