
    import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
    import { Order, OrderStatus } from '@/models/Order';
    import { BvTableFieldArray, BTable } from "bootstrap-vue";
    import moment from "moment";
    import Axios from 'axios';
    import { DispenseError } from '@/models/DispenseError';

    @Component({
        name: "OrdersListComponent",
        components: {},
    })
    export default class OrdersListComponent extends Vue {
        @Prop({ default: {} as Array<Order> }) private orders!: Order[];
        @Prop({ default: false, type: Boolean }) protected hideActionsColumn!: boolean;
        @Prop({ default: false, type: Boolean }) private expandable!: boolean;
        @Prop({ default: false, type: Boolean }) protected mergable!: boolean;
        @Prop() private mode!: string;
        @Prop({ default: false }) protected audit!: Boolean;
        @Prop({default: false}) protected busy!: Boolean;

        @Ref("order-list-table") protected readonly table!: BTable;

        get items(): any[] {
            return this.orders.map((o: any) => {
                if (o.status == OrderStatus.Canceled) o._rowVariant = "danger";
                else if (o.status == OrderStatus.Shipped) o._rowVariant = "info";
                else if (o.status == OrderStatus.ReadyForPickup) o._rowVariant = "warning";

                return o;
            });
        }

        get fields(): BvTableFieldArray {
            const fields = [];
            fields.push({ key: "id", label: "Order Id", });
            fields.push({key:"partnerId", label:"Central Fill"})
            if (this.mode === "verify") {
                fields.push({ key: "patientName", label: "Patient", class: "patient-col", sortable: true });
            }
            fields.push({
                key: "created",
                label: "Date",
                formatter: (d: any) => moment.utc(d).format("L"),
                sortable: true,
            });
            fields.push({ key: "createdBy", label: "Created By", formatter: (c: any) => c.split("@")[0] });
            fields.push({ key: "rxRfs", label: "Rx-Refill", });
            fields.push({ key: "storeName", label: "Store", });
            fields.push({ key: "status", formatter: this.orderStatusName });
            fields.push({ key: "action", label: "", class: "action-col", });
            return fields;
        }

        orderStatusName(enumNbr: number): string {
            return OrderStatus[enumNbr];
        }
        formattedDate(value: Date|null): string {
          if(value != null){
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY");
          }
          return "";
        }
        rowClicked(item: any, _index: number, _event: Event) {
            if (this.mode == 'verify') {
                this.$router.push({ name: 'OrderVerification', params: { orderId: item.id } })
            }
            if (!this.expandable) return;

            let prescriptions: any[] = item.prescriptions;
            if (prescriptions.length > 0) {
                let requests: Promise<void>[] = [];
                prescriptions.forEach(prescription => {
                    if (!prescription.rxId) return;
                    requests.push(Axios.get<DispenseError[]>(`DispenseError/by-rxid/${prescription.rxId}`)
                        .then(response => {
                            let dispenseErrors: DispenseError[] = response.data.map((err: DispenseError) => Object.assign(new DispenseError(), err));
                            let resp = "";
                            if (dispenseErrors) {
                                dispenseErrors.forEach(err => {
                                    resp += "(" + moment.utc(err.updated).format("MM/DD/yy hh:mm:ss a") + ") " + err.reason + ": " + err.errorTypeDesc + "\n";
                                });
                            }
                            prescription.error = resp;
                        })
                        .catch(error => {
                            console.error("Error while getting dispense error", {
                                error,
                                response: error?.response
                            });
                        }));

                });
                Promise.all(requests).finally(() => {
                    this.$set(item, "_showDetails", !item._showDetails);
                });
            } else {
                this.$set(item, "_showDetails", !item._showDetails);
            }
        }
    }

