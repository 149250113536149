
    import { Vue, Component, Watch } from "vue-property-decorator";
    import Card from "@/components/Cards/Card.vue";
    import PriorAuthorizationListComponent from "@/components/PriorAuthorization/PriorAuthorizationListComponent.vue";
    import PriorAuthorizationDetails from "@/components/PriorAuthorization/PriorAuthorizationDetails.vue";
    import EnumSelector from "@/components/EnumSelector.vue";
    import Axios from 'axios';
    import moment from 'moment';
    import { PriorAuthorizationStatusCode, PriorAuthorization, CoverMyMedsCallback } from '@/models/PriorAuthorization';
    import { Prescription } from '@/models/Prescription';
    import { Prescriber } from '@/models/Prescriber';
    import { Patient } from '../../models/Patient';
    import { stat } from 'fs/promises';

    @Component({
        components: {
            Card,
            PriorAuthorizationListComponent,
            PriorAuthorizationDetails, EnumSelector
        }
    })
    export default class PriorAuthorizationListPage extends Vue {

        created() {
            this.fetchPriorAuthorizations();
        }

        get totalPas(): number {
            return this.groupedPriorAuthorizations.reduce((a, b) => a + b.length, 0);
        }
        private paCodes = PriorAuthorizationStatusCode;

        private templates: any[] = [
            { value: "1", text: "NO PA'S PER MDO" },
            { value: "2", text: "NO RESPONSE FROM MDO" },
            { value: "3", text: "LVM @ MDO TO FOLLOW UP" },
            { value: "4", text: "FAXED MDO TO FOLLOW UP" },
            { value: "5", text: "MDO CLOSED UNABLE LVM" },
            { value: "6", text: "RECEIVED ADDITIONAL INFORMATION, SENT TO PLAN" },
            { value: "7", text: "SENT TO PLAN AS IS" },
            { value: "8", text: "NO RESPONSE FROM PLAN, TEST CLAIM PAYS, MARKING AS APPROVED" },
            { value: "9", text: "NO REPONSE FROM PLAN, TEST CLAIM REJECTS, MARKING AS DENIED" },
            { value: "10", text: "PATIENT DECLINED MEDICATION" },
        { value: "11", text: "NOT A PA REJECTION" },
    ];

    private isLoading = false;
    private selectedStatus: string | null = null;
    private manufacturers: string[] = ["All"];
    private selectedManufacturer: string = "All";
    private selectedPa: PriorAuthorization = {} as PriorAuthorization;
    private groupedPriorAuthorizations: Array<Array<PriorAuthorization>> = [];
    private fields: any[] = [
        { key: "patient", label: "Patient Name", formatter: (p: Patient) => `${p.lastName}, ${p.firstName}\n${moment(p.dateOfBirth).format("L")}`, tdClass: "text-center", thClass: "text-center" },
        { key: "prescription", label: "Rx Id", formatter: (p: Prescription) => `${p.storeID}-${p.rxNumber}-${p.rfNumber}` },
        { key: "prescription.productNameShort", label: "Drug Name", },
        { key: "created", label: "Initiated", formatter: (d: any) => moment.utc(d).local().format("l LT"), sortable: true },
        { key: "updated", label: "Next Touch", formatter: this.formatNextTouch, sortable: true },
        { key: "latestCallback", label: "CMM Event", formatter: this.cmmEventFormatter, },
        { key: "statusName", label: "Status" },
        { key: "completeReason", label: "Reason", },
    ];

    formatNextTouch(d: Date, key: string, item: PriorAuthorization): string {
        const daysToAdd = item.status === PriorAuthorizationStatusCode.SubmittedToInsurance ? 7 : 1;
        return moment.utc(d).local().add(daysToAdd, "day").format("l LT");
    }

    cmmEventFormatter(cb: CoverMyMedsCallback) {
        return cb?.event_key ? cb.event_key : cb?.error ?? "";
    }

    filterFunc(item: PriorAuthorization, filt: any): boolean {
        let manufact = (filt.selectedManufacturer === "All") || item.drugManufacturer == filt.selectedManufacturer;
        let status = (!filt.selectedStatus && filt.selectedStatus != 0) || item.status == filt.selectedStatus;

        return manufact && status;
    }

    fetchPriorAuthorizations() {
        let url = `/PriorAuthorization/`

        this.isLoading = true;
        this.$http.get<Array<Array<PriorAuthorization>>>(url)
            .then(response => {
                if (response.data?.length) {
                    this.groupedPriorAuthorizations = response.data.map((paList: Array<PriorAuthorization>) => {
                            return paList.map(pa => new PriorAuthorization(pa));
                        });
                        this.updateFiltersAndHoverText();
                    }
                })
                .catch(error => {
                    console.error("Error loading PAs.",{error, response: error?.response});
                })
                .finally(() => this.isLoading = false);
        }

        matchingRecords(paList:Array<PriorAuthorization>):number{
        if (this.selectedManufacturer === "All" && (!this.selectedStatus && this.selectedStatus != "0")) {
            return paList.length;
        }
            const rows = paList.filter(pa => this.filterFunc(pa, {selectedManufacturer: this.selectedManufacturer, selectedStatus: this.selectedStatus}));
            return rows?.length || 0;
    }
    private manufacturersByStatus: Array<any> = [];
    updateFiltersAndHoverText() {
        // Total Status hover text
        const flatArray: Array<PriorAuthorization> = this.groupedPriorAuthorizations.flat();

        // Manufacturer dropdown filter
        const val = this.groupedPriorAuthorizations;
        this.manufacturers = ["All"];
        let foundManu = flatArray.map(p => p.drugManufacturer).filter(this.onlyUnique).sort();
        this.manufacturers = this.manufacturers.concat(foundManu);

        // Hover text for manufacturers status.
        let groupedList = flatArray.reduce((group: any, pa) => {
            const { status } = pa;
            group[status] = group[status] ?? [];
            group[status].push(pa);
            return group;
        }, {});

        let group = Object.getOwnPropertyNames(groupedList).reduce((grp: any, status: any) => {
            grp[status] = groupedList[status].reduce((group: any, pa: any) => {
                const { drugManufacturer } = pa;
                if (!group.hasOwnProperty(drugManufacturer)) {
                    group[drugManufacturer] = 0;
                }
                group[drugManufacturer]++;
                return group;
            }, {})
            return grp;
        }, {});

        this.manufacturersByStatus = Object.getOwnPropertyNames(group).map((status: any) => {
            let manus = Object.getOwnPropertyNames(group[status]).sort();
            return { status: Number(status), text: manus.map((name: any) => `${name}: ${group[status][name]}`).join(' <br/> ') }
        });
    }


        private statusToIgnore = [PriorAuthorizationStatusCode.Abandoned, PriorAuthorizationStatusCode.Approved, PriorAuthorizationStatusCode.Denied, PriorAuthorizationStatusCode.Cancelled];
        countByStatus(status:PriorAuthorizationStatusCode):number {
            let res = 0;
            const flatArray:Array<PriorAuthorization> = this.groupedPriorAuthorizations.flat();
            res = flatArray.filter(pa => pa.status == status)?.length || 0;
            return res;
        }

        get paStatusList():Array<PriorAuthorizationStatusCode>{
            let res = new Array<PriorAuthorizationStatusCode>();
            for(const st in PriorAuthorizationStatusCode)
                if(Number(st) >= 0 && !this.statusToIgnore.some(st2 => st2 == Number(st))) res.push(Number(st) as PriorAuthorizationStatusCode);

            return res;
        }

        totalCountHoverMessage():string{
            return `Submitted: ${this.countByStatus(PriorAuthorizationStatusCode.SubmittedToInsurance)}, Initiated: ${this.countByStatus(PriorAuthorizationStatusCode.Initiated)}, Sent To Prescriber: ${this.countByStatus(PriorAuthorizationStatusCode.SentToPrescriber)}, Anticipated Approval: ${this.countByStatus(PriorAuthorizationStatusCode.AnticipatedApproval)}`;
        }

        getManufacturersByStatusHoverText(status:PriorAuthorizationStatusCode):string{
            let res = this.manufacturersByStatus.find((m:any) => m.status == status)?.text;
            return res || '';
        }

        onlyUnique(value: any, index: any, self: any) {
            return self.indexOf(value) === index;
        }

        selectPa(item: PriorAuthorization) {
            //console.log("selectPa: ", item);
            this.selectedPa = item;
            this.$bvModal.show("pa-details-modal");
        }

        tableName(item: PriorAuthorization[]): string {
            const prescriber = item[0].prescriber;
            return `${prescriber.lastName}, ${prescriber.firstName} - ${prescriber.phoneNumber}${prescriber.allowsPa ? '':' DOES NOT ALLOW PA'}`;
        }

        tableClass(item: PriorAuthorization[]) {
            const prescriber = item[0].prescriber;
            return prescriber.allowsPa ? '' : 'text-danger';
        }

        private groupUpdateList: PriorAuthorization[] = [];
        private newNoteBody: string = "";

        statusUpdate(status: PriorAuthorizationStatusCode) {
            this.groupUpdateList.forEach(pa => {
                pa.status = status;
                this.savePriorAuthorization(pa);
            });
            this.$bvModal.hide("pa-update-modal");
            this.groupUpdateList = [];
        }

        saveNoteClick() {
            const note = this.newNoteBody;
            this.groupUpdateList.forEach(pa => {
                this.savePriorAuthorizationNote(pa, note);
            });
            this.newNoteBody = "";
            this.$bvModal.hide("pa-update-modal");
            this.groupUpdateList = [];
        }

        savePriorAuthorizationNote(pa: PriorAuthorization, note: string) {
            let data = { Body: note };
            Axios.post(`PriorAuthorization/${pa.id}/Note`, data)
                .then(response => {
                    console.log("postNote: ", response.data);
                    //this.notes.push(response.data)
                    //this.newNoteBody = "";
                })
                .catch(err => {
                    console.error('Error while creating PA Note', {err, response: err?.response });
                });
        }
        savePriorAuthorization(pa: PriorAuthorization) {
            Axios.post(`PriorAuthorization/${pa.id}/Save`, pa)
                .then(response => {
                    console.log(response.data);
                })
                .catch(err => {
                    console.error('Error while saving PA', {err, response: err?.response });
                })
        }

        updateAll(paList: PriorAuthorization[]) {
            this.groupUpdateList = paList;
            this.$bvModal.show("pa-update-modal");
        }
    }
