import { render, staticRenderFns } from "./CasesListComponent.vue?vue&type=template&id=53e3e798&scoped=true"
import script from "./CasesListComponent.vue?vue&type=script&lang=ts"
export * from "./CasesListComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e3e798",
  null
  
)

export default component.exports