
 import {Audit, Auditor} from '@/models/Audit';
 import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
 import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
 import axios from 'axios';
 import { NotificationOptions } from "@/util/NotificationOptionsPresets";
 @Component({
     name: "AuditorComponent",
     components: {
         NewClearSaveButtons
     }
 })
 export default class PBMComponent extends Vue{
    private AuditorRecord:Auditor | null = null;
    @Prop({type: Boolean, default: false}) private createNewAuditor!: boolean;
  
    created(){
        if(this.createNewAuditor){
            this.createAuditor();
        }
    }

    createAuditor(){
        this.AuditorRecord= new Auditor();
    }
    backToPatientDashboard() {
         if (this.createNewAuditor) {
                this.$emit("close");
                return;
            }
    }

    showConfirmationModal() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            })
                .then(value => {
                   this.AuditorRecord = new Auditor();
                })
                .catch(err => {
                    console.log("Error caught in showConfirmationModal()");
                    console.error(err);
                });
        }
    
    submitAuditor(){
               if(this.AuditorRecord?.firstName != undefined || this.AuditorRecord?.lastName != undefined ||this.AuditorRecord?.firstName != '' || this.AuditorRecord?.lastName != ''|| this.AuditorRecord?.firstName != null || this.AuditorRecord?.lastName != null){
            axios.post<Auditor>(`/Auditor/save-auditor?firstName=${this.AuditorRecord?.firstName}&lastName=${this.AuditorRecord?.lastName}`)
      .then(res => {
                  this.$notification(NotificationOptions.notificationOptionsPreset("Auditor Created Successfully", NotificationOptions.NotificationTypes.success));
                     this.AuditorRecord = res.data;
                      this.$emit('created', this.AuditorRecord)
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Create Auditor", NotificationOptions.NotificationTypes.danger));
                    console.error("Error while saving Auditor.", {err, response: err.response});
                })
        }
    }

      get disableFields():boolean{
        return false;
    }
    
     get isFormValid() {
            return true;
        }

    private get _buttonTitles() {
            let res = {};
                res = {
                    save: "Save Changes",
                    clear: "",
                    new: "",
                    cancel: "Back to Dashboard",
                };
            return res;
        }
 }
