
    import Vue from 'vue'
    import {Component, Emit, Prop, Ref, Watch} from 'vue-property-decorator';

    import { Prescription } from '@/models/Prescription';
    import { Store } from '@/models/Store';

    import SearchComponent from '@/components/SearchComponent.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import moment from 'moment';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { Order } from '@/models/Order';

    @Component({
        name: "AutorefillQueuePage",
        components: {
            SearchComponent,
            PrescriptionsListComponent,
            RxPage: () => import('@/pages/Prescription/PrescriptionDetailsPage.vue'),
        },
    })
    export default class AutorefillQueuePage extends Vue {
        private prescriptions:Array<Prescription> = [];
        private storeFilter: Store = new Store();
        private loading:boolean = false;
        private processing:boolean = false;

        private selectedItem: Prescription = new Prescription();
        private rxForBatch: Prescription[] = [];
        
        private fieldsToRename = [
            {key:'dispensed', label: "Last Dispensed"},
            //{key:'qtyLeft', label: "Quantity Remaining"},
            {key:'patientPay', label: "Last Copay"},
            {key:'productNameShort', label: "Drug"},
            {key:'fillDate', label: "Last Fill Date"},
        ]

        private fieldsKeysToRemove = ['qtyLeft']

        private additionalFields = [
            {
                index: 9,
                field: { key: "nextProcessDate", label: "Next Proc.", sortable: true, sortDirection: "desc", formatter: this.dateFormatter },
            },
            {
                index: 6,
                field: { key: "quantityRemaining", label: "Quantity Remaining", class: "text-center"}
            }
        ]        

        dateFormatter(value: Date, key: string, item: any) {
            return moment.utc(value).format("L");
        }

        created(){
            this.search();
        }
        
        search(){
            this.loading = true;
            this.$http.get(`/autorefill/queue/${this.storeFilter?.id || ''}`)
            .then(res => {
                if(res.data?.length){
                    res.data = res.data.map((rx:any) => {
                        //Formatting PrescriptionInAutorefill object to look like regular a prescription
                        rx.dispensed = rx.lastDispensedAmount;
                        rx.patientPay = rx.lastCopay;
                        rx.productNameShort = rx.drugName;
                        rx.fillDate = rx.lastFillDate;
                        return rx;
                    })
                    this.prescriptions = res.data.map((rx:any) => new Prescription(rx));
                    console.log(this.prescriptions);
                } else {
                    this.prescriptions = new Array<Prescription>();
                }
            })
            .catch(error => {
                console.warn("Error while fetching prescriptions by date",{error, response: error?.response});
            })
            .finally(() => { this.loading = false; });
        }
        
        createOrder(rx?:Prescription){
            if(!rx) return;

            this.processing = true;
            this.$http.post<Order>('/order/auto-refill', rx)
            .then(res => {
                    if(res.data.id){{
                        this.$notification(NotificationOptions.successSaveNotificationPreset('Auto-refilled order #'+res.data.id));
                        this.search();
                    }
                }
            })
            .catch(err => {
                const errorMsg = 'Error while creating order for auto-refill prescription';
                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                console.error(errorMsg, {err, response: err.response})
            })
            .finally(() => this.processing = false)
        }
    }
        
