
    import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
    import { debounce } from "throttle-debounce";
    import { Drug } from "@/models/Drug/Drug";
    import HasLabel from "@/models/HasLabel";
    import Axios from "axios";
    import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
    import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
    import { CompoundDrug } from "@/models/Drug/CompoundDrug";
    //import VueSelect from "vue-select";

    @Component({
        name: "SearchComponent",
    })
    export default class SearchComponent extends Vue {
        /**
         * @searchURL: String that represents the URL in the API to look for the search results
         * by the introduced value in the component.
         */
        @Prop() private searchURL!: string;
        /**
         * @getByIdURL: String that represents the URL in the API to look for a specific result
         * by a given ID in the v-model or in the @id prop.
         */
        @Prop() private getByIdURL!: string;
        /**
         * @disabled: Optional boolean value to enable/disable the dropdown.
         */
        @Prop({ default: false }) protected disabled!: boolean;
        /**
         * @clearable: Optional boolean value to show or hide the clear selection button in the dropdown.
         */
        @Prop({ default: false }) protected clearable!: boolean;
        /**
         * @id: Optional numeric value which should be the unique identifier for a given entity
         * which we want to showup as selected in the dropdown
         */
        @Prop({ default: 0 }) protected id!: number|string;
        /**
         * @optionLabel: Funcion callback that defines the way the elements in the dropdown are going to be displayed.
         */
        @Prop() private optionLabel!: Function;
        /**
         * label: Optional string label text to display at the top of the component.
         */
        @Prop({ default: `Search` }) protected label: string | undefined;
        /**
         * params: Optional custom object with arguments we need to send to the searchURL API endpoint.
         */
        @Prop() private params!: any;
        /**
         * params: Optional custom object with arguments we need to send to the getById URL API endpoint.
         * The getById URL doesn't need to be set to be able to use these params.
         */
        @Prop({
            default: () => {
                return {};
            },
        })
        private paramsInGetById!: any;
        /**
         * @params: Optional Function callback that is called right after the results are being received
         * from the SearchURL API endpoint, it sends the results back to the function to be modified
         * or perform any other operation needed before being shown in the dropdown component.
         */
        @Prop({ default: null }) private resultsModifier!: Function;
        /**
         * @prepopulate : Boollean value, set to true to populate the dropdown by default with
         * the results returned by the API receiving as arguments an empty string
         * and params in request if there is any.
         */
        @Prop({ type: Boolean, default: false }) private prepopulate!: boolean;
        @Prop({ type: Boolean, default: false }) private createNewObject!: boolean;
        @Prop({ type: String, default: "Create" }) private createNewLabel!: string;
        @Prop() private value!: any;
        @Prop({ default: false, type: Boolean }) protected required!: boolean;
        @Prop({ default: false, type: Boolean }) private shouldFocus!: boolean;

        private objects: any[] = [];
        private modelObject: any = {};
        private debouncedSearch: any = debounce(500, (searchValue: string, loading: any) => {
            this.onSearch(searchValue, loading);
        });
        private searching = false;

        get object(): any {
            return this.toModelType(this.value);
        }

        set object(val: any) {
            // Best practice is to use @input event in parent components instead of watchers.
            this.$emit("input", val);
        }

        created() {
            if ((this.value === null || this.value === undefined) && !this.optionLabel)
                console.error("SeachComponent: v-model is null or undefined");

            if (!this.optionLabel && !(this.value instanceof HasLabel))
                console.error(
                    "SeachComponent: Not receiving any optionLabel and the model is not a HasLabel object."
                );

            if (this.value instanceof HasLabel)
                this.modelObject = Object.assign(this.value.constructor(), this.modelObject);

            if (this?.value?.id) this.reloadObject(this.value, {});

            this.enableNewObject();
        }

        mounted() {
            const dropdown: any = this.$refs.dropdown;
            if (this.prepopulate) {
                if (dropdown?.toggleLoading) this.searchValueUpdate("", dropdown.toggleLoading);
            }
            if (this.shouldFocus) {
                this.$el.querySelector(dropdown.searchInputQuerySelector).focus();
            }
        }

        @Watch("searchURL")
        searchUrlChanged() {
            const dropdown: any = this.$refs.dropdown;
            if (dropdown?.toggleLoading) this.searchValueUpdate("", dropdown.toggleLoading);

        }

        printLabel(o: any) {
            if (o.id == 0 && this.createNewObject) return this.createNewLabel;
            o = this.toModelType(o);
            if (this.optionLabel) return this.optionLabel(o);
            else if (o instanceof HasLabel) return this.toModelType(o).getLabel();
            else return o ? o.id : "";
        }

        searchValueUpdate(value: string, loading: any) {
            value = value || "";

            if (value || (value == "" && this.prepopulate)) this.debouncedSearch(value, loading);
        }

        reloadResults() {
            const dropdown: any = this.$refs.dropdown;
            if (dropdown?.toggleLoading) this.searchValueUpdate("", dropdown.toggleLoading);
        }

        onSearch(value: string, loading: any) {
            if (
                (value.length && this.searchURL.length && !this.searching) ||
                (value == "" && this.prepopulate)
            ) {
                loading(true);

                const paramsInRequest = this.params || { searchTerm: value };
                paramsInRequest.searchTerm = value;

                this.searching = true;
                Axios.get(this.searchURL, { params: paramsInRequest })
                    .then((response) => {
                        if (response.status == 204) response.data = [];
                        this.objects = response.data;

                        if (this.resultsModifier) this.objects = this.resultsModifier(response.data);
                        else this.objects = response.data;

                        this.enableNewObject();

                        this.objects = this.objects.map((o) => this.toModelType(o));
                    })
                    .catch((error) => {
                        console.error(error, { response: error?.response });
                    })
                    .finally(() => {
                        loading(false);
                        this.searching = false;
                    });
            }
        }

        enableNewObject() {
            if (this.createNewObject) this.objects.push({ id: 0 });
        }

        @Emit('results')
        @Watch('objects')
        onObjectsChange(value: any[], _oldValue: any[]) {
            return value;
        }

        // Removed to prevent triggering watchers twice.
        // @Watch('object') onObjectChange(value: any, oldValue: any) {
        //     this.reloadObject(value, oldValue);
        // }

        @Watch("id") onIdChange(id: number, oldId: number) {
            this.reloadObject({ id }, { id: oldId });
        }

        reloadObject(value: any, oldValue: any) {
            if (!value || !value?.id) return;
            if (oldValue && (oldValue?.id || 0) == (value?.id || 0)) return;
            this.forceReload(value);
        }

        forceReload(value: any) {
            const url = this.getByIdURL || this.searchURL;

            const dropdown: any = this.$refs.dropdown;
            const toggleLoading = dropdown?.toggleLoading;

            if (toggleLoading) toggleLoading(true);
            Axios.get(`${url}/${value.id}`, { params: this.paramsInGetById })
                .then((response) => {
                    if (value?.id) {
                        this.object = this.toModelType(response.data);
                    }
                })
                .catch((error) => {
                    console.warn(error);
                })
                .finally(() => {
                    if (toggleLoading) toggleLoading(false);
                });
        }

        @Watch("object")
        onChange() {
            if (this.createNewObject && this.object.id == 0) {
                this.$emit("createNewObject");
            }
        }

        toModelType(o: any) {
            if (this.modelObject instanceof DrugProduct) {
                const drugObj = o as DrugProduct;
                switch (drugObj.source) {
                    case DrugSource.GsddFederal:
                        return new Drug(drugObj.drugId, drugObj);
                    case DrugSource.NonGsddProduct:
                        return new NonGSDDDrug(drugObj.drugId, drugObj);
                    case DrugSource.CompoundProduct:
                        return new CompoundDrug(drugObj.drugId, drugObj);
                }
            }

            if (this.modelObject instanceof HasLabel) return this.modelObject.constructor(o);

            return o;
        }
    }

    export function drugLabel(drug: Drug) {
        if (drug && (drug.productNameLong || drug.productNameShort)) {
            return drug.ndc
                ? `NDC:${drug.ndc} - ${drug.productNameLong}`
                : drug.productNameShort || drug.productNameLong;
        } else return "";
    }
