import TrackedObject from "@/models/TrackedObject";
import HasID from "@/models/HasID";
import {EntityType} from "@/models/Note";
import HasLabel from "@/models/HasLabel";

export interface AcceptanceConfiguration extends TrackedObject, HasID {
    id: number;
    name: string;
    linkedEntities: LinkedEntity[];
    imageId?: number;
    mandatory: boolean;
    recurring: boolean;
    requiresSignature: boolean;
    status: AcceptanceConfigurationStatus;
    checked: boolean;
}

export enum AcceptanceConfigurationStatus {
    Draft,
    Active,
    Inactive,
}

export interface LinkedEntity {
    entityId: number;
    entityType: EntityType;
}

export class AcceptanceConfiguration extends HasLabel {
    constructor(obj?: AcceptanceConfiguration) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (!this.id) return "";
        return `${this.id} - ${this.name}`;
    }
}
