
    import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
    import axios from 'axios';
    import { User } from '@/models/User';

    @Component({
        name: "UserComponent",
        components: {
        }
    })
    export default class UserComponent extends Vue {
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
            this.fetchAllUsers();
        }
        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }
        private selectedUser: User = {} as unknown as User;
        private users: User[] = [];
        fetchAllUsers() {
            axios.get(`/User`)
                .then(response => {
                    //console.log("user response: ", response.data);
                    this.users = response.data;
                });
        }

        rowClicked(user: any): any {
            //console.log("Row clicked: ", user);
            this.selectedUser = Object.assign({}, user);
            this.$bvModal.show("user-modal");
        }

        handleOk(bvModalEvent: any) {
            bvModalEvent.preventDefault();

            axios.post(`/User/SaveCallback`, this.selectedUser)
                .then(response => {
                    //console.log("save response:", response);
                    const responseUser = response.data;
                    this.users = this.users.map(u => u.userName == responseUser.userName ? responseUser : u);
                    this.$nextTick(() => {
                        this.selectedUser = {} as unknown as User;
                        this.$bvModal.hide("user-modal");
                    });

                });

        }
    }
