

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Note, EntityType } from '@/models/Note';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import { Table, TableColumn } from "element-ui";
import axios from 'axios';

@Component({
    name: "NotesForm",
    components:{
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    }
})
export default class NotesForm extends Vue {
    @Prop({default: 0}) private entityId!: number;
    @Prop({default: 0}) private entityType!: EntityType;
    @Prop({default: true}) private bodyRequired!: boolean;

    /**
     * @prefixNote: String that will be inserted at the beginning of the note and saved with it.
     */
    @Prop({default: ''}) private prefixNote!: string;
    @Prop({default: "Add Note"}) private saveButtonLabel!:string;
    @Prop({default: "Write a note..."}) private placeholder!: string;
    @Prop() protected textBoxLabel!: string;
    @Prop({default: false}) silent!:boolean;


    private note: Note = {} as Note;

    save(){
        if(this.prefixNote)
            this.note.body = this.prefixNote + " " + (this.note.body || '');

        if(this.note.body){
            this.note.entityType = this.entityType;
            this.note.entityId = this.entityId.toString();

            axios.post(`Note`, this.note)
            .then(response => {
                this.$emit("saved", response.data)
                if(!this.silent) this.$notification(NotificationOptions.successSaveNotificationPreset("Note"));
                this.note = {} as Note;
            })
            .catch(error => {
                console.error("Error while posting new note.", {error, response: error?.response});
            })
        }else
            this.$emit("saved")
    }

    get disableSaveButton():boolean{
        return (this.bodyRequired && !this.note?.body);
    }

    @Watch("note.body") noteChanged(val:string){
        this.$emit('input', val)
    }

}
