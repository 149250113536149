import HasID from "@/models/HasID";
import { Order } from "@/models/Order";
import {DrugSource} from "@/models/Drug/DrugProduct";

export interface OrderAudit extends HasID {
    id: number;
    orderId: number;
    completed: Date;
    completedBy: string;
    order: Order;
}

export class OrderAudit {
    constructor(obj?: OrderAudit) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export interface OrderAuditConfiguration extends HasID {
    id: number;
    programId: number;
    percentageChance: number|null;
    minimumMargin: number|null;
    maximumMargin: number | null;
    drugId:number | null;
    drugSource: DrugSource | null;
    active:boolean;
}

export class OrderAuditConfiguration {
    constructor(obj?:OrderAuditConfiguration) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
    sanitize():void {
        // @ts-ignore
        if (this.minimumMargin == "") this.minimumMargin = null;
        // @ts-ignore
        if (this.maximumMargin == "") this.maximumMargin = null;
        // @ts-ignore
        if (this.percentageChance == "") this.percentageChance = null;
    }
}

export interface OrderAuditRow {
    orderDate: Date;
    patientName: string;
    drugName: string;
    copay: number;
    programName: string;
    edit: any;
}

export class OrderAuditRow {
    constructor(obj?: OrderAuditRow) {
        if (obj) {
            Object.assign(this, obj);
        }
        this.edit = {};
    }
}
