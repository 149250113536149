import HasID from "./HasID";
import HasLabel from "./HasLabel";
import TrackedObject from "./TrackedObject";

export interface OTCItemProgram extends HasID, TrackedObject {
    programName: string;
    itemId: number;
    programId: number;
    price: number;
}
export interface OTCItemVendor extends HasID, TrackedObject {
    vendorName: string;
    itemId: number;
    vendorId: number;
    vendorItemNbr: string;
    cost: number;
}

export interface OTCItem extends HasID, TrackedObject {
    name: string;
    description: string;
    compareTo: string;
    defaultPrice: number;
    upc: string;
    taxCode: string;
    active: boolean;

    itemPrograms: Array<OTCItemProgram>;
    itemVendors: Array<OTCItemVendor>;
}

export class OTCItem extends HasLabel {
    constructor(obj?: OTCItem) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if (this.name)
            return `Item: ${this.name}`
        return ''
    }
}
