import TrackedObject from "./TrackedObject";

export interface Inventory extends TrackedObject {
    id: number | null,
    drugSource: number,
    packageId: number,
    billingUnit: string,
    quantity: number,
    storeId: number,
    storeName: string,
    programId: number | string | undefined,
    programName: string,
    allocated: number
}

export class Inventory implements Inventory{
    constructor(obj?:Inventory){
        if(obj) Object.assign(this, obj);
    }
}