
    import Vue from 'vue'
    import { Component, Watch, Prop } from 'vue-property-decorator';
    
    import { StripeCard } from '@/models/Payment';

    @Component({
        name: "CardsTable",
        components: { 
        },
    })
    export default class CardsTable extends Vue{
        /**
         * @value is the StripeCustomerID
         */
        @Prop() private value!:number
        @Prop() private selectedCardID!:string;
        @Prop() private disabled!:boolean;
        
        private isLoading = false;
        private items: Array<StripeCard> = [];
        private fields: any[] = [
            { key: "actions", label: ''},
            { key: "brand", label: 'Brand'},
            { key: "cardNumber", label: "Card Ends With" },
            { key: "expiration", label: "Expiration" },
        ];
        private sortBy = 'cardNumber';
        private sortDesc = false;
        private selectedCardId_ = '';

        created(){
            if(this.selectedCardID) this.selectedCardId_ = this.selectedCardID;
        }

        @Watch('value') stripeIDChanged(){
            this.loadPatientPaymentMethods();
        }

        loadPatientPaymentMethods() {
            if(!this.value) return;
            
            this.isLoading = true;
            this.$http.get<Array<StripeCard>>(`/stripe/cards/${this.value}`)
            .then(response => {
                if(response?.data?.length){
                    this.items = response.data;
                    this.items.sort((a,b) => {
                        return Number(a.cardNumber) - Number(b.cardNumber);
                    })
                }
                
            })
            .catch(error => {
                console.error("Error while getting Payment Cards from Patient", {error, response: error?.response})
            })
            .finally(() => {
                this.isLoading = false;
            });
        }
        
        private cardID:string = '';
        @Watch('selectedCardId_') cardSelected(cardId:string){
            if(!cardId) return;
            
            this.$emit('cardSelected', cardId);
        }

        colorCodeTable(row: StripeCard, type: any) {
            if (!row || type !== 'row')
                return;
            if (row.id == this.selectedCardId_)
                return 'table-success';
        }
    }
