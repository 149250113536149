import { render, staticRenderFns } from "./ClaimComponent.vue?vue&type=template&id=4d8dc51e&scoped=true"
import script from "./ClaimComponent.vue?vue&type=script&lang=ts"
export * from "./ClaimComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8dc51e",
  null
  
)

export default component.exports