
 import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Drug } from '@/models/Drug/Drug';
    import axios from 'axios';
    import moment from "moment";
    import {DrugDocument} from '@/models/DrugDocument'
    import {NotificationOptions} from '@/util/NotificationOptionsPresets';
    @Component({
        name: "DrugDocumentsComponent",
        components: {}
    })
    export default class DrugDocumentsComponent extends Vue {
        @Prop() private drugId!: number;
        @Prop() private drugSource!: number;
        private selectedDrug: Drug = new Drug();
        private newDocument: File = new File([], "");
        private newDocumentCreated: DrugDocument = new DrugDocument();
        private fileName: string = '';
        private checkAll = false;
        private showFunctionalities = true;
        private isTicked = false;
        private drugDocuments = [] as DrugDocument[];
         private fields = [
            {label:"", key:"isChecked"},
            {label: "File Name", key: "fileName", sortable: true},
            {
                label: "Uploaded On",
                key: "created",
                sortable: true,
                formatter: (d: any) => moment.utc(d).local().format("L LT")
            },
            {label: "Uploaded By", key: "createdBy", sortable: true},
            {key: "view", label: "Actions"},
        ]

        get isValidFileName(): boolean {
            const regex = /^[\w,\s-]+$/;
            const validFileName: boolean = regex.test(this.fileName?.trim());
            return validFileName;
        }

        get fileNameInputClass(): string {
            const val = this.isValidFileName;
            return val ? "has-success" : "has-danger";
        }

        created() {
            console.log('Pat docs form created');
            this.loadDocs();
            if(!this.$user.isManager){
                this.fields = this.fields.filter(a => a.key != 'isChecked' );
                this.showFunctionalities = false;
            }
        }

        submit(){
            const formData = new FormData();
            formData.append('document', this.newDocument);
            formData.append('fileName', this.fileName.trim());
            const errorMsg = "Error while uploading new drug document.";

              axios.post<DrugDocument>(`/Drug/${this.drugId}/${this.drugSource}/add-document/`
                , formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    if (response.data?.id) {
                        this.resetForm(response.data);
                        this.loadDocs();
                    } else console.error(errorMsg, response);
                })
                .catch(err => {
                    console.error(errorMsg, {err, response: err.response});
                })

        }

        resetForm(resData: DrugDocument) {
            this.$notification(NotificationOptions.successSaveNotificationPreset('Drug Document'));
            this.newDocumentCreated = new DrugDocument(resData);
            this.newDocument = new File([], "");
            this.fileName = '';
            this.$emit('on-save', resData);
        }
        @Watch('checkAll')
        onCheckAllChange(Value:boolean, oldValue:boolean){
            if(Value!=oldValue){
                 this.drugDocuments.forEach(r => r.isChecked = Value)
            }
        }


       @Watch('drugId')
        onDrugIdChange(Value:number, oldValue:number){
            if(Value!=oldValue){
                this.loadDocs();
            }
        }

        loadDocs() {
            if(this.drugId <= 0 || this.drugSource == null) return;
            axios.get<Array<DrugDocument>>(`/drug/${this.drugId}/${this.drugSource}/get-documents/`)
                .then(res => {
                        this.drugDocuments = [];
                    if (res.data?.length)
                        {
                        this.drugDocuments = res.data.map(doc => new DrugDocument(doc));
                        this.drugDocuments.forEach(r => r.isChecked = false)
                        }
                })
                .catch(err => {
                    console.error("Error while loading drug documents.", {err, response: err.response});
                })
        }

            openDocument(pd: DrugDocument) {
            axios.get(`image/${pd.imageId}`, {responseType: "blob"})
                .then(res => {
                    const objectUrl = URL.createObjectURL(res.data);
                    window.open(objectUrl, '_blank');
                })
                .catch(err => {
                    console.error("Error while getting image to show.", {err, response: err.response});
                })
        }
        deleteDocument(){
            let a = this.drugDocuments.filter( dd => dd.isChecked == true);
            a.forEach(ad => {
                axios.get(`/drug/${ad.id}/delete-document`)
                .then(res => {
                    this.$notification(NotificationOptions.successDeletedNotificationPreset(`Document ${ad.fileName} has been`));
                    console.info(`File ${ad.fileName} has been deleted`);
                    this.loadDocs();
                }).catch(err =>{
                    console.error("Error in deleting a document", {err, response: err.response});
                })
            })
            this.checkAll = false;
        }
        get disableSave() {
           return false;
        }

        get emptyText(): string {
            return this.drugId ? 'No documents for this Drug.' : 'Select a Drug.'
        }
    }
