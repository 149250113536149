
    import { Component, Vue } from "vue-property-decorator";
    import axios from "axios";
    import { Configuration } from "@/models/Configuration";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { DatePicker } from "element-ui";


    @Component({
        name: "ConfigurationPage",
        components: {
            [DatePicker.name]: DatePicker,
        }
    })
    export default class ConfigurationPage extends Vue {
        protected configuration: Configuration = new Configuration();

        mounted() {
            this.fetchConfiguration();
        }

        get configKeys() {
            return Object.keys(this.configuration);
        }


        formatConfigName(name: string): string {
            let resp = name.replace(/([A-Z])/g, " $1");
            resp = resp.charAt(0).toUpperCase() + resp.slice(1);
            return resp;
        }

        fetchConfiguration() {
            axios.get<Configuration>("/Configuration")
                .then(fetchResponse => {
                    console.log(this.configuration.toString());
                    this.configuration = new Configuration(fetchResponse.data);
                    console.log(this.configuration.toString());
                })
                .catch(fetchError => {
                    this.$notification(NotificationOptions.error(fetchError));
                });
        }

        saveConfiguration() {
            const entityName = "Configuration";
            axios.post<Configuration>("/Configuration", this.configuration)
                .then(saveResponse => {
                    this.configuration = new Configuration(saveResponse.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset(entityName));
                })
                .catch(saveError => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset(entityName, saveError));
                });
        }
    }
