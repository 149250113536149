

    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Shipment } from '@/models/Shipment';
    import {Order} from '@/models/Order'
    import moment from "moment";
    import numeral from "numeral";
    import {Patient} from "@/models/Patient";
    import axios from 'axios';
    @Component
    export default class ShippingTicketComponent extends Vue {

        @Prop() private value!: Shipment;
        private order:Order = new Order();

        @Prop({ required: true }) patient!: Patient;

        public messageBody = "Here is your order's tracking details: ";

        private rxFields: any[] = [
            {key: "storeName", label: "Store"},
            {key: "rxId", label: "Rx"},
            {key: "productNameShort", label: "Drug"},
            {key: "prescriberName", label:"Prescriber"},
            {key: "dispensed", label: "Qty", class: "text-center", },
            {key: "patientPay", label: "Copay", class: "text-center", formatter: this.moneyFormatter},
            {key: "fillDate", label: "Fill Date", formatter: this.dateFormatter},
            {key: "insurance", label: "PlanNames"},
        ];

        private otcFields: any[] = [
            {key: "otcItemName", label: "Product Name", },
            {key: "otcItemUPC", label: "UPC", },
            {key: "quantity", label: "Qty", },
            {key: "price", label: "Price", formatter: this.moneyFormatter},
            {key: "tax", label: "Tax", },
        ];

        created(){
            axios.get<Order>(`/Shipping/order/${this.value.id}`)
                .then(response => {
                    this.order = new Order(response.data);
                })
                .catch(error => {
                    console.error("Error while loading order information in shipping ticket",{ error, response: error?.response });
                });
        }

        dateFormatter(value: Date, _key: string, _item: any) {
            return moment.utc(value).local().format("L");
        }

        moneyFormatter(value: number) {
            return numeral(value).format('$0,0.00');
        }

        get signatureHtml() {
            let sigData = this.value.deliveryConfirmationSignature;
            if (sigData != null && sigData.startsWith("<")) {
                return sigData;
            }
            return `<img src="${sigData}" alt="Signature"/>`;
        }

        get prescriptions(): Array<any> {
            if (!this.order?.prescriptions?.length) return [];

            return this.order.prescriptions.map(rx => {
                let res: any = rx;
                res.insurance = rx.claims?.length ? rx.claims[0].planName : ''; //Showing 1st claim plan name
                res.prescriberName = `${rx.prescriber.lastName}, ${rx.prescriber.firstName}`;
                return res;
            });
        }

        sendTrackingText(urlString: String) {
            this.messageBody += urlString;
            const response = (_textResp: any) => {
                this.$notification({
                    message: "Tracking Message Sent!",
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                });
            };

            const rejected = (err: any) => {
                this.$notification({
                    //+ err.response?.data
                    message: "Error Sending Message: \n\n" + err.toString(),
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                });
            };

            const payloadData = {
                MessageBody: urlString,
                Patient: this.patient,
            };

            axios.post('/Patient/SendPatientTrackingText', payloadData)
                .then(response)
                .catch(rejected);
        }
    }

