
import { Component, Vue } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import { AdhocDash } from "@/models/AdhocDashboard";
import { DatePicker } from "element-ui";
import Axios from 'axios';

declare let $: any;
@Component({
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker
    }
})
export default class AdhocDashboard extends Vue {
    private selectedDashboard: AdhocDash = new AdhocDash;
    private revealView: any;
    private startDate: Date = new Date();
    private endDate: Date = new Date();

    created() {
        this.startDate.setDate(1);
    }

    mounted() {
        let serverUrl = process.env.VUE_APP_APIURL;
        $.ig.RevealSdkSettings.setBaseUrl(serverUrl);
        $.ig.RevealSdkSettings.setAdditionalHeadersProvider((_url: any) => {
            var headers: Record<string, any> = {};
            headers["Authorization"] = Axios.defaults.headers?.Authorization ?? "";
            headers["StartDate"] = this.startDate.getTime();
            headers["EndDate"] = this.endDate.getTime();
            return headers;
        });
        this.revealView = new $.ig.RevealView("#revealView");

        this.revealView.canSaveAs = false;
        this.revealView.onSave = (rv: any, args: any) => {
            args.dashboardId = args.name;
            args.saveFinished();
        };
        this.revealView.onDataSourcesRequested = this.datasourcesRequested;
    }

    dashboardSelected() {
        $.ig.RVDashboard.loadDashboard(this.selectedDashboard.dashboardId, (dashboard: any) => {
            console.log(dashboard);
            this.revealView.dashboard = dashboard ?? new $.ig.RVDashboard();
            this.revealView.onDataSourcesRequested = this.datasourcesRequested;
        });
    }

    datasourcesRequested(callback: any) {
        var sqlDataSource = new $.ig.RVSqlServerDataSource();
        sqlDataSource.id = "MySqlServerDatasource";
        sqlDataSource.title = "My SQL Server";
        var storeDsi = new $.ig.RVSqlServerDataSourceItem(sqlDataSource);
        storeDsi.id = "Stores";
        storeDsi.title = "Stores";
        var scriptDsi = new $.ig.RVSqlServerDataSourceItem(sqlDataSource);
        scriptDsi.id = "Prescriptions";
        scriptDsi.title = "Prescriptions";
        let refillReportDsi = new $.ig.RVSqlServerDataSourceItem(sqlDataSource);
        refillReportDsi.id = "Refill Report";
        refillReportDsi.title = "Refill Report";
        callback(new $.ig.RevealDataSources([], [storeDsi, scriptDsi, refillReportDsi], false));
    }

    startDateUpdated(_val: any) {
        this.revealView.refreshDashboardData();
    }

    endDateUpdated(_val: any) {
        this.revealView.refreshDashbaordData();
    }
};
