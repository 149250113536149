
    import { PackagePrice } from '@/models/GSDD';
    import { DrugCategory } from '@/models/DrugCategory';
    import { Drug, DrugNote, DrugProgram, DrugStatus } from '@/models/Drug/Drug';
    import { Program } from '@/models/Program';
    import { Inventory } from '@/models/Inventory';
    import { VendorDrugMap } from '@/models/VendorDrugMap';
    import SearchComponent from '@/components/SearchComponent.vue';

    import EnumSelector from '@/components/EnumSelector.vue';
    import CategoryPickerComponent from '@/components/CategoryPickerComponent.vue';
    import InventoryComponent from '@/components/Inventory/InventoryComponent.vue';
    import VendorPriceComponent from '@/components/Vendor/VendorPriceComponent.vue';
    import EquivalentDrugsComponent from '@/components/Drug/EquivalentDrugsComponent.vue';
    import DrugDocumentsComponent from '@/components/Drug/DrugDocumentsComponent.vue';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";

    import { Component, Vue, Watch } from 'vue-property-decorator';
    import moment from 'moment';
    import { Vendor } from "@/models/Vendor";
    import DrugStatusEnum = DrugStatus.DrugStatusEnum;
    import Axios from "axios";

    @Component({
        name: "DrugComponent",
        components: {
            CategoryPickerComponent, VendorPriceComponent, InventoryComponent
            , EquivalentDrugsComponent, EnumSelector, SearchComponent, DrugDocumentsComponent
        },
    })
    export default class DrugComponent extends Vue {
        private drug: Drug | null = new Drug();
        protected imageURLs: string[] = [];

        private selectedProgram: Program = new Program();
        private currentAssociatedProgramID: number | string | null = null;

        private status: DrugStatusEnum | null = null;
        protected statuses = DrugStatusEnum;
        private disableAutorefill: boolean | null = null;
        private autorefillMaxLength: number | null = null;
        private isLoading: boolean = false;

        protected equivalentDrugs: any[] = [];

        protected inventories: Inventory[] = [];
        private alert = "";
        private note = "";
        protected notes: DrugNote[] = [];
        private notesFields = [
            "note",
            { key: "created", label: "Date", sortable: true, formatter: this.formattedDate },
            { key: "createdBy", sortable: true, }
        ];
        private primary: boolean = false;
        private price: number = 0;
        private vendor: Vendor = new Vendor();
        private vendorFields = [
            "primary",
            "vendorName",
            {
                key: "cost", sortable: true, formatter: (value: number) => {
                    return `$${value.toFixed(2)}`;
                },
            },
            { key: "action", label: "" }
        ];
        private showVendorForm = false;
        private modeVendorForm = 'new';
        private category: any = null;
        // category table
        protected categories: any[] = [];
        private categoryFields = [
            { key: 'id', sortable: true },
            { key: 'name', sortable: true },
        ];
        protected selectedCategories: DrugCategory[] = [];

        get routeDrugId(): number {
            let res = parseInt(this.$route.params.id);
            if (isNaN(res)) return 0;
            return res;
        }

        get vendors(): VendorDrugMap[] {
            return this.drug?.vendors ?? [];
        }

        // vendors table
        set vendors(value: VendorDrugMap[]) {
            if (this.drug) this.drug.vendors = value;
        }

        // from Price
        get drugPrice(): any {
            if (!this.drug || !this.drug.prices) return ``;
            const filteredPrices = this.drug.prices as PackagePrice[];

            // 1  -- Average Wholesale Price
            // 13 -- Calculated Average Wholesale Price
            // 2  -- Wholesale Acquisition Cost
            const awp = filteredPrices.find(p => p.priceTypeID == 1 || p.priceTypeID == 13);
            const wac = filteredPrices.find(p => p.priceTypeID == 2);

            const awpString = awp && awp.unitPrice ? `AWP: $${awp.unitPrice}/${awp.ncpdpBillingUnit}` : ``;
            const wacString = wac && wac.unitPrice ? `WAC: $${wac.unitPrice}/${wac.ncpdpBillingUnit}` : ``;

            return `${awpString} ${wacString}`;
        }

        get drugPackagePrice(): any {
            if (!this.drug || !this.drug.prices) return ``;
            const filteredPrices = this.drug.prices as PackagePrice[];

            const awp = filteredPrices.find(p => p.priceTypeID == 1 || p.priceTypeID == 13);
            const wac = filteredPrices.find(p => p.priceTypeID == 2);

            const awpString = awp && awp.unitPrice && awp.packageSize ? `AWP: $${awp.unitPrice * awp.packageSize}/${awp.ncpdpBillingUnit}` : ``;
            const wacString = wac && wac.unitPrice && wac.packageSize ? `WAC: $${wac.unitPrice * wac.packageSize}/${wac.ncpdpBillingUnit}` : ``;

            return `${awpString} ${wacString}`;
        }

        get packageSize(): any {
            if (!this.drug || !this.drug.packageSize || !this.drug.billingUnit) return ``;
            return `${this.drug.packageSize} ${this.drug.billingUnit}`;
        }

        // from Product
        get drugName(): any {
            if (!this.drug) return ``;
            return this.drug.productNameLong;
        }

        get marketer(): any {
            if (!this.drug) return ``;
            return this.drug.marketer;
        }

        get doseForm(): any {
            if (!this.drug) return ``;
            return this.drug.doseForm;
        }

        get deaClass(): any {
            if (!this.drug) return ``;
            return this.drug.deaClass;
        }

        get brandGeneric(): any {
            if (!this.drug) return ``;
            return this.drug.brandGenericStatus;
        }

        get marketStatus(): any {
            if (!this.drug) return ``;

            const isOffMarket: boolean = this.drug.isOffMarket;
            const isReplaced: boolean = this.drug.isReplaced;

            return (isOffMarket && isReplaced) ? "Off Market and Replaced" : isOffMarket ? "Off Market" : isReplaced ? "Replaced" : null;
        }

        // from Package
        get packageId(): string {
            return this.drug?.packageID ? this.drug.packageID.toString() : '';
        }

        get ncpdpBillingUnit(): string {
            return this.drug ? this.drug.billingUnit : '';
        }

        get NDC11(): any {
            if (!this.drug) return ``;
            return this.drug.ndc;
        }

        get packageDescription(): any {
            if (!this.drug) return ``;
            return this.drug.packageDescription;
        }

        created() {
            if (this.routeDrugId) {
                this.fetchDrug(this.routeDrugId);
            }
        }

        @Watch('$route.params.id')
        onParamChanged(value: string, oldValue: string) {
            if (value != oldValue) {
                this.fetchDrug(Number(value));
            }
        }

        @Watch('drug')
        onDrugChanged(value: Drug | null, _oldValue: Drug | null) {
            if (!value) return;

            if (value.packageID && value.packageID != -1 && value.packageID != this.routeDrugId) {
                this.$router.push({ name: 'Drug', params: { id: value.packageID.toString() } });
            }
        }

        blockNoCost() {
            let ret = false;
            if (this.drug && this.drug.activeStatus == DrugStatusEnum.Inactive) {
                ret = false;
            } else {

                if (this.status == DrugStatusEnum.Active || this.status == DrugStatusEnum.ActiveAndPreferred) {
                    //Check if there is a cost associated with this drug
                    const hasPrice = this.vendors?.some(ven => ven.cost) ?? false;
                    ret = !hasPrice;
                }
            }
            return ret;
        }

        changeDisableAutorefill(val: boolean) {
            if (!val) this.autorefillMaxLength = null;
        }

        formattedDate(value: Date): string {
            const date = moment.utc(value).local();
            return date.format("MM-DD-YYYY [At: ]hh:mm:ss a");
        }

        saveNote() {
            if (!this.note.length) return;
            if (!this.drug || !this.drug.packageID) return;

            Axios.post(`/Drug/${this.drug.packageID}/Notes`, { body: this.note })
                .then(response => {
                    this.note = "";
                    this.notes = response.data;
                })
                .catch(error => {
                    console.error('There was an error while updating Notes', { error, response: error?.response });
                })
                .finally(() => {
                    this.fetchNotes();
                });
        }

        saveAlert() {
            if (!this.alert.length) return;
            if (!this.drug || !this.drug.packageID) return;

            Axios.post(`/Drug/${this.drug.packageID}/Alert`, { body: this.alert }, { params: { drugSource: this.drug.source } })
                .then(_response => {
                    this.note = "Alert updated";
                    this.saveNote();
                })
                .catch(error => {
                    console.error('There was an error while updating alert', { error, response: error?.response });
                })
                .finally(() => {
                    console.warn("finally");
                });
        }

        fetchNotes() {
            if (!this.drug || !this.drug.packageID) return;
            Axios.get(`/Drug/${this.drug.packageID}/Notes`)
                .then(response => {
                    this.notes = response.data;
                })
                .catch(error => {
                    console.error('There was an error while getting notes', { error, response: error?.response });
                });
        }

        priceUpdate(price: any) {
            this.price = price;
        }

        primaryUpdate(primary: boolean) {
            this.primary = primary;
        }

        deleteConfirmDialog(item: VendorDrugMap) {
            this.$bvModal.msgBoxConfirm(`This vendor information associated to this drug will get deleted. Are you sure?`, {
                title: 'Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (!value) return;
                    this.removeVendor(item);
                })
                .catch(err => {
                    console.error("Error caught on Clear button.", err);
                });
        }

        removeVendor(item: VendorDrugMap) {
            const vendorID = item.vendorID;

            if (!(this.drug?.packageID && vendorID)) return;

            Axios.delete<boolean>(`/Drug/${this.drug.packageID}/Vendors`, { params: { vendorID: vendorID } })
                .then(res => {
                    if (res.data === true) {
                        this.fetchVendors();
                        this.$notification(NotificationOptions.notificationOptionsPreset("Vendor Price removed.", NotificationOptions.NotificationTypes.success));
                    }
                })
                .catch(error => {
                    const errorMsg = 'There was an error while removing vendor';
                    this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                    console.error(errorMsg, { error, response: error?.response });
                });
        }

        updateVendor(vendor: VendorDrugMap) {
            this.openVendorForm();
            this.price = vendor.cost;
            this.primary = vendor.primary;
            this.vendor.id = vendor.vendorID;
            this.modeVendorForm = 'edit';
        }

        openVendorForm() {
            this.clearVendorForm();
            this.showVendorForm = true;
        }

        closeVendorForm() {
            this.clearVendorForm();
            this.showVendorForm = false;
        }

        clearVendorForm() {
            this.primary = false;
            this.price = 0;
            this.vendor = new Vendor();
            this.modeVendorForm = 'new';
        }

        removeSelectedCategories() {
            for (const item of this.selectedCategories) {
                this.removeFromCategory(item.id);
            }
        }

        removeFromCategory(categoryId: any) {
            if (!this.drug || !this.drug.productID || !categoryId) return;

            Axios.post(`/DrugCategory/${categoryId}/Remove/${this.drug.productID}/${this.drug.source}`)
                .catch(error => {
                    console.error('There was an error while removing drug category', {
                        error,
                        response: error?.response
                    });
                })
                .finally(() => {
                    this.fetchCategories();
                });
        }

        equivalentDrugSelected(packageID: number) {
            this.isLoading = true;
            this.drug = new Drug(packageID);
            window.scrollTo(0, 0);
            this.fetchDrug(packageID);
        }

        submitVendorInformation() {
            if (!this.drug || !this.drug.packageID || !this.vendor || !this.vendor.id || !this.price) return;

            const vendorDrugMap = {
                packageID: +this.drug.packageID, // the + converts to a number.
                vendorID: this.vendor.id,
                displayOrder: 1,
                cost: +this.price,
                primary: this.primary,
            };

            Axios.post<VendorDrugMap>(`/Drug/${this.drug.packageID}/Vendors`, vendorDrugMap)
                .then(res => {
                    if (res.data.vendorID) {
                        this.$notification(NotificationOptions.successSaveNotificationPreset("Vendor information"));
                        this.fetchVendors();
                        this.closeVendorForm();
                    }
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Vendor information"));
                    console.error("Error saving vendor information", { error, response: error?.response });
                });
        }

        fetchVendors() {
            this.vendors = [];
            if (this.drug) this.drug.vendors = [];
            if (!this.drug || !this.drug.packageID) return;
            // fetch vendor/drug mappings with cost
            Axios.get(`/Drug/${this.drug.packageID}/Vendors`)
                .then(result => {
                    if (this.drug) this.drug.vendors = result.data;
                    this.vendors = result.data;
                })
                .catch(error => {
                    console.warn(error);
                })
                .finally(() => {
                    this.blockNoCost();
                });
        }

        handleOk(_bvModalEvt: any) {
            // SUBMIT our drug into this.category
            if (!this.category || !this.drug || !this.drug.productID) return;

            Axios.post(`/DrugCategory/${this.category.id}/Add/${this.drug.productID}/${this.drug.source}`)
                .then(_response => {
                    // empty
                })
                .catch(error => {
                    console.error('There was an error while adding drug to category', {
                        error,
                        response: error?.response
                    });
                })
                .finally(() => {
                    this.fetchCategories();
                });
        }

        saveConfig() {
            this.postStatus();
            this.saveProgram();
        }

        postStatus() {
            if (!this.drug?.packageID) return;

            const status = this.status || DrugStatusEnum.Inactive;
            const disableAutorefill = this.disableAutorefill || false;
            const autorefillMaxLength = disableAutorefill || !this.autorefillMaxLength ? null : this.autorefillMaxLength;
            const newStatus = new DrugStatus({ status, disableAutorefill, autorefillMaxLength } as DrugStatus);

            Axios.post(`/Drug/${this.drug?.packageID}/Status`, newStatus)
                .then(response => {
                    if (response.data)
                        this.$notification(NotificationOptions.successSaveNotificationPreset('Changes'));
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset('Changes'));
                    console.error('There was an error while updating drug status object', {
                        error,
                        response: error?.response
                    });
                });
        }

        saveProgram() {
            if (!this.drug?.packageID || this.currentAssociatedProgramID == this.selectedProgram?.id) return;

            Axios.post(`/Drug/${this.drug?.packageID}/program-autoselect`, Number(this.selectedProgram?.id) || 0)
                .then(response => {
                    if (response.data?.programId) {
                        this.currentAssociatedProgramID = response.data?.programId;
                        this.$notification(NotificationOptions.successSaveNotificationPreset('Program association'));
                    } else
                        this.$notification(NotificationOptions.notificationOptionsPreset('Program was successfully removed from this drug', NotificationOptions.NotificationTypes.success));
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset('Program association'));
                    console.error('There was an error while updating program', { error, response: error?.response });
                });
        }

        onDrugUpdate(drug: Drug) {
            const PackageID = drug.packageID;
            //this.clearClicked();
            this.equivalentDrugs = [];
            this.status = null;
            const packageId = Number(PackageID || 0);
            this.selectedProgram = new Program();
            this.disableAutorefill = null;

            this.fetchDrug(packageId);
        }

        fetchDrug(packageID: number) {
            if (!packageID) {
                this.drug = new Drug();
                return;
            }

            const baseURL = Axios?.defaults?.baseURL || '';
            Axios.get(`/Drug/${packageID}`, { params: { getCategories: true, getVendors: true, fullDetail: true } })
                .then(response => {
                    this.imageURLs = [];
                    const drug: Drug = response.data;
                    this.drug = new Drug(null, drug);

                    const imageURI = `${baseURL}/drug/get-image?packageID=${this.drug.drugId}&drugSource=${this.drug.source}`;
                    this.imageURLs.push(imageURI);

                    this.status = drug.activeStatus;
                    this.notes = drug.notes;
                    if (drug.categories) this.categories = drug.categories;
                    if (drug.vendors) this.vendors = drug.vendors;

                    // Associated NOtes
                    Axios.get(`/Drug/${this.drug.packageID}/Notes`)
                        .then(response => {
                            this.notes = response.data;
                        })
                        .catch(error => {
                            console.error('There was an error while getting Notes', {
                                error,
                                response: error?.response
                            });
                        });

                    // Drug Alert
                    Axios.get(`/Drug/${this.drug.packageID}/Alert`, { params: { drugSource: this.drug.source } })
                        .then(response => {
                            this.alert = response.data;
                        })
                        .catch(error => {
                            console.error('There was an error while getting Alerts', {
                                error,
                                response: error?.response
                            });
                        });

                    // Associated Program
                    Axios.get<Array<DrugProgram>>(`/Drug/${this.drug.packageID}/Program`)
                        .then(response => {
                            if (response.data?.length) {
                                //this.selectedProgram = Object.assign(new Program(), response.data[0]);
                                const dps = response.data.map(dp => new DrugProgram(dp));
                                this.selectedProgram = dps.find(dp => dp.autoselect)?.program || new Program();
                            }
                        })
                        .catch(error => {
                            console.error('There was an error while getting Program', {
                                error,
                                response: error?.response
                            });
                        });

                    this.fetchInventories();
                    this.fetchCategories();
                })
                .catch(error => {
                    console.error('Problem while getting drug details.', { error, response: error?.response });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        fetchInventories() {
            if (!this.drug?.packageID) return;

            Axios.get(`/Drug/${this.drug?.packageID}/Inventory`)
                .then(response => {
                    this.inventories = response.data;
                })
                .catch(error => {
                    console.error('There was an error while getting Inventory', { error, response: error?.response });
                });
        }

        fetchCategories() {
            if (!this.drug || !this.drug.productID) return;
            Axios.get(`/Drug/${this.drug.productID}/${this.drug.source}/Categories`)
                .then(response => {
                    this.categories = response.data;
                })
                .catch(error => {
                    console.error('There was an error while getting Categories', { error, response: error?.response });
                });
        }

        clearClicked() {
            this.drug = new Drug();
            this.categories = [];
            this.vendors = [];
            this.notes = [];
            this.selectedProgram = new Program();
            this.disableAutorefill = null;
        }

    }

