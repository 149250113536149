
    import { Component, Vue } from 'vue-property-decorator';
    import DoctorFormComponent from '@/components/Doctor/DoctorFormComponent.vue'

    @Component({
        name: "DoctorDetailsPage",
        components: { DoctorFormComponent },
    })

    export default class DoctorDetailsPage extends Vue {

        private buttonTitles = {
            new: "New Doctor",
            clear: "Clear",
            save: "Save Changes",
            cancel: ""
        };

        created() {
            console.log("%c Created DoctorDetailsPage", "color: green");
        }

        beforeDestroy() {
            console.log('%c Destroying DoctorDetailsPage', 'color: red');
        }
    }
