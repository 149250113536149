
import {Component, Watch, Vue } from 'vue-property-decorator';
@Component({})
export default class ContentFooter extends Vue {
    get year(){ return  new Date().getFullYear(); }
    private environment:string|null = null;
    private version:string|null=null;

    created(){
      if(!this.$root.$data.dataHub){
        console.log("no data hub");
      }
      else{
        console.log(JSON.stringify(this.$root.$data.dataHub));

          this.$root.$data.dataHub!.on("FileVersion", (res: any) => {
          console.log("Received in Footer");
          console.log(JSON.stringify(res));
          this.environment = res.environment ?? "";
          this.version = res.version;
      });
      console.log(this.$root.$data.dataHub.state);
      }
    }
    @Watch("$root.dataHub") onDataHubChange(){
      this.$root.$data.dataHub.on("FileVersion", (res: any) => {
          console.log("Received in Footer");
          console.log(JSON.stringify(res));
          this.environment = res.environment ?? "";
          this.version = res.version;
      });
    }
};
