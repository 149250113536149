
    import { CaseDetail } from '@/models/Case';
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Table, TableColumn } from 'element-ui';
    import axios from 'axios';
    import CaseDetailComponent from '@/components/Cases/CaseDetailComponent.vue';

    @Component({
        name: "CasesListComponent",
        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            CaseDetailComponent
        }
    })
    export default class CasesListComponent extends Vue {

        @Prop() private show!: string;

        private casesList: CaseDetail[] = [];
        private caseSelected: CaseDetail | any = {};

        created() {
            console.log("%c Created CasesListComponent", "color: green");
            this.getCases();
        }

        beforeDestroy() {
            console.log('%c Destroying CasesListComponent', 'color: red');
        }

        getCases() {
            this.caseSelected = {};
            axios.get(`/Case/${this.show}`)
                .then(response => {
                    this.casesList = response.data;
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        selectCase(item: CaseDetail) {
            this.caseSelected = item;
        }
    }
