import { HasDropDownOption, DropDownOption } from "./HasDropDownOption";
import HasID from "./HasID";
import TrackedObject from "./TrackedObject";

export interface PriceSchedule extends HasID, TrackedObject {
    name:string
    id:number
    dispensingStoreID:number
}

export class PriceSchedule implements HasDropDownOption {
    toDropDownOption(): DropDownOption {
        return new DropDownOption(this.id, this.name);
    }

}