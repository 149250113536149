
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { Order } from "@/models/Order";
import { BvTableFieldArray } from "bootstrap-vue";
import moment from "moment";
import { ClaimRejects } from "@/data/ClaimRejects";
import { Prescription } from "@/models/Prescription";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { OrderAuthorization } from "@/models/OrderAuthorization";

@Component({
    name: "OrderAuditDetails",
})
export default class OrderAuditDetails extends Vue {
    private order: Order = new Order();
    private orderAuthorization: OrderAuthorization = new OrderAuthorization();
    private rejects: Array<{ Code: string; Value: string }> = ClaimRejects.codes;
    private currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    transactionStatusClass(status: string) {
        return status == "Rejected" ? "text-danger" : status == "Paid" ? "text-success" : "";
    }

    rejectData(codeList: Array<string>) {
        console.log("rejectData: ", codeList);
        return codeList
            .filter((rejectcode) => rejectcode.trim() != "")
            .map((rejectCode: any) => {
                return (
                    this.rejects.find((r) => r.Code == rejectCode.trim()) ??
                    this.rejects.find((r) => r.Code == parseInt(rejectCode, 10).toString()) ?? {
                        Code: rejectCode,
                        Value: "Contact IT.",
                    }
                );
            });
    }

    get amountOwed() {
        const scriptTotal = this.order.prescriptions.reduce((total, rx) => {
            return total + rx.patientPay;
        }, 0);
        const addonTotal = this.order.items.reduce((grandTotal, item) => {
            const price = item.price;
            const qty = item.quantity;
            const tax = item.tax ? +item.tax : 0;
            const itemTotal = price * qty + tax;
            return grandTotal + itemTotal;
        }, 0);
        const balance = scriptTotal + addonTotal;
        return this.currencyFormatter.format(balance);
    }

    get rejectFields(): BvTableFieldArray {
        // return [];
        return [
            { key: "Code", label: "" },
            { key: "Value", label: "" },
        ];
    }

    get patientDashboardRoute() {
        return {
            name: "PatientDashboard",
            params: {
                id: this.order.patientID,
            },
        };
    }

    margin(rx: Prescription) {
        var paidClaims = rx.claims.filter((claim) => claim.reversed == null);
        var insTotal = paidClaims.reduce(
            (total, claim) => (total += claim.planPaid + claim.voucherPaid),
            0
        );
        return insTotal + rx.patientPay - (rx.cost + rx.tax);
    }

    marginClass(rx: Prescription) {
        return this.margin(rx) >= 0 ? "" : "text-danger";
    }

    get orderDetailsRoute() {
        return {
            name: "OrderDetails",
            params: {
                orderId: this.order.id,
            },
        };
    }

    scriptDetailsRoute(rxRow: Prescription) {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: rxRow.storeID,
                rxNumber: rxRow.rxNumber,
                rfNumber: rxRow.rfNumber!,
            },
        };
    }

    get scriptItems() {
        return this.order?.prescriptions ?? [];
    }

    get scriptFields(): BvTableFieldArray {
        return [
            { key: "rxID", headerTitle: "Full Prescription Identifier" },
            { key: "drugName", label: "Drug" },
            { key: "fillDate", formatter: (d) => moment(d).format("L") },
            { key: "programName", label: "Program" },
            { key: "storeName", label: "Store" },
            { key: "patientPay", formatter: this.currencyFormatter.format },
            { key: "payment", label: "Price" },
        ];
    }

    get paymentItems() {
        return this.order?.payments ?? [];
    }

    get paymentFields(): BvTableFieldArray {
        // return [];
        return [
            { key: "authorizedAmount", formatter: this.currencyFormatter.format },
            { key: "created", formatter: (d: any) => moment(d).format("L LT") },
            { key: "createdBy" },
        ];
    }

    get routeAuditId(): number {
        const res = parseInt(this.$route.params.id);
        return isNaN(res) ? 0 : res;
    }

    async created() {
        if (this.routeAuditId) {
            let resp = await Axios.get<Order>(`/Order/Audit/${this.routeAuditId}`);
            this.order = new Order(resp.data);

            let orderAuthResp = await Axios.get<OrderAuthorization>(
                `/Order/Authorization/${this.routeAuditId}`
            );
            this.orderAuthorization = new OrderAuthorization(orderAuthResp.data);
        }
    }
    get signatureHtml() {
        let sigData = this.orderAuthorization.signatureData;
        if(!sigData){
            return "";
        }
        if (sigData != null && sigData.startsWith("<")) {
            return sigData;
        }
        if (sigData.startsWith("data")) {
            return `<img src="${sigData}" alt="Signature"/>`;
        }
        return `<img src="data:image/png;base64,${sigData}" alt="Signature"/>`;
    }
    async completeClicked() {
        if (this.order && this.order.id) {
            try {
                let resp = await Axios.post<Order>(`/Order/Audit/${this.order.id}/Complete`);
                this.order = new Order(resp.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset("OrderAudit"));
            } catch (e) {
                this.$notification(NotificationOptions.error(e));
            }
        }
    }
}
