
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DispenseError  } from '@/models/DispenseError';
    import EnumSelector from '@/components/EnumSelector.vue';
    import NotesList from '@/components/Note/NotesList.vue'
    import NotesForm from '@/components/Note/NotesForm.vue'
    import { Note, EntityType } from '@/models/Note';

    @Component({
        name: "DispensingErrorModalComponent",
        components:{
            EnumSelector, NotesList, NotesForm
        }
    })
    export default class DispensingErrorModalComponent extends Vue {
        @Prop() private value!: DispenseError;

        @Prop({default: "dispense-error-modal"}) private modalId! : string
        @Prop({default: true}) private showButtons! : boolean
        @Prop({default: false}) private reviewMode! : boolean
        @Prop({default: "Report Error"}) private saveButtonLabel! : string

        private typeOfErrors = DispenseError.DispenseErrorTypeEnum;
        private newNote = {body: ''} as Note;
        private typeOfErrorsCustomDisplayNames = DispenseError.ERROR_TYPE_DISPLAY_NAMES;
        private entityType = EntityType.DispenseError;

        save(){
            this.$emit("onClickSave", this.value);
        }
        accept(){
            this.$emit("onClickAccept", this.value);
        }
        reject(){
            this.$emit("onClickReject", this.value);
        }

        show(){
            this.$emit("errorModalOpened")
        }

        hide(){
            this.$emit("errorModalClosed")
        }

        closeModal(){
            this.newNote.body = '';
        }

        onSavingNote(){
            this.$emit('savedNote', this.value.id)
            this.$bvModal.hide(this.modalId);
        }

        get PV():string|undefined{
            return this.value.pv?.toUpperCase();
        }

        get validForm(){
            return !!((this.value?.errorType || this.value?.errorType == 0));
        }
        
    }

