import Vue from "vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

//@ts-ignore
import routes from "./routes/routes";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
// app.js
import "bootstrap/dist/css/bootstrap.css";
import "./styles/app.less";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueRouter from "vue-router";
import App from "./App.vue";

import DashboardPlugin from "./dashboard-plugin";
import Notifications from "@/components/NotificationPlugin";
import { AxiosStatic, default as axios } from "axios";
import moment from "moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import * as signalR from "@microsoft/signalr";
import { User } from "./models/User";
import * as dotenv from 'dotenv';

import VueHtmlToPaper from 'vue-html-to-paper';
// @ts-ignore
import VueMask from "v-mask";
import Button from "@/components/Button.vue";
import Card from "@/components/Cards/Card.vue";
import { DashboardStatus } from "./models/DashboardStatus";
import { PhoneStats } from "./models/PhoneStats";
import { setupCognito, getToken } from "@/util/cognito-auth";

// Declaring global variables
declare module "vue/types/vue" {
    interface Vue {
        $http: AxiosStatic;
        $user: User;
        $stripeKey: string;
    }
}

Vue.prototype.$user = new User();
Vue.prototype.$environment = process.env.VUE_APP_ENVIRONMENT_NAME ?? "";
Vue.prototype.$version = "";
Vue.prototype.$stripeKey = process.env.STRIPE_PUBLIC_KEY ?? "";
dotenv.config();

let serverURL = window.location.origin;
//if (process.env.VUE_APP_ENVIRONMENT_NAME == "Development") {
serverURL = process.env.VUE_APP_APIURL ?? '';
//}

axios.defaults.baseURL = serverURL + "/api";
axios.defaults.headers = { "Content-Type": "application/json" };
axios.defaults.withCredentials = true;
const router = new VueRouter({
    routes,
    mode: "history",
    linkActiveClass: "active"
} as Record<string, any>);

setupCognito();

Vue.config.productionTip = true;
Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Notifications);
Vue.use(vueNumeralFilterInstaller, { locale: "en" });
Vue.use(VueMask);

const htmlToPaperOptions = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css"
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title // override the window title
};
Vue.use(VueHtmlToPaper, htmlToPaperOptions);

Vue.component("VSelect", vSelect);

Vue.component(Button.name, Button);
Vue.component(Card.name, Card);

const store = Vue.observable({ queueItems: [] });
Vue.prototype.$store = store;

// Custom filters, this should be better to be declared in a separate folder if we use more of this custom filters
Vue.filter("formatDate", (value: Date, format: string, setLocal = true) => {
    if (value && format) {
        let momentDate = moment.utc(value);
        momentDate = setLocal ? momentDate.local() : momentDate;
        return momentDate.format(format);
    } else return "";
});

Vue.filter("formatLocalDate", (value: Date, format: string, _setLocal = true) => {
    if (value && format) {
        const momentDate = moment(value);
        return momentDate.format(format);
    } else return "";
});

const dataHubConnection: signalR.HubConnection | null = null;
const dashboardHubConnection: signalR.HubConnection | null = null;
const dispenseErrorHubConnection: signalR.HubConnection | null = null;
const updateTime: Date | null = null;

new Vue({
    data: {
        dataHub: dataHubConnection,
        dashboardHub: dashboardHubConnection,
        dispenseErrorHub: dispenseErrorHubConnection,
        dashboardData: new DashboardStatus(),
        dashboardDataUpdated: updateTime,
        phoneStats: new PhoneStats(),
        environment: "",
        version: ""
    },
    created() {
        console.log("startedBeforeCreate");
        getToken().then((resp) => {
            const connectionsOptions = {
                accessTokenFactory: () => {
                    return resp;
                }
            } as signalR.IHttpConnectionOptions;

            this.$root.$data.dashboardHub = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(serverURL + "/dashboard-hub", connectionsOptions)
                .build();
            this.$root.$data.dashboardHub
                .start()
                .then(() => {
                    this.$root.$data.dashboardHub.on(
                        "UpdateDashboard",
                        (res: DashboardStatus) => {
                            this.$root.$data.dashboardData = new DashboardStatus(
                                res
                            );
                            this.$root.$data.dashboardDataUpdated = Date.now();
                        }
                    );
                })
                .catch((err: any) => {
                    console.log("Error while connecting to dashboard-hub", {
                        err,
                        response: err?.response
                    });
                });

            this.$root.$data.dispenseErrorHub = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(serverURL + "/dispense-error-hub", connectionsOptions)
                .build();

            this.$root.$data.dispenseErrorHub.start().catch((err: any) => {
                console.log("Error while connecting to dispense-error-hub", {
                    err,
                    response: err?.response
                });
            });

            this.$root.$data.dataHub = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(serverURL + "/data-hub", connectionsOptions)
                .build();

            this.$root.$data.dataHub
                .start()
                .then(() => {
                    this.$root.$data.dataHub.on(
                        "PhoneStats",
                        (res: PhoneStats) => {
                            this.$root.$data.phoneStats = res;
                        }
                    );
                    this.$root.$data.dataHub?.invoke("GetFileInfo");
                })
                .catch((err: any) => {
                    console.log("Error while connecting to data-hub", {
                        err,
                        response: err?.response
                    });
                });
        });
    },
    render: h => h(App),
    router
}).$mount("#app");
