import { render, staticRenderFns } from "./ConsultationsPage.vue?vue&type=template&id=23e3979b&scoped=true"
import script from "./ConsultationsPage.vue?vue&type=script&lang=ts"
export * from "./ConsultationsPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e3979b",
  null
  
)

export default component.exports