import HasID from "./HasID";
import { Note } from "./Note";
import TrackedObject from "./TrackedObject";
import TextUtils from "@/util/TextUtils"
import { Prescription } from "./Prescription";

export interface PrescriberFollowUpCase extends HasID, TrackedObject {
    prescriberId : number
    patientId : number
    documentId : number
    packageID : number
    type : FaxType
    storeID : number
    rxNumber : number
    rfNumber : number
    status : PrescriberFollowUpStatus
    
    patientName : string
    prescriberName : string
    storeName : string
    drugName : string
    notes?: Array<Note>

    notifiedToPatient: Date;
    notifiedToPatientBy: string;
    
    faxSent: Date;
    faxSentBy: string;
    
}

export enum FaxType {
    RxExternalTransfer,
    RefillAuthorization,
    RxClarification,
    NewRxRequest,
    UnfilledNDC,
    AlternateRxRequest
}

export enum PrescriberFollowUpStatus
{
    Created, // Newly Created
    InProgress, // No response for now, hold 48hrs
    NotResponded, //Definitly not responded
    Responded // Successfuly responded by doctor
}

export class PrescriberFollowUpCase {
    constructor(obj?:PrescriberFollowUpCase){
        this.notes = new Array<Note>();
        if(obj) {
            Object.assign(this, obj);
            this.notes = obj.notes?.map(n => new Note(n));
        }
    }

    get statusName():string {
        return PrescriberFollowUpStatus[this.status];
    }

    get requestName():string {
        return TextUtils.camelCaseToNormal(FaxType[this.type]);
    }

    get rxID(): string {
        if(!this.rxNumber) return '';
        return Prescription.createRxId(this.storeID, this.rxNumber, this.rfNumber);
    }
}
   
export class GroupOfCasesByDoctor {
    prescriberID = 0;
    prescriberName : string;
    cases : Array<PrescriberFollowUpCase> = new Array<PrescriberFollowUpCase>();

    constructor(prescriberID?:number, prescriberName?:string, cases?:Array<PrescriberFollowUpCase>){
        this.prescriberID = prescriberID || 0;
        this.prescriberName = prescriberName || '';
        this.cases = cases || new Array<PrescriberFollowUpCase>();
    }
}