

import {EscriptResponse} from '@/models/Escript';

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Axios, {AxiosResponse} from 'axios';
import PDFObject from 'pdfobject';

@Component({
    name: "ImageComponent",
})
export default class ImageComponent extends Vue {
    @Prop() private imageID!: number;
    @Prop() private escriptID!: number;
    @Prop() private rxNumber!: number;

    private imageSrc?: any = null;

    private escriptDetailRequest?: any = null;
    private imageRequest?: any = null;

    get pdfContainerID(): string {
        return 'pdf_' + this.imageID;
    }

    created() {
        console.log("%c Created ImageComponent", "color: green;");
        if (this.imageID) {
            this.fetchPrescriptionImageSource(`image/${this.imageID}`);
        } else if (this.escriptID) {
            this.fetchPrescriptionEscriptResponse(this.escriptID);
        }
    }

    beforeDestroy() {
        console.log('%c Destroying ImageComponent', 'color: red');
    }

    @Watch('imageID') imageIDUpdated(value: number) {
        if (value) {
            this.fetchPrescriptionImageSource(`image/${value}`);
        }
    }

    @Watch('escriptID') escriptIDUpdated(value: any) {
        if (value) {
            this.fetchPrescriptionEscriptResponse(value);
        }
    }

    fetchPrescriptionEscriptResponse(value: number) {
        if (this.escriptDetailRequest) {
            console.log("cancelling escriptDetailRequest");
            this.escriptDetailRequest.cancel();
        }
        let axiosSource = Axios.CancelToken.source();
        this.escriptDetailRequest = {cancel: axiosSource.cancel};
        Axios.get<EscriptResponse>(`escript/${value}`, {
            cancelToken: axiosSource.token
        })
            .then(response => {
                const escript = response.data.eScript;
                this.fetchPrescriptionImageSource(`escript/${escript.id}/image`);
                this.escriptDetailRequest = null;
            });
    }


    fetchPrescriptionImageSource(imageUrl?: string) {
        if (imageUrl) {
            this.imageSrc = null;

            if (this.imageRequest) {
                console.log("cancelling request");
                this.imageRequest.cancel();
            }

            let axiosSource = Axios.CancelToken.source();
            this.imageRequest = {cancel: axiosSource.cancel};
            Axios.get(imageUrl, {
                cancelToken: axiosSource.token
            })
                .then((response: AxiosResponse) => {

                    const url = response.request.responseURL;
                    Axios.get(url, {
                        responseType: "blob",
                        cancelToken: axiosSource.token
                    })
                        .then(r => {
                            const objectUrl = URL.createObjectURL(r.data);
                            if (response.headers["content-type"] == "application/pdf") {
                                const options = {
                                    pdfOpenParams: {
                                        view: 'FitW',
                                        pagemode: 'thumbs',
                                        toolbar: 1,
                                        scrollbar: 0,
                                    }
                                };
                                PDFObject.embed(objectUrl, '#' + this.pdfContainerID, options);
                            } else {
                                this.imageSrc = objectUrl;
                            }
                            this.imageRequest = null;

                            this.$nextTick(() => {
                                this.$forceUpdate();
                            });
                        });
                });
        }
    }

}
