
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import Axios, { AxiosError } from "axios";
import Vue2Dropzone from "vue2-dropzone";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import NewClearSaveButtons from "@/components/NewClearSaveButtons.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import DispensingErrorAlertComponent from "@/components/DispensingError/DispensingErrorAlertComponent.vue";
import NotesForm from "@/components/Note/NotesForm.vue";
import NotesList from "@/components/Note/NotesList.vue";
import InsuranceBillingComponent from "@/components/InsurancePlan/InsuranceBillingComponent.vue";
import ImageComponent from "@/components/Prescription/ImageComponent.vue";
import EquivalentDrugsComponent from "@/components/Drug/EquivalentDrugsComponent.vue";
import QuestionaryComponent from "@/components/Questionary/QuestionaryComponent.vue";
import { Lock, LockHandler } from "@/mixins/LockHandler";
import {
    DAWCode,
    FaxForm,
    OnHoldSubstatusCode,
    OriginCode,
    Prescription,
    RefillStatusCode,
} from "@/models/Prescription";
import { DeliveryCode } from "@/models/DeliveryCode";
import { Prescriber } from "@/models/Prescriber";
import { Patient } from "@/models/Patient";
import { Program } from "@/models/Program";
import { DispenseError } from "@/models/DispenseError";
import { Store } from "@/models/Store";
import { QueueItem } from "@/models/QueueItem";
import { Drug, DrugStatus } from "@/models/Drug/Drug";
import { DrugCategory } from "@/models/DrugCategory";
import { Escript, EscriptResponse, EscriptResponseMessage } from "@/models/Escript";
import { FaxType as FaxToPhysicianFormType, } from "@/models/PrescriberFollowUpCase";
import { Location } from "vue-router";
import { DatePicker } from "element-ui";
import { EntityType, Note } from "@/models/Note";
import PatientFormComponent from "@/components/Patient/PatientFormComponent.vue";
import PatientRxHistoryModalComponent from "@/components/Patient/PatientRxHistoryModalComponent.vue";
import FaxToPhysicianFormModal from "@/components/Prescription/FaxToPhysicianFormModal.vue";
import AlternateRxFormModal from "@/components/Prescription/AlternateRxFormModal.vue";
import DoctorFormComponent from "@/components/Doctor/DoctorFormComponent.vue";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import PatientDocumentsForm from "@/components/Patient/PatientDocumentsForm.vue";
import DoctorFollowUpCasesAlertComponent from "@/components/Doctor/DoctorFollowUpCasesAlertComponent.vue";
import moment from "moment";
import { Claim } from "@/models/Claim";
import DirectionsComponent from "@/components/Prescription/DirectionsComponent.vue";
import EscriptButtonComponent from "@/components/Prescription/EscriptButtonComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import TextUtils from "@/util/TextUtils";
import { ValidatorGroup } from "@/util/ValidatorGroup";
import { AutoRefillSubscription } from "@/models/AutoRefillSubscription";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
import { CompoundDrug } from "@/models/Drug/CompoundDrug";
import { PharmacyRecord } from "@/models/Pharmacy";
import PharmacyDetailPage from "@/pages/Pharmacy/PharmacyDetailPage.vue";
import { PharmacistRecord } from "@/models/Pharmacist";
import PharmacistDetailPage from "@/pages/Pharmacist/PharmacistDetailPage.vue";
import { PharmacyBenefitManager } from "@/models/PharmacyBenefitManager";
import EscriptListModal from "@/components/Prescription/EscriptListModal.vue";
import { BvTableCtxObject } from "bootstrap-vue";
import { IncludeCombinedPlan } from "@/models/CombinedPlan";
import { PatientPlan } from "@/models/PatientPlan";
import { Plan } from "@/models/Plan";

let processingInsurance = false;
let previousRoute: any;

@Component({
    computed: {
        PharmacyBenefitManager() {
            return PharmacyBenefitManager
        }
    },
    beforeRouteLeave(to, from, next) {
        if (processingInsurance) {
            alert("Please wait until insurance processing has finished");
            next(false);
        } else {
            next();
        }
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        previousRoute = from;
        next();
    },
    name: "PrescriptionDetailsPage",
    components: {
        EscriptListModal,
        PharmacistDetailPage,
        DirectionsComponent,
        NewClearSaveButtons,
        EnumSelector,
        InsuranceBillingComponent,
        ImageComponent,
        [DatePicker.name]: DatePicker,
        PatientFormComponent,
        DoctorFormComponent,
        DispensingErrorAlertComponent,
        NotesForm,
        NotesList,
        Checkbox,
        EquivalentDrugsComponent,
        EscriptButtonComponent,
        QuestionaryComponent,
        PatientRxHistoryModalComponent,
        PatientDocumentsForm,
        FaxToPhysicianFormModal,
        AlternateRxFormModal,
        DoctorFollowUpCasesAlertComponent,
        VueDropzone: Vue2Dropzone,
        SearchComponent,
        PharmacyDetailPage,
    },
})
export default class PrescriptionDetailsPage extends Mixins(LockHandler) {
    @Prop() prescriptionsInBatch!: Array<Prescription>;
    @Ref("program-search-component") programSearchComponent!: SearchComponent;
    @Ref("doctor-followup-alerts") doctorFollowupAlerts!: DoctorFollowUpCasesAlertComponent;
    @Ref("patient_rx_history_modal") rxHistoryModal!: PatientRxHistoryModalComponent;
    errorLoadingDrugDetails = false;
    // Enumerations
    protected originCodes = OriginCode;
    protected dawCodes = DAWCode;
    protected onHoldSubstatusCode = OnHoldSubstatusCode;
    protected deliveryCodes = DeliveryCode;
    protected doctorButtonTitles = {
        new: "",
        clear: "",
        save: "Save Doctor",
        cancel: "Close Doctor Form",
    };
    protected imageURLs: string[] = [];
    protected faxFormTypeRequestEnum = FaxToPhysicianFormType;
    protected hardcopyAnnotations: Note[] = [];
    protected hardcopyAnnotationsFields: any[] = [
        { label: "Note", key: "body" },
        { label: "By", key: "createdBy" },
        { label: "On", key: "created", formatter: this.formattedDate, sortable: true },
    ];
    protected patientButtonTitles = {
        new: "",
        clear: "",
        save: "Save Patient",
        cancel: "Close Patient Form",
    };
    //Props
    @Prop({ default: false }) private refillBatchMode!: boolean;
    // Entities
    private prescription: Prescription = new Prescription();
    private transferTo: FaxForm = {} as FaxForm;
    private store: Store = new Store();
    private newStore: Store = new Store();
    private patient: Patient = new Patient();
    private doctor: Prescriber = new Prescriber();
    private program: Program = new Program();
    private drug: DrugProduct = new Drug();
    private escript: Escript = new Escript();
    private claims: Array<Claim> = [];
    private drugCategories: Array<DrugCategory> = [];
    private prescriptionLock: Lock | null = null;
    private queueItem: QueueItem | null = null;
    private lastFill: Prescription = new Prescription();
    private autoRefillSubscription: AutoRefillSubscription = new AutoRefillSubscription();
    private drugAlert: string = "";
    private showDrugChange = false;
    private dispenseError: DispenseError = new DispenseError();
    private dispenseErrors: Array<DispenseError> = [];
    private errorNote: Note = {} as Note;
    private patientEntityType = EntityType.Patient;
    private dispenseErrorEntityType = EntityType.DispenseError;
    private escriptResponseMessage: EscriptResponseMessage | null = null;
    private originalFillDate: Date = new Date();
    private faxPlans: IncludeCombinedPlan[] = [];

    // Arguments
    private imageID?: number;
    private escriptID?: number;
    private queueItemID?: number;
    //Flags
    private newMode = false;
    private fromIngestion = false;
    private formIncompleteAnswersQueue = false;
    private showPatientAlert = false;
    private wasResolved: boolean = false;
    private newRefillMode = false;
    private loadingTransferFax = false;
    private PartnerFillRequired = false;
    private SerialNumberRequired = false;
    private LotNumberRequired = false;
    //View values
    private dispensedQuantity: number = 0;
    private originCode: number = 0;
    private statusCode: number = 0;
    private deliveryCode: number = 0;
    private dawCode: number = 0;
    private substatusCode: number | null = null;
    private statusCodes = RefillStatusCode;
    private drugStatuses: string[] = [
        "Inactive",
        "Active",
        "Active and Preferred",
        "Blocked From Fills",
    ];
    private notSelectableStatus = [
        RefillStatusCode.TransferOut,
        RefillStatusCode.TransferIn,
        RefillStatusCode.Canceled,
    ];
    // Buttons config
    private buttonTitles = {
        new: "",
        clear: "Clear",
        save: "Save Changes",
        cancel: "",
    };
    private saveButtonLabel: string = "Save changes";
    //Notes
    private addNote = "";
    private editPatientForm = false;
    private showPatientForm = false;
    private showDocuStore = false;
    private showDoctorForm = false;
    private editDoctorForm = false;
    private coordinationOfBenefits: any[] = [];
    private drugDetails: any = null;
    private drugInventory: any = null;
    private nextRefillRxId = "";
    private programTransferId: number | null = null;
    private fieldsPending = "";
    //--------------
    private isClaimsLoading = true;
    private initialDispensedQty = 0;
    private expiration: Date | any = null;
    private writtenDate: Date | any = null;
    private drugExpiration: Date | any = null;
    private enableExpirationDateEstimation = true;
    private loadingDrugDetails = false;
    private batchIndex: number = -1;
    private globalPreventRedirection = false;
    // validation
    private validationClasses: {
        quantity: string;
        dispense: string;
        daySupply: string;
        fillDate: string;
        writtenDate: string;
        expirationDate: string;
    } = {
        quantity: "",
        dispense: "",
        daySupply: "",
        fillDate: "",
        writtenDate: "",
        expirationDate: "",
    };
    private pharmacyDetail: PharmacyRecord = new PharmacyRecord();
    private pharmacistDetail: PharmacistRecord = new PharmacistRecord();
    private pharmacists: PharmacistRecord[] = [];

    get drugAsDrug(): Drug {
        return this.drug as Drug;
    }

    get dispenseErrorAlertButtonTitles(): any {
        return { resolve: "Resolve" };
    }

    get showDrugAlert() {
        return this.drugAlert.length > 0;
    }

    get escriptPatient() {
        return this.escriptResponseMessage?.patient ?? null;
    }

    get escriptPrescriber() {
        return this.escriptResponseMessage?.prescriber ?? null;
    }

    get disableNew(): boolean {
        return !!this.prescription;
    }

    get disableSave(): boolean {
        return !this.prescription || this.disableFields;
    }

    get disableClear(): boolean {
        return !this.prescription;
    }

    get disableTransfer(): boolean {
        if (this.transferTo.verbalTransfer == null) return true;
        if (!this.transferTo.verbalTransfer) {
            if (!this.pharmacyDetail.id) return true;
            if (!this.pharmacistDetail.id) return true;
        }
        return false;
    }

    get showSearchBar(): boolean {
        return !(
            this.prescription?.rxNumber ||
            this.newMode ||
            this.fromIngestion ||
            this.isBatchMode ||
            this.routeRxId
        );
    }

    get shouldLockEscriptProcessingStore(): boolean {
        return this.disableFields || !!(this.prescription && this.prescription.rxNumber) || ((this.escript.id > 0) && (this.store.id > 0));
    }

    get routeRxId(): string | any {
        let res = null;
        const storeID = Number(this.$route.params.storeID);
        const rxNumber = Number(this.$route.params.rxNumber);
        const rfNumber = Number(this.$route.params.rfNumber);
        const rxId = this.$route.params.rxid;
        if (!isNaN(storeID) && !isNaN(rxNumber) && !isNaN(rfNumber))
            res = `${storeID}-${rxNumber}-${rfNumber}`;
        else if (rxId) res = rxId;
        return res;
    }

    get drugLicenseType(): string {
        return this.drugDetails?.licenseType || "unknown";
    }

    get routeDispenseErrorId(): number | null {
        let res = null;
        if (this.$route.params.dispenseErrorId) res = Number(this.$route.params.dispenseErrorId);
        else if (this.dispenseError?.id) res = Number(this.dispenseError?.id);
        return res;
    }

    get hasClaims(): boolean {
        return this.claims.length > 0;
    }

    get disableFields(): boolean {
        const lockedForUser = !this.prescriptionLock;
        const noEntityLoaded = !(this.prescription && this.prescription.rxNumber);
        const isCanceled = this.prescription.status == RefillStatusCode.Canceled;
        let ManagerCond = false;
        if (!this.fromIngestion) {
            let dateNow: Date = new Date();
            let dateFillDate: Date = new Date(this.prescription.fillDate);
            let timeInMilisec: number = dateNow.getTime() - dateFillDate.getTime();
            let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
            ManagerCond =
                !this.$user.isManager &&
                daysBetweenDates > 15 &&
                this.prescription.status != RefillStatusCode.OnHold &&
                this.prescription.status != RefillStatusCode.AwaitingInsurance;
        }

        // noinspection UnnecessaryLocalVariableJS
        const shouldDisable =
            ((lockedForUser || noEntityLoaded) && !this.newMode && !this.fromIngestion) ||
            this.hasClaims ||
            isCanceled ||
            !this.prescription.isActive ||
            (this.prescription?.isRx30BeforeApril && !this.newRefillMode) ||
            ManagerCond;

        // console.log("L1", ((lockedForUser || noEntityLoaded) && !this.newMode && !this.fromIngestion));
        // console.log("L2", this.hasClaims);
        // console.log("L3", isCanceled);
        // console.log("L4", !this.prescription.isActive);
        // console.log("L5", (this.prescription?.isRx30BeforeApril && !this.newRefillMode));
        // console.log("L6", ManagerCond);
        // console.log("Main", shouldDisable);
        return shouldDisable;
    }

    get disableBillingComponent(): boolean {
        const notRefillable = !this.allowNewRefill && this.newRefillMode;

        return (
            (!this.prescription.isActive && !this.newMode) ||
            (this.prescription.isRx30BeforeApril && !this.newRefillMode) ||
            notRefillable ||
            this.statusCode == this.statusCodes.OnHold ||
            this.isOutOfState ||
            this.statusCode == this.statusCodes.AwaitingInsurance ||
            (this.prescription.hasBeenShipped && !this.newRefillMode)
        );
    }

    get disableProgramChange(): boolean {
        return this.disableFields;
    }

    get hasShipped(): boolean {
        return !!(this.prescription && this.prescription.rxNumber && !this.prescription.rfNumber);
    }

    get fillsLeft(): number {
        if (!this.prescription) return 0;
        return this.prescription.refills;
        //if (!this.prescription.rfNumber) return this.prescription.refills;
        //return this.prescription.refills - this.prescription.rfNumber;
    }

    get validFormForBilling() {
        // Form validation
        let valGrpEntities: ValidatorGroup = new ValidatorGroup([
            new ValidatorGroup.Validator("Store", !!this.prescription.storeID),
            new ValidatorGroup.Validator("Patient", !!this.prescription.patientID),
            new ValidatorGroup.Validator("Drug", !!this.prescription.packageID),
            new ValidatorGroup.Validator("Doctor", !!this.prescription.prescriberID),
            new ValidatorGroup.Validator("Program", !!this.prescription.programID),
        ]);
        const entitiesWereSelected = valGrpEntities.evaluate();

        let valGrpQuantities: ValidatorGroup = new ValidatorGroup([
            new ValidatorGroup.Validator("Day Supply", this.prescription.daySupply >= 0),
            new ValidatorGroup.Validator("Quantity", this.prescription.quantity > 0),
            new ValidatorGroup.Validator("Dispensed", this.prescription.dispensed >= 0),
        ]);
        const quantitiesWereIntroduced = valGrpQuantities.evaluate();

        let valGrpDates: ValidatorGroup = new ValidatorGroup([
            new ValidatorGroup.Validator("Written Date", !!this.writtenDate),
            new ValidatorGroup.Validator("Expiration", !!this.expiration),
        ]);
        const datesWereIntroduced = valGrpDates.evaluate();

        const remainingQuantity =
            this.prescription.remainingQuantity +
            (this.prescription.rfNumber == 0 ? 0 : this.initialDispensedQty);

        let valDispense = this.dispensedQuantity <= remainingQuantity;
        const fillDateBeforeExpiration = moment(this.prescription.fillDate).isBefore(
            this.expiration
        );
        const validRefillMode = (this.allowNewRefill && this.newRefillMode) || !this.newRefillMode;

        this.fieldsPending = "";
        this.fieldsPending += !entitiesWereSelected
            ? valGrpEntities.concatValidatorNames(false) + ", "
            : "";
        this.fieldsPending += !quantitiesWereIntroduced
            ? valGrpQuantities.concatValidatorNames(false) + ", "
            : "";
        this.fieldsPending += !datesWereIntroduced
            ? valGrpDates.concatValidatorNames(false) + ", "
            : "";
        this.fieldsPending += !valDispense
            ? "Dispensed quantity must be less than total Allowed to Dispense, "
            : "";
        this.fieldsPending += !fillDateBeforeExpiration
            ? "Fill date should be before expiration date,"
            : "";
        this.fieldsPending += this.isNPIInvalid ? "Mismatch in Prescriber NPI," : "";
        this.fieldsPending += !validRefillMode ? "Not refillable, " : "";
        this.fieldsPending += !this.isDrugActive ? "Drug is not active." : "";

        if (!valDispense && quantitiesWereIntroduced) {
            this.validationClasses.dispense = "has-danger";
        }
        // noinspection UnnecessaryLocalVariableJS
        const formIsValid =
            entitiesWereSelected &&
            quantitiesWereIntroduced &&
            datesWereIntroduced &&
            valDispense &&
            fillDateBeforeExpiration &&
            validRefillMode &&
            !this.isNPIInvalid &&
            this.isDrugActive;
        return formIsValid;
    }

    get validForm() {
        // Process validation
        const isOnHold = this.statusCode == RefillStatusCode.OnHold && this.substatusCode != null;
        const isAwaitingStatus = this.statusCode == RefillStatusCode.AwaitingInsurance;
        const isVoid = this.statusCode == RefillStatusCode.Void;
        const isFilldateInTheFuture =
            moment(this.prescription.fillDate).isAfter(moment().toDate(), "day") &&
            moment(this.prescription.fillDate).isBefore(this.expiration, "day");

        const processIsValid =
            this.hasClaims ||
            isOnHold ||
            isVoid ||
            isFilldateInTheFuture ||
            (this.prescription.isCashedOut && this.statusCode != RefillStatusCode.OnHold) ||
            isAwaitingStatus;

        //Whole validation
        return (
            this.validFormForBilling &&
            processIsValid &&
            (!this.prescription?.isRx30BeforeApril || this.newRefillMode) &&
            this.isDrugActive
        );
    }

    get isLastFill(): boolean {
        if (!this.lastFill.hasFullId) return true;
        return this.lastFill?.rxID == this.prescription?.rxID || this.newRefillMode;
    }

    get allowNewRefill(): boolean {
        return !!(this.prescription?.isRefillable && this.isLastFill); //&& !this.refillBatchMode && !this.newRefillMode
    }

    get notAllowNewRefill(): boolean {
        return !this.allowNewRefill;
    }

    get claimIsPaid(): boolean {
        return this.claims.some((cl) => cl.transactionStatus == "Paid");
    }

    get validBillingInformation() {
        const isFilldateInTheFuture =
            moment(this.prescription.fillDate).isAfter(moment().toDate(), "day") &&
            moment(this.prescription.fillDate).isBefore(this.expiration, "day");

        return (
            this.hasClaims ||
            this.prescription.isCashedOut ||
            this.prescription.status == RefillStatusCode.Void ||
            this.prescription.status == RefillStatusCode.AwaitingInsurance ||
            (this.prescription.status == RefillStatusCode.OnHold &&
                this.prescription.subStatus != null) ||
            isFilldateInTheFuture
        );
    }

    get rxWasBilled(): boolean {
        return !!this.claims?.some((cl) => cl.transactionStatus == "Paid");
    }

    get isMultiFill(): boolean {
        return (this.dispensedQuantity || 0) > (this.prescription?.quantity || 0);
    }

    get requiresPV1(): boolean {
        return (
            this.prescription.isPV1Required ||
            (this.drug instanceof Drug && this.drug.isControl) ||
            this.drug instanceof CompoundDrug
        );
    }

    get isOutOfState(): boolean {
        if (!this.patient?.addressState || !this.store?.addressState) return false;
        let storeAllowOutOfState = !!this.store?.allowOutOfState;

        return !storeAllowOutOfState && this.patient.addressState != this.store.addressState;
    }

    get showAlertIsOutOfState(): boolean {
        return this.isOutOfState;
    }

    get isDrugActive(): boolean | null {
        if (!this.drug?.activeStatus) return null;
        return (
            this.drug?.activeStatus === DrugStatus.DrugStatusEnum.Active ||
            this.drug?.activeStatus === DrugStatus.DrugStatusEnum.ActiveAndPreferred
        );
    }

    get showInactiveDrugAlert(): boolean {
        return this.isDrugActive === false;
    }

    get sigIsSmartSig(): boolean {
        return this.prescription?.directions === this.escript?.smartSig?.sigText;
    }

    //properties
    get dropzoneOptions(): any {
        return {
            url: `${Axios.defaults.baseURL}/image/prescription/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}`,
            thumbnailWidth: 150,
            withCredentials: true,
            paramName: "images",
        };
    }

    get showDoctorAlert(): boolean {
        return !!this.doctor?.alert;
    }

    get showRx30Alert(): boolean {
        return this.prescription.isRx30BeforeApril && !this.newRefillMode;
    }

    get showRefillAuthRequest(): boolean {
        //If we can't fill the next fill, allow a refill auth to go.
        return (
            (this.prescription.remainingQuantity || 0) < (this.prescription.dispensed || 0) * 2 ||
            this.isDrugExpired
        );
    }

    get willAutorefill(): boolean {
        //Is subscribed to autorefill, current date is more/equals than next process date and this page is in refill mode.
        let nextProcessDateValid = true;
        if (this.autoRefillSubscription?.nextProcessDate) {
            //nextProcessDateValid = moment().isSameOrAfter(this.autoRefillSubscription.nextProcessDate, 'day');
        }
        return !!(this.autoRefillSubscription?.id && this.newRefillMode && nextProcessDateValid);
    }

    get isDrugExpired() {
        if (!this.expiration) return false;

        return moment(moment.utc().toDate()).isSameOrAfter(this.expiration);
    }

    //dropzone

    get isRxNotActive(): boolean {
        return !this.prescription.isActive && !this.newMode;
    }

    get inactiveRxAlert() {
        if (this.prescription.isExpired) return "Prescription is expired.";
        else if (!this.prescription.isRefillableStatus) {
            const statusStr = TextUtils.camelCaseToNormal(
                RefillStatusCode[this.prescription.status]
            );
            const subStatus =
                this.prescription.status == RefillStatusCode.OnHold && this.prescription.subStatus
                    ? TextUtils.camelCaseToNormal(OnHoldSubstatusCode[this.prescription.subStatus])
                    : "";
            return `Prescription is not active because its status is ${statusStr}${
                subStatus ? " - " + subStatus : ""
            }.`;
        } else {
            return "Prescription is inactive";
        }
    }

    get prescriptionIdentifier(): string {
        if (!this.prescription) return "";
        if (
            this.prescription.storeID == null ||
            this.prescription.rxNumber == null ||
            this.prescription.rfNumber == null
        )
            return "N/A";
        return `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
    }

    get storeLocationInformation(): string {
        if (!this.store?.id) return "";
        return `${this.store.addressCity}, ${this.store.addressState}`;
    }

    get typedBy(): string {
        return this.prescription?.createdBy?.split("@")[0];
    }

    get origRxDate(): any {
        return this.prescription?.originalRxDate;
    }

    set origRxDate(value: any) {
        if (!this.prescription) return;
        this.prescription.originalRxDate = value ? value : undefined;
    }

    get isNPIInvalid() {
        //Apply only if we are loading Escript or Program Transfer
        if (!this.escriptID && !this.programTransferId) return false;

        //Invalid if there is no prescriber specified in escript or if not equal to selected doctor.
        return !this.escriptPrescriber || this.escriptPrescriber.npiNumber != this.doctor.npiNumber;
    }

    get searchProgramGetParams() {
        const programToAutoselect =
            (this.escriptID ?? 0) > 0 || (this.programTransferId ?? 0)
                ? this.prescription.programID
                : null;
        return {
            active: true,
            flags: "Autoselect Only",
            discard: true,
            forceProgramID: programToAutoselect || 0,
        };
    }

    get patientDOB(): string {
        if (!this.patient || !this.patient.dateOfBirth) return "";
        return moment(this.patient.dateOfBirth).format("L");
        //this.patient && this.formattedDate(this.patient.dateOfBirth)
    }

    get escriptAllergyAlert() {
        return (
            this.escriptID &&
            this.escriptResponseMessage &&
            this.escriptResponseMessage.allergies?.length > 0 &&
            this.patient.nkda
        );
    }

    get isBatchOver(): boolean {
        return this.batchIndex + 1 > (this.prescriptionsInBatch?.length || 0) - 1;
    }

    get isBatchMode(): boolean {
        return !!this.prescriptionsInBatch?.length;
    }

    created() {
        this.create();
    }

    mounted() {
        this.mount();

        document.addEventListener("keydown", this.preventDefaultForHotkeys);
        document.addEventListener("keyup", this.hotKeysListener);
    }

    beforeDestroy() {
        document.removeEventListener("keydown", this.preventDefaultForHotkeys);
        document.removeEventListener("keyup", this.hotKeysListener);
    }

    create() {
        this.queueItemID = Number(this.$route.query.queueItemID);
        this.escriptID = Number(this.$route.query.escriptID);
        this.imageID = Number(this.$route.query.imageID);
        this.programTransferId = Number(this.$route.query.programTransfer);
        this.fromIngestion = !!this.queueItemID;
        this.formIncompleteAnswersQueue = previousRoute?.name == "IncompleteAnswersQueue";

        if (this.fromIngestion) {
            this.buttonTitles.clear = "";
            this.buttonTitles.cancel = "Back to Ingestion";
            if (!this.escriptID && !this.programTransferId) this.buttonTitles.new = "Save and New";
            this.buttonTitles.save = "Save and Finish";
        }

        if (this.routeRxId || this.routeDispenseErrorId) {
            this.getPrescription(this.routeRxId);
            this.buttonTitles = {
                save: "Save Changes",
                clear: "",
                new: "",
                cancel: "",
            };

            if (this.routeDispenseErrorId) this.buttonTitles.cancel = "Back to Dispense Error List";
            else if (this.routeRxId) this.buttonTitles.cancel = "Back";
        }

        if (this.isBatchMode) {
            this.buttonTitles.clear = "";
            this.buttonTitles.cancel = "Next";
            this.buttonTitles.new = "";
            this.buttonTitles.save = "Save and Next";

            if (this.prescriptionsInBatch?.length) this.nextRx();
        }
    }

    mount() {
        if (this.imageID) {
            console.log("create with imageID");
            this.createPrescription(this.imageID);
        }
        if (this.escriptID) {
            console.log("create with escriptID");
            this.createPrescriptionEscript(this.escriptID);
        }
        if (this.programTransferId) {
            console.log("create with programTransferId");
            this.createPrescriptionFromTransfer(this.programTransferId);
        }
        if (this.queueItemID) {
            this.addLockWithURL(`/IngestionQueue/${this.queueItemID}/Lock`, 60000);

            Axios.get<QueueItem>(`/IngestionQueue/${this.queueItemID}`)
                .then((res) => {
                    if (res.data.id) this.queueItem = res.data;
                })
                .catch((err) => {
                    console.error("Error while loading item queue data.", {
                        err,
                        response: err.response,
                    });
                });
        }
    }

    preventDefaultForHotkeys(e: any) {
        if (e.code === "F5") e.preventDefault();
    }

    hotKeysListener(e: any) {
        if (e.code === "F5" && this.patient?.id) {
            this.showRxHistory();
        }
    }

    cancelTransfer() {
        this.$bvModal.hide('transfer-rx-by-fax');
        this.pharmacyDetail = new PharmacyRecord();
        this.pharmacistDetail = new PharmacistRecord();
    }

    backToPreviousPage(skipBillingValidation = false) {
        if (this.fromIngestion) {
            if (this.validBillingInformation || skipBillingValidation)
                this.$router.push({ name: "Ingestion" });
            else
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        "Complete billing information before going back to ingestion list.",
                        NotificationOptions.NotificationTypes.danger
                    )
                );
        } else if (this.routeDispenseErrorId) this.$router.push({ name: "DispenseErrors" });
            // else if (this.routeRxId){
            //     this.$router.push({ name: 'PatientDashboard', params: { id: (this.prescription?.patientID.toString() || '') } });
        // }
        else if (previousRoute) this.$router.push(previousRoute);
        else this.$router.go(-1);
    }

    claimsLoaded(billedClaims: Claim[], rx: Prescription) {
        this.claims = billedClaims;
        this.isClaimsLoading = false;
        this.updatePricing(rx);
    }

    updatePricing(rx: Prescription) {
        if (rx?.rxNumber) {
            this.prescription.patientPay = rx.patientPay;
            this.prescription.cost = rx.cost;
            this.prescription.totalPrice = rx.totalPrice;
            this.prescription.tax = rx.tax;
            this.prescription.priceScheduleID = rx.priceScheduleID;
            this.prescription.status = rx.status;
            this.statusCode = rx.status;
        }
    }

    changeDispense(num: number) {
        if (this.prescription.quantity.toString().length > 11) {
            this.prescription.quantity = Number(
                this.prescription.quantity.toString().substring(0, 11)
            );
        }
        if (num.toString().length > 11) {
            num = Number(num.toString().substring(0, 11));
        }
        this.dispensedQuantity = Number(num);
        this.prescription.dispensed = Number(num);
    }

    fetchPrescription(prescription: Prescription) {
        if (prescription.rfNumber || prescription.rfNumber == 0)
            this.getPrescription(prescription.rxID);
    }

    rxTransferRefresh(transferredPrescription: Prescription) {
        this.getPrescription(transferredPrescription.rxID).then(
            this.replaceRouterParamsWithCurrentPrescription
        );
    }

    // noinspection SpellCheckingInspection
    /**
     * rxID: Concatenated Rx Ids in the format StoreID-RxNumber-RfNumber
     * setupRefill: If true, the method will load the prescription for RxID and immediately will set up its refill in the front end ready to be edited and saved.
     */
    getPrescription(rxID: string, setupRefill = false) {
        return new Promise((resolve, reject) => {
            this.addLockWithURL(`/Prescription/${rxID}/Lock`, 60000)
                .then((result) => {
                    this.prescriptionLock = result;
                })
                .catch((error) => {
                    if (error.response && error.response.status == 418) {
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(
                            `The Prescription is locked by ${lockedBy} until ${moment
                                .utc(expires)
                                .local()
                                .format("LT")}.`
                        );
                    }
                })
                .finally(() => {
                    // linked objects will be automatically fetched by the SearchComponent when we set the objects...
                    // if we manually fetch them they are called for twice.
                    Axios.get(`api/Prescription/${rxID}`)
                        .then((response) => {
                            if (!this.prescription) return reject();

                            this.prescription = new Prescription(response.data);
                            this.setViewValues();

                            this.initialDispensedQty = this.prescription.dispensed;

                            //Remove current status from notSelectableStatus so it can be shown as selected.
                            this.notSelectableStatus = this.notSelectableStatus.filter(
                                (st) => st != this.prescription.status
                            );

                            this.patient.id = this.prescription.patientID;
                            this.doctor.id = this.prescription.prescriberID;
                            this.store.id = this.prescription.storeID;
                            this.program.id = this.prescription.programID || 0;
                            this.originalFillDate = this.prescription.fillDate;

                            this.enableExpirationDateEstimation = false;

                            switch (this.prescription.drugSource) {
                                case DrugSource.GsddFederal:
                                    this.drug = new Drug(this.prescription.packageID);
                                    break;
                                case DrugSource.NonGsddProduct:
                                    this.drug = new NonGSDDDrug(this.prescription.packageID);
                                    break;
                                case DrugSource.CompoundProduct:
                                    this.drug = new CompoundDrug(this.prescription.packageID);
                                    break;
                            }
                            this.getDrugDetails(this.drug);

                            //Getting last fill information
                            this.$http
                                .get<Prescription>(
                                    `/Prescription/last-fill/${this.prescription.patientID}/${this.prescription.storeID}/${this.prescription.rxNumber}`
                                )
                                .then((res) => {
                                    if (res.data?.rxNumber > 0)
                                        this.lastFill = new Prescription(res.data);
                                })
                                .catch((err) =>
                                    console.error("Error while getting last fill info", {
                                        err,
                                        response: err?.response,
                                    })
                                );

                            //Setting up RX form in refill batch
                            if (setupRefill) {
                                this.newRefill();
                                this.$http
                                    .get<AutoRefillSubscription>(
                                        `autorefill/subcriptions-by-rx/${this.prescription.storeID}/${this.prescription.rxNumber}`
                                    )
                                    .then((res) => {
                                        if (res.data.id)
                                            this.autoRefillSubscription =
                                                new AutoRefillSubscription(res.data);
                                    })
                                    .catch((err) =>
                                        console.error("Error while getting autorefill sub data.", {
                                            err,
                                            response: err?.response,
                                        })
                                    );
                            } else {
                                this.fetchAnnotations();
                                this.fetchDispenseError();
                                this.fetchEscript();
                            }
                            resolve(undefined);
                        })
                        .catch((error) => {
                            console.error("Error while getting Prescription", {
                                error,
                                response: error?.response,
                            });
                            reject();
                        });
                });
        });
    }

    fetchEscript() {
        Axios.get(`/escript/prescription/${this.prescription.rxID}`)
            .then((resp) => {
                if (resp && resp.data) {
                    this.prescription.escriptID = resp.data.eScript.id;
                    this.escriptID = resp.data.eScript.id;
                }
            })
            .catch((error) => {
                console.error("Error while getting escript", { error, response: error?.response });
            });
    }

    fetchDispenseError() {
        Axios.get(`DispenseError/by-rxid/${this.prescription.rxID}`)
            .then((response) => {
                const findCreatedCallBack = (err: DispenseError) =>
                    err.statusEnum == DispenseError.DispenseErrorStatusEnum.Created;
                const emptyDispenseError = new DispenseError(this.prescription);
                this.dispenseErrors = response.data.map((err: DispenseError) =>
                    Object.assign(new DispenseError(), err)
                );
                if (this.dispenseErrors?.some(findCreatedCallBack))
                    this.dispenseError =
                        this.dispenseErrors.find(findCreatedCallBack) || emptyDispenseError;
                else this.dispenseError = emptyDispenseError;
            })
            .catch((error) => {
                console.error("Error while getting dispense error", {
                    error,
                    response: error?.response,
                });
            });
    }

    // events
    dropzoneSending(file: any, xhr: XMLHttpRequest, _formData: FormData) {
        xhr.setRequestHeader("Authorization", Axios.defaults.headers["Authorization"]);
    }

    dropzoneUploadSuccess(file: any, response: any) {
        const dropzone: any = this.$refs.myVueDropzone;
        dropzone.removeFile(file);

        // close the modal.

        // reload prescription's image
        console.log("response: ", response);
        this.prescription.imageID = response;
    }

    @Watch("patient.alert") onAlertChange(value: string, oldValue: string) {
        this.showPatientAlert = !!value && !oldValue;
    }

    @Watch("searchProgramGetParams") searchProgramParamsChanged() {
        this.programSearchComponent?.reloadResults();
    }

    @Watch("writtenDate") changedWrittenDate(value: string | Date | null) {
        let val = value;
        console.log("written val: ", val);
        if (!(val instanceof Date)) val = moment(val).toDate();

        this.prescription.writtenDate = val;

        if (value && this.enableExpirationDateEstimation) {
            this.estimateExpirationBasedOnPackage(this.prescription.packageID, val);
        }

        if (!this.enableExpirationDateEstimation) this.enableExpirationDateEstimation = true;
    }

    estimateExpirationBasedOnPackage(packageID: number, writtenDate: Date) {
        let estimatedExpirationDate = moment(writtenDate);
        if (packageID) {
            Axios.get(`Drug/${packageID}/DeaSchedule`).then((response) => {
                const deaSchedule = response.data;
                switch (deaSchedule) {
                    case 2: {
                        if (this.patient.addressState == "IL") {
                            estimatedExpirationDate = estimatedExpirationDate.add(90, "days");
                        } else if (this.patient.addressState == "NY") {
                            estimatedExpirationDate = estimatedExpirationDate.add(30, "days");
                        } else if (this.patient.addressState == "TX") {
                            estimatedExpirationDate = estimatedExpirationDate.add(30, "days");
                        } else if (this.patient.addressState == "OH") {
                            estimatedExpirationDate = estimatedExpirationDate.add(180, "days");
                        } else if (this.patient.addressState == "IN") {
                            estimatedExpirationDate = estimatedExpirationDate.add(364, "days");
                        } else {
                            estimatedExpirationDate = estimatedExpirationDate.add(7, "days");
                        }
                        break;
                    }
                    case 3:
                    case 4:
                    case 5: {
                        if (this.patient.addressState == "OH") {
                            estimatedExpirationDate = estimatedExpirationDate.add(180, "days");
                        } else if (this.patient.addressState == "TX") {
                            estimatedExpirationDate = estimatedExpirationDate.add(180, "days");
                        } else if (this.patient.addressState == "IN") {
                            estimatedExpirationDate = estimatedExpirationDate.add(180, "days");
                        } else {
                            estimatedExpirationDate = estimatedExpirationDate.add(6, "months");
                        }
                        break;
                    }
                    default:
                        estimatedExpirationDate = estimatedExpirationDate.add(1, "years");
                        break;
                }
                this.expiration = estimatedExpirationDate.toDate();
            });
        } else {
            estimatedExpirationDate = estimatedExpirationDate.add(1, "years");
            this.expiration = estimatedExpirationDate.toDate();
        }
    }

    formattedDate(value: Date): string {
        if (!value) return "";
        const date = moment.utc(value);
        return date.format("L");
    }

    formattedDateAndTime(value: Date): string {
        if (!value) return "";
        const date = moment.utc(value);
        return date.format("lll");
    }

    @Watch("store") storeUpdate(value: any) {
        if (!value || !this.prescription) return;
        this.prescription.storeID = value.id;
    }

    patientUpdate(value: any) {
        if (!value || !this.prescription) return;

        this.prescription.patientID = value.id;
        if (
            !this.prescription.deliveryOption &&
            (value.defaultDelivery != undefined || value.defaultDelivery != null) &&
            !this.prescription.rxNumber
        ) {
            this.deliveryCode = value.defaultDelivery;
        }
    }

    doctorUpdate(value: any) {
        if (!value || !this.prescription) return;

        this.prescription.prescriberID = value.id;
        this.setRecommendedProgram();
    }

    programUpdate(value: any) {
        if (!this.prescription) return;
        if (!value?.id) this.program = new Program();

        this.prescription.programID = value?.id;
        this.PartnerFillRequired =
            value.flags?.find((m: any) => m.name == "Partner Fill Number Required")?.value ?? false;
        this.SerialNumberRequired =
            value.flags?.find((m: any) => m.name == "Serial Number Required")?.value ?? false;
        this.LotNumberRequired =
            value.flags?.find((m: any) => m.name == "Lot Number Required")?.value ?? false;
    }

    @Watch("drugDetails")
    drugDetailsUpdate(value: Drug | null, _oldValue: Drug | null) {
        this.imageURLs = [];
        const baseURL = Axios?.defaults?.baseURL || "";
        if (!value) return;
        const imageURI =
            baseURL + `/drug/get-image?packageID=${value.packageID}&drugSource=${value.source}`;
        this.imageURLs.push(imageURI);

        this.drug.ndc = value.ndc;
        if (this.drug instanceof Drug) this.drug.productNameLong = value.productNameLong;
    }

    @Watch("drug")
    drugUpdate(value: DrugProduct, oldValue: DrugProduct) {
        if (
            !value ||
            !value.drugId ||
            (oldValue && oldValue.drugId && +value.drugId == +oldValue.drugId && (value as Drug).productID == (oldValue as Drug).productID)
            //|| this.disableFields
        ) {
            return;
        }

        let packId: number = +value.drugId;
        if ((value as Drug).productID) this.fetchDrugCategory((value as any).productID);

        let drug: DrugProduct;
        switch (value.source) {
            case DrugSource.GsddFederal:
                drug = new Drug(packId, value);
                break;
            case DrugSource.NonGsddProduct:
                drug = new NonGSDDDrug(packId, value);
                break;
            case DrugSource.CompoundProduct:
                drug = new CompoundDrug(packId, value);
                break;
        }

        this.fetchDrug(drug);
        this.fetchDrugAlert(drug);
    }

    getDrugDetails(drug: DrugProduct) {
        const drugId = drug.drugId;
        this.loadingDrugDetails = true;
        Axios.get<DrugProduct>(`/Drug/${drugId}`, {
            params: { getProgram: true, drugSource: drug.source },
        })
            .then((response) => {
                if (this.prescription?.packageID == response.data.drugId) {
                    switch (response.data.source) {
                        case DrugSource.GsddFederal:
                            this.drug = new Drug(response.data.drugId, response.data);
                            break;
                        case DrugSource.NonGsddProduct:
                            this.drug = new NonGSDDDrug(response.data.drugId, response.data);
                            break;
                        case DrugSource.CompoundProduct:
                            this.drug = new CompoundDrug(response.data.drugId, response.data);
                    }

                    this.drugDetails = response.data;
                    this.drugDetails.status = this.drug.activeStatus;
                    this.prescription.drugSource = drug.source;

                    Axios.get<any[]>(`/Drug/${drugId}/Inventory`, {
                        params: { drugSource: drug.source },
                    })
                        .then((invResp) => {
                            let storeInventories = invResp.data.filter(
                                (inv) => inv.storeId == this.prescription.storeID
                            );
                            if (storeInventories.length == 1) {
                                this.drugInventory = storeInventories[0];
                            } else {
                                this.drugInventory = storeInventories.filter(
                                    (inv) => inv.programId == this.prescription.programID
                                )[0];
                            }
                        })
                        .catch((err) => this.$notification(NotificationOptions.error(err)));
                } else {
                    console.warn(
                        "drug details didn't match the prescription's packageID",
                        response.data
                    );
                }
            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                this.loadingDrugDetails = false;
            });
    }

    fetchDrugAlert(drug: DrugProduct) {
        Axios.get(`/Drug/${drug.drugId}/Alert`, {
            params: { getProgram: true, drugSource: drug.source },
        })
            .then((response) => {
                this.drugAlert = response.data;
            })
            .catch((error) => {
                console.error("Error while getting drug alert", {
                    error,
                    response: error?.response,
                });
            });
    }

    fetchDrug(drug: DrugProduct) {
        const packId = Number(drug.drugId);
        if (this.prescription) {
            this.prescription.packageID = packId;
            this.prescription.drugSource = drug.source;
        }
        //Estimate expiration date only if we are creating a new RX
        if (!this.prescription?.rxNumber)
            this.estimateExpirationBasedOnPackage(packId, moment(this.writtenDate).toDate());

        this.loadingDrugDetails = true;
        this.errorLoadingDrugDetails = false;
        Axios.get<DrugProduct>(`/Drug/${drug.drugId}`, {
            params: { getProgram: true, drugSource: drug.source },
        })
            .then((response) => {
                if (!this.disableFields && this.prescription?.packageID == response.data.drugId) {
                    this.drugDetails = response.data;
                    this.setRecommendedProgram();
                }
            })
            .catch((error) => {
                console.error("Error while getting drug details", {
                    error,
                    response: error?.response,
                });
                this.errorLoadingDrugDetails = true;
            })
            .finally(() => {
                this.loadingDrugDetails = false;
            });
    }

    setRecommendedProgram() {
        if (!this.prescription?.rxNumber) {
            const programPrescriberId = this.doctor?.programId || 0;
            const drugProgramId = this.drugDetails?.program?.id || 0;

            //Prescriber program takes precedence over drug program
            const programId = programPrescriberId || drugProgramId;

            if (!this.program) this.program = new Program();

            if (!this.programTransferId || isNaN(this.programTransferId)) {
                this.program.id = programId;
            }
        }
    }

    /**
     * Prepares the from to submit a new refill for current prescription.
     */
    newRefill() {
        if (!this.prescription || !this.prescription?.isActive) return;

        const nextRfNumber = (this.prescription.rfNumber || 0) + 1;
        this.nextRefillRxId = Prescription.createRxId(
            this.prescription.storeID,
            this.prescription.rxNumber,
            nextRfNumber
        );
        this.newRefillMode = true;

        this.prescription.rfNumber = undefined;
        this.prescription.priceScheduleID = undefined;
        this.prescription.fillDate = moment().startOf("day").toDate();

        const comp = this.$refs.insuranceComponent as InsuranceBillingComponent;
        if (comp) comp.fetchPatientClaims();
    }

    unVoid() {
        this.$bvModal
            .msgBoxConfirm(
                "Prescription status will be set back to Refillable and save changes if you confirm. Are you sure you want to unVoid this prescriptions?",
                {
                    title: "Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            .then((value) => {
                if (!value) return;
                this.statusCode = RefillStatusCode.Refillable;
                this.save();
            })
            .catch((err) => {
                console.error("Error caught on unVoid button.", err);
            });
    }

    cancelPV2() {
        this.$bvModal
            .msgBoxConfirm(
                "By Cancelling PV2, any previous PV2 done for this RX will cancelled and inventory will be put back to stock. Are you sure?",
                {
                    title: "Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            .then((value) => {
                if (!value) return;

                const rxId = this.prescription.rxID;
                if (!rxId) return;

                Axios.get(`/Prescription/${rxId}/cancel-pv2/`)
                    .then((response) => {
                        if (response.data.cancelled) {
                            this.prescription.pV2ID = null;
                            this.$notification(
                                NotificationOptions.notificationOptionsPreset(
                                    "PV2 was cancelled.",
                                    NotificationOptions.NotificationTypes.success
                                )
                            );
                        } else
                            this.$notification(
                                NotificationOptions.errorSaveNotificationPreset("PV2 cancellation")
                            );
                    })
                    .catch((error) => {
                        console.error("Error while cancelling PV2", {
                            error,
                            response: error?.response,
                        });
                        this.$notification(
                            NotificationOptions.errorSaveNotificationPreset(
                                "PV2 cancellation",
                                error
                            )
                        );
                    });
            })
            .catch((err) => {
                console.error("Error caught on cancel PV2 button.", err);
            });
    }

    newPrescription() {
        this.newMode = true;
        this.createPrescription();
    }

    createPrescription(imageID?: number, loadImageInformation = true) {
        this.prescription = new Prescription();
        this.setPrescriptionDefaultValues();

        this.prescription.imageID = imageID;
        if (imageID && loadImageInformation) {
            // If prescription image was received via fax, the Origin is set as Fax by default.
            Axios.get("image/ImageInformation/" + imageID).then((response) => {
                if (response.data?.createdBy == "system/SRFax") {
                    this.originCode = OriginCode.Fax;
                }
            });
        }
    }

    setPrescriptionDefaultValues() {
        //Default values needs to be assign to another variable
        this.prescription.originalRxDate = moment().toDate();
        this.prescription.fillDate = moment().toDate();

        this.dawCode = DAWCode.NotSpecified;
        this.originCode = OriginCode.NotSpecified;
        this.statusCode = RefillStatusCode.Refillable;
        this.substatusCode = null;
        this.writtenDate = moment().toDate();
        this.drugExpiration = null;
    }

    createPrescriptionFromTransfer(transferId?: number) {
        if (!transferId) return;
        Axios.get(`ProgramTransfer/${transferId}`).then((resp) => {
            this.escriptResponseMessage = resp.data;
            console.log("programTransfer resp: ", resp);
            this.prescription = Object.assign(new Prescription(), resp.data.prescription);
            this.prescription.originalRxDate = moment().toDate();
            this.writtenDate = moment(resp.data.prescription.writtenDate).toDate();

            this.prescription.fillDate = moment().toDate();
            this.prescription.dispensed = this.prescription.quantity;
            this.dispensedQuantity = this.prescription.dispensed;
            this.originCode = OriginCode.Transfer;
            this.prescription.originCode = OriginCode.Transfer;

            this.drug = new Drug(this.prescription.packageID);
            this.drug.source = this.prescription.drugSource;
            this.getDrugDetails(this.drug);

            this.program = Object.assign(new Program(), resp.data.programObj);
            this.store = Object.assign(new Store(), resp.data.storeObj);
            this.patient = Object.assign(new Patient(), resp.data.patientObj);
            this.doctor = Object.assign(new Prescriber(), resp.data.prescriberObj);
        });
    }

    createPrescriptionEscript(escriptID?: number) {
        console.log("Creating prescription from escript");

        if (escriptID) {
            this.originCode = OriginCode.Escript;
            Axios.get<EscriptResponse>(`/escript/${escriptID}`)
                .then((resp) => {

                    this.escriptResponseMessage = resp.data.message;
                    this.escript = new Escript(resp.data.eScript);

                    if (resp.data.message?.kind === "NewRx") {
                        this.prescription = new Prescription(resp.data.message.prescription);
                        this.prescription.originalRxDate = moment().toDate();
                        this.prescription.fillDate = moment().toDate();
                        this.prescription.escriptID = escriptID;
                        this.prescription.originCode = OriginCode.Escript;
                        this.prescription.daySupply = this.escript.daySupply;
                        this.prescription.directions = resp.data.eScript.smartSig.sigText;
                        this.prescription.memo = resp.data.eScript.smartSig.note;
                        this.changeDispense(this.prescription.quantity || 0);
                        this.store = Object.assign(new Store(), resp.data.message.storeObj);

                        this.patient = Object.assign(new Patient(), resp.data.message.patientObj);
                        this.doctor = Object.assign(
                            new Prescriber(),
                            resp.data.message.prescriberObj
                        );

                        if (resp.data.preferredDrugObj) {
                            if (
                                resp.data.message.drugObj.packageID !=
                                resp.data.preferredDrugObj.packageID
                            ) {
                                this.showDrugChange = true;
                            }
                            this.drug = new Drug(null, resp.data.preferredDrugObj);
                        } else {
                            this.drug = new Drug(null, resp.data.message.drugObj);
                        }

                        this.setViewValues(true);
                    } else if (resp.data.message?.kind === "RxRenewalResponse") {
                        this.prescription = new Prescription(resp.data.message.prescription);
                        this.prescription.originalRxDate = moment().toDate();
                        this.prescription.fillDate = moment().toDate();
                        this.prescription.escriptID = escriptID;
                        this.prescription.originCode = OriginCode.Escript;
                        this.prescription.daySupply = this.escript.daySupply;
                        this.prescription.directions = resp.data.eScript.smartSig.sigText;
                        this.prescription.memo = resp.data.eScript.smartSig.note;
                        this.changeDispense(this.prescription.quantity || 0);

                        this.store = Object.assign(new Store(), resp.data.message.storeObj);

                        this.patient = Object.assign(new Patient(), resp.data.message.patientObj);
                        this.doctor = Object.assign(
                            new Prescriber(),
                            resp.data.message.prescriberObj
                        );

                        if (resp.data.preferredDrugObj) {
                            if (
                                resp.data.message.drugObj.packageID !=
                                resp.data.preferredDrugObj.packageID
                            ) {
                                this.showDrugChange = true;
                            }
                            this.drug = new Drug(null, resp.data.preferredDrugObj);
                        } else {
                            this.drug = new Drug(null, resp.data.message.drugObj);
                        }

                        this.setViewValues(true);
                    } else if (resp.data.message?.kind === "RxChangeResponse") {
                        this.prescription = new Prescription(resp.data.message.prescription);
                        this.prescription.originalRxDate = moment().toDate();
                        this.prescription.fillDate = moment().toDate();
                        this.prescription.escriptID = escriptID;
                        this.prescription.originCode = OriginCode.Escript;
                        this.prescription.daySupply = this.escript.daySupply;
                        this.prescription.directions = resp.data.eScript.smartSig.sigText;
                        this.prescription.memo = resp.data.eScript.smartSig.note;
                        this.changeDispense(this.prescription.quantity || 0);
                        this.store = Object.assign(new Store(), resp.data.message.storeObj);
                        this.patient = Object.assign(new Patient(), resp.data.message.patientObj);
                        this.doctor = Object.assign(
                            new Prescriber(),
                            resp.data.message.prescriberObj
                        );

                        if (resp.data.preferredDrugObj) {
                            if (
                                resp.data.message.drugObj.packageID !=
                                resp.data.preferredDrugObj.packageID
                            ) {
                                this.showDrugChange = true;
                            }
                            this.drug = new Drug(null, resp.data.preferredDrugObj);
                        } else {
                            this.drug = new Drug(null, resp.data.message.drugObj);
                        }

                        this.setViewValues(true);
                    } else if (resp.data.message?.exception) {
                        const errorMsg = "There was an exception while loading Escript data.";
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(
                                errorMsg,
                                NotificationOptions.NotificationTypes.danger
                            )
                        );
                        console.log(errorMsg, resp.data.message.exception);
                    } else {
                        const errorMsg = "Unknown error, not able to load escript data.";
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(
                                errorMsg,
                                NotificationOptions.NotificationTypes.danger
                            )
                        );
                        console.log(errorMsg, resp);
                    }
                })
                .catch((err) => {
                    const errorMsg = "Error while loading Escript data";
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            errorMsg,
                            NotificationOptions.NotificationTypes.danger
                        )
                    );
                    console.log(errorMsg, { err, response: err.response });
                });
        }
    }

    startEditPatient() {
        this.editPatientForm = true;
    }

    clearForm(continueSameImage = false) {
        if (this.prescriptionLock && this.prescriptionLock.refreshURL) {
            this.releaseLockAtURL(this.prescriptionLock.refreshURL);
            this.prescriptionLock = null;
        }
        this.clearPrescription(continueSameImage);
        this.drugCategories = [];
        this.drugAlert = "";
        this.claims = [];
        (this.$refs.insuranceComponent as any).clearComponent();
        this.setPrescriptionDefaultValues();
        this.notSelectableStatus = [
            RefillStatusCode.TransferOut,
            RefillStatusCode.TransferIn,
            RefillStatusCode.Canceled,
        ];

        this.enableExpirationDateEstimation = true;
        this.initialDispensedQty = 0;

        this.newMode = false;
        this.newRefillMode = false;
        this.$nextTick(() => {
            this.$forceUpdate();
        });
    }

    clearPrescription(continueSameImage = false) {
        if (this.prescriptionLock && this.prescriptionLock.refreshURL) {
            this.releaseLockAtURL(this.prescriptionLock.refreshURL);
            this.prescriptionLock = null;
        }
        this.prescription = new Prescription();

        if (!continueSameImage) {
            this.patient = new Patient();
            this.doctor = new Prescriber();
            this.store = new Store();
        }

        this.program = new Program();
        this.drug = new Drug();
        this.expiration = null;
        this.writtenDate = null;
        this.coordinationOfBenefits = [];
        this.drugDetails = null;
        this.dispenseError = new DispenseError();
        this.dispenseErrors = [];

        this.dispensedQuantity = 0;
        // when we clear we need to clear the imageID query if it exists.
        this.clearQuery();
    }

    undoChanges() {
        this.clearForm();
        window.scrollTo(0, 0);
    }

    setViewValues(_isEscript = false) {
        this.prescription.fillDate = moment(this.prescription.fillDate).toDate();

        this.dawCode = this.prescription.dawCode;
        this.originCode = this.prescription.originCode;
        this.statusCode = this.prescription.status;
        this.substatusCode = this.prescription.subStatus ?? null;
        this.deliveryCode = this.prescription.deliveryCode;
        this.dispensedQuantity = this.prescription.dispensed;

        this.expiration = moment(this.prescription.expirationDate).toDate();
        this.writtenDate = moment(this.prescription.writtenDate).format();
        if (this.prescription.drugExpirationDate) this.drugExpiration = moment(this.prescription.drugExpirationDate).toDate();
    }

    assignViewValues() {
        this.prescription.dawCode = this.dawCode;
        this.prescription.originCode = this.originCode;
        this.prescription.status = this.statusCode;
        this.prescription.subStatus =
            this.statusCode == RefillStatusCode.OnHold ? this.substatusCode : null;
        this.prescription.deliveryCode = this.deliveryCode;
        this.prescription.expirationDate = this.expiration;
        this.prescription.writtenDate = moment.utc(this.writtenDate).toDate();
        this.prescription.drugExpirationDate = this.drugExpiration;
    }

    @Watch("statusCode") statusCodeUpdate(newValue: number, oldValue: number) {
        if (
            (oldValue === RefillStatusCode.OnHold ||
                oldValue === RefillStatusCode.AwaitingInsurance) &&
            newValue !== RefillStatusCode.OnHold &&
            newValue !== RefillStatusCode.AwaitingInsurance
        )
            this.prescription.fillDate = moment().toDate();
    }

    saveCoordinationOfBenefits() {
        console.log("coordinationOfBenefits", this.coordinationOfBenefits);
        if (!this.coordinationOfBenefits || this.coordinationOfBenefits.length == 0) return;
        this.coordinationOfBenefits.forEach((v) => {
            v.patientId = this.patient?.id;
        });
        Axios.post(`/CoordinationOfBenefits/`, this.coordinationOfBenefits)
            .then((_response) => {
                //empty
            })
            .catch((_error) => {
                // empty
            });
    }

    fetchAnnotations() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.get(`/Prescription/${id}/Annotations`)
            .then((response) => {
                this.hardcopyAnnotations = response.data;
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    addHardcopyNote() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.post(`/Prescription/${id}/AddHardcopyAnnotation`, { Body: this.addNote })
            .then((_response) => {
                this.addNote = "";
                if (this.prescription.imageID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionImageSource(
                        `image/${this.prescription.imageID}`
                    );
                else if (this.prescription.escriptID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionEscriptResponse(
                        this.prescription.escriptID
                    );
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset("Hardcopy Note")
                );
            })
            .catch((err) => {
                console.warn(err);
                this.$notification(
                    NotificationOptions.notificationOptionsPreset(
                        err,
                        NotificationOptions.NotificationTypes.danger
                    )
                );
            })
            .finally(() => {
                this.fetchAnnotations();
            });
    }

    saveAndContinue() {
        this.savePrescription();
    }

    saveHardCopyWithCallback(_billInsuranceCallback: Function) {
        if (this.drug instanceof Drug)
            this.prescription.productNameShort = this.drug.productNameLong;
        this.prescription.quantity = this.prescription.quantity || 0;
        this.prescription.dispensed = this.prescription.dispensed || 0;
        this.assignViewValues();

        Axios.post("/Prescription/", this.prescription, {
            params: { createNewRefill: this.newRefillMode },
        }).then((response) => {
            this.prescription = Object.assign(new Prescription(), response.data);
            this.$notification(
                NotificationOptions.successSaveNotificationPreset(
                    `Prescription ${this.prescription.rxID}`
                )
            );
            this.addHardcopyNote();
        });
    }

    saveAndBill(billInsuranceCallback: Function) {
        if (this.validFormForBilling) {
            this.savePrescription(false, billInsuranceCallback);
        } else {
            billInsuranceCallback(null, true);
            const message =
                "Not possible to save and bill. Please, first fill all required fields." +
                (this.fieldsPending ? "Check the next fields: " + this.fieldsPending : "");
            this.$bvModal.msgBoxOk(message, {
                title: "Not possible to save and bill.",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            });
        }
    }

    saveAndBackToIngestion(finish: boolean, billInsuranceCallback: Function) {
        this.savePrescription(finish, billInsuranceCallback);
    }

    saveAndFinish() {
        if (this.dispenseError?.id) {
            this.$bvModal.show("modal_save_confirmation");
        } else this.savePrescription(true);
    }

    modalSaveAndResolve(_dispenseErrorId: number) {
        if (this.wasResolved) {
            // Mark dispense error as resolved.
            Axios.post(`DispenseError/resolve/${this.dispenseError.id}`)
                .then((response) => {
                    this.dispenseError = Object.assign(new DispenseError(), response.data);
                    console.log("Dispense Error was marked as resolved", this.dispenseError);
                    //this.$notification(NotificationOptions.notificationOptionsPreset("Dispense Error was marked as resolved", NotificationOptions.NotificationTypes.info));

                    this.fetchDispenseError();
                    this.savePrescription(true);
                })
                .catch((error) => {
                    const errorMsg = "Error while resolving dispense error";
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset(
                            errorMsg,
                            NotificationOptions.NotificationTypes.danger
                        )
                    );
                    console.log(errorMsg, { error, response: error?.response });
                })
                .finally(() => {
                    this.$bvModal.hide("modal_save_confirmation");
                });
        } else {
            this.savePrescription();
            this.$bvModal.hide("modal_save_confirmation");
        }
    }

    updateSaveButtonLabel() {
        let firstAction = "";
        let secondAction = "";
        let mainAction = "Save changes";
        if (this.errorNote.body && this.wasResolved) {
            firstAction = ", add note and";
            secondAction = "resolve";
        } else if (this.errorNote.body || this.wasResolved) {
            mainAction += " and ";
            if (this.errorNote.body) secondAction = "add note ";
            if (this.wasResolved) secondAction = "resolve";
        } else {
            this.saveButtonLabel = mainAction;
        }

        this.saveButtonLabel = `${mainAction}${firstAction} ${secondAction}`;
    }

    noteChanged(newNote: string) {
        this.errorNote.body = newNote;
        this.updateSaveButtonLabel();
    }

    @Watch("wasResolved") wasResolvedChanged() {
        this.updateSaveButtonLabel();
    }

    /**
     * finish: If true, it indicates to finish creating new Rx for the same script image coming from the ingestion.
     * billPlanCallback: Function callback sent from InsuranceBillingComponent that allow us to save prescription before billing an insurance.
     * goNextRx: If true, the prescription is saved and the next Rx stored in this.prescriptionsInBatch will be loaded in the form.
     */
    savePrescription(
        finish: boolean = false,
        billPlanCallback: Function | null = null,
        goNextRx: boolean = false
    ) {
        if (!this.prescription) return;

        if (this.drug instanceof Drug)
            this.prescription.productNameShort = this.drug.productNameLong;
        this.prescription.quantity = this.prescription.quantity || 0;
        this.prescription.dispensed = this.prescription.dispensed || 0;
        this.assignViewValues();
        if (this.queueItemID && !this.prescription.rxNumber) {
            this.addLockWithURL(`/IngestionQueue/${this.queueItemID}/lock`, 60000)
                .then((_result) => {
                    this.saveAfterCheck(finish, billPlanCallback, goNextRx);
                })
                .catch((error) => {
                    if (error.response && error.response.status == 418) {
                        console.log(`QueueItem is locked`);
                        console.log(error.response.data);
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(
                            `The QueueItem is locked by ${lockedBy} until ${expires}.`
                        );
                    } else {
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(
                                error,
                                NotificationOptions.NotificationTypes.danger
                            )
                        );
                    }
                });
            //do lock check
            //then do save
        } else {
            this.saveAfterCheck(finish, billPlanCallback, goNextRx);
        }
    }

    saveAfterCheck(
        finish: boolean = false,
        billPlanCallback: Function | null = null,
        goNextRx: boolean = false
    ) {
        if (this.prescription.rxID != "--") {
            let dateNow: Date = new Date();
            let dateFillDate: Date = new Date(this.prescription.fillDate);
            let timeInMilisec: number = dateNow.getTime() - dateFillDate.getTime();
            let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
            if (
                !this.$user.isManager &&
                daysBetweenDates > 15 &&
                this.prescription.fillDate != this.originalFillDate
            ) {
                this.$bvModal.msgBoxOk(`You cannot change the filldate to older than 14days ago.`);
                return;
            }
        }

        Axios.post("/Prescription/", this.prescription, {
            params: { createNewRefill: this.newRefillMode },
        })
            .then((response) => {
                this.prescription = Object.assign(new Prescription(), response.data);
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset(
                        `Prescription ${this.prescription.rxID}`
                    )
                );

                new Promise((resolve, reject) => {
                    /**
                     * When on a Ingestion, the route will be replaced by the newly created RX
                     * and will push a new URL on the browsing history for every new RX created
                     * from the same image.
                     */
                    if (!this.isBatchMode && this.fromIngestion) {
                        const currentRoute = this.$route;
                        const route = {
                            name: "PrescriptionDetails",
                            params: {
                                storeID: this.prescription.storeID.toString(),
                                rxNumber: this.prescription.rxNumber.toString(),
                                rfNumber: this.prescription.rfNumber?.toString() ?? "",
                            },
                        };
                        if (currentRoute.query.queueItemID) this.$router.replace(route);
                        else if (currentRoute.name == "PrescriptionDetails")
                            this.$router.push(route);
                    }

                    if (
                        this.queueItemID &&
                        this.prescription &&
                        this.prescription.storeID &&
                        this.prescription.rxNumber &&
                        (this.prescription.rfNumber || 0) >= 0
                    ) {
                        const queueItem: QueueItem = {
                            id: +this.queueItemID,
                            storeID: +this.prescription.storeID,
                            rxNumber: +this.prescription.rxNumber,
                            rfNumber: +(this.prescription.rfNumber || 0),
                        } as QueueItem;

                        // Complete ingestion
                        Axios.post(`/IngestionQueue/`, queueItem)
                            .then((response) => {
                                console.log(`IngestionQueue marking complete:`, response);
                                //this.$notification(NotificationOptions.notificationOptionsPreset("Ingestion was completed", NotificationOptions.NotificationTypes.success));
                                resolve(response);
                            })
                            .catch((error) => {
                                const errorMsg = `IngestionQueue marking complete error:`;
                                console.error(errorMsg, { error, response: error?.response });
                                this.$notification(
                                    NotificationOptions.notificationOptionsPreset(
                                        errorMsg,
                                        NotificationOptions.NotificationTypes.danger
                                    )
                                );
                                reject(error);
                            });
                    } else {
                        if (this.routeRxId && finish) {
                            // If this page was shown by clicking a details button from another page, we go back to the previous page.
                            this.backToPreviousPage();
                        } else {
                            console.error(
                                "Something went wrong while identifying the case of saving",
                                {
                                    rxid: this.prescription.rxID,
                                    fromIngestion: this.fromIngestion,
                                    queueItemID: this.queueItemID,
                                    routeRxId: this.routeRxId,
                                    finish,
                                }
                            );
                            reject();
                        }
                    }
                }).then((_res) => {
                    if (finish) {
                        if (this.$store.queueItems?.length ?? 0 > 0) {
                            const nextItem = this.$store.queueItems?.pop();
                            this.clearForm();
                            this.$router.replace({
                                name: "Prescription",
                                query: {
                                    imageID: `${nextItem?.imageID}`,
                                    escriptID: `${nextItem?.escriptID}`,
                                    queueItemID: `${nextItem?.id}`,
                                    programTransfer: `${nextItem?.programTransferId}`,
                                },
                            });
                            this.create();
                            this.mount();
                            return;
                        }
                        // when we clear we need to clear the imageID query if it exists.
                        this.clearQuery();

                        // Back to ingestion queue screen
                        this.backToPreviousPage();
                    } else if (!finish) {
                        //Save and continue with the same image
                        if (this.fromIngestion && !billPlanCallback) {
                            //When "Saving and New", We should keep the Store, patient and doctor information same.
                            const continueSameImage = true;
                            this.clearForm(continueSameImage);
                            this.mount();

                            this.prescription.storeID = Number(this.store.id);
                            this.prescription.patientID = Number(this.patient.id);
                            this.prescription.prescriberID = Number(this.doctor.id);

                            this.$notification(
                                NotificationOptions.notificationOptionsPreset(
                                    "You can start introducing information for the new RX with the same image.",
                                    NotificationOptions.NotificationTypes.info
                                )
                            );
                        }
                    }
                });

                //Prevent redirection to previous page after submitting answer to custom questions:
                // - Saving RX was triggered by billing component
                // - Finish is false which means we continue with the same RX image
                // - If we are in a refill batch and we need to go to the next RX.
                const preventRedirection = !!billPlanCallback || !finish || goNextRx;
                this.submitQuestionaries(this.prescription, preventRedirection);

                //If Next button was clicked
                if (goNextRx) {
                    if (this.willAutorefill) {
                        this.$emit("batchFinished", this.prescription);
                    }
                    this.nextRx();
                    return;
                }

                this.newMode = false;
                this.newRefillMode = false;

                if (billPlanCallback) {
                    billPlanCallback(this.prescription);
                    return;
                }

                if (this.addNote.length > 0) this.addHardcopyNote();
            })
            .catch((error: AxiosError) => {
                if (billPlanCallback) billPlanCallback(null, true);
                this.$notification(
                    NotificationOptions.errorSaveNotificationPreset("Prescription", error)
                );
            });
    }

    clearQuery() {
        // when we clear we need to clear the imageID query if it exists.
        if (Object.keys(this.$route.query).length) {
            this.$router.replace({
                name: this.$route.name,
                query: {},
            } as Location);
        }
    }

    backToPatientDashboard() {
        if (this.routeRxId)
            this.$router.push({
                name: "PatientDashboard",
                params: { id: this.prescription?.patientID.toString() || "" },
            });
    }

    onPatientCreated(newPatient: Patient) {
        this.editPatientForm = false;
        this.showPatientForm = false;
        this.patient = newPatient;
        this.$bvModal.hide("create-patient");
    }

    onDoctorCreated(newDoctor: Prescriber) {
        this.showDoctorForm = false;
        this.editDoctorForm = false;
        this.doctor = newDoctor;
        this.$bvModal.hide("create-doctor");
    }

    showPrescriptionForm(close?: string) {
        switch (close) {
            case "hidePatientForm":
                this.editPatientForm = false;
                this.showPatientForm = false;
                this.patient = new Patient();
                break;
            case "hideDoctorForm":
                this.showDoctorForm = false;
                this.editDoctorForm = false;
                this.doctor = new Prescriber();
                break;
        }
        this.showDocuStore = false;
    }

    replaceRouterParamsWithCurrentPrescription() {
        if (this.routeRxId == null) return;

        if (!isNaN(Number(this.$route.params.storeID))) {
            this.$router.replace({
                name: "PrescriptionDetails",
                params: {
                    rfNumber: this.prescription.rfNumber?.toString() ?? "",
                    rxNumber: this.prescription.rxNumber?.toString() ?? "",
                    storeID: this.prescription.storeID.toString(),
                },
            });
            window.location.reload();
            return;
        }
        if (this.$route.params.rxid) {
            this.$router.replace({
                name: "PrescriptionDetails",
                params: { rxid: this.prescription.rxID },
            });
            window.location.reload();
            return;
        }
    }

    transferRx() {
        console.log("route: ", this.$route);
        Axios.post(`/Store/${this.newStore?.id}/transfer`, this.prescription)
            .then((response) => {
                this.prescription = Object.assign(new Prescription(), response.data);

                this.$notification(
                    NotificationOptions.successSaveNotificationPreset("Prescription Transferred")
                );

                this.replaceRouterParamsWithCurrentPrescription();
            })
            .catch((error: AxiosError) => {
                if (error) {
                    this.$notification(
                        NotificationOptions.errorSaveNotificationPreset("Internal Transfer", error)
                    );
                    console.error("Internal Transfer - ", { error, response: error?.response });
                }
            });
        this.$bvModal.hide("transfer-rx");
    }

    transferRxByFax() {
        this.loadingTransferFax = true;
        this.transferTo.pharmacist = this.pharmacistDetail != null ? `${this.pharmacistDetail.lastName}, ${this.pharmacistDetail.firstName}` : this.pharmacyDetail.pharmacist;
        this.transferTo.patientPlanIds = this.faxPlans.filter(v => v.include).map(p => +p.patientPlan.id);
        Axios.post(`/Prescription/${this.prescription.rxID}/external-transfer`, this.transferTo)
            .then((response) => {
                this.prescription = Object.assign(new Prescription(), response.data);
                this.fetchPrescription(this.prescription);
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset("Prescription transferred")
                );
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status === 403) {
                    this.$notification(NotificationOptions.error("You need to be a pharmacist to perform this action."));
                } else {
                    this.$notification(NotificationOptions.error(error));
                }
            })
            .finally(() => {
                this.$bvModal.hide("transfer-rx-by-fax");
                this.loadingTransferFax = false;
            });
    }

    equivalentDrugSelected(packageID: number) {
        // Show Modal selector for the objects
        this.loadingDrugDetails = true;
        this.drugDetails = null;
        this.drug = new Drug(packageID);

        this.fetchDrug(this.drug);
    }

    save() {
        if (this.isBatchMode) this.saveAndNext();
        else this.saveAndFinish();
    }

    cancel() {
        if (this.isBatchMode) this.nextRx();
        else this.backToPreviousPage();
    }

    //**BATCH MODE FUNCTIONS */
    saveAndNext() {
        this.savePrescription(true, null, true);
    }

    nextRx() {
        const thisRx = this.prescription;
        if (this.batchIndex >= 0) {
            this.clearForm();
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    `Next Rx in batch...`,
                    NotificationOptions.NotificationTypes.info
                )
            );
        }

        if (!this.isBatchOver) {
            this.batchIndex++;
            const nextRxId = Object.assign(
                new Prescription(),
                this.prescriptionsInBatch[this.batchIndex]
            ).rxID;
            this.getPrescription(nextRxId, this.refillBatchMode);
            this.scrollToBeginning();

            if (this.isBatchOver) {
                this.buttonTitles.save = "Save and Finish";
                this.buttonTitles.cancel = "Finish";
            }
        } else {
            this.$notification(
                NotificationOptions.notificationOptionsPreset(
                    `Batch was finished.`,
                    NotificationOptions.NotificationTypes.info
                )
            );
            this.$emit("batchFinished", thisRx);
        }
    }

    scrollToBeginning() {
        const switchId = "rx-modal___BV_modal_header_";
        const switch_dom = document.getElementById(switchId);
        if (switch_dom) switch_dom.scrollIntoView({ block: "start" });
    }

    //**CUSTOM QUESTIONS METHODS*/
    fetchDrugCategory(productID: number | string = 0) {
        if (!(this.drug?.drugId || productID)) return;

        productID = productID || this.drug?.drugId;
        Axios.get<Array<DrugCategory>>(`Drug/${productID}/${this.drug.source}/categories`)
            .then((response) => {
                this.drugCategories = response.data.map((r) =>
                    Object.assign(new DrugCategory(), r)
                );
            })
            .catch((error) => {
                console.error("Error while getting drug categories", {
                    error,
                    response: error?.response,
                });
            });
    }

    questionarySaved(categoryId: number, preventRedirection: boolean = false) {
        if (!(this.globalPreventRedirection || preventRedirection)) {
            this.globalPreventRedirection = true;
            this.backToPreviousPage();
        }
    }

    redrawEscript() {
        Axios.get(`Escript/Redraw/${this.escriptID}`)
            .then((resp) => {
                console.log("escript Redraw response: ", resp);
                window.location.reload();
            })
            .catch((_err) => {
            });
    }

    submitQuestionaries(rx: Prescription, preventRedirection: boolean = false) {
        const refs = this.$refs;
        this.drugCategories?.forEach((cat) => {
            const comp = (refs[`questionary_${cat.id}`] as Array<any>)[0] as QuestionaryComponent;
            if (comp.save) comp.save(rx, preventRedirection);
        });
    }

    //Patient Notes
    refreshPatientNotes(_newNote: Note) {
        if (this.$refs.patientNotesList instanceof NotesList)
            this.$refs.patientNotesList.loadNotes();
    }

    showRxHistory() {
        const modal = this.rxHistoryModal;
        if (modal) modal.openRxHistory();
        else console.error("Reference dont exist for the component", modal);
    }

    @Watch("prescription")
    prescriptionUpdated(rx: Prescription) {
        console.log("this.prescription is now: ", rx);
        if (
            rx.programName?.includes("COVID") &&
            rx.updatedBy == "PatientWeb" &&
            this.notAllowNewRefill &&
            this.refillBatchMode
        ) {
            console.log(
                "prescription's programName includes 'COVID' and was updated by PatientWeb. Lets skip it."
            );
            this.nextRx();
        }
    }

    @Watch("expiration")
    expirationDateUpdate(val: Date, _oldVal: Date) {
        // noinspection PointlessBooleanExpressionJS,SuspiciousTypeOfGuard
        if (!(val instanceof Date) || isNaN(val as any)) return;
        this.validationClasses.expirationDate = val ? "has-success" : "has-danger";
    }

    @Watch("prescription.fillDate")
    @Watch("prescription.expirationDate")
    fillDateUpdate(val: Date, oldVal: Date) {
        console.log("rx FillDate Change: ", oldVal, val);

        const myMoment = moment;

        //let valid = !!val && moment(val).isSameOrBefore(this.expiration);
        let valid = !!val && myMoment(val).isSameOrBefore(this.expiration);
        this.validationClasses.fillDate = valid ? "has-success" : "has-danger";
    }

    @Watch("prescription.daySupply")
    daySupplyUpdate(val: number, _oldVal: number) {
        this.validationClasses.daySupply = val ? "has-success" : "has-danger";
    }

    @Watch("dispensedQuantity")
    dispenseUpdate(val: number) {
        this.validationClasses.dispense = val ? "has-success" : "has-danger";
    }

    @Watch("prescription.quantity")
    quantityUpdate(val: number) {
        this.validationClasses.quantity = val ? "has-success" : "has-danger";
    }

    fetchFollowUpCases() {
        this.doctorFollowupAlerts?.fetchFollowUpCases();
    }

    startSurescriptsElectronicPriorAuthorization() {
        Axios.post("PriorAuthorization/Create/Surescripts", this.prescription)
            .then((resp) => {
                console.log(resp);
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset(
                        "Electronic Prior Authorization"
                    )
                );
            })
            .catch((err) => {
                this.$notification(
                    NotificationOptions.errorSaveNotificationPreset(
                        "Electronic Prior Authorization",
                        err
                    )
                );
            });
    }

    startSurescriptsElectronicPriorAuthorizationExpedited() {
        Axios.post("PriorAuthorization/Create/Surescripts/Expedited", this.prescription)
            .then((resp) => {
                console.log(resp);
                this.$notification(
                    NotificationOptions.successSaveNotificationPreset(
                        "Electronic Prior Authorization"
                    )
                );
            })
            .catch((err) => {
                this.$notification(
                    NotificationOptions.errorSaveNotificationPreset(
                        "Electronic Prior Authorization",
                        err
                    )
                );
            });
    }

    onPharmacyChange(value: PharmacyRecord) {
        if (this.pharmacyDetail.id) {
            Axios.get<PharmacyRecord>(`/StoredPharmacy/${this.pharmacyDetail.id}`).then(
                (response) => {
                    this.pharmacyDetail = new PharmacyRecord(response.data);
                    this.transferTo.name = this.pharmacyDetail.name;
                    this.transferTo.address = this.pharmacyDetail.address;
                    this.transferTo.city = this.pharmacyDetail.addressCity;
                    this.transferTo.state = this.pharmacyDetail.addressState;
                    this.transferTo.zip = this.pharmacyDetail.addressZip;
                    this.transferTo.phone = this.pharmacyDetail.phoneNumber;
                    this.transferTo.fax = this.pharmacyDetail.faxNumber;
                    this.transferTo.dea = this.pharmacyDetail.dea;
                    this.transferTo.pharmacist = this.pharmacistDetail != null ? `${this.pharmacistDetail.lastName}, ${this.pharmacistDetail.firstName}` : this.pharmacyDetail.pharmacist;
                    this.transferTo.storedPharmacyId = this.pharmacyDetail.id;
                }
            );
        }
    }

    newStoredPharma() {
        this.pharmacyDetail = new PharmacyRecord();
        this.$bvModal.show("addNewPharmacy");
    }

    newStoredPharmacist() {
        this.pharmacistDetail = new PharmacistRecord();
        this.$bvModal.show("addNewPharmacist");
    }

    onPharmacyCreate(newPharma: PharmacyRecord) {
        this.pharmacyDetail = newPharma;
        this.$bvModal.hide("addNewPharmacy");
    }

    onPharmacistCreate(newPharmacist: PharmacistRecord) {
        this.pharmacistDetail = newPharmacist;
        this.$bvModal.hide("addNewPharmacist");
    }

    async combinedPlans(ctx: BvTableCtxObject) {
        try {
            type PlanResponse = { plans: Plan[]; patientPlans: PatientPlan[]; };
            const response = await Axios.get<PlanResponse>(`/api/Insurance/${this.patient.id}`);
            let combinedPlans = response.data.patientPlans.filter(pp => pp.active)
                .map(pp => {
                    let combinedPlan: any = new IncludeCombinedPlan({
                        patientPlan: new PatientPlan(pp),
                        plan: new Plan(response.data.plans.find(p => p.id == pp.planId)),
                        include: false
                    } as IncludeCombinedPlan);
                    return combinedPlan;
                });
            this.faxPlans = combinedPlans;
            return this.faxPlans;
        } catch (err) {
            return [];
        }

    }

}
