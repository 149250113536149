
import TWTwainJS, {
    TWEvent,
    ScanSettings,
    TWScanFinishedStatus,
    ScannedFileSettings,
    twConvertBinaryStringToUint8Array
} from "../TWTwainJS";
import { CapEnum } from "../TWTwainJSCapabilities";
import { default as axios } from "axios";

export class Scanner {
    fromPort = 50000;
    toPort = 65535;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor(uploadCompleteCallback = () => { }, uploadErrorCallback = () => { }) {
        this.twTwainJS = new TWTwainJS("twain1", "Scriptly", "g/4JFMjn6KtYflPFhE2NynFSLkQQTORrwTd5kGeebSZOGsluvivvEIgDGySPGwRFWHo0kfvJ/gE0muw4DObMOVctaGVjKpg3/IissS9+1bMCbWWtNsE91IZJN74xsof+v/SCVwl6IsFJhAGZ8/uCCXrDCCG5diFG");

        this.scannedFileSettings = new ScannedFileSettings();
        this.scannedFileSettings.scanFormatType = CapEnum.TWScanFormatType.PDF;
        this.scannedFileSettings.fileName = "scan.pdf";
        this.scannedFileSettings.isMultiPageFile = true;
        this.scannedFileSettings.imageQuality = 90;

        window.addEventListener(TWEvent.SINGLE_FILE_RETRIEVED, (e) => { this.uploadFile(e, uploadCompleteCallback, uploadErrorCallback); });

        this.uploadCompleteCallback = uploadCompleteCallback;
    }

    Scan(deviceName) {
        this.scanSettings = new ScanSettings(deviceName)
        this.scanSettings.scanFormatType = CapEnum.TWScanFormatType.PDF;
        this.scanSettings.imageQuality = 90;
        this.scanSettings.showUI = false;
        this.scanSettings.isMultiPageFile = true;
        this.twTwainJS.connectToClient(this.fromPort, this.toPort).then(() => {
            this.twTwainJS.scan(this.scanSettings).then((result) => {
                if (result.scanFinishedStatus === TWScanFinishedStatus.SUCCESS) {
                    this.twTwainJS.getFile(this.scannedFileSettings)
                }
            })
                .catch((error) => {
                    console.log("scan error", error);
                });
        })
            .catch((error) => {
                console.log("connectToClient promise error:", error);
            })
    }

    getAvailableScanners() {
        return this.twTwainJS.connectToClient(this.fromPort, this.toPort).then(() => {
            return this.twTwainJS.getAvailableDevices().then(result => {
                return result.devicesName;
            });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    uploadFile(e, uploadCompleteCallback = () => { }, uploadErrorCallback = (e) => { }) {
        console.log(TWEvent.FILE_RETRIEVED + "[" + e.detail.twTwainJSInstance.name + "]", e.detail);
        const apiUrl = `${axios.defaults.baseURL}/image`; //URL for upload screen of local scriptly server goes here
        const getresult = e.detail;
        const formData = new FormData();
        const content = twConvertBinaryStringToUint8Array(getresult.fileContent);
        const fileName = getresult.fileName;
        const blob = new Blob(content, { type: "application/pdf" });
        const file = new File([blob], fileName, { type: "application/pdf" });
        formData.append("images", file);
        axios.post(apiUrl, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
                uploadCompleteCallback();
            })
            .catch(function (error) {
                console.log("Axios post upload. Error!", error);
                uploadErrorCallback(error);
            });

    }
}
window.addEventListener(TWEvent.TW_ERROR, function (e) {
    console.log(TWEvent.TW_ERROR + "[" + e.detail.twTwainJSInstance.name + "]", e.detail);
    // e.detail.error
});

window.addEventListener(TWEvent.DEVICE_LIST_RETRIEVED, function (e) {
    console.log(TWEvent.DEVICE_LIST_RETRIEVED + "[" + e.detail.twTwainJSInstance.name + "]", e.detail);
    console.log("Default device:", e.detail.defaultDeviceName, "Devices:", e.detail.devicesName)
});

window.addEventListener(TWEvent.DEVICE_INFO_RETRIEVED, function (e) {
    console.log(TWEvent.DEVICE_INFO_RETRIEVED + "[" + e.detail.twTwainJSInstance.name + "]", e.detail);
});
