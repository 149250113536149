import HasID from "./HasID";
import { Prescription } from "./Prescription";
import TrackedObject from "./TrackedObject";
import moment from "moment";

export interface AutoRefillSubscription extends HasID, TrackedObject {
    patientId : number
    storeId : number
    rxNumber : number
    cardId : string | null

    nextProcessDate : Date | null
    cancellationDate : Date | null
    expirationDate : Date | null
    nextRfNumber : number | null

    storeName : string
}

export class AutoRefillSubscription {    
    constructor(obj?: AutoRefillSubscription){
        this.cancellationDate = null;
        this.nextProcessDate = null;
        this.cardId = null;

        if(obj) Object.assign(this, obj);
    }

    setPrescription(rx: Prescription):void {
        this.patientId = rx.patientID;
        this.storeId = rx.storeID;
        this.rxNumber = rx.rxNumber;
        this.nextRfNumber = (rx.rfNumber || 0) + 1 || 1;

        //Calculate next process date if valid, else it is set to max possible date.
        this.nextProcessDate = moment(rx.fillDate).add(rx.daySupply, 'day').toDate();
        if(AutoRefillSubscription.isProcessDateInvalid(this.nextProcessDate, rx.expirationDate, this.expirationDate))
            this.nextProcessDate = moment().add(1,'day').toDate();
    }

    static isProcessDateInvalid(date:Date, rxExpirationDate: Date, subExpirationDate:Date|null):boolean {
        const mDate = moment(date);
        const today = moment().startOf('day').toDate();
        const lowerRangeInvalid = mDate.isSameOrBefore(today);
        
        const maxDate = moment(rxExpirationDate).isBefore(subExpirationDate, 'day') ? rxExpirationDate : subExpirationDate;
        
        let upperRangeInvalid = false;
        if(maxDate)
            upperRangeInvalid = mDate.isSameOrAfter(moment(maxDate).add(-7, 'days'));
            
        return lowerRangeInvalid || upperRangeInvalid;
    }

    get StoreRx():string {
        return `${this.storeId}-${this.rxNumber}`
    }

    get isExpired():boolean {
        const today = moment().startOf('day').toDate();
        return moment(today).isSameOrAfter(this.expirationDate);
    }
}

