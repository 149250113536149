
    import { CaseNote, CaseDetail } from '@/models/Case';
    import { Prescription } from '@/models/Prescription';
    import { Note, EntityType } from '@/models/Note';
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Table, TableColumn } from 'element-ui';
    // @ts-ignore
    import { Radio } from '@/components/index';
    import NotesList from '@/components/Note/NotesList.vue';
    import axios from 'axios';

    @Component({
        name: "CaseDetailComponent",
        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Radio.name]: Radio,
            NotesList
        }
    })
    export default class CaseDetailComponent extends Vue {

        @Prop() private caseSelected!: CaseDetail;
        @Prop() private show!: string;
        @Prop() private editable!: boolean;

        private prescriptions: Prescription[] = [];
        private notes: Note[] = [];
        private shipments: any[] = [];
        private isResolved = 'false';
        private carePointsError = 'false';
        private option = 'accept';
        private newNote = '';

        private caseType = EntityType.Case;

        created() {
            this.getCaseDetail();
        }

        @Watch('caseSelected')
        getCaseDetail() {
            if (this.caseSelected.id) {
                this.carePointsError = this.caseSelected.error ? 'true' : 'false';
                this.isResolved = 'false';
                this.newNote = '';
                this.option = 'reject';
                axios.get(`/Case/${this.caseSelected.id}/Prescriptions`)
                    .then(response => {
                        this.prescriptions = response.data;
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            }
        }

        updateCase() {
            if (this.editable) {
                let newCaseNote: CaseNote | any = {
                    id: this.caseSelected.id,
                    problem: this.caseSelected.problem,
                    note: this.newNote,
                    error: this.carePointsError == 'true'
                }

                if (this.show == 'opened') {
                    newCaseNote.resolvedBy = this.isResolved;
                    newCaseNote.acceptedBy = 'false';
                }
                else {
                    newCaseNote.resolvedBy = this.option == 'accept' ? 'true' : 'false';
                    newCaseNote.acceptedBy = this.option == 'accept' ? 'true' : 'false';
                }

                this.caseSelected.error = newCaseNote.error;
                this.caseSelected.resolved = newCaseNote.resolved;
                axios.post('/Case/', {
                    caseProblem: newCaseNote,
                    prescriptions: []
                })
                    .then(response => {
                        this.newNote = '';
                        this.isResolved = 'false';
                        this.$emit('updateCase');
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            }
        }
    }
