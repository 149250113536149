
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
    import { debounce } from 'throttle-debounce';
    import axios from 'axios';
    import { ReportExporter } from '../../mixins/ReportExporter';

    @Component({
        name: "InventoryReportPage",
        components: { Checkbox }
    })
    export default class InventoryReportPage extends Mixins(ReportExporter) {

        private clear = false;
        private store: any = null;
        private stores: any[] = [];
        private program: any = null;
        private programs: any[] = [];
        private deaSelection: boolean[] = [true, true, true, true, true, true];
        private sortBy: any = null;
        private reportItems: any[] = [];
        private reportFields: any[] = [];

        created() {
            console.log("%c Created InventoryReportPage", "color: green;");
        }

        beforeDestroy() {
            console.log('%c Destroying InventoryReportPage', 'color: red');
        }

        storeLabel(option: any) {
            return option.name;
        }

        programLabel(option: any) {
            return option.name;
        }

        private throttledSearchStore: Function = debounce(500, (loading, searchValue) => { this.onSearchStore(loading, searchValue) });

        searchStore(value: string, loading: any) {
            if (!value) return;
            this.throttledSearchStore(loading, value);
        }

        onSearchStore(loading: any, searchValue: string) {
            if (!searchValue.length) return;
            loading(true);
            axios.get(`/Store/`, { params: { searchTerm: searchValue } })
                .then(response => {
                    this.stores = response.data;
                })
                .catch(error => {
                    console.warn(error);
                })
                .finally(() => {
                    loading(false);
                });
        }

        private throttledSearchProgram: Function = debounce(500, (loading, searchValue) => { this.onSearchProgram(loading, searchValue) });

        searchProgram(value: string, loading: any) {
            if (!value) return;
            this.throttledSearchProgram(loading, value);
        }

        onSearchProgram(loading: any, searchValue: string) {
            if (!searchValue.length) return;
            loading(true);
            axios.get(`/Program/consigned`, { params: { searchTerm: searchValue } })
                .then(response => {
                    this.programs = response.data;
                })
                .catch(error => {
                    console.warn(error);
                })
                .finally(() => {
                    loading(false);
                });
        }

        @Watch('store')
        @Watch('program')
        @Watch('deaSelection')
        getReport() {
            if (this.clear) return;
            const config = {
                params: {
                    storeId: this.store ? this.store.id : 0,
                    programId: this.program ? this.program.id : 0,
                    deaClasses: this.deaSelection.map(selection => selection ? 1 : 0).join()
                }
            }

            const reportPath = `/inventory/report`;
            axios.get(reportPath, config)
                .then(response => {
                    this.reportItems = response.data;
                    this.reportFields = [
                        { label: "NDC", key: "ndc" },
                        { label: "Drug Name", key: "drugName" },
                        { label: "Quantity", key: "quantity" },
                        { label: "Allocated", key: "allocated" },
                        { label: "Unit of Measure", key: "billingUnit" },
                        { label: "DEA Schedule", key: "deaClassId" },
                        { label: "Store", key: "storeName" },
                        { label: "Program", key: "programName" }
                    ];
                    this.sortBy = this.reportFields[0].key;//"NDC";

                    const deaSelectionEmpty = !this.deaSelection.includes(true);
                    if (deaSelectionEmpty)
                        for (let i = 0; i < 6; i++)
                            this.deaSelection[i] = true;

                })
                .catch(error => console.warn(error));
        }

        savePDF() {
            this.ExportPDF("Inventory Report", "#report-table")
        }
        saveCSV() {
            this.ExportCSV("Inventory Report", this.reportItems, this.reportFields);
        }

        clearClicked() {
            this.clear = true;
            this.store = {};
            this.program = {};
            this.stores = [];
            this.programs = [];
            this.reportFields = [];
            this.reportItems = [];
        }
    }
