
    import Vue from 'vue'
    import { Component, Emit, Prop, Ref, Watch, Mixins } from 'vue-property-decorator';
    import Axios from 'axios';
    import moment from 'moment';
    import SearchComponent from '@/components/SearchComponent.vue';
    import { Store } from "@/models/Store"
    import { Patient } from "@/models/Patient";
    import { Prescription } from "@/models/Prescription";
    import { Payment } from "@/models/Payment";
    import { Order } from "@/models/Order";
    import { Program } from "@/models/Program";
    import { LockHandler, Lock } from '@/mixins/LockHandler';



    @Component({
        name: "PatientWebPartialPaymentQueue",
        components: {
            SearchComponent,
        },
    })

    export default class PatientWebPartialPaymentQueue extends Mixins(LockHandler) {
        private partialPaymentQueue: Order[] = [];
        private storeFilter: Store = {} as Store;
        private programFilter: Program | null = null;
        private fields = [
            { key: "id", label: "Order ID", sortable: true },
            { key: 'patient', label: 'Patient Name', formatter: this.patientName, sortable: true },
            { key: 'patient.dateOfBirth', label: 'Date of Birth', formatter: this.convertDate, sortable: true },
            { key: 'program', label: 'Program', sortable: true },
            { key: 'created', label: 'Order Date', formatter: this.convertDate, sortable: true },
            { key: 'prescriptions', label: 'Total Copay', formatter: this.totalCopay, sortable: true },
            { key: 'payments', label: 'Payment Total', formatter: this.totalPayment, sortable: true }
        ];
        private rxFields = [
            { key: 'rxNbr', label: 'Rx #' },
            { key: 'rfNbr', label: 'Rf #' },
            { key: 'drugName', label: 'Drug Name' },
            { key: 'amount', formatter: this.convertAmount }
        ];
        private addonFields = [
            { key: 'productName', label: 'Product' },
            { key: 'productAmount', label: 'Price', formatter: this.convertAmount },
            { key: 'taxAmount', label: 'Tax', formatter: this.convertAmount }
        ];
        private rxForBatch: Prescription[] = [];



        created() {
            Axios.get(`/Store/`).then(response => {
                this.storeFilter = response.data[0];
            }).then(() => {
                this.getUnprocessedOnlineRefills();
            })
        }

        getProgramName(item: Order) {
            let programNames = item?.prescriptions?.filter(i => !!i.programName)?.map(i => i.programName) ?? [];
            if (programNames.length == 0) return '';
            return programNames[0];
        }

        @Watch('storeFilter')
        @Watch('programFilter')
        getUnprocessedOnlineRefills() {
            Axios.get<Array<Order>>(`/Order/partial-payment-queue`).then(response => {
                this.partialPaymentQueue = response.data.map(o => new Order(o));
                if (this.storeFilter) {
                    this.partialPaymentQueue = this.partialPaymentQueue.filter((order => order.storeID == this.storeFilter.id));
                }
                if (this.programFilter) {
                    console.log("programFilter =", this.programFilter);
                    console.log("partialPaymentQueue = ", this.partialPaymentQueue);
                    this.partialPaymentQueue = this.partialPaymentQueue.filter((order => this.getProgramName(order) == this.programFilter!.name));
                }
            });

        }

        hoverRow() {
            return "hoverRow";
        }

        rowClicked(object: Order) {
            return window.location.href = "/cphub/patient-dashboard/" + object.patientID;
        }

        convertDate(date: string) {
            return moment.utc(date).format("L");
        }

        convertAmount(amt: number) {
            return '$' + amt.toFixed(2);
        }

        searchLabel(object: Store) {
            if (object?.id)
                return object.name;
            else '';
        }

        patientName(object: Patient) {
            return object.firstName + " " + object.lastName;
        }

        programName(object: Prescription[]) {
            if (object.length > 0)  {
            return object[0].programName;
            } else {
                return "Unknown";
            }
        }

        totalCopay(object: Prescription[]) {
            var totalCopay = 0;

            object.forEach(p => {
                totalCopay += p.patientPay;
            });

            return '$' + totalCopay.toFixed(2);
        }

        totalPayment(object: Payment[]) {
            var totalPayment = 0;

            object.forEach(p => {
                totalPayment += p.authorizedAmount;
            });

            return '$' + totalPayment.toFixed(2);
        }

    }
