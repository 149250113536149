export interface User {
    userName: string;
    isAdmin: boolean;
    isManager: boolean;
    isPharmacist: boolean;
    isPatientsManager: boolean;
    isInventoryManager: boolean;
    isReadOnly: boolean;
    isCompoundManager: boolean;
    isPapApproval: boolean;
}

export class User {

}
