
import axios from 'axios';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons, {NewClearSaveTitles} from '@/components/NewClearSaveButtons.vue';
import {PharmacyRecord} from "@/models/Pharmacy"
import {PharmacistRecord} from "@/models/Pharmacist";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import PharmacistDetailPage from "@/pages/Pharmacist/PharmacistDetailPage.vue";

@Component({
    name: 'PharmacyDetailPage',
    components: {
        PharmacistDetailPage,
        SearchComponent,
        NewClearSaveButtons,
        Checkbox
    }
})


export default class PharmacyDetailPage extends Vue {

    protected pharmacists: PharmacistRecord[] = [];
    private pharmacyDetail: PharmacyRecord = new PharmacyRecord();
    private pharmacistDetail: PharmacistRecord = new PharmacistRecord();

    private pharmacistsFields = [
        "id",
        "firstName",
        "lastName",
        "isActive",
    ];

    @Prop({type: Number, default: 0}) private pharmacyId!: number;
    @Prop({type: Boolean, default: false}) private newMode!: boolean;

    onPharmacyChange(_value: PharmacyRecord) {

        if (this.pharmacyDetail.id) {

            axios.get<PharmacyRecord>(`/StoredPharmacy/${this.pharmacyDetail.id}`)
                .then(pbmResponse => {
                    this.pharmacyDetail = new PharmacyRecord(pbmResponse.data);

                });

            //  LOAD ALL THE PHARMACISTS FOR A GIVEN PHARMACY AFTER IT HAS BEEN SELECTED
            this.loadPharmacists();
        }
        this.loadPharmacists();
    }

    //  THIS GETS ALL OF THE STORED PHARMACISTS ASSOCIATED WITH A SPECIFIC PHARMACY
    async loadPharmacists() {
        axios.get<PharmacistRecord[]>(`/StoredPharmacist/GetAllPharmacistsByStore/${this.pharmacyDetail.id}`).then((response) => {
            this.pharmacists = response.data;
        }).catch((error) => {
            console.error("Problem loading Pharmacists by Pharmacy", {error, response: error?.response});
        });
    }

    get disableFields(): boolean {
        return (this.pharmacyDetail?.id || 0) == 0 && !this.newMode;
    }

    get isFormValid() {
        return this.pharmacyDetail.name && this.pharmacyDetail.faxNumber && this.pharmacyDetail.phoneNumber && this.pharmacyDetail.dea
    }

    protected buttonTitles: NewClearSaveTitles = {
        new: "New Pharmacy",
        clear: "Clear",
        save: "Save Changes",
        cancel: "Cancel",
    };

    createStoredPharma() {
        this.newMode = true;
        this.pharmacyDetail = new PharmacyRecord();
    }

    clearForm() {
        this.newMode = false;
        this.pharmacyDetail = new PharmacyRecord();
    }

    saveStoredPharmacy() {
        if (this.pharmacyDetail?.id as number) this.pharmacyDetail.id = this.pharmacyDetail.id as number;
        else this.pharmacyDetail.id = null as unknown as number;
        axios.post<PharmacyRecord>('/StoredPharmacy', this.pharmacyDetail)
            .then(response => {
                if (response.data.id) {
                    this.pharmacyDetail = Object.assign(new PharmacyRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacy"));
                    this.$emit('created', this.pharmacyDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacy", error));
            });
    }

    saveStoredPharmacist() {
        this.pharmacistDetail.pharmacyId = this.pharmacyId;
        if (this.pharmacistDetail?.id as number) this.pharmacistDetail.id = this.pharmacistDetail.id as number;
        else this.pharmacistDetail.id = null as unknown as number;

        axios.post<PharmacistRecord>('/SavePharmacist', this.pharmacistDetail)
            .then(response => {

                if (response.data.id) {
                    this.loadPharmacists();
                    this.pharmacistDetail = Object.assign(new PharmacistRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacist"));
                    this.$emit('created', this.pharmacistDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacist", error));
            });
    }

    onPharmacistCreate(newPharmacist: PharmacistRecord) {
        this.pharmacistDetail = newPharmacist;
        this.$bvModal.hide("addNewPharmacist");
    }

    updateActive(pharmacist: PharmacistRecord) {
        console.log("pharmacist: ", pharmacist);
        axios.post<PharmacistRecord>('/StoredPharmacist', pharmacist)
            .then(response => {

                if (response.data.id) {
                    this.loadPharmacists();
                    this.pharmacistDetail = Object.assign(new PharmacistRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacist"));
                    this.$emit('created', this.pharmacistDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacist", error));
            });
    }

    newStoredPharmacist() {
        this.pharmacistDetail = new PharmacistRecord();
        this.pharmacistDetail.isActive = true;
        this.$bvModal.show("addNewPharmacist");
    }


}
