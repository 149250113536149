import HasID from "@/models/HasID";
import TrackedObject from "@/models/TrackedObject";
import { Patient } from "@/models/Patient";

export interface PatientAssistanceProgram extends HasID, TrackedObject {
    id: number;
    imageId: number;
    patientId: number;
    status: PatientAssistanceProgramStatus;
    attachmentIds: number[];
    patient: Patient;
    edit: any;
}

export enum PatientAssistanceProgramStatus {
    Created,
    ApplicationSubmitted,
    Approved,
    Denied,
    Deleted,
}

export class PatientAssistanceProgram {
    constructor(obj?: PatientAssistanceProgram) {
        if (obj) Object.assign(this, obj);
        if (obj?.patient) this.patient = new Patient(obj.patient);

        this.edit = {};
    }

}
