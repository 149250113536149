
    import { Vue, Component, Prop } from "vue-property-decorator";
    import { Store } from '@/models/Store';
    import { Prescriber } from '@/models/Prescriber';
    import { Patient } from '@/models/Patient';
    import { FaxForm, Prescription } from '@/models/Prescription';
    import { PatientDocument } from "@/models/PatientDocument";
    import { FaxType } from "@/models/PrescriberFollowUpCase";
    import { Drug } from "@/models/Drug/Drug";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import PrescriptionDetailsSummary from '@/components/Prescription/PrescriptionDetailsSummary.vue'
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "AlternateRxFormModal",
        components: { PrescriptionDetailsSummary, SearchComponent }
    })
    export default class AlternateRxFormModal extends Vue {
        @Prop({ default: () => new Store() }) private store!: Store;
        @Prop({ default: () => new Prescriber() }) private prescriber!: Prescriber;
        @Prop() private prescription!: Prescription;
        @Prop() private patient!: Patient;
        @Prop({ default: () => new Drug() }) private drug!: Drug;
        @Prop({ default: "" }) private modalId!: string;
        @Prop({ default: FaxType.RefillAuthorization }) private formType!: FaxType;

        private faxFormData: FaxForm = new FaxForm();
        private isSending: boolean = false;

        private selectedDrug : Drug = new Drug();
        private alternateMedications: Drug[] = [];

        private formTypeEnum = FaxType;

        get title(): string {
            if (this.formType == FaxType.AlternateRxRequest) return 'Alternate RX Request';

            return '';
        }

        get alternateMedicationFields() {
            return [
                { key: "ndc", label: "NDC" },
                { key: "productNameShort", label: "Drug Name" },
            ];
        }

        get endpointURI(): string {
            let res = '';
            if (this.formType == FaxType.AlternateRxRequest) res = 'alternate-rx-request';

            return res;
        }

        get prescriberToShow() : Prescriber{
            return this.prescriber;
        }

        get drugToShow(): Drug {
            return this.drug?.packageID ? this.drug : this.selectedDrug;
        }

        get isFormValid(): boolean {
            let validSelectedFields = this.formType == FaxType.AlternateRxRequest && !!this.selectedDrug.packageID
                || this.formType == FaxType.AlternateRxRequest;

            return !!this.prescriberToShow.faxNumber && validSelectedFields;
        }

        get showInvalidFormAlert(): boolean {
            return !this.isFormValid && this.formType != FaxType.AlternateRxRequest;
        }

        addAlternateDrug() {
            if (this.alternateMedications.length < 3) {

                this.alternateMedications.push(this.selectedDrug);
            }
        }

        show() {
        }

        send() {
            let errorMsg = `Error while sending ${this.title}.`;

            this.faxFormData.fax = this.prescriberToShow.faxNumber;

            if(this.formType == FaxType.AlternateRxRequest) {
                this.faxFormData.prescriberID = Number(this.prescriber.id);
                this.faxFormData.patientID = Number(this.patient.id);
                this.faxFormData.packageID = Number(this.prescription.packageID);
                this.faxFormData.drugSource = Number(this.prescription.drugSource);
                this.faxFormData.alternateDrug = this.alternateMedications.map(d => `${d.ndc} - ${d.productNameShort}`).join("\n");
            } else {
                this.faxFormData.rxID = this.prescription.rxID
            }

            this.isSending = true;
            this.$http.post<PatientDocument>(`prescription/${this.endpointURI}`, this.faxFormData)
                .then(res => {
                    if (res.data.id) {
                        let msg = this.title + " sent successfully and saved as a new patient document. A new Follow-Up case was created.";
                        if (!(this.$user.isManager || this.$user.isPharmacist) && this.formType == FaxType.AlternateRxRequest)
                            msg = this.title + " was saved as a new patient document and new Follow-Up case was created for review.";
                        this.$notification(
                            NotificationOptions.notificationOptionsPreset(msg, NotificationOptions.NotificationTypes.success)
                        );
                        this.$emit('saved');
                    }
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(
                        `${errorMsg} ${err?.response.data.substring(0, 100)}`, NotificationOptions.NotificationTypes.danger
                    ));
                    console.error(errorMsg, { err, response: err?.response })
                })
                .finally(() => this.isSending = false)
        }

        close() {
            this.$bvModal.hide(this.modalId);
            this.selectedDrug = new Drug();
        }

        clearForm() {
            this.faxFormData = new FaxForm();
        }
    }
