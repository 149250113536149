
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { Person } from '@/models/Person';
    import { History, historyDiff } from '@/models/History';
    import NameSearchComponent from './NameSearchComponent.vue';
    import axios from 'axios';
    import { DatePicker } from 'element-ui';
    import moment from 'moment';

    @Component({
        name: "HistoryComponent",
        components: {
            NameSearchComponent,
            [DatePicker.name]: DatePicker,
        },
    })
    export default class HistoryComponent extends Vue {
        created() {
            console.log("%c Created HistoryComponent", "color: green");
        }
        private object: Person | null = null; // The selected object of the search type (Patient/Prescriber/etc..)
        private objects: Person[] = []; // The list of objects returned from the search

        private fromDate: string = moment().subtract(7, 'd').format("YYYY-MM-DD");
        private toDate: string = moment().format("YYYY-MM-DD");

        private displayName = "";

        private searchTypes: string[] = ["Patient", "Prescriber", "Vendor", "DrugCategory", "Store", "Prescription", "User"];
        private searchType: string = this.searchTypes[0];
        private histories: History[] = [];

        optionLabel(option: any) {
            if (option.name != null) return option.name;
            if (option.storeID) return `Store-Rx-Rf:${option.storeID}-${option.rxNumber}-${option.rfNumber} Patient:${option.patientID}`;
            if (option.userName) return option.userName;
            
            const obj = new Person(option);
            return obj.displayNamesForPerson();
        }

        formattedDateForHistory(history: History): string {
            const date = moment.utc(history.updated).local();
            return date.format("[ On: ]MM-DD-YYYY [At: ]hh:mm:ss a");
        }
        diffForHistory(history: History): string[] {
            return historyDiff(history);
        }

        @Watch('searchType') onSearchTypeUpdate(value: any, oldValue: any) {
            this.objects = [];
        }

        @Watch('object') onObjectUpdate(value: Person, oldValue: Person) {
            if (value) {
                //this.displayName = value.displayNamesForPerson();
                this.getHistoryForObject(value);
            }
        }
        
        @Watch('fromDate') onFromDateUpdate(value: any, oldValue: any) {
            if (this.object) {
                this.getHistoryForObject(this.object);
            }
        }
        @Watch('toDate') onToDateUpdate(value: any, oldValue: any) {
            if (this.object) {
                this.getHistoryForObject(this.object);
            }
        }

        clearClicked() {
            this.object = null;
            this.displayName = "";
            this.histories = [];
        }

        getHistoryForObject(value: any) {
            if (value && (value.id || value.storeID || value.userName)) {
                let id = value.id;
                if (this.searchType == "Prescription") {
                    id = `${value.storeID}-${value.rxNumber}-${value.rfNumber}`;
                } else if (this.searchType == "User") {
                    id = value.userName;
                }
                
                const searchPath = encodeURI(`/${this.searchType}/${id}/history`);
                const config = {
                    params: {
                        fromDate: moment(this.fromDate).startOf('day').utc().toDate(),
                        toDate: moment(this.toDate).endOf('day').utc().toDate()
                    },
                };

                axios.get(searchPath, config)
                    .then(response => {
                        const histories = response.data as History[];
                        this.histories = histories.filter((value, index, array) => {
                            return this.diffForHistory(value);
                        });
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            }
        }

        searchValueUpdated(value: string) {
            if (value.length) {
                const searchPath = "/" + this.searchType;
                const config = {
                    params: { searchTerm: value },
                };
                axios.get(searchPath, config)
                    .then(response => {
                        this.objects = response.data;
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            }
        }
    }

