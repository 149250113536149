
    import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
    import Axios, { AxiosError } from 'axios';
    import { DatePicker } from "element-ui";
    import SearchComponent from '@/components/SearchComponent.vue';
    import OrdersListComponent from '@/components/Order/OrdersListComponent.vue';
    import { Prescription } from '@/models/Prescription';
    import { Store } from '@/models/Store';
    import { Order } from '@/models/Order';
    import { Printer } from '@/models/Printer';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";

    @Component({
        name: "VerificationQueuePage",
        components: {
            SearchComponent,
            [DatePicker.name]: DatePicker,
            OrdersListComponent
        },
    })
    export default class VerificationQueuePage extends Vue {

        private orders: Array<Order> = [];
        private searchedOrder: Order | null = null;
        private searchString: string = "";
        private voidTrackingNumber: string = "";
        private timeout: any = null;
        private ReprintId: string = "";
        private printers: Array<Printer> = [];
        private selectedPrinter: Printer = {} as Printer;

        created() {
            if (this.routeStoreId) {
                this.storeFilter.id = this.routeStoreId;
                this.loadOrders();

            }

            else {
                Axios.get(`/Store/`).then(response => {
                    this.storeFilter = response.data[0];
                }).then(() => {
                    this.loadOrders();
                });
            }
            this.loadPrinters();
        }

        mounted() {
            window.scrollTo(0, 0);
        }

        loadPrinters() {

            Axios.get('/Shipping/GetPrinters')
                .then(response => {
                    this.printers = response.data;
                    console.log(this.printers);
                    this.selectedPrinter = JSON.parse(localStorage.getItem("CPHubVerifyPrinter") as string) as Printer ?? this.printers[0];
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        selectPrinter(printer: Printer) {
            localStorage.setItem("CPHubVerifyPrinter", JSON.stringify(printer));
            this.selectedPrinter = printer;
        }

        awaitEndOfTyping() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.searchFiltered();
            }, 250);
        }

        searchFiltered() {
            clearTimeout(this.timeout);
            Axios.get(`/Order/pending-pv2-search/`, {
                params: {
                    storeId: this.storeFilter.id,
                    searchTerm: this.searchString
                }
            })
                .then(response => {
                    this.orders = response.data;
                    const scriptFormat = /\d+-\d+-\d+/;

                    if(this.orders.length == 1 && this.searchString.match(scriptFormat)){
                        this.$router.push({ name: 'OrderVerification', params: { orderId: response.data[0].id } });
                    }
                    if (this.orders.length < 1) {
                        this.$notification({
                            message: "No Order Found",
                            timeout: 1000,
                            icon: "now-ui-icons ui-1_bell-53",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "danger"
                        });
                    }
                }).catch(error => {
                    console.warn(error);
                });
        }

        loadOrders() {
            //Get patient's prescriptions and format the data to be shown in the grid.
            Axios.get(`/Order/pending-pv2`, {
                params: {
                    patientId: null,
                    prescriberId: null,
                    programId: null,

                    storeID: null,
                    rxNumber: null,
                    rfNumber: null,

                    orderStoreId: this.storeFilter?.id
                }
            })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        rxID(prescription: Prescription | null): string {
            if (!prescription) return "";
            return `${prescription.storeID}-${prescription.rxNumber}-${prescription.rfNumber}`;
        }

        get routeStoreId(): number {
            let res = 0;
            res = parseInt(this.$route.params.storeId);
            if (isNaN(res))
                return 0;
            return res;
        }

        voidShipment() {
            if (this.voidTrackingNumber != "") {
                Axios.post(`/Shipping/VoidLabel/` + this.voidTrackingNumber).then(response =>
                {
                    this.voidTrackingNumber = "";
                    this.$notification(NotificationOptions.notificationOptionsPreset("Successfully voided shipment!", NotificationOptions.NotificationTypes.success));
                    console.log("Successfully voided shipment!");
                })
                .catch(error => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Failed to void shipment!", NotificationOptions.NotificationTypes.danger));
                    console.log("Failed to void shipment!", { error, response: error?.response });
                });
            }
        }

        reprintLabel() {

            Axios.get<number>("Order/OrderId", { params: { query: this.ReprintId } })
                .then(resp => {
                    const orderId = resp.data;
                    this.$router.push({ name: "OrderVerification", params: { orderId: orderId.toString() } });
                })
                .catch((err: AxiosError) => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(err.response?.data, NotificationOptions.NotificationTypes.danger));

                });
            return;
        }

        private storeFilter: Store = {} as Store;
        storeLabel(object: Store) {
            if (object?.id)
                return object.name;
            else '';
        }

        clearFilters() {
            this.searchString = "";
            this.storeFilter = {} as Store;
            this.loadOrders();
            console.log(this.$refs.searchString);
        }

        adjustExpressShipdate(){
            if(!this.storeFilter.id) return;

            Axios.post(`Shipping/CloseExpress/${this.storeFilter.id}`)
                .then(resp => {
                    //validate if successful
                    this.$notification(NotificationOptions.notificationOptionsPreset("Shipdates Successfully Adjusted", NotificationOptions.NotificationTypes.success));
                })
                .catch((err) => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Not able to adjust Shipdates", NotificationOptions.NotificationTypes.danger));
                    console.log("Errow while adjusting shipdates", { err, response: err?.response });
                });
        }

    }

