
import Vue from 'vue'
import {GroupOfCasesByDoctor, PrescriberFollowUpCase, FaxType } from '@/models/PrescriberFollowUpCase'
import { Prescriber } from '@/models/Prescriber';
import { Component,  Ref } from 'vue-property-decorator';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import DoctorFollowUpCaseForm from '@/components/Doctor/DoctorFollowUpCaseForm.vue'
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';

@Component({
    name: "DoctorFollowUpQueue",
    components: {
        DoctorFollowUpCaseForm, SearchComponent,
        EnumSelector
    },
})
export default class DoctorFollowUpQueue extends Vue {
    private caseModalId = 'followup-case-form';
    private isLoading = false;
    private items:Array<PrescriberFollowUpCase> = new Array<PrescriberFollowUpCase>();
    private groupByDr:Array<GroupOfCasesByDoctor> = new Array<GroupOfCasesByDoctor>();
    private selectedCase : PrescriberFollowUpCase = new PrescriberFollowUpCase();
    private selectedDoctor:Prescriber = new Prescriber();
    private faxTypeEnum = FaxType;
    private selectedFaxType : FaxType | null = null;

    private fields: any[] = [
        { key: "patientName", label: "Patient" },
        { key: "requestName", label: "Request" },
        { key: "statusName", label: "Status" },
        { key: "created", label: "Created" },
        { key: "updated", label: "Updated" },
        { key: "actions", label: ''},
    ];
    private sortBy:string = 'created'
    private showSubmitted = false;
    private showRemoved = false;

    created(){
        this.loadItems();
    }

    loadItems(){
        this.isLoading = true;
        this.$http.get<Array<PrescriberFollowUpCase>>(`/Prescriber/follow-up-queue/${this.selectedDoctor?.id || 0}/${this.selectedFaxType || ''}`)
        .then(res => {
            this.items = res.data.map(i => new PrescriberFollowUpCase(i));

            this.groupByDr = this.items.map(i => i.prescriberId).filter((val:number, idx:number, self) => self.indexOf(val) == idx)
            .map(drID => {
                const cases = this.items.filter(i => i.prescriberId == drID);
                return new GroupOfCasesByDoctor(drID, cases[0].prescriberName, cases);
            })
        })
        .catch(err => {
            let errorMsg = 'Error while loading prescribers follow-up cases.';
            this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
            console.error(errorMsg, {err, response: err?.response})
        })
        .finally(() => {
            this.isLoading = false;
        })
    }

    closeCaseModalAndReload(){
        this.$bvModal.hide(this.caseModalId);
        this.loadItems();
    }

    openDetails(selectedItem:PrescriberFollowUpCase){
        this.selectedCase = selectedItem;
        this.$bvModal.show(this.caseModalId);
    }

}
