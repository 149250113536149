
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
    import {CustomerInfo, Payment, StripeCard} from '@/models/Payment';
    import {StripeElementCard} from '@vue-stripe/vue-stripe';
    import { Patient } from '@/models/Patient';

    @Component({
        name: "StripeVueCard",
        components: {
            StripeElementCard
        }
    })
    export default class StripeVueCard extends Vue {
        @Ref('stripeElementCard') stripeElementCard!:StripeElementCard;
        @Prop() patient!:Patient;
        @Prop({default: false}) disabled!:boolean;
        private spk!:string;
        private saving = false;

        submit() {
            if(this.stripeElementCard) (this.stripeElementCard as any).submit();
        }

        beforeCreate(){
            const environment = process.env.VUE_APP_ENVIRONMENT_NAME;
            if (environment === "Development"){
                this.spk = "pk_test_6qlXqcFdhIA2Ix0w8xBWGljk00Bj2UY7mm";
            } else {
                this.spk = process.env.STRIPE_PUBLIC_KEY ?? "pk_live_6eFaEdSHQLSIayd43Dhb3ily00ieAjZNJc";
            }
        }
        addPaymentMethod(token:any) {
            this.saving = true;

            console.log("Stripe Token: ", token);
            const cardToken = token?.id;
            if (!cardToken) {
                this.$notification(
                    NotificationOptions.notificationOptionsPreset('Error getting token from stripe', NotificationOptions.NotificationTypes.danger)
                );
                return;
            }

            let customerInfo = {
                patientId: this.patient.id,
                name: this.patient.lastName + ', ' + this.patient.firstName,
                id: this.patient.stripeCustomerId
            };
            this.$http.post(`/stripe/${cardToken}`, customerInfo)
                .then(response => {
                    if(response.data?.customerId){
                        this.$notification(NotificationOptions.successSaveNotificationPreset("Card"))
                        this.$emit('cardAdded', response.data)
                    }
                })
                .catch(error => {
                    NotificationOptions.notificationOptionsPreset("Card Error:" + error.response?.data.substring(0, 100), NotificationOptions.NotificationTypes.danger)
                    console.log("Error while adding a card", {error, response: error.response })
                })
                .finally(()=>this.saving = false);
        }

    }
