
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { Lock, LockHandler } from '@/mixins/LockHandler';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import DispensingErrorModalComponent from '@/components/DispensingError/DispensingErrorModalComponent.vue';
import DispensingErrorAlertComponent from '@/components/DispensingError/DispensingErrorAlertComponent.vue';
import Switch from "@/components/Switch.vue";
import NSwitch from "@/components/Switch.vue";
import ImageComponent from '@/components/Prescription/ImageComponent.vue';
import PatientRxHistoryModalComponent from '@/components/Patient/PatientRxHistoryModalComponent.vue';
import InsuranceBillingComponent from '@/components/InsurancePlan/InsuranceBillingComponent.vue';
import QuestionaryComponent from '@/components/Questionary/QuestionaryComponent.vue';

import { DAWCode, OriginCode, Prescription, RefillStatusCode } from '@/models/Prescription';
import { DeliveryCode } from '@/models/DeliveryCode';
import { Prescriber } from '@/models/Prescriber';
import { Verification, VerificationResponse } from '@/models/Verification';
import { Patient } from '@/models/Patient';
import { Store } from '@/models/Store';
import { Drug } from '@/models/Drug/Drug';
import { Note } from '@/models/Note';
import { DispenseError } from '@/models/DispenseError';
import { GSDDPatientAllergy } from '@/models/GSDD';
import { Order } from '@/models/Order';
import { DrugCategory } from '@/models/DrugCategory';
import { Claim } from '@/models/Claim';
import Button from '@/components/Button.vue';
import { Printer } from '@/models/Printer';
import DrugChangeModal from "@/components/Prescription/DrugChangeModal.vue";
import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { CompoundDrug } from "@/models/Drug/CompoundDrug";
import { BvMsgBoxOptions } from "bootstrap-vue";
import { FunctionalityFlag } from "@/models/FunctionalityFlag";
import DispenseErrorTypeEnum = DispenseError.DispenseErrorTypeEnum;
import EscriptListModal from "@/components/Prescription/EscriptListModal.vue";
import { EscriptData } from '@/models/EscriptData';

@Component({
    name: "PrescriptionVerificationDetailsPage",
    components: {
        EscriptListModal,
        NSwitch,
        DrugChangeModal,
        SearchComponent,
        NewClearSaveButtons,
        DispensingErrorModalComponent,
        DispensingErrorAlertComponent,
        [Switch.name]: Switch,
        ImageComponent,
        PatientRxHistoryModalComponent,
        InsuranceBillingComponent,
        QuestionaryComponent,
        Button,
    },
})
export default class PrescriptionVerificationDetailsPage extends Mixins(LockHandler) {
    @Ref('patient_this_history_modal') protected historyModal!: PatientRxHistoryModalComponent;
    @Ref('patient_rx_history_modal') private rxHistoryModal!: PatientRxHistoryModalComponent;
    // Flags for checkboxes
    private prescription: Prescription = new Prescription();
    private prescriptionLock: Lock | null = null;
    private durStatus = false;
    protected drugCategories: Array<DrugCategory> = [];

    private store: Store | null = new Store();
    private patient: Patient = new Patient();
    private doctor: Prescriber = new Prescriber();
    private drug: DrugProduct | null = null;
    private order: Order | null = null;
    private dispenseError: DispenseError = new DispenseError();
    private dispenseErrors: Array<DispenseError> = [];
    protected dispenseErrorAlertButtonTitles: any = { accept: 'Approve', reject: 'Reject' };
    private patientAllergies: GSDDPatientAllergy[] = [];
    private drugUtilizationReviews: any = {};
    protected ipAddress: Array<Printer> = [];
    private printerIpAddress = '';
    private drugModalVisible: boolean = false;
    private pv1ModalVisible: boolean = false;
    private programFlags: FunctionalityFlag[] = [];
    private PartnerFillRequired = false;
    private SerialNumberRequired = false;
    private LotNumberRequired = false;
    protected drugAlert: string = "";

    protected mode: string = '';
    protected isLoading: boolean = true;
    protected showPatientAlert = false;
    protected patientAddressString = "";
    protected patientAllergyString = "";
    private isPrintingRxRf: boolean = false;

    private buttonTitles: any = {
        clear: "Reject",
        save: "Verify",
    };

    //Notes
    private addNote = '';
    protected hardcopyAnnotations: Note[] = [];
    protected hardcopyAnnotationsFields: any[] = [
        { label: "Note", key: "body" },
        { label: "By", key: "createdBy" },
        { label: "On", key: "created", formatter: this.formattedDate, sortable: true },
    ];

    protected verificationIndex = 0;
    protected allClaimsAreOffline = false;
    private escriptData: EscriptData | null = null;

    blockListeners(): boolean {
        if (this.drugModalVisible) return true;
        if (this.pv1ModalVisible) return true;
        //Add HTML attribute 'block-listener' to any input where we dont want to listen key events.
        const blockersElements = document.querySelectorAll("*[block-listener]");
        let block = false;
        if (blockersElements?.length)
            blockersElements.forEach(el => {
                if (document.activeElement == el && !block)
                    block = true;
            });

        return block;
    }

    keydownListener(e: any) {
        if (this.blockListeners()) return;
        if (e.code === "F5") e.preventDefault();
    }

    keyupListener(e: any) {
        if (this.blockListeners()) return;

        switch (e.code) {
            case 'NumpadEnter':
            case 'Enter':
                // if (this.hardcopyAnnotations) break;
                if ((this.durChecked || this.drugUtilizationReviews?.isEmpty))
                    if (!this.errorIsPending)
                        this.verifyClicked();
                    else
                        this.$notification(NotificationOptions.notificationOptionsPreset("Dispense Error is pending", NotificationOptions.NotificationTypes.warning));
                else this.openErrorDispenseModal();
                break;
            case 'F5':
                this.rxHistoryModal.openRxHistory();
                break;
        }
    }

    beforeDestroy() {
        this.removeListeners();
    }

    mounted() {
        this.addListeners();
    }

    addListeners() {
        document.addEventListener("keydown", this.keydownListener);
        document.addEventListener("keyup", this.keyupListener);
    }

    removeListeners() {
        document.removeEventListener("keydown", this.keydownListener);
        document.removeEventListener("keyup", this.keyupListener);
    }

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        this.getPrescription(this.routeRxId);
        this.buttonTitles.save = "Accept";
        this.buttonTitles.new = null;
        //this.pvNbr = 2;
        this.isLoading = true;

        if (this.routeDispenseErrorId)
            this.buttonTitles.cancel = "Back to Dispense Error List";
        else if (this.routeOrderId)
            this.buttonTitles.cancel = 'Back to Order Verification';
        else this.buttonTitles.cancel = null;
    }

    drugChangeSuccess() {
        this.drugModalVisible = false;
        this.$router.go(0);
    }

    drugChangeFail(message: string) {
        this.drugModalVisible = false;
        this.dispenseError = new DispenseError(this.prescription);
        this.dispenseError.errorType = DispenseErrorTypeEnum.WrongNDC;
        this.dispenseError.reason = message;
        this.openErrorDispenseModal();
    }

    drugChangeComplete(response: AxiosResponse, newDrug: string) {
        console.log("newDrug: ", newDrug);

        function isAxiosError(item: any): item is AxiosError {
            // noinspection JSIncompatibleTypesComparison
            return (item as AxiosError).isAxiosError !== undefined;
        }

        if (isAxiosError(response)) {
            const err: AxiosError = response as AxiosError;
            const errorMessage = err.response?.data.message + '\n' + newDrug;
            this.drugChangeFail(errorMessage);
        } else if (response?.status == 200) {
            this.drugChangeSuccess();
        } else {
            console.warn("Bad place to have gotten into!");
        }
    }

    setIsLoading(_val: Patient) {
        this.isLoading = true;
    }

    get doctorAddressString(): string {
        if (!this.doctor) return "";
        return (this.doctor.address1 + (this.doctor.address2 ? ', ' + this.doctor.address2 : '') + ', '
            + this.doctor.addressCity + ', ' + this.doctor.addressState + ' ' + this.doctor.addressZip).toString();
    }

    // used to calculate if the patient is 12 & under
    get patientYearsOld(): number {
        return moment().diff(moment(this.patient.dateOfBirth), "years");
    }

    get patientIsUnder12(): boolean {
        return this.patientYearsOld <= 12;
    }

    get patientDobString(): string {
        if (!this.patient?.dateOfBirth) return 'unknown';
        let dobString = `${this.formattedLocalDate(this.patient.dateOfBirth)} (${this.patientYearsOld} years old)`;
        if (this.patientIsUnder12) dobString = " ⚠️ " + dobString + " ⚠️ ";
        return dobString;
    }

    get upc(): string {
        if (this.drug?.source != DrugSource.GsddFederal) return '';
        const drug = this.drug as Drug;
        const idents = drug?.packageDetail?.packageIdentifier?.filter((pi: any) => pi.identifierType == 8);
        return idents?.length > 0 ? idents[0].identifier : '';
    }

    get isPV2() {
        return (this.prescription?.pV2ID || 0) > 0;
    }

    get pageMode() {
        let res = '';
        if (this.$router.currentRoute.name == 'PV2') {
            res = "pv2";
        }
        if (this.$router.currentRoute.name == 'PV1') {
            res = "pv1";
        }
        if (this.$router.currentRoute.name == 'Audit') {
            res = "audit";
            document.title = `Audit ${this.routeRxId}`;
        }
        return res;
    }

    get routeRxId(): string | any {
        let res = this.$route.params.id;
        if (!res) {
            const storeID = Number(this.$route.params.storeID);
            const rxNumber = Number(this.$route.params.rxNumber);
            const rfNumber = Number(this.$route.params.rfNumber);
            if (!isNaN(storeID) && !isNaN(rxNumber) && !isNaN(rfNumber))
                res = `${storeID}-${rxNumber}-${rfNumber}`;
        }
        return res;
    }

    get routeOrderId(): number {
        let res = 0;
        if (this.$route.params.orderID)
            res = Number(this.$route.params.orderID);

        return res;
    }

    get routeDispenseErrorId(): number | null {
        let res = null;
        if (this.$route.params.dispenseErrorId)
            res = Number(this.$route.params.dispenseErrorId);
        else if (this.dispenseError?.id)
            res = Number(this.dispenseError?.id);
        return res;
    }

    get disableFields(): boolean {
        //return !(this.prescriptionLock || (this.prescription && !this.prescription.rxNumber));
        const lockedForUser = !this.prescriptionLock;
        const noEntityLoaded = !(this.prescription?.rxNumber);

        // Disable fields if
        // ** its locked for the user
        // ** or if there is no object loaded and
        return (lockedForUser || noEntityLoaded);
    }

    get errorIsPending(): boolean {
        return !!(this.dispenseError?.id && !this.dispenseError?.accepted);
    }

    get disableVerify(): boolean {
        if (this.errorIsPending) {
            return true;
        }

        let validChecks = true;

        if (!this.drugUtilizationReviews?.isEmpty)
            validChecks = validChecks && this.durChecked;

        return !validChecks;
    }

    get durChecked(): boolean {
        return this.drugUtilizationReviews?.isEmpty || this.durStatus;
    }

    get doctorNameLabel(): string {
        return this.doctor.displayNamesForPerson();
    }

    get fillsLeft(): number {
        if (!this.prescription) return 0;
        if (!this.prescription.rfNumber) return this.prescription.refills;
        return this.prescription.refills - this.prescription.rfNumber;
    }

    get statusCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.status} - ${RefillStatusCode[this.prescription.status]}`;
    }

    get deliveryCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.deliveryCode} - ${DeliveryCode[this.prescription.deliveryCode]}`;
    }

    get originCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.originCode} - ${OriginCode[this.prescription.originCode]}`;
    }

    get dawCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.dawCode} - ${DAWCode[this.prescription.dawCode]}`;
    }

    get lactatingInformationDate(): string {
        return moment(this.patient.lactating).fromNow();
    }

    get pregnancyInformationDate(): string {
        return moment(this.patient.pregnant).fromNow();
    }

    get requiresPV1(): boolean {
        return this.prescription.isPV1Required || (this.drug instanceof Drug && this.drug.isControl) || (this.drug instanceof CompoundDrug);
    }

    get showDrugAlert() {
        return this.drugAlert.length > 0;
    }

    get showDoctorAlert(): boolean {
        return !!this.doctor?.alert;
    }


    printByRxRf() {
        if (!this.isPrintingRxRf) {

            this.isPrintingRxRf = true;
            type PrintedViewModel = { printedLabels: number, badLabels: { label: any, error: string }[] };
            Axios.get<PrintedViewModel>(`/Print/${this.prescription.rxID}/rx-pated`)
                .then(res => {
                    if (res.data.printedLabels > 0 && res.data.badLabels?.length == 0) {
                        const successMessage = `PatEd for Rx ${this.prescription.rxID} was successfully printed`;
                        this.$notification(NotificationOptions.notificationOptionsPreset(successMessage, NotificationOptions.NotificationTypes.success));
                    }
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    console.error("Error while pulling printing Rx", { err, response: err?.response });
                })
                .finally(() => {
                    this.isPrintingRxRf = false;
                });
        }
    }

    selectedPrescriptionLabel(object: Prescription) {
        if (!this.drug || !this.patient) return object.toString();

        const rxID = object.rxID;
        const name = this.patient.displayNamesForPerson();
        if (rxID)
            return `#${rxID} For "${name}" - ${this.drug.productNameShort}`;
        else return '';
    }

    @Watch('prescription')
    async prescriptionUpdate(value: Prescription | null, oldValue: Prescription | null) {
        if (!value || !value.rxNumber) return;

        if (oldValue && oldValue.storeID && oldValue.rxNumber && oldValue.rfNumber) {
            if (oldValue.storeID != value.storeID
                || oldValue.rxNumber != value.rxNumber
                || oldValue.rfNumber != value.rfNumber) {
                this.fetchPrescription(value);
            }
        }
    }

    fetchDrugAlert(drug: DrugProduct) {
        Axios.get(`/Drug/${drug.drugId}/Alert`, {
            params: { getProgram: true, drugSource: drug.source },
        })
            .then((response) => {
                this.drugAlert = response.data;
            })
            .catch((error) => {
                console.error("Error while getting drug alert", {
                    error,
                    response: error?.response,
                });
            });
    }

    fetchPrescription(prescription: Prescription) {
        const rxID = prescription.rxID;
        this.getPrescription(rxID);
    }

    getPrescription(rxID: string) {
        this.addLockWithURL(`/Prescription/${rxID}/Lock`, 60000)
            .then(result => {
                this.prescriptionLock = result;
            })
            .catch(error => {
                if (error.response && error.response.status == 418) {
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Prescription is locked by ${lockedBy} until ${expires}.`);
                }
            })
            .finally(() => {
                // If it's locked should we even allow verification to view anything?
                // this.patientStatus = this.doctorStatus = this.quantityStatus = this.drugStatus = false;

                Axios.get<VerificationResponse>(`/Prescription/${rxID}/verification`)
                    .then(response => {
                        const data = response.data;

                        this.prescription = new Prescription(data.prescription);
                        this.patient = new Patient(data.patient);

                        this.patientAddressString = this.buildAddressString(this.patient);
                        this.doctor = new Prescriber(data.prescriber);
                        this.store = new Store(data.store);
                        this.order = new Order(data.order);
                        this.programFlags = data.programFlags;

                        this.PartnerFillRequired = this.programFlags.find((m: any) => m.name == "Partner Fill Number Required")?.value ?? false;
                        this.SerialNumberRequired = this.programFlags.find((m: any) => m.name == "Serial Number Required")?.value ?? false;
                        this.LotNumberRequired = this.programFlags.find((m: any) => m.name == "Lot Number Required")?.value ?? false;
                        Axios.get(`/Allergy/GetGSDDAllergiesForPatient/${this.patient.id}`)
                            .then(response => {
                                this.patientAllergies = response.data;
                                this.patientAllergyString = this.buildAllergyString(this.patientAllergies);
                                if (this.patient.nkda) {
                                    this.patientAllergyString += 'NKDA';
                                }
                            }).catch(error => {
                            console.error("Error while loading allergies", { error, response: error?.response });
                        });

                        Axios.post(`/Prescription/${rxID}/GenerateDURs`)
                            .then(response => {
                                this.drugUtilizationReviews = Object.assign(new Verification.DrugUtilizationReviews(), response.data);
                            }).catch(error => {
                            console.error("Error while pulling DUR", { error, response: error?.response });
                        });

                        Axios.get<EscriptData>(`/Escript/Parsed/${this.prescription.escriptID}`)
                            .then(response => {
                                this.escriptData = new EscriptData(response.data);
                            })
                            .catch(error => {
                                console.warn("Error attempting to fetch escript", { error, response: error?.response });
                            });

                        //Pull all errors reported for this prescription
                        this.dispenseErrors = data.dispenseErrors.map(err => Object.assign(new DispenseError(), err));

                        //Take the current one.
                        if (this.dispenseErrors?.some(err => !err.accepted))
                            this.dispenseError = this.dispenseErrors.filter(err => !err.accepted)[0];

                        //If there is no active Dispense error, set the new one with the RxID
                        if (!this.dispenseError.id) {
                            this.dispenseError.storeID = this.prescription.storeID;
                            this.dispenseError.rxNumber = this.prescription.rxNumber;
                            this.dispenseError.rfNumber = this.prescription.rfNumber;
                        }

                        if (data.drug.source == DrugSource.GsddFederal) {
                            this.drug = new Drug(null, data.drug);
                            this.drugDetails = new Drug(null, data.drug);
                        }
                        if (data.drug.source == DrugSource.NonGsddProduct) {
                            this.drug = new NonGSDDDrug(null, data.drug);
                            this.drugDetails = new NonGSDDDrug(null, data.drug);
                        }
                        if (data.drug.source == DrugSource.CompoundProduct) {
                            this.drug = new CompoundDrug(null, data.drug);
                            this.drugDetails = new CompoundDrug(null, data.drug);
                        }

                        if (this.drug != null) this.fetchDrugAlert(this.drug);
                        this.fetchDrugCategory(this.drug?.drugId);

                        //const verification = data.verifications.find(p => this.prescription && this.prescription.pV2ID ? p.id == this.prescription.pV2ID : this.prescription && this.prescription.pV1ID ? p.id == this.prescription.pV1ID : p.id != null);
                        let verification: Verification | null = {} as Verification;
                        if (this.pageMode == 'pv2') {
                            verification = data.verifications.find(v => v.id == (this.prescription?.pV2ID || 0)) || null;
                        } else if (this.pageMode == 'pv2') {
                            verification = data.verifications.find(v => v.id == (this.prescription?.pV1ID || 0)) || null;
                        }
                        if (verification) {
                            //    this.patientStatus = verification.patient;
                            //    this.doctorStatus = verification.prescriber;
                            //    this.quantityStatus = verification.quantity;
                            //    this.directionStatus = verification.direction;
                            //    this.drugStatus = verification.drug;
                            //    this.durStatus = verification.dur;
                        }

                    })
                    .catch(error => {
                        console.error('Error while loading verification details.', {
                            error,
                            response: error?.response
                        });
                    })
                    .finally(() => {
                        if (this.pageMode == "pv2" && this.requiresPV1 && !this.prescription.pV1ID) {
                            const opt: BvMsgBoxOptions = {
                                title: "PV1 Needed",
                                size: "md",
                                buttonSize: "md",
                                okTitle: "OK",
                                hideHeaderClose: true,
                                centered: true,
                            };
                            const msg = "PV1 was not preformed for this prescription. " +
                                "Ensure you have done all the checks required before dispensing.";
                            this.pv1ModalVisible = true;
                            this.$bvModal.msgBoxOk(msg, opt).finally(() => {
                                this.pv1ModalVisible = false;
                            });
                        }
                        this.isLoading = false;
                    });
            });
    }

    // fetch Annotations
    fetchAnnotations() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.get(`/Prescription/${id}/Annotations`)
            .then(response => {
                this.hardcopyAnnotations = response.data;
            })
            .catch(err => {
                console.warn(err);
            });
    }

    addHardcopyNote() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.post(`/Prescription/${id}/AddHardcopyAnnotation`, { Body: this.addNote })
            .then(_response => {
                this.addNote = '';
                if (this.prescription.imageID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionImageSource(`image/${this.prescription.imageID}`);
                else if (this.prescription.escriptID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionEscriptResponse(this.prescription.escriptID);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Hardcopy Note"));
            })
            .catch(err => {
                console.warn(err);
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Hardcopy Note", err));
            })
            .finally(() => {
                this.fetchAnnotations();
            });
    }

    saveHardCopyWithCallback(_billInsuranceCallback: Function) {
        // this.prescription.productNameShort = this.drug.productNameLong;
        this.prescription.quantity = this.prescription.quantity || 0;
        this.prescription.dispensed = this.prescription.dispensed || 0;
        // this.assignViewValues();
        console.log(this.saveHardCopyWithCallback);

        Axios.post('/Prescription/', this.prescription)
            .then(response => {
                this.prescription = Object.assign(new Prescription(), response.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset(`Prescription ${this.prescription.rxID}`));
                this.addHardcopyNote();
            });
    }

    backToPreviousPage(backToOrderPage = false) {
        let orderId = this.routeOrderId || this.order?.id;
        if (!!(backToOrderPage || this.routeOrderId) && orderId)
            this.$router.push({ name: 'OrderVerification', params: { orderId: orderId.toString() } });
        else if (this.routeDispenseErrorId)
            this.$router.push({ name: 'PendingDispenseErrors' });
        else this.$router.go(-1);
    }

    postVerification() {
        if (!this.prescription) return;

        const PVNbr = this.pageMode == 'pv1' ? 1 : (this.pageMode == 'pv2' ? 2 : null);
        const postData = {
            Prescription: this.prescription,
            Verification: {
                Patient: true,
                Prescriber: true,
                Quantity: true,
                Drug: true,
                Direction: true,
                dur: this.drugUtilizationReviews?.isEmpty ? null : this.durStatus
            },
            DUR: this.drugUtilizationReviews?.isEmpty ? null : this.drugUtilizationReviews,
            PVNbr
        };

        Axios.post(`/Prescription/${this.prescriptionIdentifier}/verification`, postData)
            .then(_response => {
                this.$notification(NotificationOptions.successSaveNotificationPreset("Verification"));
                this.backToPreviousPage(true);
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Verification", error));
                console.log('Error while posting verification', { error, response: error?.response });
            });
    }

    fetchDrugCategory(productID: number | string = 0) {
        if (!(this.drug?.drugId || productID)) return;
        if (this.drug?.source != DrugSource.GsddFederal) return;

        productID = productID as any || this.drug?.drugId as any;
        Axios.get<Array<DrugCategory>>(`Drug/${productID}/${this.drug.source}/categories`)
            .then(response => {
                this.drugCategories = response.data.map(r => Object.assign(new DrugCategory(), r));
            })
            .catch(error => {
                console.error('Error while getting drug categories', { error, response: error?.response });
            });
    }

    @Watch('patient.alert') onAlertChange(value: string, oldValue: string) {
        this.showPatientAlert = (!!value && !oldValue);
    }

    getPrinterByIPAddress() {
        Axios.post(`Print/PrintByIPAddress/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}?ipAddress=${this.printerIpAddress}`)
            .then(_response => {
                this.printerIpAddress = '';
                this.$notification(NotificationOptions.notificationOptionsPreset(`Label was successfully printed`, NotificationOptions.NotificationTypes.success));
            })
            .catch(_error => {
                this.$notification(NotificationOptions.notificationOptionsPreset(`Unable to print label`, NotificationOptions.NotificationTypes.danger));
            });
    }


    get lastIpAddress(): any {
        const objStr = localStorage.getItem('printQueue_ipAddress');
        if (objStr)
            return JSON.parse(objStr);
        else return null;

    }

    @Watch('printerIpAddress')
    lastIpAddressChanged(val1: any, _val2: any) {
        if (val1 == new Printer()) {
            return;
        }
        localStorage.setItem('printQueue_ipAddress', JSON.stringify(val1));
        console.log('printerIpAddress', this.lastIpAddress);

    }

    get prescriptionIdentifier(): string {
        return this.prescription.rxID;
    }

    get storeLocationInformation(): string {
        if (!this.store) return "";
        return `${this.store.addressCity}, ${this.store.addressState}`;
    }

    formattedLocalDate(value: Date): string {
        if (!value) return '';
        const date = moment(value);
        return date.format("MM-DD-YYYY");
    }

    formattedDate(value: Date): string {
        if (!value) return '';
        const date = moment.utc(value);
        return date.format("MM-DD-YYYY");
    }

    get origRxDate(): any {
        if (!this.prescription || !this.prescription.originalRxDate) return "";
        return this.formattedDate(this.prescription.originalRxDate);
    }

    get expiration(): string {
        if (!this.prescription || !this.prescription.expirationDate) return "";
        return this.formattedDate(this.prescription.expirationDate);
    }

    get fillDate(): any {
        if (!this.prescription || !this.prescription.fillDate) return "";
        return this.formattedDate(this.prescription.fillDate);
    }

    get writtenDate(): any {
        if (!this.prescription || !this.prescription.writtenDate) return "";
        return this.formattedDate(this.prescription.writtenDate);
    }

    get drugExpDate(): any {
        if (!this.prescription || !this.prescription.drugExpirationDate) return "";
        return moment(this.prescription.drugExpirationDate).format('YYYY-MM-DD');
    }

    set drugExpDate(value: any) {
        if (!this.prescription) return;
        this.prescription.drugExpirationDate = value ? value : undefined;
    }

    get safety(): string {
        return this.patient && this.patient.childCaps ? "USE SAFETY CAP" : "USE NON-SAFETY CAP";
    }

    drugOptionLabel(option: any) {
        return option.packageID;
    }

    drugLabel(option: any) {
        if (!option) return '';
        if (option.ndc) {
            return `NDC:${option.ndc} - ${option.name ? option.name : option.productNameShort ? option.productNameShort : option.productNameLong}`;
        }
        if (option.packageIdentifier) {
            return `NDC:${option.ndc} - ${option.productNameShort ? option.productNameShort : option.productNameLong}`;
        }
        return option.productNameLong;
    }

    drugLabelSmall(option: any) {
        if (!option) return '';
        return option.packageDescription;
    }

    protected drugImageURLs: string[] = [];
    private drugDetails: DrugProduct | null = null;

    @Watch('drugDetails')
    drugDetailsUpdate(value: DrugProduct | null, oldValue: DrugProduct | null) {
        if (value == oldValue) return;
        this.drugImageURLs = [];
        const baseURL = Axios?.defaults?.baseURL || '';
        if (!value) return;
        const imageURI = `${baseURL}/drug/get-image?packageID=${value.drugId}&drugSource=${value.source}`;
        this.drugImageURLs.push(imageURI);
    }

    formatDate(date: any): string {
        return moment(date).format('L');
    }

    ago(date: any): string {
        return moment(date).fromNow();
    }

    get patientMatchClass(): string {
        try {
            if (this.escriptData == null) return '';
            if (this.patient?.firstName.toLowerCase() != this.escriptData?.patient?.firstName.toLowerCase()) return 'text-danger firstName'
            if (this.patient?.lastName.toLowerCase() != this.escriptData?.patient?.lastName.toLowerCase()) return 'text-danger lastName'
            if (this.patient?.dateOfBirth != this.escriptData?.patient?.dateOfBirth) return 'text-danger dob'
            return 'text-success';
        } catch {
            return '';
        }
    }

    get patientAllergyMatch(): string {
        if (this.escriptData == null) return '';
        if (this.patient.nkda && (this.escriptData.allergies?.length ?? 0) > 0) return 'text-danger';
        if (this.patient.nkda && (this.escriptData.allergies?.length ?? 0) == 0) return 'text-success';
        return 'text-warning'
    }

    get doctorNameMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.firstName.toLowerCase() != this.escriptData.prescriber?.firstName.toLowerCase()) return 'text-danger';
        if (this.doctor?.lastName.toLowerCase() != this.escriptData.prescriber?.lastName.toLowerCase()) return 'text-danger';
        return 'text-success';
    }

    get doctorNpiMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.npiNumber != this.escriptData.prescriber?.npiNumber) return 'text-danger';
        return 'text-success';
    }

    get doctorDeaMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.deaNumber != this.escriptData.prescriber?.deaNumber) return 'text-danger';
        return 'text-success';
    }

    get quantityMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.quantity != this.escriptData.prescribedMed?.quantityValue) return 'text-danger';
        return 'text-success';
    }

    get refillsMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.refills != this.escriptData.prescribedMed?.numberOfRefills) return 'text-danger';
        return 'text-success';
    }

    get dawMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.dawCode != this.escriptData.prescribedMed?.substitutions) return 'text-danger';
        return 'text-success';
    }

    get writtenMatch(): string {
        if (this.escriptData == null) return '';
        if (moment(this.prescription.writtenDate)
            .startOf('day')
            .isSame(moment(this.escriptData.prescribedMed?.writtenDate).startOf('day'))) return 'text-danger';
        return 'text-success';
    }

    get drugMatch(): string {
        if (!this.escriptData?.prescribedMed) return '';

        if (this.drugDetails?.ndc == null) return '';
        let processedNdc = this.drugDetails.ndc.replaceAll('-', '');
        if (processedNdc.length > 9) processedNdc = processedNdc.substring(0, 9);

        if ((this.escriptData.prescribedMed.allowedNdcList?.length ?? 0) > 0 && this.escriptData.prescribedMed.allowedNdcList.indexOf(processedNdc) >= 0) {
            return 'text-success';
        }

        if (this.escriptData.prescribedMed.ndc11 == null) return '';
        let prescribedNdc = this.escriptData.prescribedMed.ndc11.replaceAll('-', '');
        if (prescribedNdc.length > 9) prescribedNdc = prescribedNdc.substring(0, 9);

        if (prescribedNdc != processedNdc) return 'text-danger'

        return 'text-success';
    }

    get fillDateOk(): string {
        if (moment(this.prescription.fillDate).isSameOrAfter(moment().subtract(2, 'weeks'))) {
            return 'text-success';
        }
        return 'text-warning';
    }

    get originOk(): string {
        if (this.escriptData == null && this.prescription.originCode == OriginCode.Escript) {
            return 'text-danger';
        }

        if (!this.escriptData?.store) {
            return '';
        }

        if (this.escriptData.store.npiNumber != this.store?.npiNumber && this.prescription.originCode != OriginCode.Transfer) {
            return 'text-danger';
        }

        if (this.escriptData.store.npiNumber == this.store?.npiNumber && this.prescription.originCode != OriginCode.Escript) {
            return 'text-danger';
        }

        return 'text-success';
    }

    @Watch('drug') drugUpdate(value: DrugProduct | null, oldValue: any) {
        if (!value || value && oldValue && oldValue.id) {
            this.drugDetails = value;
        }
        if (!value || !value.drugId || (oldValue && oldValue.packageID && +value.drugId == +oldValue.packageID)) return;
        if (this.prescription) this.prescription.packageID = +value.drugId; // ensure packageID is a number;

        if (value.source == DrugSource.GsddFederal) {
            Axios.get(`/Drug/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new Drug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
        if (value.source == DrugSource.NonGsddProduct) {
            Axios.get(`/NonGSDD/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new NonGSDDDrug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
        if (value.source == DrugSource.CompoundProduct) {
            Axios.get(`/Compound/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new CompoundDrug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }


    }

    saveDispenseError() {
        this.dispenseError.pv = this.pageMode;
        Axios.post<DispenseError>("/DispenseError", this.dispenseError)
            .then(response => {
                if (response.data.id) {
                    this.fetchDispenseErrors();
                    this.$notification(NotificationOptions.notificationOptionsPreset("Dispense error was reported.", NotificationOptions.NotificationTypes.info));
                }
            })
            .catch(error => {
                console.error("Error saving dispense error", { error, response: error?.response });
            })
            .finally(() => this.openErrorDispenseModal());
    }

    buildAddressString(_patient: Patient) {
        return (this.patient.address1 + (this.patient.address2 ? ', ' + this.patient.address2 : '') + ', '
            + this.patient.addressCity + ', ' + this.patient.addressState + ' ' + this.patient.addressZip).toString();
    }

    buildAllergyString(allergies: GSDDPatientAllergy[]) {
        let result = "";
        allergies.forEach(allergy => result = result.concat(allergy.allergyName + ', '));
        result = result.slice(0, -2);
        return result;
    }

    fetchDispenseErrors() {
        // Refresh errors and get the new pending.
        Axios.get<Array<DispenseError>>(`DispenseError/by-rxid/${this.prescription.rxID}/`)
            .then(response => {
                const findActiveDispenseErrorCallback = (err: DispenseError) => err.statusEnum != DispenseError.DispenseErrorStatusEnum.Accepted;
                const emptyDispenseError = new DispenseError(this.prescription);
                this.dispenseErrors = response.data.map((err: DispenseError) => Object.assign(new DispenseError(), err));
                if (this.dispenseErrors?.some(findActiveDispenseErrorCallback))
                    this.dispenseError = this.dispenseErrors.find(findActiveDispenseErrorCallback) || emptyDispenseError;
                else this.dispenseError = emptyDispenseError;
            })
            .catch(err => {
                console.log("Error while refreshing dispense errors", err);
            });
    }

    verifyClicked() {
        if (!this.prescription) return;

        if (this.LotNumberRequired && (!this.prescription.lotNumber || this.prescription.lotNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Lot Number required.`);
        }
        if (this.SerialNumberRequired && (!this.prescription.serialNumber || this.prescription.serialNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Serial Number required.`);
        }
        if (this.PartnerFillRequired && (!this.prescription.partnerFillNumber || this.prescription.partnerFillNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Partner Fill Number required.`);
        }


        return this.postVerification();
    }

    openErrorDispenseModal() {
        this.$bvModal.show("dispense-error-modal");
    }

    cancelClicked() {
        this.backToPreviousPage();
    }

    openRxEdit() {
        this.$router.push({
            name: 'PrescriptionDetails',
            params: {
                storeID: this.prescription.storeID.toString(),
                rxNumber: this.prescription.rxNumber.toString(),
                rfNumber: (this.prescription.rfNumber as number).toString()
            }
        });
    }

    reloadNotesForErrorAlert(dispenseErrorId: number) {
        const components = this.$refs.DispensingErrorAlertComponents as Array<DispensingErrorAlertComponent>;
        const component = components?.find((com: DispensingErrorAlertComponent) => com.value.id == dispenseErrorId);
        if (component)
            component.loadNotes();
        else
            console.error("Reference dont exist for the Dispense Error Alert component");
    }

    cancelPV2() {
        this.$bvModal.msgBoxConfirm("By Cancelling PV2, any previous PV2 done for this RX will cancelled and inventory will be put back to stock. Are you sure?", {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;

                const rxId = this.prescription.rxID;
                if (!rxId) return;

                Axios.get(`/Prescription/${rxId}/cancel-pv2/`)
                    .then(response => {
                        if (response.data.cancelled) {
                            this.prescription.pV2ID = null;
                            this.$notification(NotificationOptions.notificationOptionsPreset("PV2 was cancelled.", NotificationOptions.NotificationTypes.success));
                        } else
                            this.$notification(NotificationOptions.errorSaveNotificationPreset("PV2 cancellation"));
                    })
                    .catch(error => {
                        console.error("Error while cancelling PV2", { error, response: error?.response });
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("PV2 cancellation", error));
                    });
            })
            .catch(err => {
                console.error("Error caught on cancel PV2 button.", err);
            });
    }

    onClaimsLoaded(billedClaims: Claim[]) {
        let allOffline = true;
        for (const claim of billedClaims) {
            if (!claim.isOffline) {
                allOffline = false;
                break;
            }
        }
        this.allClaimsAreOffline = allOffline;
    }
}

